import { View, Text, Image } from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { useTrackEvents } from "../../../hooks/useTrackEvents";
import { TrackEventTypes } from "../../../utils/enums";
import { getPageNameByRoute } from "../../../utils/functions";
import { useLocation } from "react-router-dom";

const Details = (props) => {
  useEffect(() => {
    const isOpenArray = new Array(props.data?.contents.length);
    isOpenArray.fill(true, 0, 1);
    isOpenArray.fill(false, 1, props.data?.contents.length);
    setShowSection(isOpenArray);
  }, []);

  const [showSection, setShowSection] = useState([]);
  const trackEventsAdobeAnalytics = useTrackEvents();
  const location = useLocation();
  const updateToggle = (id) => {
    if (!showSection[id] && props?.benefitName) {
      trackEventsAdobeAnalytics({
        linkName: TrackEventTypes.VIEW_FAQ,
        linkPageName: getPageNameByRoute(location.pathname),
        benefitsName: [props?.benefitName],
      });
    }
    var temp = [...showSection];
    temp[id] = !temp[id];
    setShowSection([...temp]);
  };
  return (
    <View w="100%" mb="24px">
      <View>
        {props.data?.contents.map((section, index) => (
          <View borderBottomWidth="1px" borderBottomColor="#E5E5E5" w="100%">
            <View
              my="20px"
              w="100%"
              flexWrap="wrap"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <View>
                <Text
                  color="#303030"
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="20px"
                >
                  {section.title}
                </Text>
              </View>
              <View onClick={() => updateToggle(index)}>
                <View display={showSection[index] ? "flex" : "none"}>
                  <Image
                    source={{
                      uri:
                        process.env.REACT_APP_AWS_S3_BUCKET_URL +
                        "upVector.svg",
                    }}
                    width="16px"
                    height="16px"
                    alt="Alternate Text "
                  />
                </View>

                <View display={showSection[index] ? "none" : "flex"}>
                  <Image
                    source={{
                      uri:
                        process.env.REACT_APP_AWS_S3_BUCKET_URL +
                        "downVector.svg",
                    }}
                    width="16px"
                    height="16px"
                    alt="Alternate Text "
                  />
                </View>
              </View>
            </View>
            {
              <View
                w="100%"
                mb="20px"
                display={showSection[index] ? "flex" : "none"}
              >
                {section.paragraphs.map((point) => (
                  <View
                    flexDirection="row"
                    w="100%"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    mb="8px"
                  >
                    <View w="10%">
                      <Image
                        source={{
                          uri:
                            process.env.REACT_APP_AWS_S3_BUCKET_URL +
                            "Pointer.svg",
                        }}
                        width="28px"
                        height="28px"
                        alt="Alternate Text "
                      />
                    </View>
                    <View ml="4px" w="80%" mt="2px">
                      <Text
                        color="#303030"
                        fontWeight="600"
                        fontSize="12px"
                        lineHeight="20px"
                      >
                        {point}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            }
          </View>
        ))}
      </View>
    </View>
  );
};

export default Details;
