import { View, Text, Image, Progress } from "native-base";
import React from "react";
import { DECIMAL_PLACES } from "../../../utils/constants";
import moment from "moment";
import RewardIcon from "../../svg/halfCashBack";
import ProgressBar from "./ProgressBar/ProgressBar";
import { ProgressBarType } from "../../../utils/enums";

const UnlimitedCashBack = (props) => {
  const isSpend = props.data.benefit.qualifyingThresholdValue > 0;

  const isSpendTargetAchieved =
    props.data.benefit.qualifyingThresholdValue <=
    props.data.currentCycleTotalQulaifyingSpend;

  const isTransaction = props.data?.benefit?.minQualifyingTxnCount > 0;

  const netQualifyingTransactions =
    props.data?.benefitCycles[0]?.accountTransactions?.reduce(
      (acc, transaction) => {
        if (transaction.transactionNature === "DEBIT") {
          acc += 1;
        } else if (transaction.transactionNature === "CREDIT") {
          acc -= 1;
        }
        return acc;
      },
      0
    ) || 0;

  const isTransactionTargetAchieved =
    netQualifyingTransactions >= props.data?.benefit?.minQualifyingTxnCount;

  const isTargetAchieved =
    isSpend && isTransaction
      ? isSpendTargetAchieved && isTransactionTargetAchieved
      : isSpend
      ? isSpendTargetAchieved
      : isTransaction
      ? isTransactionTargetAchieved
      : true;

  const spendAmmount = props.data.benefit.qualifyingThresholdValue -
  props.data.currentCycleTotalQulaifyingSpend >= 0 ? props.data.benefit.qualifyingThresholdValue -
  props.data.currentCycleTotalQulaifyingSpend : 0;

  const transactionCount = props.data.benefit.minQualifyingTxnCount -
  netQualifyingTransactions >= 0 ? props.data.benefit.minQualifyingTxnCount -
  netQualifyingTransactions : 0

  return (
    <View w="100%" bgColor="#FFFFFF">
      <View
        mx="16px"
        py="16px"
        pb={isTransaction || isSpend ? "0" : "16px"}
        borderBottomWidth={
          isTargetAchieved
            ? "1px"
            : "0px"
        }
        borderBottomColor="#B1B5BB"
        borderBottomStyle="dotted"
        alignItems="center"
        justifyContent={
          isTargetAchieved
            ? "flex-start"
            : "center"
        }
        flexDirection={
          isTargetAchieved
            ? "row"
            : "column"
        }
      >
        {isTargetAchieved ? (
          <View w="90%" flexDirection="row" alignItems="center">
            <View mr="12px" p="8px">
              {
                <RewardIcon
                  percent={
                    1 -
                    props.data.currentCycleCashbackAccrued /
                      props.data.benefit.maxBenefitValue
                  }
                  color={props.data.benefit.color}
                  size="38px"
                />
              }
            </View>
            <View w="80%">
              <Text
                fontFamily="Quicksand"
                color="#303030"
                fontWeight="700"
                fontSize="18px"
                lineHeight="30px"
              >
                {`₹${props.data.currentCycleCashbackAccrued
                  .toFixed(DECIMAL_PLACES)
                  .toLocaleString("en-IN")} Cashback earned`}
              </Text>
              <Text
                fontFamily="Quicksand"
                color="#303030"
                fontWeight="500"
                fontSize="12px"
                lineHeight="14px"
                mt="4px"
              >
                {props.data.currentCycleCashbackAccrued === 0
                  ? ""
                  : props.data.currentCycleCashbackAccrued ===
                    props.data.benefit.maxBenefitValue
                  ? "Your cashback will be credited post completion of cycle"
                  : `in current cycle ${moment(
                      props.data.currentCycleStartDate
                    ).format("DD MMM")} to ${moment(
                      props.data.currentCycleEndDate
                    ).format("DD MMM")}`}
              </Text>
            </View>
          </View>
        ) : (
          <>
            {isSpend && isTransaction ? (
              <Text
                fontFamily="Quicksand"
                color="#303030"
                fontWeight="700"
                fontSize="24px"
                lineHeight="32px"
              >
                {
                  `${!isTransactionTargetAchieved? `Do ${transactionCount} more transactions`:""}${!isSpendTargetAchieved&&!isTransactionTargetAchieved?` and `:""}${!isSpendTargetAchieved?`Spend of ₹${spendAmmount} more`:""} to unlock your cashback`
                }
              </Text>
            ) : isTransaction ? (
              <Text
                fontFamily="Quicksand"
                color="#303030"
                fontWeight="700"
                fontSize="24px"
                lineHeight="32px"
                textAlign={"start"}
                width={"100%"}
              >
                Do{" "}
                {transactionCount}{" "}
                more transactions
              </Text>
            ) : (
              <View alignItems="center">
                <Text
                  fontFamily="Quicksand"
                  color="#303030"
                  fontWeight="700"
                  fontSize="24px"
                  lineHeight="32px"
                >
                  {`Spend ₹${(
                    props.data.benefit.qualifyingThresholdValue -
                    props.data.currentCycleTotalQulaifyingSpend
                  )
                    .toFixed(DECIMAL_PLACES)
                    .toLocaleString("en-IN")} more by ${moment(
                    props.data.currentCycleEndDate
                  ).format("DD MMM")}`}{" "}
                  to unlock your cashback
                </Text>
              </View>
            )}
            {isTransaction && (
              <View width={"100%"} mt={"10px"}>
                <ProgressBar
                  maxValue={props.data.benefit.minQualifyingTxnCount}
                  currentValue={netQualifyingTransactions}
                  color={props.data.benefit?.colorConfig?.transactionMilestoneColor}
                  type={ProgressBarType.TRANSACTION}
                />
              </View>
            )}
            {isTransaction && (
              <View
                width={"100%"}
                borderBottomWidth={"1px"}
                borderBottomColor="#D7DBE0"
                borderBottomStyle="dotted"
                mt={"16px"}
                mb={isSpend ? "16px" : "0px"}
              ></View>
            )}
            {isSpend && (
              <View width={"100%"} mt={isTransaction ? "0px" : "10px"}>
                <ProgressBar
                  maxValue={props.data.benefit.qualifyingThresholdValue}
                  currentValue={props.data.currentCycleTotalQulaifyingSpend}
                  color={props.data.benefit.color}
                  type={ProgressBarType.SPEND}
                />
              </View>
            )}
            {isSpend && !isTransaction && (
              <View
                width={"100%"}
                borderBottomWidth={"1px"}
                borderBottomColor="#D7DBE0"
                borderBottomStyle="dotted"
                mt={"16px"}
              ></View>
            )}
          </>
        )}
      </View>
      <View my="16px" px="16px">
        <Text
          fontFamily="Quicksand"
          color="#303030"
          fontWeight="600"
          fontSize="12px"
          lineHeight="20px"
        >
          {`Continue to enjoy this feature till`}
          <Text
            ml="4px"
            fontFamily="Quicksand"
            color="#303030"
            fontWeight="700"
            fontSize="12px"
            lineHeight="20px"
          >
            {moment(props.data.endDate).format("DD MMM 'YY")}
          </Text>
        </Text>
      </View>
    </View>
  );
};

export default UnlimitedCashBack;
