import React, { useState, useEffect } from "react";
import { Image, Text, View } from "native-base";
import { useSelector } from "react-redux";
import styles from "./styles";
import { Button } from "../../core";
import confetti from "../../../../assets/images/fortuneWheel/confetti.png";
import au_logo from "../../../../assets/images/fortuneWheel/au_logo.png";
import lit_banner from "../../../../assets/images/fortuneWheel/lit_banner.png";

const WheelShare = () => {
  const manageBenefits = useSelector((state) => state.manageBenefits);

  const [amount, setAmount] = useState(null);

  useEffect(() => {
    setAmount(manageBenefits.queryParams?.a);
  }, [manageBenefits.queryParams]);

  return (
    <View {...styles.container}>
      <Image {...styles.confetti} src={confetti} />
      <Image {...styles.auLogo} src={au_logo} />
      <Text {...styles.text1}>
        Wow!
        <br />
        Your friend has won
      </Text>
      {amount && <Text {...styles.amount}>₹{amount}</Text>}
      <Text {...styles.text2}>
        by playing AU Bank's
        <br />
        Spin the Wheel
      </Text>
      <Text {...styles.text3}>
        To get these benefits,
        <br />
        Apply now for
      </Text>
      <Text {...styles.text4}>LIT Credit Card</Text>
      <Text {...styles.text5}>India's 1st Customisable Card</Text>
      <Image {...styles.litBanner} src={lit_banner} />
      <Button
        {...styles.applyButton}
        text="Apply Now"
        onPress={() =>
          (window.location.href = process.env.REACT_APP_AU_ONBOARDING_URL)
        }
      />
    </View>
  );
};

export default WheelShare;
