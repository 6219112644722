import React from "react";

const Wheel = ({
	...otherProps
}) => {
	return (
		<svg
			width="23"
			height="23"
			viewBox="0 0 23 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...otherProps}
		>
			<ellipse cx="11.5" cy="12" rx="1.5" ry="1" fill="#383A48"/>
			<circle cx="11.5" cy="11.5" r="11.4" fill="#F37435" stroke="#383A48" stroke-width="0.2"/>
			<mask id="path-3-outside-1_67_31607" maskUnits="userSpaceOnUse" x="11" y="11" width="12" height="10" fill="black">
			<rect fill="white" x="11" y="11" width="12" height="10"/>
			<path d="M22 11.5C22 13.3435 21.5146 15.1545 20.5927 16.751C19.6708 18.3474 18.3448 19.6729 16.7481 20.5944L11.5 11.5H22Z"/>
			</mask>
			<path d="M22 11.5C22 13.3435 21.5146 15.1545 20.5927 16.751C19.6708 18.3474 18.3448 19.6729 16.7481 20.5944L11.5 11.5H22Z" fill="#D95289"/>
			<path d="M22 11.5C22 13.3435 21.5146 15.1545 20.5927 16.751C19.6708 18.3474 18.3448 19.6729 16.7481 20.5944L11.5 11.5H22Z" stroke="#383A48" stroke-width="0.4" mask="url(#path-3-outside-1_67_31607)"/>
			<mask id="path-4-outside-2_67_31607" maskUnits="userSpaceOnUse" x="11" y="2" width="12" height="10" fill="black">
			<rect fill="white" x="11" y="2" width="12" height="10"/>
			<path d="M22 11.5C22 9.65649 21.5146 7.84548 20.5927 6.24905C19.6708 4.65262 18.3448 3.32705 16.7481 2.40563L11.5 11.5H22Z"/>
			</mask>
			<path d="M22 11.5C22 9.65649 21.5146 7.84548 20.5927 6.24905C19.6708 4.65262 18.3448 3.32705 16.7481 2.40563L11.5 11.5H22Z" fill="#FFACCE"/>
			<path d="M22 11.5C22 9.65649 21.5146 7.84548 20.5927 6.24905C19.6708 4.65262 18.3448 3.32705 16.7481 2.40563L11.5 11.5H22Z" stroke="#383A48" stroke-width="0.4" mask="url(#path-4-outside-2_67_31607)"/>
			<mask id="path-5-outside-3_67_31607" maskUnits="userSpaceOnUse" x="5" y="11" width="13" height="12" fill="black">
			<rect fill="white" x="5" y="11" width="13" height="12"/>
			<path d="M16.748 20.5944C15.1513 21.5158 13.3401 22.0006 11.4966 22C9.65312 21.9994 7.84226 21.5135 6.24613 20.591L11.5 11.5L16.748 20.5944Z"/>
			</mask>
			<path d="M16.748 20.5944C15.1513 21.5158 13.3401 22.0006 11.4966 22C9.65312 21.9994 7.84226 21.5135 6.24613 20.591L11.5 11.5L16.748 20.5944Z" fill="#E0719E"/>
			<path d="M16.748 20.5944C15.1513 21.5158 13.3401 22.0006 11.4966 22C9.65312 21.9994 7.84226 21.5135 6.24613 20.591L11.5 11.5L16.748 20.5944Z" stroke="#383A48" stroke-width="0.4" mask="url(#path-5-outside-3_67_31607)"/>
			<mask id="path-6-outside-4_67_31607" maskUnits="userSpaceOnUse" x="5" y="0" width="13" height="12" fill="black">
			<rect fill="white" x="5" width="13" height="12"/>
			<path d="M16.748 2.4056C15.1513 1.48419 13.3401 0.999409 11.4966 1C9.65312 1.00059 7.84226 1.48654 6.24613 2.40897L11.5 11.5L16.748 2.4056Z"/>
			</mask>
			<path d="M16.748 2.4056C15.1513 1.48419 13.3401 0.999409 11.4966 1C9.65312 1.00059 7.84226 1.48654 6.24613 2.40897L11.5 11.5L16.748 2.4056Z" fill="#E0719E"/>
			<path d="M16.748 2.4056C15.1513 1.48419 13.3401 0.999409 11.4966 1C9.65312 1.00059 7.84226 1.48654 6.24613 2.40897L11.5 11.5L16.748 2.4056Z" stroke="#383A48" stroke-width="0.4" mask="url(#path-6-outside-4_67_31607)"/>
			<mask id="path-7-outside-5_67_31607" maskUnits="userSpaceOnUse" x="0" y="11" width="12" height="10" fill="black">
			<rect fill="white" y="11" width="12" height="10"/>
			<path d="M6.24663 20.5913C4.65045 19.669 3.32524 18.3426 2.40424 16.7457C1.48325 15.1487 0.998935 13.3374 1 11.4939L11.5 11.5L6.24663 20.5913Z"/>
			</mask>
			<path d="M6.24663 20.5913C4.65045 19.669 3.32524 18.3426 2.40424 16.7457C1.48325 15.1487 0.998935 13.3374 1 11.4939L11.5 11.5L6.24663 20.5913Z" fill="#FFACCE"/>
			<path d="M6.24663 20.5913C4.65045 19.669 3.32524 18.3426 2.40424 16.7457C1.48325 15.1487 0.998935 13.3374 1 11.4939L11.5 11.5L6.24663 20.5913Z" stroke="#383A48" stroke-width="0.4" mask="url(#path-7-outside-5_67_31607)"/>
			<mask id="path-8-outside-6_67_31607" maskUnits="userSpaceOnUse" x="0" y="2" width="12" height="10" fill="black">
			<rect fill="white" y="2" width="12" height="10"/>
			<path d="M6.24663 2.40868C4.65045 3.33102 3.32524 4.65736 2.40424 6.25432C1.48325 7.85128 0.998935 9.66257 1 11.5061L11.5 11.5L6.24663 2.40868Z"/>
			</mask>
			<path d="M6.24663 2.40868C4.65045 3.33102 3.32524 4.65736 2.40424 6.25432C1.48325 7.85128 0.998935 9.66257 1 11.5061L11.5 11.5L6.24663 2.40868Z" fill="#D95289"/>
			<path d="M6.24663 2.40868C4.65045 3.33102 3.32524 4.65736 2.40424 6.25432C1.48325 7.85128 0.998935 9.66257 1 11.5061L11.5 11.5L6.24663 2.40868Z" stroke="#383A48" stroke-width="0.4" mask="url(#path-8-outside-6_67_31607)"/>
			<g filter="url(#filter0_d_67_31607)">
			<path d="M12 13L12.866 11.5H11.134L12 13Z" fill="#6D276D"/>
			</g>
			<circle cx="12" cy="11" r="0.75" fill="#F37435" stroke="#6D276D" stroke-width="0.5"/>
			<defs>
			<filter id="filter0_d_67_31607" x="7.13397" y="10.5" width="9.73206" height="9.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dy="3"/>
			<feGaussianBlur stdDeviation="2"/>
			<feComposite in2="hardAlpha" operator="out"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_67_31607"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_67_31607" result="shape"/>
			</filter>
			</defs>
		</svg>
  );
};

export default Wheel;
