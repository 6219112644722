import React from "react";
import { Text, Button as BaseButton } from "native-base";

export const Button = ({
	text,
	textProps,
	children,
	...otherProps
}) => {
  return (
    <BaseButton
			alignItems="center"
			justifyContent="center"
			px="24px"
			py="10px"
			bgColor="#F37435"
			boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
			borderRadius="4px"
			width="fit-content"
			height="fit-content"
			{...otherProps}
    >
			{children ||
				<Text
					color="#fff"
					fontFamily="Quicksand"
					fontSize="16px"
					fontWeight={700}
					{...textProps}
				>
					{text}
				</Text>
			}
    </BaseButton>
  );
};
