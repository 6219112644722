import { Text, View } from "native-base";
import React from "react";
import { useWindowDimensions } from "react-native-web";
import { useNavigate } from "react-router-dom";
import styles from "./styles";
import { Button } from "../../core";
import BackArrow2 from "../../../../assets/images/BackArrow2";
import SadEmoji from "../../../../assets/images/SadEmoji";
import { getHomeScreenPath } from "../../../../utils/functions";

const TryAgain = ({ hasMoreSpins, setShowTryAgain }) => {
  const dimensions = useWindowDimensions();
  const navigate = useNavigate();

  const onTryAgainSubmit = () => {
    if (hasMoreSpins) {
      setShowTryAgain(false);
    } else {
      navigate(getHomeScreenPath());
    }
  };

  return (
    <View bgColor="#FFDBD4" height="100%" overflowY="auto">
      <View {...styles.mainContainer} height={dimensions.height}>
        <View {...styles.headerContainer}>
          <View
            {...styles.backButtonContainer}
            onClick={() => navigate(getHomeScreenPath())}
          >
            <BackArrow2 {...styles.backButton} />
          </View>
          <Text {...styles.titleText}>Spin the Wheel</Text>
          <View></View>
        </View>
        <View {...styles.emojiContainer}>
          <View {...styles.emojiBackground}>
            <SadEmoji />
          </View>
        </View>
        <View {...styles.title2Container}>
          <Text {...styles.text2}>
            {hasMoreSpins ? "Better Luck" : "Spend More"}
          </Text>
          <Text {...styles.text2}>
            {hasMoreSpins ? "Next Time!" : "To Get More Surprises"}
          </Text>
        </View>
        <View flex="1"></View>
        <View {...styles.footerContainer}>
          <Button
            {...styles.submitButton}
            textProps={{ ...styles.submitButtonText }}
            text={hasMoreSpins ? "Get One More Chance" : "Manage Features"}
            onPress={onTryAgainSubmit}
          ></Button>
        </View>
      </View>
    </View>
  );
};

export default TryAgain;
