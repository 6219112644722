import React, { useEffect, useState } from "react";
import {
  Image,
  Text,
  View,
  Modal,
  useToast,
  ChevronRightIcon,
} from "native-base";
import { SummaryCard } from "../Components/BenefitsCards";
import { useLocation, useNavigate } from "react-router-dom";
import {
  benefitNameWithId,
  consoleError,
  getPageNameByRoute,
} from "../../../utils/functions";
import ManageBenefitService from "../../../services/ManageBenefitService";
import { useDispatch, useSelector } from "react-redux";

import {
  setBenefitsAddition,
  setIsTermsAccepted,
  setLoungeCount,
} from "../../../store/actions/ManageBenefits";
import { useWindowDimensions } from "react-native-web";
import { showToast1 } from "../../core/toast";
import Header from "../Components/Header";
import moment from "moment";
import CustomLoader from "../../core/loader";
import queryString from "query-string";
import { logger } from "@sentry/utils";
import { ProgramConfigs } from "../../../utils/constants";
import { setOtpState } from "../../../store/actions/Otp";
import { ErrorReasons, TrackEventTypes } from "../../../utils/enums";
import useNavigateToPath from "../../../hooks/useNavigateToPath";
import successIcon from "../../../assets/images/SuccessIcon.svg";
import { useTrackEvents } from "../../../hooks/useTrackEvents";

const Checkout = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [open, setOpen] = useState(false);
  const [ButtonLoading, setButtonLoading] = useState(false);
  const [pageProcessing, setPageProcessing] = useState(false);
  const [selectedItems, setSelectedItems] = useState([""]);
  const [selectedItemsPrice, setSelectedItemsPrice] = useState([""]);
  const manageBenefits = useSelector((state) => state.manageBenefits);
  const programConfigsData = useSelector((state) => state.programConfigs.data);
  const otpStore = useSelector((state) => state.otpStore);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const nativeToast = useToast();
  const windowDimensions = useWindowDimensions();
  const navigateToPath = useNavigateToPath();
  const trackEventsAdobeAnalytics = useTrackEvents();
  useEffect(() => {
    setSelectedItems(manageBenefits.benefitsList);
    setTermsAccepted(manageBenefits.termsAccepted);
  }, []);

  const goBack = () => {
    const manageBenefitsPage = "/Features/ManageFeatures";
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.LEFT_ARROW_CLICK,
      benefitsName: manageBenefits.benefitsList.map((benefit) => {
        return benefitNameWithId(benefit?.title, benefit?.id);
      }),
      walletBalance: manageBenefits?.walletDetails?.totalBalanceAmount,
      linkPageName: getPageNameByRoute(location.pathname),
      previousPageName: getPageNameByRoute(location.pathname),
      pageName: getPageNameByRoute(manageBenefitsPage),
    });
    dispatch(setIsTermsAccepted({ termsAccepted: false }));
    navigateToPath(manageBenefitsPage);
  };

  useEffect(() => {
    if (manageBenefits.benefitsList.length === 0) {
      dispatch(
        setBenefitsAddition({
          benefits: [],
          benefitsListId: [],
          benefitsGroupId: [],
        })
      );
      dispatch(
        setLoungeCount({
          benefitsGroupIdCount: 0,
          benefitsListIdCount: {},
        })
      );
      navigateToPath("/Features/ManageFeatures");
    } else {
      var tempSum = 0;

      for (var i = 0; i < manageBenefits.benefitsList.length; i++) {
        tempSum = tempSum + manageBenefits.benefitsList[i].price;
      }
      setSelectedItemsPrice(tempSum);
    }
  }, [manageBenefits.benefitsList]);

  const theme = {
    fontFamily: "Inter",
    color1: "#0D286C",
    color2: "#53B7ED",
    color3: "#F8F8F8",
    toastColor: "#FFFFFF",
    toastBgColor: "#303030",
  };
  const toast = (message) => {
    showToast1({ nativeToast, theme, message });
  };

  const activateBenefits = async () => {
    let accountId = null;
    if (!ButtonLoading) {
      try {
        const queryParams = queryString.parse(location.search);
        accountId = queryParams.accountId || localStorage.getItem("cardId");
        setButtonLoading(true);
        setPageProcessing(true);
        const data = [];
        for (var i = 0; i < manageBenefits.benefitsList.length; i++) {
          data.push(manageBenefits.benefitsList[i].id);
        }
        let activateBenefitsData = {};
        activateBenefitsData.benefits = manageBenefits.benefitsListId;
        if (programConfigsData?.benefitActivationAuthConfig?.isEnabled) {
          activateBenefitsData.mobileVerificationRefId =
            location?.state?.mobileVerificationRefId;
        }
        const response = await ManageBenefitService.activateBenefits(
          activateBenefitsData,
          manageBenefits.cardId
        );
        if (response.status === 200) {
          dispatch(setIsTermsAccepted({ termsAccepted: false }));
          setOpen(true);
          setPageProcessing(false);
          if (location?.state?.otpVerified) {
            trackEventsAdobeAnalytics({
              linkName: TrackEventTypes.OTP_BENEFIT_ACTIVATION,
              otpSubmitted: "y",
            });
          } else {
            trackEventsAdobeAnalytics({
              linkName: TrackEventTypes.WITHOUT_OTP_BENEFIT_ACTIVATION,
              linkPageName: getPageNameByRoute(location.pathname),
            });
          }
        } else if (
          response.status === 409 &&
          response?.data?.reason === ErrorReasons.SAME_BENEEFIT_GROUP
        ) {
          logger.error({
            message: `An error occurred while activate Benefits for accountId ${accountId}`,
            responseData: response.data,
            accountId: accountId,
            correlationId: response.headers.x_correlation_id,
          });
          const isOtpVerified = !(
            programConfigsData?.benefitActivationAuthConfig?.isEnabled ===
              false ||
            programConfigsData?.benefitActivationAuthConfig?.featureCount >
              manageBenefits?.benefitsList?.length
          );
          navigate("/Features/SameGroupError", {
            replace: true,
            state: { isOtpVerified },
          });
          setPageProcessing(false);
        } else {
          logger.error({
            message: `An error occurred while activate Benefits for accountId ${accountId}`,
            responseData: response.data,
            accountId: accountId,
            correlationId: response.headers.x_correlation_id,
          });
          toast("Sorry, Failed to subscribe all benefits!!");
          setPageProcessing(false);
          navigateToPath("/Features/ManageFeatures");
        }
      } catch (error) {
        logger.error({
          message: `An exception occurred while activate Benefits for accountId ${accountId}`,
          error: error.message,
          accountId: accountId,
          stackTrace: error.stack,
        });
        consoleError(error);
        setPageProcessing(false);
      }
    }
  };

  //to check if otp authentication is needed or not if needed then redirect to otp route
  const handleActivateFeatures = async () => {
    if (
      programConfigsData?.benefitActivationAuthConfig?.isEnabled === false ||
      programConfigsData?.benefitActivationAuthConfig?.featureCount >
        manageBenefits?.benefitsList?.length ||
      location?.state?.otpVerified
    ) {
      activateBenefits();
    } else if (
      programConfigsData?.benefitActivationAuthConfig?.isEnabled &&
      programConfigsData?.benefitActivationAuthConfig?.featureCount <=
        manageBenefits.benefitsList.length
    ) {
      const queryParams = queryString.parse(location.search);
      let accountId = queryParams.accountId || localStorage.getItem("cardId");
      try {
        const response = await ManageBenefitService.generateOtp({
          accountId: accountId,
          scope: "BENEFIT_ACTIVATION_AUTH",
          benefitIds: manageBenefits.benefitsListId,
        });
        if (response.status === 200) {
          dispatch(
            setOtpState({
              onSuccess: onOtpSuccess,
              onError: onOtpError,
              onCancel: onOtpCancel,
              Scope: "BENEFIT_ACTIVATION_AUTH",
              reason: "OTP Authentication - Feature Activation Flow",
              mobileNumber: response.data.maskedMobileNumber,
              mobileVerificationRefId: response.data.mobileVerificationRefId,
            })
          );
          navigate("/Features/ManageFeatures/OTP", {
            state: { requestingPage: "checkout" },
          });
          trackEventsAdobeAnalytics({
            linkName: TrackEventTypes.ACTIVATE_FEATURE,
            benefitsName: manageBenefits.benefitsList.map((benefit) => {
              return benefitNameWithId(benefit?.title, benefit?.id);
            }),
            walletBalance: manageBenefits?.walletDetails?.totalBalanceAmount,
            benefitsCount: manageBenefits?.benefitsList?.length,
            otpRequested: "y",
          });
        } else {
          logger.error({
            message: `An error occurred while generateOtp for accountId ${accountId}`,
            responseData: response.data,
            accountId: accountId,
            correlationId: response.headers.x_correlation_id,
          });
          navigate("/Features/GenericError", {
            state: {
              x_correlation_id: response.headers.x_correlation_id,
            },
          });
        }
      } catch (err) {
        logger.error({
          message: `An exception occurred while generateOtp for accountId ${accountId}`,
          error: err.message,
          accountId: accountId,
          stackTrace: err.stack,
        });

        console.log(err);
        navigate("/Features/GenericError");
      }
    }
  };

  const onOtpSuccess = (mobileVerificationRefId) => {
    navigate("/Features/ManageFeatures/Checkout", {
      state: {
        otpVerified: true,
        mobileVerificationRefId: mobileVerificationRefId,
      },
      replace: true,
    });
  };

  const onOtpError = (_error) => {
    navigate("/Features/GenericError");
  };

  const onOtpCancel = () => {
    navigate(-1);
    const otpPageRoute = "/Features/ManageFeatures/OTP"
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.CANCEL_OTP_PAGE,
      pageName: getPageNameByRoute(location.pathname),
      previousPageName: getPageNameByRoute(otpPageRoute),
      linkPageName: getPageNameByRoute(otpPageRoute),
    })
  };

  //If OTP has been verified then automatically it should add activate the benifits
  useEffect(() => {
    if (location?.state?.otpVerified) {
      setTermsAccepted(true);
      handleActivateFeatures();
    }
  }, []);

  const handleActivate = () => {
    if (termsAccepted) {
      handleActivateFeatures();

      const otpRequested =
        programConfigsData?.benefitActivationAuthConfig?.isEnabled &&
        programConfigsData?.benefitActivationAuthConfig?.featureCount <=
          manageBenefits.benefitsList.length

      if(!otpRequested) {
        trackEventsAdobeAnalytics({
          linkName: TrackEventTypes.ACTIVATE_FEATURE,
          benefitsName: manageBenefits.benefitsList.map((benefit) => {
            return benefitNameWithId(benefit?.title, benefit?.id);
          }),
          walletBalance: manageBenefits?.walletDetails?.totalBalanceAmount,
          benefitsCount: manageBenefits?.benefitsList?.length,
          linkPageName: getPageNameByRoute(location.pathname)
        });
      }
    }
  };

  const handleTncCheckbox = () => {
    if (!termsAccepted) {
      trackEventsAdobeAnalytics({
        linkName: TrackEventTypes.TNC_CHECKBOX,
        linkPageName: getPageNameByRoute(location.pathname),
      });
    }
    setTermsAccepted(!termsAccepted);
  };

  const handleTncRedirection = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.TNC_REDIRECTION,
      linkPageName: getPageNameByRoute(location.pathname),
    });
    dispatch(setIsTermsAccepted({ termsAccepted: termsAccepted }));
    window.open(programConfigsData?.tncConfig?.redirectionLink);
  };

  const handleSuccessfulModalClose = () => {
      setOpen(false);
      dispatch(
        setBenefitsAddition({
          benefits: [],
          benefitsListId: [],
          benefitsGroupId: [],
        })
      );
      dispatch(
        setLoungeCount({
          benefitsGroupIdCount: 0,
          benefitsListIdCount: {},
        })
      );
      dispatch(setIsTermsAccepted({ termsAccepted: false }));
      navigateToPath("/Features/ManageFeatures");
      trackEventsAdobeAnalytics({
        linkName: TrackEventTypes.EXIT_SUCCESSFUL_POPUP,
      })
  }

  return (
    <View alignItems="center" justifyContent="center">
      <View _web={{ maxW: "412px" }} width="100%" bgColor={"#FFFFFF"}>
        {
          <>
            <Header page="Checkout" />
          </>
        }
        <View
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          p="16px"
          shadow="1"
        >
          <View flexDirection="row" alignItems="center" w="100%">
            <View
              mr="8px"
              alignItems="center"
              cursor="pointer"
              onClick={() => (!ButtonLoading ? goBack() : null)}
            >
              <Image
                source={{
                  uri:
                    process.env.REACT_APP_AWS_S3_BUCKET_URL + "backArrow.svg",
                }}
                height="24px"
                width="24px"
                alt=""
              />
            </View>
            <Text
              color="#303030"
              fontWeight="700"
              fontFamily="Quicksand"
              fontSize="18px"
              lineHeight="26px"
            >
              Summary
            </Text>
          </View>
          <View cursor="pointer">
            {/* <Close size="24" color="#6D3078" /> */}
          </View>
        </View>

        <View>
          <Modal
            isOpen={open}
            onClose={handleSuccessfulModalClose}
            mt={12}
          >
            <Modal.Content maxWidth="550">
              <Modal.CloseButton />

              <Modal.Body bgColor="#C9E5BF">
                <View bgColor="#C9E5BF" px="24px" py="40px">
                  <View w="100%" alignItems={"center"}>
                    <Image
                      source={{
                        uri:
                          process.env.REACT_APP_AWS_S3_BUCKET_URL +
                          "benefitActivateSuccessfull.png",
                      }}
                      height="100px"
                      width="195px"
                      alt=""
                    />
                  </View>
                  <View alignItems="center" mt="24px">
                    <Text
                      color="#303030"
                      fontWeight="700"
                      fontFamily="Quicksand"
                      fontSize="24px"
                      lineHeight="32px"
                    >
                      Congratulations!
                    </Text>
                    <Text
                      color="#303030"
                      fontWeight="500"
                      fontFamily="Quicksand"
                      fontSize="14px"
                      lineHeight="22px"
                    >
                      Your feature(s) have been activated
                    </Text>
                  </View>
                </View>
              </Modal.Body>
            </Modal.Content>
          </Modal>
          {manageBenefits?.walletDetails?.totalBalanceAmount >= 1 &&
            manageBenefits?.walletDetails?.expiryOn >=
              moment().format("YYYY-MM-DD") && (
              <View
                mx="16px"
                mt="12px"
                p="12px"
                borderRadius={"4px"}
                shadow="1"
                borderWidth={"1px"}
                borderColor="#CCCCCC"
                alignItems={"center"}
                justifyContent="space-between"
                flexDirection={"row"}
                cursor="pointer"
                onClick={() =>
                  navigate("/Features/ManageFeatures/WalletDisclaimer")
                }
              >
                <View>
                  <View>
                    <Text
                      color="#303030"
                      fontWeight="500"
                      fontFamily="Quicksand"
                      fontSize="12px"
                      lineHeight="24px"
                    >
                      {
                        ProgramConfigs[localStorage.getItem("program")]
                          .walletTitle
                      }
                    </Text>
                  </View>
                  <View>
                    <Text
                      color="#303030"
                      fontWeight="700"
                      fontFamily="Quicksand"
                      fontSize="20px"
                      lineHeight="24px"
                    >
                      {`₹${manageBenefits.walletDetails.totalBalanceAmount}`}
                    </Text>
                  </View>
                  <View>
                    <Text
                      color="#303030"
                      fontWeight="500"
                      fontFamily="Quicksand"
                      fontSize="12px"
                      lineHeight="24px"
                    >
                      {`valid till ${moment(
                        manageBenefits.walletDetails?.expiryOn
                      ).format("DD MMM YYYY")}`}
                    </Text>
                  </View>
                </View>
                <View>
                  <View>
                    <Image
                      source={{
                        uri:
                          process.env.REACT_APP_AWS_S3_BUCKET_URL +
                          "Wallet.svg",
                      }}
                      height="40px"
                      width="40px"
                      alt=""
                    />
                  </View>
                  <View alignItems={"center"}>
                    <ChevronRightIcon cursor="pointer" size="20px" />
                  </View>
                </View>
              </View>
            )}
          {!pageProcessing ? (
            <View mb="140px" mx="16px">
              <View>
                {manageBenefits.benefitsList.map((item, index) => (
                  <View mt="12px" key={index}>
                    <SummaryCard
                      id={item.id}
                      data={item}
                      disclaimerText="*Rewards will be credited post statement generation"
                    />
                  </View>
                ))}
              </View>

              {manageBenefits?.walletDetails?.totalBalanceAmount >= 1 &&
                manageBenefits?.walletDetails?.expiryOn >=
                  moment().format("YYYY-MM-DD") && (
                  <View
                    mt={"24px"}
                    p="12px"
                    borderRadius={"6px"}
                    backgroundColor="#D4F6DE"
                    flexDirection={"row"}
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                    _web={{ maxW: "412px" }}
                  >
                    <View w="10%">
                      <Image
                        source={{ uri: successIcon }}
                        height="24px"
                        width="24px"
                        alt=""
                      />
                    </View>
                    <View w="90%">
                      <Text
                        color="#303030"
                        fontWeight="500"
                        fontFamily="Quicksand"
                        fontSize="12px"
                        lineHeight="20px"
                      >
                        <Text fontWeight="700">
                          {`₹${
                            manageBenefits.walletDetails.totalBalanceAmount >
                            selectedItemsPrice
                              ? selectedItemsPrice
                              : manageBenefits.walletDetails.totalBalanceAmount
                          } `}
                        </Text>
                        {`from wallet balance will be instantly credited as cashback to your card account on feature activation.`}
                      </Text>
                    </View>
                  </View>
                )}

              {selectedItems.length !== 0 && (
                <View
                  width="100%"
                  mt="24px"
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  alignSelf="center"
                  bgColor="#FEF2F0"
                  p="8px"
                  mb="20%"
                  borderRadius="4px"
                >
                  <View cursor="pointer" onClick={handleTncCheckbox}>
                    {/* <Checkbox
                value="TC"
                id="1"
                name="TC"
                accessibilityLabel="Terms"
                isChecked={termsAccepted}
                onChange={(isSelected) => setTermsAccepted(isSelected)}
                _checked={{ bgColor: "none" }}
              /> */}

                    <View display={termsAccepted ? "none" : "flex"}>
                      <Image
                        source={{
                          uri:
                            process.env.REACT_APP_AWS_S3_BUCKET_URL +
                            "UnTickBox.svg",
                        }}
                        height="24px"
                        width="24px"
                        alt=""
                      />
                    </View>

                    <View display={termsAccepted ? "flex" : "none"}>
                      <Image
                        source={{
                          uri:
                            process.env.REACT_APP_AWS_S3_BUCKET_URL +
                            "TickBox.svg",
                        }}
                        height="24px"
                        width="24px"
                        alt=""
                      />
                    </View>
                  </View>
                  <View ml="12px" w="90%">
                    <Text color="#000">
                      By ticking the box, I confirm to accept the{" "}
                      <Text
                        onPress={handleTncRedirection}
                        fontWeight="700"
                        textDecoration="underline"
                      >
                        Terms & Conditions
                      </Text>
                    </Text>
                  </View>
                </View>
              )}
            </View>
          ) : (
            <View
              w="100%"
              _web={{ maxW: "412px" }}
              alignItems="center"
              justifyContent="center"
              bgColor={"#FFFFFF"}
              mt="40%"
            >
              <View>
                <CustomLoader color={"#F37435"} width={120} height={120} />
              </View>
              <View mt="12px">
                <Text
                  color="#303030"
                  fontWeight="500"
                  fontFamily="Quicksand"
                  fontSize="12px"
                  lineHeight="24px"
                >
                  Activating your selected benefits. Please wait...
                </Text>
              </View>
            </View>
          )}
          <View
            alignItems="center"
            justifyContent="center"
            position="fixed"
            width="100%"
            _web={{ maxW: "412px" }}
            bottom="0px"
          >
            {
              <View width={"100%"}>
                <View
                  bgColor="#FFFFFF"
                  opacity={ButtonLoading ? "0.4" : "1"}
                  width="100%"
                  pt="16px"
                  mx="16px"
                  pb="36px"
                  alignItems="center"
                  justifyContent="space-between"
                  flexDirection="row"
                  borderTopWidth="1px"
                  borderTopColor="#EBEDF0"
                >
                  <View w="40%">
                    <View>
                      <Text
                        color="#303030"
                        fontWeight="700"
                        fontFamily="Quicksand"
                        fontSize="20px"
                        lineHeight="24px"
                        display="flex"
                      >
                        {`₹${selectedItemsPrice} `}
                        <Text fontSize="10px" fontWeight="600" color="#94A0B4">
                          (GST Payable)
                        </Text>
                      </Text>
                    </View>
                    <View>
                      <Text
                        color="#303030"
                        fontWeight="500"
                        fontFamily="Quicksand"
                        fontSize="12px"
                        lineHeight="20px"
                      >{`${manageBenefits.benefitsList.length} Feature(s) selected`}</Text>
                    </View>
                  </View>
                  <View w="60%" alignItems="center" justifyContent="center">
                    <View
                      px="30px"
                      py="10px"
                      bgColor={termsAccepted ? "#F37435" : "#D7DBE0"}
                      alignItems="center"
                      justifyContent="center"
                      cursor="pointer"
                      onClick={handleActivate}
                    >
                      <Text
                        color="#FFFFFF"
                        fontWeight="700"
                        fontFamily="Quicksand"
                        fontSize="14px"
                        lineHeight="22px"
                      >
                        Activate Features
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            }
          </View>
        </View>
      </View>
    </View>
  );
};

export default Checkout;
