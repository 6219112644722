const baseText = {
	fontFamily: "Quicksand",
	fontWeight: 700,
	fontSize: "26px",
	color: "#303030",
};

const styles = {
	mainContainer: {
		h: "100%",
		display: "flex",
		flexDirection: "column"
	},
	headerContainer: {
		mt: "20px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between"
	},
	backButtonContainer: {
		cursor: "pointer",
		ml: "15px",
		mt: "1px"
	},
	backButton: {
		fill: "#303030"
	},
	titleText: {
		...baseText,
		fontSize: "18px",
	},
	title2Container: {
		mt: "30px",
		alignItems: "center",
	},
	volumeContainer: {
		cursor: "pointer",
		mr: "20px",
		mt: "3px"
	},
	volumeMute: {
		marginTop: "-5px",
	},
	text2: {
		...baseText,
	},
	text3: {
		...baseText,
		color: "#6D276D"
	},
	wheelContainer: {
		alignItems: "center",
		mt: "10px"
	},
	wheelPointer: {
		width: "50px",
		height: "50px",
		marginBottom: "10px"
	},
	footerContainer: {
		alignItems: "center",
		mb: "20px"
	},
	attemptsLeftsText: {
		...baseText,
		fontSize: "16px",
		color: "#2B2B2B",
		mb: "10px"
	},
	submitButton: {
		width: "200px"
	},
	footerText: {
		...baseText,
		mt: "20px",
		fontSize: "16px",
		fontWeight: 500
	},
	footerText2: {
		...baseText,
		fontSize: "16px",
		color: "#353535"
	},
};

export default styles;
