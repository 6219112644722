import AccRewardsCarousel from "../assets/images/AccRewardsCarousel.svg";
import CategoryBenefitsCarousel from "../assets/images/CategoryBenefitsCarousel.svg";
import LoungeAccessCarousel from "../assets/images/LoungeAccessCarousel.svg";
import MembershipsCarousel from "../assets/images/MembershipsCarousel.svg";
import MilestoneBenefitsCarousel from "../assets/images/MilestoneBenefitsCarousel.svg";
export const FLOAT_REGEX = /^[0-9]*\.?[0-9]*$/;
export const PHYSICAL_CARD_ACTIVATION_PATH = "PhysicalCardActivation";
export const DECIMAL_PLACES = 2;
export const AU0101_DEEP_LINK =
  "https://aubank.onelink.me/oBF3?pid=deep%20link&af_force_deeplink=true&c=deep%20link&is_retargeting=true&deep_link_value=ANCC&af_dp=aufmobile%3A%2F%2Faufmobile&af_web_dp=https%3A%2F%2Fwww.aubank.in%2Fpersonal-banking%2Fdigital-banking%2Fau-0101-app";

export const ProgramTypes = Object.freeze({
  CREDIT_CARD: "CREDIT_CARD",
  PAY_LATER: "PAY_LATER",
  OTHER: "OTHER",
});

export const ProgramConfigs = Object.freeze({
  "ABC-FLEX": {
    siteHeader: "ABC Flex",
    loginUrl: "/Features/ManageFeatures/abc-flex/Login",
    loginHeader: "Build Your Credit Card",
    loginBanner: "ABCLLoginBanner",
    loginHeroTitle:
      "Login to your ABC FLEX Credit Card & customise it as per your needs",
    loginCarousel: [
      {
        image: CategoryBenefitsCarousel,
        Title: "Category Benefits",
        subTitle: "Get 5% cashback on Dining & Travel spends",
      },
      {
        image: LoungeAccessCarousel,
        Title: "Lounge Access",
        subTitle: "Fly comfortably with domestic airport lounge access",
      },
      {
        image: MembershipsCarousel,
        Title: "Memberships",
        subTitle: "Enjoy exciting memberships",
      },
      {
        image: MilestoneBenefitsCarousel,
        Title: "Milestone Benefits",
        subTitle: "Get additional 2%/5% cashback on all spends",
      },
    ],
    pageHeader: "ABC Flex Credit Card",
    walletTitle: "Credit Card Wallet Balance",
    walletTermsHeader: "ABC Flex Credit Card",
    aboutWalletContent: {
      title: "About Wallet",
      paragraphs: [
        "From time to time, AU Small Finance Bank Ltd. (“AU”) shall credit complimentary funds to your wallet. These funds can only be used to purchase features on your Aditya Birla Finance AU ABC FLEX or Aditya Birla Finance AU ABC NXT Credit Cards.",
        "Funds shall have an expiry date and will be forfeited if not utilised by the cardholder before expiry.",
      ],
    },

    walletContent: {
      contents: [
        {
          title: "How to use Wallet to purchase features",
          paragraphs: [
            "Assume that Rs. 200 is credited to your Aditya Birla Finance AU Credit Card Wallet and are scheduled to be expired on 31st December 2022.",
            "In a scenario where cost of all features selected is Rs.298, customer is first charged with Rs. 298 + GST amount of Rs. 53.64 and then receives an instant Cashback of Rs. 200 from his wallet balance (Cashback received will always be less than or equal to the sum of fees of the features selected). If cost of all features selected is Rs. 99, customer will be charged with Rs. 99 + GST amount of Rs.17.82 and receives an instant Cashback of Rs. 99 from his wallet balance. The remaining Rs. 101 in the wallet can be used to purchase another feature before the expiry date of 31st December 2022.",
          ],
        },
        // { title: "Terms & Conditions", paragraphs: ["", "", ""] },
      ],
    },
    termsContent: [
      {
        heading: "Feature Duration",
        points: [
          `All features are valid for 90 days effective from the day they are activated. For example, if a customer chooses the feature(s) on April 5, 2022, the feature(s) will be valid until July 3, 2022, with April 5th being considered as the first day. Each feature, however, will be designated as auto renewal and will be renewed after 90 days. For example, in the preceding scenario, the feature would be automatically renewed on July 4, 2022. Customers can disable auto renewal of any feature that has already been selected by visiting the AU 0101 Mobile Banking App/ ABFL mobile app. Once disabled, feature would not be renewed post 90 days of existing feature duration.`,
        ],
      },
      {
        heading: "Feature Pricing",
        points: [
          `Pricing shown against each feature during the feature selection journey is applicable for 90 days. Feature fees for 90 days would be levied on the day customer opts in for the feature except in case of feature selected during application process, in which fee is levied from the date of Card issuance. Feature(s) fee will be billed in the upcoming monthly billing statement. GST will be levied as applicable on the feature fee.`,
        ],
      },
      {
        heading: "Additional Cashback",
        points: [
          `For categories like Milestone Cashback, customer is required to meet spends condition in every 30 days cycle to be eligible for Cashback as per the feature selected. For example, if customer opts for feature on 5 th April 2022, then spends done between 5 th April and 4 th May would be considered to check if spends condition has been met or not.`,
          `Similarly, spends between 5 th May and 3rd June, and spends between 4 th June and 3 rd July 2022 will be considered to check if condition has been met or not. Cash withdrawal, rent, EMI and fuel transactions (with MCC 5172, 5541, 5542, 5552, 5983 and 6513) will not be considered for Milestone achievement and for Cashback calculation. Cashback will be credited after 5 days of the 30-day ending period. Customer can opt for single feature in this category.`,
        ],
      },
      {
        heading: "Category Cashback",
        points: [
          `This category offers Cashback earning basis the spends done on specific category, Dining category is defined by the spends done on MCC – 5811, 5812, 5813, 5814, Travel category is defined by the spends done on MCC – 4112, 4131, 4511, 4722, 4723 and Airline MCCs, Apparel category is defined by the spends done on MCC- 5611, 5621, 5631, 5641, 5651, 5655, 5661, 5691, 5698, 5699, 7296, 5139, 5697, 5941 and Electronics category is defined by the spends done on MCC – 4812, 4816, 5044, 5045, 5732, 5734, 5722, 7622. Max. Cashback of INR 1,000 is applicable for each 30 days' cycle/period. Cashback will be credited after 5 days of the 30-day ending period.`,
        ],
      },
      {
        heading: "Lounge Access",
        points: [
          `Features under this category offers complimentary visit to domestic lounge (within India) as applicable. Only the Cardholder is eligible for complimentary visit and voucher/QR code is non- transferable. Lounge access outside India will be chargeable at the rate of USD 27 per visit. Charges will be billed in monthly billing statement. QR Code issued for the complimentary visit to Lounge will be valid till the end date of feature or up to 90 days from the date of feature enabled. All unused voucher/QR codes will get expired post feature end date or 90 days, whichever is earlier. Visit Important Documents section on AU Bank Credit Card page to check out the list of eligible lounges.`,
        ],
      },
      {
        heading: "Memberships",
        points: [
          `This is the category where customers can get memberships. The membership voucher code will be issued instantly and will be available in the AU 0101 App/NetBanking. To avail selected membership, follow the steps outlined in AU 0101. The fulfilment of these memberships is done by third party. All memberships will be subject to spends condition, which will be specified throughout the membership selection process. Fuel and cash withdrawal transactions will not be considered for spends calculation. If the spends requirement is not met, further membership charges will be billed in your credit card statement.`,
          `The Bank is not responsible or accountable in any manner for non-delivery of third-party services, such as lounge access and memberships, including but not limited to deficiency/delay in services, quality, benefits, facilities, and so on.`,
          `The Bank reserves the right to add, modify or amend any of the above-mentioned terms and conditions with or without prior notice to the customers.`,
          `The Courts in Jaipur shall have jurisdiction in with respect to any dispute herein subject to the arbitration clause hereafter. Any dispute, matters, difference or claim arising out of or in connection with the terms herein shall be referred to arbitration by a sole arbitrator (“Arbitrator”) to be appointed by AU Small Finance Bank. The venue for conducting arbitration proceedings shall be in Jaipur, India. The language of arbitration shall be English.`,
          `This Aditya Birla Finance AU ABC FLEX & Aditya Birla Finance AU ABC NXT Credit Card T&Cs are in addition to Card Member agreement, Most Important Terms and Conditions of credit card & AU Bank General Terms and Conditions as uploaded on Bank's website and agrees and gives the consent to all its content.`,
        ],
      },
    ],
    tncHeader:
      "General Terms and Conditions for Features of Aditya Birla Finance Credit Cards",
    cobrandId: {
      dev: "ABCL",
      "benefits-uat": "ABCL",
      "au-sandbox": "ABCL",
      "au-prod": "504",
    },
  },
  "ABC-FLEX-RUPAY": {
    siteHeader: "ABC Flex",
    loginUrl: "/Features/ManageFeatures/abc-flex-rupay/login",
    loginHeader: "Build Your Credit Card",
    loginBanner: "ABCLLoginBanner",
    loginHeroTitle:
      "Login to your ABC FLEX Rupay Credit Card & customize it as per your needs",
    loginCarousel: [
      {
        image: CategoryBenefitsCarousel,
        Title: "Category Benefits",
        subTitle: "Get 5% cashback on Dining & Travel spends",
      },
      {
        image: LoungeAccessCarousel,
        Title: "Lounge Access",
        subTitle: "Fly comfortably with domestic airport lounge access",
      },
      {
        image: MembershipsCarousel,
        Title: "Memberships",
        subTitle: "Enjoy exciting memberships",
      },
      {
        image: MilestoneBenefitsCarousel,
        Title: "Milestone Benefits",
        subTitle: "Get additional 2%/5% cashback on all spends",
      },
    ],
    pageHeader: "ABC Flex Rupay Credit Card",
    walletTitle: "Credit Card Wallet Balance",
    walletTermsHeader: "ABC Flex Rupay Credit Card",
    aboutWalletContent: {
      title: "About Wallet",
      paragraphs: [
        "From time to time, AU Small Finance Bank Ltd. (“AU”) shall credit complimentary funds to your wallet. These funds can only be used to purchase features on your Aditya Birla Finance AU ABC FLEX or Aditya Birla Finance AU ABC NXT Credit Cards.",
        "Funds shall have an expiry date and will be forfeited if not utilised by the cardholder before expiry.",
      ],
    },

    walletContent: {
      contents: [
        {
          title: "How to use Wallet to purchase features",
          paragraphs: [
            "Assume that Rs. 200 is credited to your Aditya Birla Finance AU Credit Card Wallet and are scheduled to be expired on 31st December 2022.",
            "In a scenario where cost of all features selected is Rs.298, customer is first charged with Rs. 298 + GST amount of Rs. 53.64 and then receives an instant Cashback of Rs. 200 from his wallet balance (Cashback received will always be less than or equal to the sum of fees of the features selected). If cost of all features selected is Rs. 99, customer will be charged with Rs. 99 + GST amount of Rs.17.82 and receives an instant Cashback of Rs. 99 from his wallet balance. The remaining Rs. 101 in the wallet can be used to purchase another feature before the expiry date of 31st December 2022.",
          ],
        },
        // { title: "Terms & Conditions", paragraphs: ["", "", ""] },
      ],
    },
    termsContent: [
      {
        heading: "Feature Duration",
        points: [
          `All features are valid for 90 days effective from the day they are activated. For example, if a customer chooses the feature(s) on April 5, 2022, the feature(s) will be valid until July 3, 2022, with April 5th being considered as the first day. Each feature, however, will be designated as auto renewal and will be renewed after 90 days. For example, in the preceding scenario, the feature would be automatically renewed on July 4, 2022. Customers can disable auto renewal of any feature that has already been selected by visiting the AU 0101 Mobile Banking App/ ABFL mobile app. Once disabled, feature would not be renewed post 90 days of existing feature duration.`,
        ],
      },
      {
        heading: "Feature Pricing",
        points: [
          `Pricing shown against each feature during the feature selection journey is applicable for 90 days. Feature fees for 90 days would be levied on the day customer opts in for the feature except in case of feature selected during application process, in which fee is levied from the date of Card issuance. Feature(s) fee will be billed in the upcoming monthly billing statement. GST will be levied as applicable on the feature fee.`,
        ],
      },
      {
        heading: "Additional Cashback",
        points: [
          `For categories like Milestone Cashback, customer is required to meet spends condition in every 30 days cycle to be eligible for Cashback as per the feature selected. For example, if customer opts for feature on 5 th April 2022, then spends done between 5 th April and 4 th May would be considered to check if spends condition has been met or not.`,
          `Similarly, spends between 5 th May and 3rd June, and spends between 4 th June and 3 rd July 2022 will be considered to check if condition has been met or not. Cash withdrawal, rent, EMI and fuel transactions (with MCC 5172, 5541, 5542, 5552, 5983 and 6513) will not be considered for Milestone achievement and for Cashback calculation. Cashback will be credited after 5 days of the 30-day ending period. Customer can opt for single feature in this category.`,
        ],
      },
      {
        heading: "Category Cashback",
        points: [
          `This category offers Cashback earning basis the spends done on specific category, Dining category is defined by the spends done on MCC – 5811, 5812, 5813, 5814, Travel category is defined by the spends done on MCC – 4112, 4131, 4511, 4722, 4723 and Airline MCCs, Apparel category is defined by the spends done on MCC- 5611, 5621, 5631, 5641, 5651, 5655, 5661, 5691, 5698, 5699, 7296, 5139, 5697, 5941 and Electronics category is defined by the spends done on MCC – 4812, 4816, 5044, 5045, 5732, 5734, 5722, 7622. Max. Cashback of INR 1,000 is applicable for each 30 days' cycle/period. Cashback will be credited after 5 days of the 30-day ending period.`,
        ],
      },
      {
        heading: "Lounge Access",
        points: [
          `Features under this category offers complimentary visit to domestic lounge (within India) as applicable. Only the Cardholder is eligible for complimentary visit and voucher/QR code is non- transferable. Lounge access outside India will be chargeable at the rate of USD 27 per visit. Charges will be billed in monthly billing statement. QR Code issued for the complimentary visit to Lounge will be valid till the end date of feature or up to 90 days from the date of feature enabled. All unused voucher/QR codes will get expired post feature end date or 90 days, whichever is earlier. Visit Important Documents section on AU Bank Credit Card page to check out the list of eligible lounges.`,
        ],
      },
      {
        heading: "Memberships",
        points: [
          `This is the category where customers can get memberships. The membership voucher code will be issued instantly and will be available in the AU 0101 App/NetBanking. To avail selected membership, follow the steps outlined in AU 0101. The fulfilment of these memberships is done by third party. All memberships will be subject to spends condition, which will be specified throughout the membership selection process. Fuel and cash withdrawal transactions will not be considered for spends calculation. If the spends requirement is not met, further membership charges will be billed in your credit card statement.`,
          `The Bank is not responsible or accountable in any manner for non-delivery of third-party services, such as lounge access and memberships, including but not limited to deficiency/delay in services, quality, benefits, facilities, and so on.`,
          `The Bank reserves the right to add, modify or amend any of the above-mentioned terms and conditions with or without prior notice to the customers.`,
          `The Courts in Jaipur shall have jurisdiction in with respect to any dispute herein subject to the arbitration clause hereafter. Any dispute, matters, difference or claim arising out of or in connection with the terms herein shall be referred to arbitration by a sole arbitrator (“Arbitrator”) to be appointed by AU Small Finance Bank. The venue for conducting arbitration proceedings shall be in Jaipur, India. The language of arbitration shall be English.`,
          `This Aditya Birla Finance AU ABC FLEX & Aditya Birla Finance AU ABC NXT Credit Card T&Cs are in addition to Card Member agreement, Most Important Terms and Conditions of credit card & AU Bank General Terms and Conditions as uploaded on Bank's website and agrees and gives the consent to all its content.`,
        ],
      },
    ],
    tncHeader:
      "General Terms and Conditions for Features of ABC Flex Credit Card",
    cobrandId: {
      "au-sandbox": "915",
      "au-prod": "915",
    },
  },
  "ABC-NXT": {
    siteHeader: "ABC Nxt",
    loginUrl: "/Features/ManageFeatures/abc-nxt/Login",
    loginHeader: "Build Your Credit Card",
    loginBanner: "ABCLLoginBanner",
    loginHeroTitle:
      "Login to your ABC NXT Credit Card & customise it as per your needs",
    loginCarousel: [
      {
        image: CategoryBenefitsCarousel,
        Title: "Category Benefits",
        subTitle: "Get 5% cashback on Dining & Travel spends",
      },
      {
        image: LoungeAccessCarousel,
        Title: "Lounge Access",
        subTitle: "Fly comfortably with domestic airport lounge access",
      },
      {
        image: MembershipsCarousel,
        Title: "Memberships",
        subTitle: "Enjoy exciting memberships",
      },
      {
        image: MilestoneBenefitsCarousel,
        Title: "Milestone Benefits",
        subTitle: "Get additional 2%/5% cashback on all spends",
      },
    ],
    pageHeader: "ABC Nxt Credit Card",
    walletTitle: "Credit Card Wallet Balance",
    walletTermsHeader: "ABC Nxt Credit Card",
    aboutWalletContent: {
      title: "About Wallet",
      paragraphs: [
        "From time to time, AU Small Finance Bank Ltd. (“AU”) shall credit complimentary funds to your wallet. These funds can only be used to purchase features on your Aditya Birla Finance AU ABC FLEX or Aditya Birla Finance AU ABC NXT Credit Cards.",
        "Funds shall have an expiry date and will be forfeited if not utilised by the cardholder before expiry.",
      ],
    },

    walletContent: {
      contents: [
        {
          title: "How to use Wallet to purchase features",
          paragraphs: [
            "Assume that Rs. 200 is credited to your Aditya Birla Finance AU Credit Card Wallet and are scheduled to be expired on 31st December 2022.",
            "In a scenario where cost of all features selected is Rs.298, customer is first charged with Rs. 298 + GST amount of Rs. 53.64 and then receives an instant Cashback of Rs. 200 from his wallet balance (Cashback received will always be less than or equal to the sum of fees of the features selected). If cost of all features selected is Rs. 99, customer will be charged with Rs. 99 + GST amount of Rs.17.82 and receives an instant Cashback of Rs. 99 from his wallet balance. The remaining Rs. 101 in the wallet can be used to purchase another feature before the expiry date of 31st December 2022.",
          ],
        },
        // { title: "Terms & Conditions", paragraphs: ["", "", ""] },
      ],
    },

    termsContent: [
      {
        heading: "Feature Duration",
        points: [
          `All features are valid for 90 days effective from the day they are activated. For example, if a customer chooses the feature(s) on April 5, 2022, the feature(s) will be valid until July 3, 2022, with April 5th being considered as the first day. Each feature, however, will be designated as auto renewal and will be renewed after 90 days. For example, in the preceding scenario, the feature would be automatically renewed on July 4, 2022. Customers can disable auto renewal of any feature that has already been selected by visiting the AU 0101 Mobile Banking App/ ABFL mobile app. Once disabled, feature would not be renewed post 90 days of existing feature duration.`,
        ],
      },
      {
        heading: "Feature Pricing",
        points: [
          `Pricing shown against each feature during the feature selection journey is applicable for 90 days. Feature fees for 90 days would be levied on the day customer opts in for the feature except in case of feature selected during application process, in which fee is levied from the date of Card issuance. Feature(s) fee will be billed in the upcoming monthly billing statement. GST will be levied as applicable on the feature fee.`,
        ],
      },
      {
        heading: "Additional Cashback",
        points: [
          `For categories like Milestone Cashback, customer is required to meet spends condition in every 30 days cycle to be eligible for Cashback as per the feature selected. For example, if customer opts for feature on 5 th April 2022, then spends done between 5 th April and 4 th May would be considered to check if spends condition has been met or not.`,
          `Similarly, spends between 5 th May and 3rd June, and spends between 4 th June and 3 rd July 2022 will be considered to check if condition has been met or not. Cash withdrawal, rent, EMI and fuel transactions (with MCC 5172, 5541, 5542, 5552, 5983 and 6513) will not be considered for Milestone achievement and for Cashback calculation. Cashback will be credited after 5 days of the 30-day ending period. Customer can opt for single feature in this category.`,
        ],
      },
      {
        heading: "Category Cashback",
        points: [
          `This category offers Cashback earning basis the spends done on specific category, Dining category is defined by the spends done on MCC – 5811, 5812, 5813, 5814, Travel category is defined by the spends done on MCC – 4112, 4131, 4511, 4722, 4723 and Airline MCCs, Apparel category is defined by the spends done on MCC- 5611, 5621, 5631, 5641, 5651, 5655, 5661, 5691, 5698, 5699, 7296, 5139, 5697, 5941 and Electronics category is defined by the spends done on MCC – 4812, 4816, 5044, 5045, 5732, 5734, 5722, 7622. Max. Cashback of INR 1,000 is applicable for each 30 days' cycle/period. Cashback will be credited after 5 days of the 30-day ending period.`,
        ],
      },
      {
        heading: "Lounge Access",
        points: [
          `Features under this category offers complimentary visit to domestic lounge (within India) as applicable. Only the Cardholder is eligible for complimentary visit and voucher/QR code is non- transferable. Lounge access outside India will be chargeable at the rate of USD 27 per visit. Charges will be billed in monthly billing statement. QR Code issued for the complimentary visit to Lounge will be valid till the end date of feature or up to 90 days from the date of feature enabled. All unused voucher/QR codes will get expired post feature end date or 90 days, whichever is earlier. Visit Important Documents section on AU Bank Credit Card page to check out the list of eligible lounges.`,
        ],
      },
      {
        heading: "Memberships",
        points: [
          `This is the category where customers can get memberships. The membership voucher code will be issued instantly and will be available in the AU 0101 App/NetBanking. To avail selected membership, follow the steps outlined in AU 0101. The fulfilment of these memberships is done by third party. All memberships will be subject to spends condition, which will be specified throughout the membership selection process. Fuel and cash withdrawal transactions will not be considered for spends calculation. If the spends requirement is not met, further membership charges will be billed in your credit card statement.`,
          `The Bank is not responsible or accountable in any manner for non-delivery of third-party services, such as lounge access and memberships, including but not limited to deficiency/delay in services, quality, benefits, facilities, and so on.`,
          `The Bank reserves the right to add, modify or amend any of the above-mentioned terms and conditions with or without prior notice to the customers.`,
          `The Courts in Jaipur shall have jurisdiction in with respect to any dispute herein subject to the arbitration clause hereafter. Any dispute, matters, difference or claim arising out of or in connection with the terms herein shall be referred to arbitration by a sole arbitrator (“Arbitrator”) to be appointed by AU Small Finance Bank. The venue for conducting arbitration proceedings shall be in Jaipur, India. The language of arbitration shall be English.`,
          `This Aditya Birla Finance AU ABC FLEX & Aditya Birla Finance AU ABC NXT Credit Card T&Cs are in addition to Card Member agreement, Most Important Terms and Conditions of credit card & AU Bank General Terms and Conditions as uploaded on Bank's website and agrees and gives the consent to all its content.`,
        ],
      },
    ],
    tncHeader:
      "General Terms and Conditions for Features of Aditya Birla Finance Credit Cards",
    cobrandId: {
      dev: "ABCL",
      "benefits-uat": "ABCL2",
      "au-sandbox": "ABCL2",
      "au-prod": "505",
    },
  },
  "ABC-NXT-RUPAY": {
    siteHeader: "ABC Nxt",
    loginUrl: "/Features/ManageFeatures/abc-nxt-rupay/login",
    loginHeader: "Build Your Credit Card",
    loginBanner: "ABCLLoginBanner",
    loginHeroTitle:
      "Login to your ABC NXT Rupay Credit Card & customize it as per your needs",
    loginCarousel: [
      {
        image: CategoryBenefitsCarousel,
        Title: "Category Benefits",
        subTitle: "Get 5% cashback on Dining & Travel spends",
      },
      {
        image: LoungeAccessCarousel,
        Title: "Lounge Access",
        subTitle: "Fly comfortably with domestic airport lounge access",
      },
      {
        image: MembershipsCarousel,
        Title: "Memberships",
        subTitle: "Enjoy exciting memberships",
      },
      {
        image: MilestoneBenefitsCarousel,
        Title: "Milestone Benefits",
        subTitle: "Get additional 2%/5% cashback on all spends",
      },
    ],
    pageHeader: "ABC Nxt Rupay Credit Card",
    walletTitle: "Credit Card Wallet Balance",
    walletTermsHeader: "ABC Nxt Rupay Credit Card",
    aboutWalletContent: {
      title: "About Wallet",
      paragraphs: [
        "From time to time, AU Small Finance Bank Ltd. (“AU”) shall credit complimentary funds to your wallet. These funds can only be used to purchase features on your Aditya Birla Finance AU ABC FLEX or Aditya Birla Finance AU ABC NXT Credit Cards.",
        "Funds shall have an expiry date and will be forfeited if not utilised by the cardholder before expiry.",
      ],
    },

    walletContent: {
      contents: [
        {
          title: "How to use Wallet to purchase features",
          paragraphs: [
            "Assume that Rs. 200 is credited to your Aditya Birla Finance AU Credit Card Wallet and are scheduled to be expired on 31st December 2022.",
            "In a scenario where cost of all features selected is Rs.298, customer is first charged with Rs. 298 + GST amount of Rs. 53.64 and then receives an instant Cashback of Rs. 200 from his wallet balance (Cashback received will always be less than or equal to the sum of fees of the features selected). If cost of all features selected is Rs. 99, customer will be charged with Rs. 99 + GST amount of Rs.17.82 and receives an instant Cashback of Rs. 99 from his wallet balance. The remaining Rs. 101 in the wallet can be used to purchase another feature before the expiry date of 31st December 2022.",
          ],
        },
        // { title: "Terms & Conditions", paragraphs: ["", "", ""] },
      ],
    },

    termsContent: [
      {
        heading: "Feature Duration",
        points: [
          `All features are valid for 90 days effective from the day they are activated. For example, if a customer chooses the feature(s) on April 5, 2022, the feature(s) will be valid until July 3, 2022, with April 5th being considered as the first day. Each feature, however, will be designated as auto renewal and will be renewed after 90 days. For example, in the preceding scenario, the feature would be automatically renewed on July 4, 2022. Customers can disable auto renewal of any feature that has already been selected by visiting the AU 0101 Mobile Banking App/ ABFL mobile app. Once disabled, feature would not be renewed post 90 days of existing feature duration.`,
        ],
      },
      {
        heading: "Feature Pricing",
        points: [
          `Pricing shown against each feature during the feature selection journey is applicable for 90 days. Feature fees for 90 days would be levied on the day customer opts in for the feature except in case of feature selected during application process, in which fee is levied from the date of Card issuance. Feature(s) fee will be billed in the upcoming monthly billing statement. GST will be levied as applicable on the feature fee.`,
        ],
      },
      {
        heading: "Additional Cashback",
        points: [
          `For categories like Milestone Cashback, customer is required to meet spends condition in every 30 days cycle to be eligible for Cashback as per the feature selected. For example, if customer opts for feature on 5 th April 2022, then spends done between 5 th April and 4 th May would be considered to check if spends condition has been met or not.`,
          `Similarly, spends between 5 th May and 3rd June, and spends between 4 th June and 3 rd July 2022 will be considered to check if condition has been met or not. Cash withdrawal, rent, EMI and fuel transactions (with MCC 5172, 5541, 5542, 5552, 5983 and 6513) will not be considered for Milestone achievement and for Cashback calculation. Cashback will be credited after 5 days of the 30-day ending period. Customer can opt for single feature in this category.`,
        ],
      },
      {
        heading: "Category Cashback",
        points: [
          `This category offers Cashback earning basis the spends done on specific category, Dining category is defined by the spends done on MCC – 5811, 5812, 5813, 5814, Travel category is defined by the spends done on MCC – 4112, 4131, 4511, 4722, 4723 and Airline MCCs, Apparel category is defined by the spends done on MCC- 5611, 5621, 5631, 5641, 5651, 5655, 5661, 5691, 5698, 5699, 7296, 5139, 5697, 5941 and Electronics category is defined by the spends done on MCC – 4812, 4816, 5044, 5045, 5732, 5734, 5722, 7622. Max. Cashback of INR 1,000 is applicable for each 30 days' cycle/period. Cashback will be credited after 5 days of the 30-day ending period.`,
        ],
      },
      {
        heading: "Lounge Access",
        points: [
          `Features under this category offers complimentary visit to domestic lounge (within India) as applicable. Only the Cardholder is eligible for complimentary visit and voucher/QR code is non- transferable. Lounge access outside India will be chargeable at the rate of USD 27 per visit. Charges will be billed in monthly billing statement. QR Code issued for the complimentary visit to Lounge will be valid till the end date of feature or up to 90 days from the date of feature enabled. All unused voucher/QR codes will get expired post feature end date or 90 days, whichever is earlier. Visit Important Documents section on AU Bank Credit Card page to check out the list of eligible lounges.`,
        ],
      },
      {
        heading: "Memberships",
        points: [
          `This is the category where customers can get memberships. The membership voucher code will be issued instantly and will be available in the AU 0101 App/NetBanking. To avail selected membership, follow the steps outlined in AU 0101. The fulfilment of these memberships is done by third party. All memberships will be subject to spends condition, which will be specified throughout the membership selection process. Fuel and cash withdrawal transactions will not be considered for spends calculation. If the spends requirement is not met, further membership charges will be billed in your credit card statement.`,
          `The Bank is not responsible or accountable in any manner for non-delivery of third-party services, such as lounge access and memberships, including but not limited to deficiency/delay in services, quality, benefits, facilities, and so on.`,
          `The Bank reserves the right to add, modify or amend any of the above-mentioned terms and conditions with or without prior notice to the customers.`,
          `The Courts in Jaipur shall have jurisdiction in with respect to any dispute herein subject to the arbitration clause hereafter. Any dispute, matters, difference or claim arising out of or in connection with the terms herein shall be referred to arbitration by a sole arbitrator (“Arbitrator”) to be appointed by AU Small Finance Bank. The venue for conducting arbitration proceedings shall be in Jaipur, India. The language of arbitration shall be English.`,
          `This Aditya Birla Finance AU ABC FLEX & Aditya Birla Finance AU ABC NXT Credit Card T&Cs are in addition to Card Member agreement, Most Important Terms and Conditions of credit card & AU Bank General Terms and Conditions as uploaded on Bank's website and agrees and gives the consent to all its content.`,
        ],
      },
    ],
    tncHeader:
      "General Terms and Conditions for Features of ABC Nxt Credit Card",
    cobrandId: {
      "au-sandbox": "916",
      "au-prod": "916",
    },
  },
  LIT: {
    siteHeader: "AU LIT",
    loginUrl: "/Features/ManageFeatures/Login",
    loginHeader: "Build Your LIT Credit Card",
    loginBanner: "AuLoginBanner",
    loginHeroTitle:
      "Login to your LIT Credit Card and customise it as per your lifestyle needs",
    loginCarousel: [
      {
        image: AccRewardsCarousel,
        Title: "Accelarated Rewards",
        subTitle: "Get 5X/10X rewards on online and offline spends",
      },
      {
        image: CategoryBenefitsCarousel,
        Title: "Category Benefits",
        subTitle: "Get 5% cashback on grocery and travel spends",
      },
      {
        image: LoungeAccessCarousel,
        Title: "Lounge Access",
        subTitle: "Fly comfortably with domestic airport lounge access",
      },
      {
        image: MembershipsCarousel,
        Title: "Memberships",
        subTitle: "Enjoy exciting memberships",
      },
      {
        image: MilestoneBenefitsCarousel,
        Title: "Milestone Benefits",
        subTitle: "Get additional 2%/5% cashback on all spends",
      },
    ],
    pageHeader: "LIT Credit Card",
    walletTitle: "LIT wallet balance",
    walletTermsHeader: "LIT Card",
    aboutWalletContent: {
      title: "About Wallet",
      paragraphs: [
        "From time to time, AU Small Finance Bank Ltd. (“AU”) shall credit complimentary funds to your wallet. These funds can only be used to purchase features on your AU Small Finance Bank LIT Credit Card",
        "Funds shall have an expiry date and will be forfeited if not utilised by the cardholder before expiry.",
      ],
    },
    walletContent: {
      contents: [
        {
          title: "How to use Wallet to purchase features",
          paragraphs: [
            "Assume that Rs. 200 is credited to your AU Small Finance Bank LIT Wallet and are scheduled to be expired on 31st December 2022.",
            "In a scenario where cost of all features selected is Rs.298, customer is first charged with Rs. 298 + GST amount of Rs. 53.64 and then receives an instant Cashback of Rs. 200 from his wallet balance (Cashback received will always be less than or equal to the sum of fees of the features selected). If cost of all features selected is Rs. 99, customer will be charged with Rs. 99 + GST amount of Rs.17.82 and receives an instant Cashback of Rs. 99 from his wallet balance. The remaining Rs. 101 in the wallet can be used to purchase another feature before the expiry date of 31st December 2022.",
          ],
        },
        // { title: "Terms & Conditions", paragraphs: ["", "", ""] },
      ],
    },
    termsContent: [
      {
        heading: "Feature Duration",
        points: [
          `All features, activated by cardholders, are valid for 90 days effective from the day they are activated. For example, if a cardholder chooses the feature(s) on April 5, 2022, the feature(s) will be valid until July 3, 2022, with April 5th being considered as the first day. Each feature will be designated as auto renewal and will be renewed after 90 days by default, unless explicitly disabled by cardholders. For example, in the preceding scenario, the feature would be automatically renewed on July 4, 2022. Additionally, Feature cannot be deactivated within 90 days of activation. Cardholders can disable auto renewal of any feature that has already been selected by visiting the AU0101 Mobile Banking App. Once disabled, feature would not be renewed post 90 days of existing feature duration.`,
        ],
      },
      {
        heading: "Feature Pricing",
        points: [
          `Pricing shown against each feature during the feature selection journey is applicable for 90 days. Feature fees for 90 days would be levied on the day Cardholder opts in for the feature except in case of feature selected during application process, in which fee is levied from the date of Card issuance. Feature(s) fee will be billed in the upcoming monthly billing statement. GST will be levied as applicable on the feature fee. Feature fee waivers through promotional campaigns such as wallet, spin the wheel or any other similar campaigns will not include GST, applicable GST will be chargeable to the credit card account.`,
        ],
      },
      {
        heading: "Total Qualifying Spends",
        points: [
          `Reversal transactions accrued during a feature cycle shall be considered for the calculation of milestone spends and cashback/reward points calculation.  Hereby, reversals accrued during current feature period or cycle, of transactions that earned benefit in previous feature cycle/period, will be adjusted to total eligible spends for the current feature cycle/period.\nIllustration for benefit calculation is as below:`,
          {
            tableHead: [
              "Trxn Date",
              "Category",
              "Type",
              "Amount (Rs.)",
              "Cashback Category",
              "Cashback Accrued (Rs.)",
            ],
            tableData: [
              [
                "11-Nov",
                "Railway",
                "Purchase",
                "5000",
                "5% Cashback on Travel",
                "250",
              ],
              [
                "18-Nov",
                "Airlines",
                "Purchase",
                "7000",
                "5% Cashback on Travel",
                "350",
              ],
              [
                "19-Nov",
                "Taxi",
                "Purchase",
                "500",
                "5% Cashback on Travel",
                "25",
              ],
              [
                "20-Nov",
                "Railway",
                "Reversal",
                "-2500",
                "5% Cashback on Travel",
                "-125",
              ],
              [
                "5-Dec",
                "Airlines",
                "Purchase",
                "8000",
                "5% Cashback on Travel",
                "400",
              ],
              ["", "", "Net spends", "18000", "Net Cashback", "900"],
            ],
          },
          ``,
        ],
      },
      {
        heading: "Base Reward Points (Program Rewards)",
        points: [
          `Cardholders are eligible for earn rate of 1 Reward Point for every INR 100 retail spends done on the LIT Card. Earning of Base Reward Points is in addition to Cashback as applicable in case features opted by the customer. Cardholders can earn a maximum of  25000 Reward Points in a statement cycle. Cash Withdrawal (MCC- 6010, 6011), EMI, Fuel transactions (MCC- 5172, 5541, 5542, 5552 and 5983), Rent (MCC- 6513), Education & Govt (MCC- 8211, 8220, 8241, 8244, 8249, 8299, 9211, 9222, 9223, 9311, 9399, 9402, 9405, 9950) transactions will not be considered for earning of Base Reward Points. Cardholders can earn base -1 Reward Point per INR 100 spent on Utility and Telecom spends (with a maximum of 100 Reward Points per transaction).`,
        ],
      },
      {
        heading: "Additional Cashback",
        points: [
          `For categories like Milestone Cashback, cardholders are required to meet spends condition in every 30 days cycle to be eligible for Cashback as per the feature selected. For example, if a cardholder opts for feature on 5th April 2022, then spends done between 5th April and 4th May would be considered to check if spends condition has been met or not.`,
          `Similarly, spends between 5th May and 3rd June, and spends between 4th June and 3rd July 2022 will be considered to check if condition has been met or not. Cash withdrawal (MCC-6010,6011), EMI and fuel transactions (with MCC 5172, 5541, 5542, 5552, 5983 and 6513) transactions will not be considered for Milestone achievement and for Cashback calculation. Cashback will be credited after 5 days of the 30-day ending period. Cardholders can opt for single feature in this category.`,
        ],
      },
      {
        heading: "Accelerated Reward Points (5X/10X)",
        points: [
          `Cardholders can enjoy accelerated reward points as applicable if feature(s) under this category is/are opted. Base earning of Reward Points is included in accelerated earn rate. For example, 10X Reward Points on Online Spends includes earning of 1 RP/INR 100. Transactions done on the following categories will not be considered for accelerated Reward Points (5X/10X Rewards) and such transactions will earn as per the below mentioned grid:`,

          {
            tableData: [
              ["Fuel", "5541, 5542, 5172, 5983", "0 Reward points"],
              ["Electric Vehicle Charging", "5552", "0 Reward points"],
              ["Rent transactions", "6513", "0 Reward points"],
              [
                "Utilities & Telecom",
                "4812, 4814, 4899, 4900",
                "1 Reward Point (Max 100 RPs per transaction)",
              ],
              [
                "Education & Govt",
                "8211, 8220, 8241, 8244, 8249, 8299, 9211, 9222, 9223, 9311, 9399, 9402, 9405, 9950",
                "0 Reward Points",
              ],
            ],
          },
          `Transactions done on the following categories along with BBPS transactions will not be considered for accelerated Reward Points (5X/10X Rewards) and such transactions will earn reward points as per the below mentioned grid. Cash withdrawal (MCC-6010,6011), Rent (MCC- 6513), EMI and fuel transactions (with MCC 5172, 5541, 5542, 5552, 5983 and 6513), Education & Govt (MCC- 8211, 8220, 8241, 8244, 8249, 8299, 9211, 9222, 9223, 9311, 9399, 9402, 9405, 9950) would not be considered for earning of accelerated reward points. Reward Points can be redeemed post statement generation. Reward Points earning can be checked through AU 0101 or https://rewardz.aubank.in`,
          `Cardholders can opt for either 5X or 10X reward points in subcategory of Online and Offline (POS and Contactless) spends.`,
        ],
      },
      {
        heading: "Category Cashback",
        points: [
          `This category offers Cashback earning basis the spends done on specific category. Grocery category is defined by the spends done on MCC – 5411, 5422, 5441, 5451, 5462, 5499 and 5921, Dining category is defined by the spends done on MCC – 5811, 5812, 5813, 5814, Travel category is defined by the spends done on MCC – 4112, 4131, 4511, 4722, 4723 and Airline MCCs, Apparel category is defined by the spends done on MCC- 5611, 5621, 5631, 5641, 5651, 5655, 5661, 5691, 5698, 5699, 7296, 5139, 5697, 5941 and Electronics category is defined by the spends done on MCC – 4812, 4816, 5044, 5045, 5732, 5734, 5722, 7622. Max. Cashback of INR 1,000 is applicable for each 30 days' cycle/period. Cashback will be credited after 5 days of the 30-day ending period.`,
        ],
      },
      {
        heading: "Fuel Surcharge Waiver",
        points: [
          `All fuel transactions between INR 400 and INR 5,000 will be eligible for Fuel Surcharge Waiver if a Cardholder opts for the Fuel Surcharge Waiver feature. Fuel transactions are considered basis the spends done on MCC - 5172, 5541, 5542, 5552 and 5983.`,
        ],
      },
      {
        heading: "Lounge Access",
        points: [
          `Features under this category offers complimentary visit to domestic lounge (within India) as applicable. Only the Cardholder is eligible for complimentary visit and voucher/QR code is non- transferable. Lounge access outside India will be chargeable at the rate of USD 27 per visit. Charges will be billed in monthly billing statement. QR Code issued for the complimentary visit to Lounge will be valid till the end date of feature or up to 90 days from the date of feature enabled. All unused voucher/QR codes will get expired post feature end date or 90 days, whichever is earlier. Visit Important Documents section on AU Bank Credit Card page to check out the list of eligible lounges.`,
        ],
      },
      {
        heading: "Memberships",
        points: [
          `This is the category where Cardholders can get memberships of different brands/merchants. The membership voucher code will be issued instantly and will be available in the AU 0101 App/NetBanking. To avail selected membership, follow the steps outlined in AU 0101. The fulfilment of these memberships is done by third party. All memberships will be subject to spends condition, which will be specified throughout the membership selection process. Reversal transactions accrued during a feature cycle shall be considered for the calculation of milestone spends and cashback/reward points calculation.  Hereby, reversals accrued during current feature period or cycle, of transactions that earned benefit in previous feature cycle/period, will be adjusted to total eligible spends for the current feature cycle/ period. Fuel and cash withdrawal transactions will not be considered for spends calculation. If the spends requirement is not met, further membership charges will be billed in your credit card statement.`,
          `The Bank is not responsible or accountable in any manner for non-delivery of third-party services, such as lounge access and memberships, including but not limited to deficiency/delay in services, quality, benefits, facilities, and so on.`,
          `The Bank reserves the right to add, modify or amend any of the above-mentioned terms and conditions with or without prior notice to the Cardholders.`,
          `The Courts in Jaipur shall have jurisdiction in with respect to any dispute herein subject to the arbitration clause hereafter. Any dispute, matters, difference, or claim arising out of or in connection with the terms herein shall be referred to arbitration by a sole arbitrator (“Arbitrator”) to be appointed by AU Small Finance Bank. The venue for conducting arbitration proceedings shall be in Jaipur, India. The language of arbitration shall be English.`,
          `This LIT Credit Card T&Cs are in addition to Card Member agreement, Most Important Terms and Conditions of credit card & AU Bank General Terms and Conditions as uploaded on Bank's website and agrees and gives the consent to all its content.\n `,
        ],
      },
    ],
    tncHeader: "General Terms and Conditions for Features of LIT Credit Card",
    cobrandId: {
      dev: "LIT",
      "benefits-uat": "LIT",
      "au-sandbox": "LIT",
      "au-prod": "LIT",
    },
  },
});

export const Programs = Object.freeze({
  dev: { abc: "ABC-FLEX", lit: "LIT" },
  "benefits-uat": {
    program_2yiicbcecucpm1: "ABC-FLEX",
    program_bzmtdspc0bfukp: "LIT",
  },
  "au-sandbox": {
    program_jhnqdlsn0kkitd: "ABC-FLEX",
    program_ycczqrzuswm96n: "ABC-NXT",
    program_ajli1a5imzf3hg: "LIT",
    program_7le0tyfyjxylxm: "ABC-NXT-RUPAY",
    program_fqzi997lvhuq3l: "ABC-FLEX-RUPAY",
  },
  "au-prod": {
    program_t0zedpb82lpyk4: "ABC-FLEX",
    program_dwx6qsxdxxrmxh: "ABC-NXT",
    program_ajli1a5imzf3hg: "LIT",
    program_s9lcjqjds9lspt: "ABC-NXT-RUPAY",
    program_wmoj1nblsgroj6: "ABC-FLEX-RUPAY",
  },
});

export const RequestingPage = Object.freeze({
  LOGIN: "LOGIN",
});
