import { Image, Text, View } from "native-base";
import React from "react";
import { useWindowDimensions } from "react-native-web";
import preLoader from "../../../assets/images/preLoader.gif";

const PreLoader = () => {
  const dimensions = useWindowDimensions();
  return (
    <>
      <View
        w="100%"
        alignItems="center"
        justifyContent="center"
        height={dimensions.height}
      >
        <Image source={{ uri: preLoader }} height="64px" width="64px" alt="" />
        <View alignItems="center" justifyContent="center" mt="12px">
          <Text
            color="#636D7D"
            fontWeight="500"
            fontFamily="Quicksand"
            fontSize="14px"
            lineHeight="22px"
          >
            One moment please...
          </Text>
        </View>
      </View>
    </>
  );
};

export default PreLoader;
