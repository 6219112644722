import { Image, Text, View } from "native-base";
import React from "react";
import lockWithBackground from "../../../../assets/images/lockWithBackground.svg";

const LockedVoucher = () => {
  return (
    <View style={{ width: "100%", aspectRatio: "343/72" }} mb={"12px"}>
      <Image
        source={{
          uri: lockWithBackground,
        }}
        style={{ width: "100%", height: "100%" }}
        resizeMode="contain"
        alt=""
      />
    </View>
  );
};

export default LockedVoucher;
