import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const usePageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const previousPage = sessionStorage.getItem("currentPage") || null;;
    let currentPage = location.pathname;
    const isLoginPage = /login/i.test(location.pathname);
    if (localStorage.getItem("program") && isLoginPage) {
      currentPage = "/Features/ManageFeatures/Login";
    }
    
    if (currentPage !== previousPage) {
      if (previousPage) {
        sessionStorage.setItem("previousPage", previousPage);
      }

      sessionStorage.setItem("currentPage", currentPage);
    }

  }, [location]);

  return {
    previousPage: sessionStorage.getItem("previousPage"),
    currentPage: sessionStorage.getItem("currentPage"),
  };
};

export default usePageTracker;
