import {
  SET_BENEFITS_CARD_ID,
  SET_BENEFITS_ADDITION,
  SET_CURRENT_BENEFITS,
  SET_CALLBACK_URL,
  SET_TERMS_ACCEPTED,
  SET_LOUNGE_COUNT,
  SET_ERROR_DETAILS,
  SET_MAX_LOUNGE_COUNT,
  SET_CURRENT_VOUCHER,
  SET_CHANNEL,
  SET_WALLET_DETAILS,
  SET_QUERY_PARAMS
} from "../ActionTypes";

const initialState = {
  termsAccepted: false,
  accountId: "",
  cardId: 1,
  leadId: "",
  benefitsList: [],
  benefitsListId: [],
  benefitsGroupId: [],
  benefitsGroupIdCount: 0,
  benefitsListIdCount: {},
  channel: "MB",
  callBackUrl: "https://www.aubank.in/personal-banking/credit-cards",
  benefit: {},
  errorDetails: null,
  maxLoungeCount: 0,
  currentVoucher: null,
  walletDetails: {},
  queryParams: null // query params from url which was hit
};

const ManageBenefitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BENEFITS_CARD_ID:
      return {
        ...state,
        accountId: action.payload.accountId,
        cardId: action.payload.cardId,
        channel: action.payload.channel,
      };
    case SET_CHANNEL:
      return {
        ...state,
        channel: action.payload.channel,
      };
    case SET_LOUNGE_COUNT:
      return {
        ...state,
        benefitsGroupIdCount: action.payload.benefitsGroupIdCount,
        benefitsListIdCount: action.payload.benefitsListIdCount,
      };

    case SET_WALLET_DETAILS:
      return {
        ...state,
        walletDetails: action.payload.walletDetails,
      };
    case SET_MAX_LOUNGE_COUNT:
      return {
        ...state,
        leadId: action.payload.leadId,
        maxLoungeCount: action.payload.maxLoungeCount,
      };
    case SET_ERROR_DETAILS:
      return {
        ...state,
        errorDetails: action.payload.errorDetails,
      };
    case SET_BENEFITS_ADDITION:
      return {
        ...state,
        benefitsList: action.payload.benefits,
        benefitsListId: action.payload.benefitsListId,
        benefitsGroupId: action.payload.benefitsGroupId,
      };
    case SET_TERMS_ACCEPTED:
      return {
        ...state,
        termsAccepted: action.payload.termsAccepted,
      };
    case SET_CALLBACK_URL:
      return {
        ...state,
        callBackUrl: action.payload.callBackUrl,
      };
    case SET_CURRENT_BENEFITS:
      return {
        ...state,
        benefit: action.payload.benefit,
      };
    case SET_CURRENT_VOUCHER:
      return {
        ...state,
        currentVoucher: action.payload.currentVoucher,
      };
    case SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: action.payload.queryParams,
      };
    default:
      return state;
  }
};

export default ManageBenefitsReducer;
