import React from "react";

const VolumeMute = ({
    ...otherProps
}) => {
    return (
			<svg
				width="23"
				height="28"
				viewBox="0 0 23 28"
				fill="#303030"
				xmlns="http://www.w3.org/2000/svg"
				{...otherProps}
			>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M10.9113 6.26933C11.0488 6.33005 11.1657 6.42942 11.2477 6.55535C11.3298 6.68127 11.3735 6.82833 11.3735 6.97863V24.0389C11.3736 24.1892 11.33 24.3363 11.248 24.4623C11.166 24.5883 11.0492 24.6878 10.9117 24.7486C10.7742 24.8094 10.622 24.8289 10.4736 24.8048C10.3252 24.7807 10.187 24.7139 10.0759 24.6127L4.61143 19.6446H1.80942C1.32953 19.6446 0.869298 19.4539 0.529967 19.1146C0.190635 18.7753 0 18.315 0 17.8351V13.1823C0 12.1835 0.81062 11.3729 1.80942 11.3729H4.61143L10.0759 6.40478C10.1872 6.30387 10.3253 6.23744 10.4736 6.21358C10.622 6.18972 10.774 6.20945 10.9113 6.27037V6.26933ZM9.82256 8.73118L5.43343 12.7222C5.29055 12.8521 5.10436 12.924 4.91128 12.9239H1.80942C1.74086 12.9239 1.67512 12.9511 1.62664 12.9996C1.57816 13.048 1.55093 13.1138 1.55093 13.1823V17.8351C1.55093 17.9778 1.66673 18.0936 1.80942 18.0936H4.91128C5.10436 18.0935 5.29055 18.1654 5.43343 18.2953L9.82256 22.2863V8.73118Z" />
				<path d="M16.1256 11.536C16.3923 11.536 16.6109 11.648 16.7816 11.872L22.2376 18.752C22.3549 18.8907 22.4136 19.0507 22.4136 19.232C22.4136 19.4453 22.3283 19.632 22.1576 19.792C21.9869 19.952 21.8003 20.032 21.5976 20.032C21.3416 20.032 21.1229 19.92 20.9416 19.696L15.4856 12.816C15.3683 12.6773 15.3096 12.5173 15.3096 12.336C15.3096 12.112 15.3896 11.9253 15.5496 11.776C15.7203 11.616 15.9123 11.536 16.1256 11.536ZM16.1416 20.032C15.9603 20.032 15.7843 19.9627 15.6136 19.824C15.4429 19.6853 15.3576 19.5147 15.3576 19.312C15.3576 19.1307 15.4216 18.9653 15.5496 18.816L18.0936 15.664L18.9096 16.976L16.7816 19.696C16.6109 19.92 16.3976 20.032 16.1416 20.032ZM21.5816 11.536C21.8056 11.536 21.9869 11.6107 22.1256 11.76C22.2749 11.8987 22.3496 12.064 22.3496 12.256C22.3496 12.3413 22.3336 12.432 22.3016 12.528C22.2803 12.6133 22.2323 12.6933 22.1576 12.768L19.6456 15.92L18.8136 14.736L20.9416 11.904C21.1229 11.6587 21.3363 11.536 21.5816 11.536Z" />
			</svg>

    );
};

export default VolumeMute;
