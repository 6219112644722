import { Image, Text, View } from "native-base";
import React from "react";
import { useWindowDimensions } from "react-native";
import { useSelector } from "react-redux";

const Forbidden = () => {
  const manageBenefits = useSelector((state) => state.manageBenefits);
  const dimensions = useWindowDimensions();
  const redirect = () => {
    window.location.href = manageBenefits.callBackUrl;
  };
  return (
    <View alignItems={"center"} justifyContent="center" bgColor={"#FFFDFA"}>
      <View
        h={dimensions.height}
        _web={{ maxW: "412px" }}
        width="100%"
        bgColor={"#FFFFFF"}
      >
        {manageBenefits.channel !== "MB" && (
          <View
            alignItems="center"
            justifyContent="space-between"
            flexDirection="row"
            p="16px"
            shadow="1"
          >
            <View flexDirection="row" alignItems="center">
              <View mr="8px" alignItems="center">
                {/* <Image source={{ uri: back }} height="24px" width="24px" alt="" /> */}
              </View>
              <Text
                color="#303030"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="18px"
                lineHeight="26px"
              >
                Manage Features
              </Text>
            </View>
            <View onClick={() => redirect()}>
              {/* <Close size="24" color="#6D3078" /> */}
            </View>
          </View>
        )}

        <View alignItems="center" justify-content="center" mt="50%">
          <View>
            <Image
              source={{
                uri:
                  process.env.REACT_APP_AWS_S3_BUCKET_URL +
                  "accountBlocked.png",
              }}
              height="160px"
              width="160px"
              alt=""
            />
          </View>
          <View alignItems="center" w="80%">
            <View>
              <Text
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="24px"
                lineHeight="32px"
              >
                Forbidden!
              </Text>
            </View>
            <View alignItems="center" mt="20px">
              <Text
                textAlign="center"
                fontWeight="500"
                fontFamily="Quicksand"
                fontSize="14px"
                lineHeight="22px"
              >
                You are not authorized to view this page
              </Text>
            </View>
          </View>
          <View></View>
        </View>
      </View>
    </View>
  );
};

export default Forbidden;
