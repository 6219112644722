const styles = {
    container: {
        backgroundColor: "#F7E4CC",
        textAlign: "center",
        position: "relative",
        minHeight: "100%"
    },
    confetti: {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        zIndex: -"1",
    },
    text1: {
        fontFamily: "Quicksand",
        fontSize: "22px",
        fontWeight: "700",
        color: "#303030",
        margin: "20px 0px",
    },
    text2: {
        fontFamily: "Quicksand",
        fontSize: "22px",
        fontWeight: "700",
        color: "#565750",
        margin: "20px 0px",
    },
    text3: {
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontWeight: "400",
        color: "#000000",
        margin: "10px 0px 10px 0px",
    },
    text4: {
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontWeight: "700",
        color: "#6D3078",
        margin: "5px 0px 0px 0px",
    },
    text5: {
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontWeight: "700",
        color: "#E95C25",
        margin: "0px 0px 20px 0px",
    },
    amount: {
        fontFamily: "Quicksand",
        fontSize: "40px",
        fontWeight: "700",
        color: "#6D3078",
    },
    auLogo: {
        width: "120px",
        height: "60px",
        mt: "20px",
        mb: "10px",
        marginLeft: "auto",
        marginRight: "auto",
    },
    litBanner: {
        width: "320px",
        height: "190px",
        mt: "40px",
        mb: "20px",
        marginLeft: "auto",
        marginRight: "auto",
    },
    applyButton: {
        width: "250px",
        my: "30px",
        marginLeft: "auto",
        marginRight: "auto",
    },
};

export default styles;
