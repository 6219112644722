import {
  SET_WHEEL_PROGRAM,
  SET_WHEEL_SPINS
} from "../ActionTypes";

const initialState = {
  id: null,
  availableRewardSpins: 0,
  availableSpins: 0,
  expiryDate: null,
  buckets: [],
  maxReward: 0
};

const WheelProgramReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WHEEL_PROGRAM:
      return {
        ...state,
        id: action.payload.id,
        availableRewardSpins: action.payload.availableRewardSpins,
        availableSpins: action.payload.availableSpins,
        expiryDate: action.payload.expiryDate,
        buckets: action.payload.buckets,
        maxReward: action.payload.maxReward
      };
    case SET_WHEEL_SPINS:
      return {
        ...state,
        availableRewardSpins: action.payload.availableRewardSpins,
        availableSpins: action.payload.availableSpins,
      };
    default:
      return state;
  }
};

export default WheelProgramReducer;
