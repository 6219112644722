export const SET_FONT = "SET_FONT";
export const SET_DESIGN = "SET_DESIGN";
export const SET_SESSION = "SET_SESSION";
export const SET_REDIRECT_URL = "SET_REDIRECT_URL";
export const SET_REPAYMENT_DETAILS = "SET_REPAYMENT_DETAILS";
export const SET_SESSION_EXPIRY = "SET_SESSION_EXPIRY";
export const SET_USER = "SET_USER";
export const SET_SUMMARY = "SET_SUMMARY";
export const SET_CARDS = "SET_CARDS";
export const SET_OTP_STATE = "SET_OTP_STATE";
export const SET_BENEFITS_CARD_ID = "SET_BENEFITS_CARD_ID";
export const SET_BENEFITS_ADDITION = "SET_BENEFITS_ADDITION";
export const SET_CURRENT_BENEFITS = "SET_CURRENT_BENEFITS";
export const SET_CALLBACK_URL = "SET_CALLBACK_URL";
export const SET_TERMS_ACCEPTED = "SET_TERMS_ACCEPTED";
export const SET_LOUNGE_COUNT = "SET_LOUNGE_COUNT";
export const SET_ERROR_DETAILS = "SET_ERROR_DETAILS";
export const SET_MAX_LOUNGE_COUNT = "SET_MAX_LOUNGE_COUNT";
export const SET_CURRENT_VOUCHER = "SET_CURRENT_VOUCHER";
export const SET_CHANNEL = "SET_CHANNEL";
export const SET_WALLET_DETAILS = "SET_WALLET_DETAILS";
export const SET_WHEEL_PROGRAM = "SET_WHEEL_PROGRAM";
export const SET_WHEEL_SPINS = "SET_WHEEL_SPINS";
export const SET_QUERY_PARAMS = "SET_QUERY_PARAMS";
export const SET_PROGRAM_CONFIGS = "SET_PROGRAM_CONFIGS"