const styles = {
  otpVerificationText: {
    fontFamily: "Inter",
    fontWeight: 600,
  },
  saveSettingText: {
    mt: 2,
    fontSize: 14,
    fontFamily: "Inter",
    fontWeight: 400,
    color: "#808080",
  },
  pinMainView: {
    px: 10,
    py: 7,
    mt: "8px",
    borderRadius: 5,
  },
  pinMainView1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  textInput: {
    width: "16%",
    py: 3,
    m: 2,
    backgroundColor: "#FFF",
    borderColor: "#303030",
    borderWidth: "1px",
    textAlign: "center",
    secureTextEntry: "true",
    maxLength: 1,
    keyboardType: "numeric",
  },
  resendOtp: {
    fontFamily: "Inter",
    fontWeight: 400,
    color: "#808080",
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    bottom: "16px",
  },
  cancelButton: {
    backgroundColor: "#FFF",
    borderColor: "#6180FF",
    borderWidth: 1,
  },
};

export default styles;
