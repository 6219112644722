import { Image, Text, View, Button } from "native-base";
import React, { useEffect, useState } from "react";
import benefit from "../../../assets/images/benefit.svg";
import Close from "../../svg/close";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setBenefitsAddition,
  setCurrentBenefit,
} from "../../../store/actions/ManageBenefits";
import { setLoungeCount } from "../../../store/actions/ManageBenefits";
import { useTrackEvents } from "../../../hooks/useTrackEvents";
import { BenefitIssuanceType, TrackEventTypes } from "../../../utils/enums";
import {
  benefitNameWithId,
  capitalizeFirstLetter,
  getPageNameByRoute,
} from "../../../utils/functions";
import { DECIMAL_PLACES } from "../../../utils/constants";
import { ListGroup } from "react-bootstrap";

const ActiveCard = (props) => {
  const navigate = useNavigate();
  const trackEventsAdobeAnalytics = useTrackEvents();

  //For Cashback
  const isSpend = props.data.benefit.qualifyingThresholdValue > 0;

  const isSpendTargetAchieved =
    props.data.benefit.qualifyingThresholdValue <=
    props.data.currentCycleTotalQulaifyingSpend;

  const isTransaction = props.data?.benefit?.minQualifyingTxnCount > 0;

  const netQualifyingTransactions =
    props.data?.benefitCycles[0]?.accountTransactions?.reduce(
      (acc, transaction) => {
        if (transaction.transactionNature === "DEBIT") {
          acc += 1;
        } else if (transaction.transactionNature === "CREDIT") {
          acc -= 1;
        }
        return acc;
      },
      0
    ) || 0;

  const isTransactionTargetAchieved =
    netQualifyingTransactions >= props.data?.benefit?.minQualifyingTxnCount;

  const isTargetAchieved =
    isSpend && isTransaction
      ? isSpendTargetAchieved && isTransactionTargetAchieved
      : isSpend
      ? isSpendTargetAchieved
      : isTransaction
      ? isTransactionTargetAchieved
      : true;
  const titleText = (data) => {
    switch (data.benefit.type) {
      case "FUEL_SURCHARGE_WAIVER":
        if (data.currentCycleCashbackAccrued !== 0) {
          return `₹${Number(
            data.currentCycleCashbackAccrued || 0
          ).toLocaleString("en-IN")} saved on Fuel Surcharge`;
        } else {
          return `Start spending to earn Fuel Surcharge waiver`;
        }

      case "CASHBACK":
        const spendAmmount =
          props.data.benefit.qualifyingThresholdValue -
            props.data.currentCycleTotalQulaifyingSpend >=
          0
            ? props.data.benefit.qualifyingThresholdValue -
              props.data.currentCycleTotalQulaifyingSpend
            : 0;

        const transactionCount =
          props.data.benefit.minQualifyingTxnCount -
            netQualifyingTransactions >=
          0
            ? props.data.benefit.minQualifyingTxnCount -
              netQualifyingTransactions
            : 0;

        if (isSpend && isTransaction && !isTargetAchieved) {
          return `${
            !isSpendTargetAchieved ? `Spend ${spendAmmount} more` : ""
          }${
            !isSpendTargetAchieved && !isTransactionTargetAchieved
              ? ` and `
              : ""
          }${
            !isTransactionTargetAchieved
              ? `complete ${transactionCount} transactions`
              : ""
          } to unlock ${data.benefit.subtitle}.`;
        } else if (isSpend && !isTargetAchieved) {
          return `Spend ₹${Number(
            data.benefit.qualifyingThresholdValue -
              data.currentCycleTotalQulaifyingSpend
          ).toLocaleString("en-IN")} to unlock ${data.benefit.subtitle}`;
        } else if (isTransaction && !isTargetAchieved) {
          return `Do ${
            data.benefit.minQualifyingTxnCount - netQualifyingTransactions
          } more transactions to unlock ${data.benefit.subtitle}`;
        } else {
          return `₹${Number(
            data.currentCycleCashbackAccrued || 0
          ).toLocaleString("en-IN")} Cashback earned`;
        }

      case "AIRPORT_LOUNGE":
        const { benefit, currentCycleTotalQulaifyingSpend, voucher } =
          props.data || {};
        const vouchersAvailable =
          benefit.vouchersToBeIssued -
          voucher.filter((item) => item.status === "REDEEMED").length;
        const spendRemaining =
          benefit.qualifyingThresholdValue - currentCycleTotalQulaifyingSpend;

        const isEndOfPeriod =
          benefit?.benefitIssuanceType === BenefitIssuanceType.END_OF_PERIOD;
        const isTargetReached =
          benefit.qualifyingThresholdValue <= currentCycleTotalQulaifyingSpend;
        const isMilestone = benefit.qualifyingThresholdValue > 0;

        if (isMilestone) {
          if (!isTargetReached) {
            return `Spend ₹${Number(
              spendRemaining.toFixed(DECIMAL_PLACES)
            ).toLocaleString("en-IN")} more to ${
              isEndOfPeriod
                ? "unlock your Lounge Access"
                : "avoid additional charges"
            }`;
          } else {
            return `${vouchersAvailable.toLocaleString(
              "en-IN"
            )} Voucher(s) available`;
          }
        }

        return vouchersAvailable === 0
          ? "No Voucher(s) available"
          : `${vouchersAvailable.toLocaleString("en-IN")} Voucher(s) available`;

      case "POSHVINE_VOUCHER":
        if (
          data.benefit.qualifyingThresholdValue <=
          data.currentCycleTotalQulaifyingSpend
        ) {
          return `Voucher unlocked`;
        } else {
          if (data.benefit.benefitIssuanceType !== "IMMEDIATE") {
            return `Spend ₹${(
              props.data.benefit.qualifyingThresholdValue -
              props.data.currentCycleTotalQulaifyingSpend
            ).toLocaleString("en-IN")} more to unlock membership`;
          } else {
            return `Spend ₹${(
              props.data.benefit.qualifyingThresholdValue -
              props.data.currentCycleTotalQulaifyingSpend
            ).toLocaleString("en-IN")} more to avoid additional charges`;
          }
        }

      case "REWARD_ACCELERATOR":
        var totalRewards = 0;
        for (
          var i = 0;
          i < data.benefitCycles[0].accountTransactions.length;
          i++
        ) {
          totalRewards =
            totalRewards +
            data.benefitCycles[0].accountTransactions[i].rewardPoints;
        }
        const maxBenefitValue = data.benefit.maxBenefitValue;
        if (totalRewards === 0) {
          return "Start spending to earn Reward Points";
        } else {
          return `${Number(totalRewards >= maxBenefitValue ? maxBenefitValue : totalRewards).toLocaleString(
            "en-IN"
          )} points earned`;
        }
      default:
        return `Features Title`;
    }
  };

  const validityMessage = (data) => {
    switch (data.benefit.type) {
      case "FUEL_SURCHARGE_WAIVER":
        return `Continue to enjoy this feature till ${moment(
          data.endDate
        ).format("DD MMM")}`;
      case "CASHBACK":
        if (!isTargetAchieved) {
          return `Meet spend limit by ${moment(data.currentCycleEndDate).format(
            "DD MMM"
          )}  to unlock features`;
        } else {
          return `Continue to enjoy this feature till ${moment(
            data.endDate
          ).format("DD MMM")}`;
        }

      case "REWARD_ACCELERATOR":
        return `Continue to enjoy this feature till ${moment(
          data.endDate
        ).format("DD MMM")}`;
      case "AIRPORT_LOUNGE":
        return `Visit domestic airport lounge till ${moment(
          data.endDate
        ).format("DD MMM")}`;
      case "POSHVINE_VOUCHER":
        if (
          data.benefit.benefitIssuanceType !== "IMMEDIATE" &&
          data.benefit.qualifyingThresholdValue >=
            data.currentCycleTotalQulaifyingSpend
        ) {
          return `Meet the spend target by  ${moment(data.endDate).format(
            "DD MMM"
          )} to unlock`;
        } else {
          return `Redeem the voucher by ${moment(data.endDate).format(
            "DD MMM"
          )}`;
        }

      default:
        return `continue earning points `;
    }
  };

  const handleGoToDetails = (id) => {
    navigate("/Features/ManageFeatures/Feature", {
      state: { id: id || 1 },
    });
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.RIGHT_ARROW_CLICK,
    });
  };

  return (
    <View
      p="16px"
      bgColor={props.data.benefit.color || "#C9E5BF"}
      borderRadius="8px"
      mb="4px"
    >
      <View
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb="8px"
      >
        <View w="77%">
          <View display="inline">
            <View
              px="8px"
              py="4px"
              bg={props.data.benefit.titleBgColor || "light.50:alpha.40"}
              borderRadius="4px"
              flex="1"
              mb="6px"
              display="inline-block"
            >
              <Text
                opacity="100%"
                color="#303030"
                fontWeight="600"
                fontFamily="Quicksand"
                fontSize="12px"
                lineHeight="18px"
              >
                {props.data.benefit.subtitle}
              </Text>
            </View>
          </View>
          <View w="100%">
            <Text
              color="#303030"
              fontWeight="700"
              fontFamily="Quicksand"
              fontSize="20px"
              lineHeight="28px"
            >
              {capitalizeFirstLetter(titleText(props.data))}
            </Text>
          </View>
        </View>
        <View w="23%">
          <Image
            source={{
              uri: props.data.benefit.displayPrimaryImageLocation || benefit,
            }}
            height="80px"
            width="80px"
            alt=""
          />
        </View>
      </View>
      <View
        w="90%"
        mb="16px"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
      >
        <View w="95%" alignItems="center" flexDirection="row">
          <Image
            display={
              props.data.benefit.type === "CASHBACK" && !isTargetAchieved
                ? "flex"
                : "none"
            }
            source={{
              uri: process.env.REACT_APP_AWS_S3_BUCKET_URL + "lock.svg",
            }}
            height="20px"
            width="20px"
            alt=""
            mr="4px"
          />
          <Text
            color="#303030"
            fontWeight="600"
            fontFamily="Quicksand"
            fontSize="12px"
            lineHeight="20px"
          >
            {validityMessage(props.data)}
          </Text>
        </View>
        <View w="3%">
          <View
            bgColor="none"
            cursor="pointer"
            onClick={() => handleGoToDetails(props.data.id)}
          >
            {/* <Image
              source={{ uri: ViewDetails }}
              height="24px"
              width="24px"
              alt=""
            /> */}
            <Text
              color="#303030"
              fontWeight="600"
              fontFamily="Quicksand"
              fontSize="26px"
              lineHeight="20px"
            >
              {`>`}
            </Text>
          </View>
        </View>
      </View>
      {props.data.benefit.type !== "FUEL_SURCHARGE_WAIVER" &&
        props.data.benefit.type !== "POSHVINE_VOUCHER" &&
        props.data.benefit.type !== "AIRPORT_LOUNGE" && (
          <View borderStyle="dotted" borderTopWidth="1px">
            <Text
              color="#303030"
              opacity="0.8"
              fontWeight="500"
              fontFamily="Quicksand"
              fontSize="12px"
              lineHeight="20px"
              mt="8px"
            >
              {props.data.benefit.type === "CASHBACK"
                ? "*Cashback will be credited post completion of cycle"
                : "*Rewards can be redeemed post statement generation"}
            </Text>
          </View>
        )}
    </View>
  );
};

const AddingCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const manageBenefits = useSelector((state) => state.manageBenefits);
  const [count, setCount] = useState(0);
  const location = useLocation();
  const groupingName = manageBenefits.benefitsList.filter((item) => {
    if (props.data.benefitGroup !== null && item.benefitGroup !== null) {
      return item.benefitGroup.id === props.data.benefitGroup.id;
    }
  });

  const trackEventsAdobeAnalytics = useTrackEvents();
  const handleAddBenefitEvent = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.ADD_BENEFIT,
      benefitsName: [benefitNameWithId(props?.data?.title, props?.data?.id)],
      linkPageName: getPageNameByRoute(location.pathname),
    });
  };

  const handleRemoveBenefitEvent = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.REMOVE_BENEFIT,
      benefitsName: [benefitNameWithId(props?.data?.title, props?.data?.id)],
      walletBalance: manageBenefits?.walletDetails?.totalBalanceAmount,
      linkPageName: getPageNameByRoute(location.pathname),
    });
  };
  const addLoungeCount = () => {
    if (manageBenefits.benefitsListIdCount[props.data.id.toString()]) {
      let group =
        manageBenefits.benefitsGroupIdCount +
        Number(props.data.vouchersToBeIssued);

      let item = manageBenefits.benefitsListIdCount;
      item[props.data.id.toString()] =
        manageBenefits.benefitsListIdCount[props.data.id.toString()] + 1;
      dispatch(
        setLoungeCount({
          benefitsGroupIdCount: group,
          benefitsListIdCount: item,
        })
      );
      dispatch(
        setBenefitsAddition({
          benefits: [...manageBenefits.benefitsList, props.data],
          benefitsListId: [...manageBenefits.benefitsListId, props.data.id],
          benefitsGroupId: [...manageBenefits.benefitsGroupId],
        })
      );
    } else {
      dispatch(
        setBenefitsAddition({
          benefits: [...manageBenefits.benefitsList, props.data],
          benefitsListId: [...manageBenefits.benefitsListId, props.data.id],
          benefitsGroupId: [...manageBenefits.benefitsGroupId],
        })
      );

      let group =
        manageBenefits.benefitsGroupIdCount +
        Number(props.data.vouchersToBeIssued);
      let item = manageBenefits.benefitsListIdCount;
      item[props.data.id.toString()] = 1;
      dispatch(
        setLoungeCount({
          benefitsGroupIdCount: group,
          benefitsListIdCount: item,
        })
      );
    }
  };

  const removeLoungeCount = () => {
    if (manageBenefits.benefitsListIdCount[props.data.id.toString()] !== 1) {
      let group =
        manageBenefits.benefitsGroupIdCount -
        Number(props.data.vouchersToBeIssued);
      let item = manageBenefits.benefitsListIdCount;
      item[props.data.id.toString()] =
        manageBenefits.benefitsListIdCount[props.data.id.toString()] - 1;
      dispatch(
        setLoungeCount({
          benefitsGroupIdCount: group,
          benefitsListIdCount: item,
        })
      );
      let tempList = [];
      let tempListId = [];
      let tempListLoungeCount = 0;
      for (let i = 0; i < manageBenefits.benefitsList.length; i++) {
        if (manageBenefits.benefitsList[i].id !== props.id) {
          tempList.push(manageBenefits.benefitsList[i]);
          tempListId.push(manageBenefits.benefitsList[i].id);
        } else {
          if (tempListLoungeCount < 1) {
            tempListLoungeCount = tempListLoungeCount + 1;
          } else {
            tempList.push(manageBenefits.benefitsList[i]);
            tempListId.push(manageBenefits.benefitsList[i].id);
          }
        }
      }
      dispatch(
        setBenefitsAddition({
          benefits: tempList,
          benefitsListId: tempListId,
          benefitsGroupId: [...manageBenefits.benefitsGroupId],
        })
      );
    } else {
      dispatch(
        setBenefitsAddition({
          benefits: manageBenefits.benefitsList.filter((item) => {
            return item.id !== props.id;
          }),
          benefitsListId: manageBenefits.benefitsListId.filter((item) => {
            return item !== props.id;
          }),
          benefitsGroupId: [...manageBenefits.benefitsGroupId],
        })
      );

      let group =
        manageBenefits.benefitsGroupIdCount -
        Number(props.data.vouchersToBeIssued);
      let item = manageBenefits.benefitsListIdCount;
      item[props.data.id.toString()] = undefined;
      dispatch(
        setLoungeCount({
          benefitsGroupIdCount: group,
          benefitsListIdCount: item,
        })
      );
    }
  };

  return props.data.benefitGroup !== null ? (
    <View
      backgroundColor={
        manageBenefits.benefitsGroupId.includes(props.data.benefitGroup.id) &&
        !manageBenefits.benefitsListId.includes(props.data.id)
          ? "#EBEDF0"
          : props.data.color
          ? `${props.data.color}:alpha.40`
          : null
      }
      zIndex={0}
      borderRadius={"8px"}
    >
      <View
        alignItems="center"
        borderRadius="8px"
        backgroundColor={
          manageBenefits.benefitsGroupId.includes(props.data.benefitGroup.id) &&
          !manageBenefits.benefitsListId.includes(props.data.id)
            ? "#EBEDF0"
            : props.data.color
        }
        p="16px"
      >
        <View w="100%">
          <View>
            <Text
              color="#303030"
              fontWeight="700"
              fontFamily="Quicksand"
              fontSize="20px"
              lineHeight="24px"
            >
              {props.data.title}
            </Text>
          </View>
          <View
            mt="6px"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <View w="60%">
              {props.data.description && (
                <View flexDirection="row" mt="8px">
                  <Text
                    color="#303030"
                    fontWeight="500"
                    fontFamily="Quicksand"
                    fontSize="12px"
                    lineHeight="18px"
                  >
                    {props.data.description}
                  </Text>
                </View>
              )}
              <View flexDirection="row" alignItems="center">
                <Text
                  color="#303030"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="16px"
                  lineHeight="24px"
                >
                  {`₹${props.data.price} `}
                </Text>
                <Text
                  color="#303030"
                  fontWeight="500"
                  fontFamily="Quicksand"
                  fontSize="12px"
                  lineHeight="20px"
                >
                  {`(for ${props.data.periodDays} days)`}
                </Text>
              </View>
            </View>
            <View>
              {manageBenefits.benefitsGroupId.includes(
                props.data.benefitGroup.id
              ) &&
                !manageBenefits.benefitsListId.includes(props.data.id) && (
                  <View
                    position="absolute"
                    w="64px"
                    h="64px"
                    bg={"light.50:alpha.40"}
                  />
                )}
              <img
                src={props.data.displayPrimaryImageLocation}
                height="64px"
                width="64px"
                alt=""
              />
            </View>
          </View>
        </View>
        {props.data.subscribed && props.data.type !== "AIRPORT_LOUNGE" ? (
          <View
            mt="16px"
            py="10px"
            w="100%"
            bg={"light.50:alpha.40"}
            alignItems="center"
            justifyContent="center"
            borderRadius="4px"
          >
            <Text
              color="#303030"
              fontWeight="700"
              fontFamily="Quicksand"
              fontSize="14px"
              lineHeight="18px"
            >
              Already Active
            </Text>
          </View>
        ) : manageBenefits.benefitsGroupId.includes(
            props.data.benefitGroup.id
          ) &&
          !manageBenefits.benefitsListId.includes(props.data.id) &&
          props.data.type !== "AIRPORT_LOUNGE" ? (
          <>
            <View
              mt="16px"
              w="100%"
              py="10px"
              px="8px"
              bg={"light.50:alpha.40"}
              alignItems="center"
              justifyContent="center"
              borderRadius="4px"
            >
              <Text
                color="#303030"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="14px"
                lineHeight="18px"
              >
                {`Already Selected ${
                  groupingName.length > 0
                    ? groupingName[0].subtitle
                    : "other features from group"
                }`}
              </Text>
            </View>
          </>
        ) : props.data.type === "AIRPORT_LOUNGE" ? (
          <View
            w="100%"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mt="16px"
          >
            {!Boolean(
              manageBenefits.benefitsListIdCount[props.data.id.toString()]
            ) && !manageBenefits.benefitsListId.includes(props.data.id) ? (
              <View
                flexDirection="row"
                alignItems="center"
                backgroundColor="#ffffff"
                cursor="pointer"
                shadow="1"
                borderRadius="4px"
                px="26px"
                py="12px"
                onClick={() => {
                  handleAddBenefitEvent();
                  manageBenefits.benefitsGroupIdCount +
                    Number(props.data.vouchersToBeIssued) <=
                  manageBenefits.maxLoungeCount
                    ? addLoungeCount()
                    : console.log("");
                }}
                opacity={
                  manageBenefits.benefitsGroupIdCount +
                    Number(props.data.vouchersToBeIssued) <=
                  manageBenefits.maxLoungeCount
                    ? "1"
                    : "0.6"
                }
              >
                <Image
                  source={{
                    uri: process.env.REACT_APP_AWS_S3_BUCKET_URL + "add.svg",
                  }}
                  height="12px"
                  width="12px"
                  alt=""
                />
                <Text
                  ml="8px"
                  color="#F37435"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="14px"
                  lineHeight="22px"
                >
                  Add
                </Text>
              </View>
            ) : (
              <View
                flexDirection="row"
                alignItems="center"
                justifyContent={"space-between"}
                backgroundColor="#ffffff"
                cursor="pointer"
                shadow="1"
                borderRadius="4px"
                px="26px"
                py="12px"
              >
                <View cursor="pointer" onClick={() => removeLoungeCount()}>
                  <Image
                    source={{
                      uri:
                        process.env.REACT_APP_AWS_S3_BUCKET_URL + "minus.svg",
                    }}
                    height="16px"
                    width="16px"
                    alt=""
                  />
                </View>
                <Text
                  mx="8px"
                  color="#F37435"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="14px"
                  lineHeight="22px"
                >
                  {manageBenefits.benefitsListIdCount[props.data.id.toString()]}
                </Text>
                <View
                  cursor="pointer"
                  onClick={() => {
                    manageBenefits.benefitsGroupIdCount +
                      Number(props.data.vouchersToBeIssued) <=
                    manageBenefits.maxLoungeCount
                      ? addLoungeCount()
                      : console.log("");
                  }}
                  opacity={
                    manageBenefits.benefitsGroupIdCount +
                      Number(props.data.vouchersToBeIssued) <=
                    manageBenefits.maxLoungeCount
                      ? "1"
                      : "0.6"
                  }
                >
                  <Image
                    source={{
                      uri: process.env.REACT_APP_AWS_S3_BUCKET_URL + "add.svg",
                    }}
                    height="12px"
                    width="12px"
                    alt=""
                  />
                </View>
              </View>
            )}

            <View
              cursor="pointer"
              onClick={() => {
                dispatch(
                  setCurrentBenefit({
                    benefit: props.data,
                  })
                );
                trackEventsAdobeAnalytics({
                  linkName: TrackEventTypes.VIEW_DETAILS,
                  benefitsName: [
                    benefitNameWithId(props?.data?.title, props?.data?.id),
                  ],
                  linkPageName: getPageNameByRoute(location.pathname),
                });
                if (!props.isFullList) {
                  if (props.isOnboarding) {
                    navigate("/Features/Onboarding/FeatureDetails", {
                      state: { category: props.activeType },
                    });
                  } else {
                    navigate("/Features/ManageFeatures/FeatureDetails", {
                      state: { category: props.activeType },
                    });
                  }
                } else {
                  navigate("/Features/ManageFeatures/FeatureDetails");
                }
              }}
            >
              <Text
                color="#303030"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="14px"
                lineHeight="22px"
                underline
              >
                View Details
              </Text>
            </View>
          </View>
        ) : (
          <View
            w="100%"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mt="16px"
          >
            {!manageBenefits.benefitsListId.includes(props.data.id) ? (
              <View
                flexDirection="row"
                alignItems="center"
                backgroundColor="#ffffff"
                cursor="pointer"
                shadow="1"
                borderRadius="4px"
                px="26px"
                py="12px"
                onClick={() => {
                  handleAddBenefitEvent();
                  dispatch(
                    setBenefitsAddition({
                      benefits: [...manageBenefits.benefitsList, props.data],
                      benefitsListId: [
                        ...manageBenefits.benefitsListId,
                        props.data.id,
                      ],
                      benefitsGroupId: [
                        ...manageBenefits.benefitsGroupId,
                        props.data.benefitGroup.id,
                      ],
                    })
                  );
                }}
              >
                <Image
                  source={{
                    uri: process.env.REACT_APP_AWS_S3_BUCKET_URL + "add.svg",
                  }}
                  height="12px"
                  width="12px"
                  alt=""
                />
                <Text
                  ml="8px"
                  color="#F37435"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="14px"
                  lineHeight="22px"
                >
                  Add
                </Text>
              </View>
            ) : (
              <View
                flexDirection="row"
                alignItems="center"
                backgroundColor="#ffffff"
                cursor="pointer"
                shadow="1"
                borderRadius="4px"
                px="16px"
                py="9px"
                onClick={() => {
                  handleRemoveBenefitEvent();
                  dispatch(
                    setBenefitsAddition({
                      benefits: manageBenefits.benefitsList.filter((item) => {
                        return item.id !== props.id;
                      }),
                      benefitsListId: manageBenefits.benefitsListId.filter(
                        (item) => {
                          return item !== props.id;
                        }
                      ),
                      benefitsGroupId: manageBenefits.benefitsGroupId.filter(
                        (item) => {
                          return item !== props.data.benefitGroup.id;
                        }
                      ),
                    })
                  );
                }}
              >
                <Text
                  color="#F37435"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="14px"
                  lineHeight="22px"
                >
                  Added
                </Text>
                <Close ml="4px" size="24" color="#F37435" />
              </View>
            )}
            <View
              cursor="pointer"
              onClick={() => {
                dispatch(
                  setCurrentBenefit({
                    benefit: props.data,
                  })
                );
                trackEventsAdobeAnalytics({
                  linkName: TrackEventTypes.VIEW_DETAILS,
                  benefitsName: [
                    benefitNameWithId(props?.data?.title, props?.data?.id),
                  ],
                  linkPageName: getPageNameByRoute(location.pathname),
                });
                if (!props.isFullList) {
                  if (props.isOnboarding) {
                    navigate("/Features/Onboarding/FeatureDetails", {
                      state: { category: props.activeType },
                    });
                  } else {
                    navigate("/Features/ManageFeatures/FeatureDetails", {
                      state: { category: props.activeType },
                    });
                  }
                } else {
                  navigate("/Features/ManageFeatures/FeatureDetails");
                }
              }}
            >
              <Text
                color="#303030"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="14px"
                lineHeight="22px"
                underline
              >
                View Details
              </Text>
            </View>
          </View>
        )}
      </View>
      {props?.data?.nudgeDetails?.text &&
        (props.data.type !== "AIRPORT_LOUNGE"
          ? props.data.benefitGroup.id
            ? !manageBenefits.benefitsGroupId.includes(
                props.data.benefitGroup.id
              ) && (
                <View
                  padding={"4px"}
                  display={"flex"}
                  alignItems={"flex-start"}
                  justifyContent={"center"}
                >
                  {" "}
                  <Text
                    fontFamily="Quicksand"
                    fontStyle="normal"
                    fontWeight="500"
                    fontSize="12px"
                    lineHeight="20px"
                  >{`🔥 ${props?.data?.nudgeDetails?.text}`}</Text>
                </View>
              )
            : !props.data.subscribed && (
                <View
                  padding={"4px"}
                  display={"flex"}
                  alignItems={"flex-start"}
                  justifyContent={"center"}
                >
                  {" "}
                  <Text
                    fontFamily="Quicksand"
                    fontStyle="normal"
                    fontWeight="500"
                    fontSize="12px"
                    lineHeight="20px"
                  >{`🔥 ${props?.data?.nudgeDetails?.text}`}</Text>
                </View>
              )
          : manageBenefits.benefitsGroupIdCount +
              Number(props.data.vouchersToBeIssued) <=
              manageBenefits.maxLoungeCount && (
              <View
                padding={"4px"}
                display={"flex"}
                alignItems={"flex-start"}
                justifyContent={"center"}
              >
                {" "}
                <Text
                  fontFamily="Quicksand"
                  fontStyle="normal"
                  fontWeight="500"
                  fontSize="12px"
                  lineHeight="20px"
                >{`🔥 ${props?.data?.nudgeDetails?.text}`}</Text>
              </View>
            ))}
    </View>
  ) : (
    <View
      backgroundColor={props.data.color ? `${props.data.color}:alpha.40` : null}
      zIndex={0}
      borderRadius={"8px"}
    >
      <View
        alignItems="center"
        borderRadius="8px"
        backgroundColor={props.data.color}
        p="16px"
      >
        <View w="100%">
          <View>
            <Text
              color="#303030"
              fontWeight="700"
              fontFamily="Quicksand"
              fontSize="20px"
              lineHeight="24px"
            >
              {props.data.title}
            </Text>
          </View>
          <View
            mt="6px"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <View w="60%">
              {props.data.description && (
                <View flexDirection="row" mt="8px">
                  <Text
                    color="#303030"
                    fontWeight="500"
                    fontFamily="Quicksand"
                    fontSize="12px"
                    lineHeight="18px"
                  >
                    {props.data.description}
                  </Text>
                </View>
              )}
              <View flexDirection="row" alignItems="center">
                <Text
                  color="#303030"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="16px"
                  lineHeight="24px"
                >
                  {`₹${props.data.price} `}
                </Text>
                <Text
                  color="#303030"
                  fontWeight="500"
                  fontFamily="Quicksand"
                  fontSize="12px"
                  lineHeight="20px"
                >
                  {`(for ${props.data.periodDays} days)`}
                </Text>
              </View>
            </View>
            <View>
              <img
                src={props.data.displayPrimaryImageLocation}
                height="64px"
                width="64px"
                alt=""
              />
            </View>
          </View>
        </View>
        {props.data.subscribed && props.data.type !== "AIRPORT_LOUNGE" ? (
          <View
            mt="18px"
            py="10px"
            w="90%"
            bg={"light.50:alpha.40"}
            alignItems="center"
            justifyContent="center"
            borderRadius="4px"
          >
            <Text
              color="#303030"
              fontWeight="700"
              fontFamily="Quicksand"
              fontSize="14px"
              lineHeight="18px"
            >
              Already Active
            </Text>
          </View>
        ) : props.data.type === "AIRPORT_LOUNGE" ? (
          <View
            w="100%"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mt="16px"
          >
            {!Boolean(
              manageBenefits.benefitsListIdCount[props.data.id.toString()]
            ) && !manageBenefits.benefitsListId.includes(props.data.id) ? (
              <View
                flexDirection="row"
                alignItems="center"
                backgroundColor="#ffffff"
                cursor="pointer"
                shadow="1"
                borderRadius="4px"
                px="26px"
                py="12px"
                onClick={() => {
                  handleAddBenefitEvent();
                  manageBenefits.benefitsGroupIdCount +
                    Number(props.data.vouchersToBeIssued) <=
                  manageBenefits.maxLoungeCount
                    ? addLoungeCount()
                    : console.log("");
                }}
                opacity={
                  manageBenefits.benefitsGroupIdCount +
                    Number(props.data.vouchersToBeIssued) <=
                  manageBenefits.maxLoungeCount
                    ? "1"
                    : "0.6"
                }
              >
                <Image
                  source={{
                    uri: process.env.REACT_APP_AWS_S3_BUCKET_URL + "add.svg",
                  }}
                  height="12px"
                  width="12px"
                  alt=""
                />
                <Text
                  ml="8px"
                  color="#F37435"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="14px"
                  lineHeight="22px"
                >
                  Add
                </Text>
              </View>
            ) : (
              <View
                flexDirection="row"
                alignItems="center"
                justifyContent={"space-between"}
                backgroundColor="#ffffff"
                cursor="pointer"
                shadow="1"
                borderRadius="4px"
                px="26px"
                py="12px"
              >
                <View cursor="pointer" onClick={() => removeLoungeCount()}>
                  <Image
                    source={{
                      uri:
                        process.env.REACT_APP_AWS_S3_BUCKET_URL + "minus.svg",
                    }}
                    height="16px"
                    width="16px"
                    alt=""
                  />
                </View>
                <Text
                  mx="8px"
                  color="#F37435"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="14px"
                  lineHeight="22px"
                >
                  {manageBenefits.benefitsListIdCount[props.data.id.toString()]}
                </Text>
                <View
                  cursor="pointer"
                  onClick={() => {
                    manageBenefits.benefitsGroupIdCount +
                      Number(props.data.vouchersToBeIssued) <=
                    manageBenefits.maxLoungeCount
                      ? addLoungeCount()
                      : console.log("");
                  }}
                  opacity={
                    manageBenefits.benefitsGroupIdCount +
                      Number(props.data.vouchersToBeIssued) <=
                    manageBenefits.maxLoungeCount
                      ? "1"
                      : "0.6"
                  }
                >
                  <Image
                    source={{
                      uri: process.env.REACT_APP_AWS_S3_BUCKET_URL + "add.svg",
                    }}
                    height="12px"
                    width="12px"
                    alt=""
                  />
                </View>
              </View>
            )}

            <View
              cursor="pointer"
              onClick={() => {
                dispatch(
                  setCurrentBenefit({
                    benefit: props.data,
                  })
                );
                trackEventsAdobeAnalytics({
                  linkName: TrackEventTypes.VIEW_DETAILS,
                  benefitsName: [
                    benefitNameWithId(props?.data?.title, props?.data?.id),
                  ],
                  linkPageName: getPageNameByRoute(location.pathname),
                });
                if (!props.isFullList) {
                  if (props.isOnboarding) {
                    navigate("/Features/Onboarding/FeatureDetails", {
                      state: { category: props.activeType },
                    });
                  } else {
                    navigate("/Features/ManageFeatures/FeatureDetails", {
                      state: { category: props.activeType },
                    });
                  }
                } else {
                  navigate("/Features/ManageFeatures/FeatureDetails");
                }
              }}
            >
              <Text
                color="#303030"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="14px"
                lineHeight="22px"
                underline
              >
                View Details
              </Text>
            </View>
          </View>
        ) : (
          <View
            w="100%"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mt="18px  "
          >
            {!manageBenefits.benefitsListId.includes(props.data.id) ? (
              <View
                flexDirection="row"
                alignItems="center"
                backgroundColor="#ffffff"
                cursor="pointer"
                shadow="1"
                borderRadius="4px"
                px="26px"
                py="12px"
                onClick={() => {
                  handleAddBenefitEvent();
                  dispatch(
                    setBenefitsAddition({
                      benefits: [...manageBenefits.benefitsList, props.data],
                      benefitsListId: [
                        ...manageBenefits.benefitsListId,
                        props.data.id,
                      ],
                      benefitsGroupId: [...manageBenefits.benefitsGroupId],
                    })
                  );
                }}
              >
                <Image
                  source={{
                    uri: process.env.REACT_APP_AWS_S3_BUCKET_URL + "add.svg",
                  }}
                  height="12px"
                  width="12px"
                  alt=""
                />
                <Text
                  ml="8px"
                  color="#F37435"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="14px"
                  lineHeight="18px"
                >
                  Add
                </Text>
              </View>
            ) : (
              <View
                flexDirection="row"
                alignItems="center"
                backgroundColor="#ffffff"
                cursor="pointer"
                shadow="1"
                borderRadius="4px"
                px="16px"
                py="9px"
                onClick={() => {
                  handleRemoveBenefitEvent();
                  dispatch(
                    setBenefitsAddition({
                      benefits: manageBenefits.benefitsList.filter((item) => {
                        return item.id !== props.id;
                      }),
                      benefitsListId: manageBenefits.benefitsListId.filter(
                        (item) => {
                          return item !== props.id;
                        }
                      ),
                      benefitsGroupId: [...manageBenefits.benefitsGroupId],
                    })
                  );
                }}
              >
                <Text
                  color="#F37435"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="14px"
                  lineHeight="18px"
                >
                  Added
                </Text>
                <Close ml="4px" size="24" color="#F37435" />
              </View>
            )}
            <View
              cursor="pointer"
              onClick={() => {
                dispatch(
                  setCurrentBenefit({
                    benefit: props.data,
                  })
                );
                trackEventsAdobeAnalytics({
                  linkName: TrackEventTypes.VIEW_DETAILS,
                  benefitsName: [
                    benefitNameWithId(props?.data?.title, props?.data?.id),
                  ],
                  linkPageName: getPageNameByRoute(location.pathname),
                });
                if (!props.isFullList) {
                  if (props.isOnboarding) {
                    navigate("/Features/Onboarding/FeatureDetails", {
                      state: { category: props.activeType },
                    });
                  } else {
                    navigate("/Features/ManageFeatures/FeatureDetails", {
                      state: { category: props.activeType },
                    });
                  }
                } else {
                  navigate("/Features/ManageFeatures/FeatureDetails");
                }
              }}
            >
              <Text
                color="#303030"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="14px"
                lineHeight="22px"
                underline
              >
                View Details
              </Text>
            </View>
          </View>
        )}
      </View>
      {props?.data?.nudgeDetails?.text &&
        (props.data.type !== "AIRPORT_LOUNGE"
          ? !props.data.subscribed && (
              <View
                padding={"4px"}
                display={"flex"}
                alignItems={"flex-start"}
                justifyContent={"center"}
              >
                {" "}
                <Text
                  fontFamily="Quicksand"
                  fontStyle="normal"
                  fontWeight="500"
                  fontSize="12px"
                  lineHeight="20px"
                >{`🔥 ${props?.data?.nudgeDetails?.text}`}</Text>
              </View>
            )
          : manageBenefits.benefitsGroupIdCount +
              Number(props.data.vouchersToBeIssued) <=
              manageBenefits.maxLoungeCount && (
              <View
                padding={"4px"}
                display={"flex"}
                alignItems={"flex-start"}
                justifyContent={"center"}
              >
                {" "}
                <Text
                  fontFamily="Quicksand"
                  fontStyle="normal"
                  fontWeight="500"
                  fontSize="12px"
                  lineHeight="20px"
                >{`🔥 ${props?.data?.nudgeDetails?.text}`}</Text>
              </View>
            ))}
    </View>
  );
};

const SummaryCard = (props) => {
  const manageBenefits = useSelector((state) => state.manageBenefits);
  const dispatch = useDispatch();
  const trackEventsAdobeAnalytics = useTrackEvents();
  const location = useLocation();

  const remove = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.DELETE_BENEFIT,
      benefitsName: [benefitNameWithId(props?.data?.title, props?.data?.id)],
      linkPageName: getPageNameByRoute(location.pathname),
    });
    if (props.data.type === "AIRPORT_LOUNGE") {
      if (manageBenefits.benefitsListIdCount[props.data.id.toString()] !== 1) {
        let group =
          manageBenefits.benefitsGroupIdCount -
          Number(props.data.vouchersToBeIssued);
        let item = manageBenefits.benefitsListIdCount;
        item[props.data.id.toString()] =
          manageBenefits.benefitsListIdCount[props.data.id.toString()] - 1;
        dispatch(
          setLoungeCount({
            benefitsGroupIdCount: group,
            benefitsListIdCount: item,
          })
        );
        let tempList = [];
        let tempListId = [];
        let tempListLoungeCount = 0;
        for (let i = 0; i < manageBenefits.benefitsList.length; i++) {
          if (manageBenefits.benefitsList[i].id !== props.id) {
            tempList.push(manageBenefits.benefitsList[i]);
            tempListId.push(manageBenefits.benefitsList[i].id);
          } else {
            if (tempListLoungeCount < 1) {
              tempListLoungeCount = tempListLoungeCount + 1;
            } else {
              tempList.push(manageBenefits.benefitsList[i]);
              tempListId.push(manageBenefits.benefitsList[i].id);
            }
          }
        }
        dispatch(
          setBenefitsAddition({
            benefits: tempList,
            benefitsListId: tempListId,
            benefitsGroupId: [...manageBenefits.benefitsGroupId],
          })
        );
      } else {
        dispatch(
          setBenefitsAddition({
            benefits: manageBenefits.benefitsList.filter((item) => {
              return item.id !== props.id;
            }),
            benefitsListId: manageBenefits.benefitsListId.filter((item) => {
              return item !== props.id;
            }),
            benefitsGroupId: [...manageBenefits.benefitsGroupId],
          })
        );

        let group =
          manageBenefits.benefitsGroupIdCount -
          Number(props.data.vouchersToBeIssued);
        let item = manageBenefits.benefitsListIdCount;
        item[props.data.id.toString()] = undefined;
        dispatch(
          setLoungeCount({
            benefitsGroupIdCount: group,
            benefitsListIdCount: item,
          })
        );
      }
    } else {
      dispatch(
        setBenefitsAddition({
          benefits: manageBenefits.benefitsList.filter((item) => {
            return item.id !== props.id;
          }),
          benefitsListId: manageBenefits.benefitsListId.filter((item) => {
            return item !== props.id;
          }),
          benefitsGroupId: [...manageBenefits.benefitsGroupId],
        })
      );
    }
  };
  return (
    <View
      flexDirection="row"
      justifyContent="space-betwee"
      alignItems="center"
      borderRadius="4px"
      borderWidth="2px"
      borderColor="#D7DBE0"
      p="12px"
    >
      <View
        bgColor={props.data.color || "#C9E5BF"}
        w="64px"
        h="64px"
        borderRadius="8px"
        alignItems="center"
        justifyContent="center"
      >
        <Image
          source={{ uri: props.data.displayPrimaryImageLocation || benefit }}
          height="48px"
          width="48px"
          alt=""
        />
      </View>
      <View w="67%" mx="8px">
        <View mb="2px">
          <Text
            color="#303030"
            fontWeight="700"
            fontFamily="Quicksand"
            fontSize="14px"
            lineHeight="20px"
          >
            {props.data.title}
          </Text>
        </View>
        <View flexDirection="row" alignItems="center">
          <Text
            color="#303030"
            fontWeight="500"
            fontFamily="Quicksand"
            fontSize="14px"
            lineHeight="22px"
          >
            {`₹${props.data.price} `}
          </Text>
          <Text
            color="#303030"
            fontWeight="500"
            fontFamily="Quicksand"
            fontSize="12px"
            lineHeight="22px"
          >
            {`(for ${props.data.periodDays} days)`}
          </Text>
        </View>
      </View>
      {props.data.benefitGroup ? (
        <View
          w="15%"
          cursor="pointer"
          onClick={() => {
            trackEventsAdobeAnalytics({
              linkName: TrackEventTypes.DELETE_BENEFIT,
              benefitsName: [
                benefitNameWithId(props?.data?.title, props?.data?.id),
              ],
              linkPageName: getPageNameByRoute(location.pathname),
            });
            dispatch(
              setBenefitsAddition({
                benefits: manageBenefits.benefitsList.filter((item) => {
                  return item.id !== props.id;
                }),
                benefitsListId: manageBenefits.benefitsListId.filter((item) => {
                  return item !== props.id;
                }),
                benefitsGroupId: manageBenefits.benefitsGroupId.filter(
                  (item) => {
                    return item !== props.data.benefitGroup.id;
                  }
                ),
              })
            );
          }}
        >
          <Image
            source={{
              uri: process.env.REACT_APP_AWS_S3_BUCKET_URL + "delete.svg",
            }}
            height="24px"
            width="24px"
            alt=""
          />
        </View>
      ) : (
        <View w="15%" cursor="pointer" onClick={() => remove()}>
          <Image
            source={{
              uri: process.env.REACT_APP_AWS_S3_BUCKET_URL + "delete.svg",
            }}
            height="24px"
            width="24px"
            alt=""
          />
        </View>
      )}
    </View>
  );
};

export { ActiveCard, AddingCard, SummaryCard };
