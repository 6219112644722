import { Text, View } from "native-base";
import React from "react";
import { useWindowDimensions } from "react-native-web";
import { useNavigate, useLocation } from "react-router-dom";
import { RWebShare } from "react-web-share";
import styles from "./styles";
import { Button } from "../../core";
import BackArrow2 from "../../../../assets/images/BackArrow2";
import Money from "../../../../assets/images/Money";
import Confetti from "../../../../assets/images/Confetti";
import Share from "../../../../assets/images/Share";
import { getHomeScreenPath } from "../../../../utils/functions";
import { Channels, Envs } from "../../../../utils/enums";
import { useSelector } from "react-redux";
import logger from "../../../../utils/logger";
import queryString from "query-string";

const shareText = `Wow! I have just won a wallet credit on my LIT Credit Card by playing AU Bank’s Spin the Wheel. You can earn wallet credit and customise your card as per your choice too!
Apply Now to get your own customisable credit card - 
`;
const shareUrl = process.env.REACT_APP_AU_ONBOARDING_URL;
const shareTitle = "Share";

const ShareButton = (
  // Core Button not working with RWebShare
  <div style={{ ...styles.shareButton }}>
    <View {...styles.shareButtonContent}>
      <Text {...styles.shareButtonText}>Share</Text>
      <Share {...styles.shareButtonIcon} />
    </View>
  </div>
);

const FortuneWheel = () => {
  const dimensions = useWindowDimensions();
  const navigate = useNavigate();
  const location = useLocation();
  const manageBenefits = useSelector((state) => state.manageBenefits);

  const targetBucket = location.state.targetBucket;
  const queryParams = queryString.parse(location.search);
  let accountId = queryParams.accountId || localStorage.getItem("cardId");

  return (
    <View bgColor="#FFDBD4" height="100%" overflowY="auto">
      <View {...styles.mainContainer} height={dimensions.height}>
        <View {...styles.headerContainer}>
          <View
            {...styles.backButtonContainer}
            onClick={() => navigate(getHomeScreenPath())}
          >
            <BackArrow2 {...styles.backButton} />
          </View>
          <Text {...styles.titleText}>Spin the Wheel</Text>
          <View></View>
        </View>
        <View {...styles.title2Container}>
          <Text {...styles.text2}>Congratulations!</Text>
          <Money {...styles.moneyImage} />
          <Confetti style={{ ...styles.confettiImage }} />
          <Text {...styles.text3}>You have won</Text>
          <Text {...styles.text4}>₹{targetBucket.rewardValue}</Text>
          <Text {...styles.text5}>
            Amount is credited to
            <br />
            your wallet
          </Text>
        </View>
        <View flex="1"></View>
        <View {...styles.footerContainer}>
          <Text {...styles.footerText}>#LIT #LIVEITTODAY</Text>
          <View {...styles.shareButtonContainer}>
            {window.navigator?.share ? (
              <View
                onClick={() => {
                  try {
                    window.navigator.share({
                      text: shareText,
                      url: shareUrl,
                      title: shareTitle,
                    });
                  } catch (error) {
                    logger.error({
                      message: `An exception occurred while navigating windows for accountId ${accountId}`,
                      error: error?.message,
                      accountId: accountId,
                      stackTrace: error?.stack,
                    });

                  }
                }}
              >
                {ShareButton}
              </View>
            ) : (
              <RWebShare
                data={{
                  url: `Wow! I have just won a wallet credit on my LIT Credit Card by playing AU Bank’s Spin the Wheel. You can earn wallet credit and customise your card as per your choice too! Apply Now to get your own customisable credit card - ${process.env.REACT_APP_AU_ONBOARDING_URL}`,
                  title: "share",
                }}
              >
                {ShareButton}
              </RWebShare>
            )}
          </View>
          <Button
            text="Activate Feature"
            {...styles.submitButton}
            onPress={() => navigate(getHomeScreenPath())}
          />
        </View>
      </View>
    </View>
  );
};

export default FortuneWheel;
