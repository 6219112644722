import { Image, Text, View } from "native-base";
import React, { useEffect } from "react";
import { useWindowDimensions } from "react-native";
import { useLocation, useNavigate } from "react-router-dom";

const SameGroupError = () => {
  const dimensions = useWindowDimensions();
  const location = useLocation();
  const navigate = useNavigate();
  const [redirected, setRedirected] = React.useState(false);

  useEffect(() => {
    const handlePopState = () => {
      if (!redirected) {
        if(location?.state?.isOtpVerified){
          navigate(-1);
        }
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [redirected, navigate, location?.state?.navigateBack]);

  const redirect = () => {
    setRedirected(true);
    if (location?.state?.isOtpVerified) {
      navigate(-2);
    } else {
      navigate(-1);
    }
  };

  return (
    <View alignItems={"center"} justifyContent="center" bgColor={"#FFFDFA"}>
      <View
        h={dimensions.height}
        _web={{ maxW: "412px" }}
        width="100%"
        bgColor={"#FFFFFF"}
      >
        <View alignItems="center" justify-content="center" mt="50%">
          <View>
            <Image
              source={{
                uri:
                  process.env.REACT_APP_AWS_S3_BUCKET_URL +
                  "accountBlocked.png",
              }}
              height="160px"
              width="160px"
              alt=""
            />
          </View>
          <View alignItems="center" w="90%">
            <View>
              <Text
                textAlign="center"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="24px"
                lineHeight="32px"
              >
                You've already selected features from this group. Discover more
                exciting options for your card!
              </Text>
            </View>
            <View alignItems="center" mt="20px">
              <Text
                textAlign="center"
                fontWeight="500"
                fontFamily="Quicksand"
                fontSize="14px"
                lineHeight="22px"
              >
                Contact customer care on 1800 1200 1500 or in case of any
                queries Email to creditcard.support@aubank.in
              </Text>
            </View>
          </View>
        </View>
        <View
          display={"flex"}
          alignItems={"center"}
          position={"fixed"}
          bottom={"24px"}
          left={"0"}
          right={"0"}
          margin={"auto"}
          maxW={"412px"}
        >
          <View alignItems="center" justifyContent="center" width="100%">
            <View
              cursor="pointer"
              w="90%"
              alignItems="center"
              justifyContent="center"
              bgColor="#F37435"
              p="10px 140px"
              borderRadius="4px"
              onClick={() => redirect()}
            >
              <Text
                color="#FFFFFF"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="14px"
                lineHeight="22px"
              >
                Go Back
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default SameGroupError;
