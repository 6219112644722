import React from "react";

const Confetti = ({
    ...otherProps
}) => {
    return (
        <svg
            width="356"
            height="253"
            viewBox="0 0 356 253"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...otherProps}
        >
            <path d="M214.831 129.543L211.703 130.047L209.495 126.107L214.397 125.939L214.831 129.543Z" fill="#E0719E"/>
            <path d="M220.014 143.817L217.357 143.526L217.182 141.136L221.049 141.487L220.014 143.817Z" fill="#AAC6FC"/>
            <path d="M229.101 127.55L226.247 127.237L225.258 123.266L230.852 122.517L229.101 127.55Z" fill="#F6E049"/>
            <path d="M171.744 162.519L168.943 161.022L169.552 156.547L173.563 159.38L171.744 162.519Z" fill="#E0719E"/>
            <path d="M257.666 177.487L255.093 175.632L256.288 171.279L259.888 174.616L257.666 177.487Z" fill="#E0719E"/>
            <path d="M273.253 139.25L271.914 136.371L275.072 133.156L276.587 137.837L273.253 139.25Z" fill="#E0719E"/>
            <path d="M252.262 144.519L250.313 142.014L252.688 138.173L255.192 142.396L252.262 144.519Z" fill="#E0719E"/>
            <path d="M308.218 120.158L306.925 117.256L310.144 114.094L311.575 118.798L308.218 120.158Z" fill="#E0719E"/>
            <path d="M296.733 180.183L297.86 177.212L302.358 177.258L300.059 181.596L296.733 180.183Z" fill="#E0719E"/>
            <path d="M287.478 205.507L285.142 203.361L286.839 199.176L290.02 202.918L287.478 205.507Z" fill="#E0719E"/>
            <path d="M342.514 138.295L341.106 135.454L344.188 132.163L345.81 136.806L342.514 138.295Z" fill="#E0719E"/>
            <path d="M331.896 202.246L329.781 199.886L331.881 195.884L334.682 199.917L331.896 202.246Z" fill="#E0719E"/>
            <path d="M310.768 231.854L307.632 232.312L305.486 228.348L310.38 228.241L310.768 231.854Z" fill="#E0719E"/>
            <path d="M354.014 121.326L351.092 120.089L351.305 115.576L355.544 118.035L354.014 121.326Z" fill="#E0719E"/>
            <path d="M128.308 70.946L125.218 70.2282L124.654 65.7454L129.251 67.4407L128.308 70.946Z" fill="#E0719E"/>
            <path d="M89.9325 75.0088L87.124 73.5425L87.6872 69.0597L91.7211 71.8471L89.9325 75.0088Z" fill="#E0719E"/>
            <path d="M93.4184 43.8353L90.8687 41.9567L92.1093 37.6113L95.6712 40.9868L93.4184 43.8353Z" fill="#E0719E"/>
            <path d="M47.1279 34.1748L44.1063 33.1896L43.9236 28.6763L48.3685 30.7611L47.1279 34.1748Z" fill="#E0719E"/>
            <path d="M67.9136 133.14L68.8954 136.164L65.3639 138.967L64.4277 134.141L67.9136 133.14Z" fill="#E0719E"/>
            <path d="M28.3968 121.013L26.1744 123.289L22.072 121.441L25.9004 118.378L28.3968 121.013Z" fill="#E0719E"/>
            <path d="M74.2537 162.42L76.2249 164.902L73.8959 168.774L71.3386 164.581L74.2537 162.42Z" fill="#E0719E"/>
            <path d="M45.6207 177.8L46.7776 180.763L43.4135 183.765L42.2034 178.999L45.6207 177.8Z" fill="#E0719E"/>
            <path d="M78.5235 222.254L75.3801 222.697L73.249 218.726L78.143 218.642L78.5235 222.254Z" fill="#E0719E"/>
            <path d="M112.347 196.251L109.28 197.053L106.715 193.341L111.579 192.707L112.347 196.251Z" fill="#E0719E"/>
            <path d="M183.511 109.932L182.164 107.053L185.33 103.838L186.844 108.519L183.511 109.932Z" fill="#E0719E"/>
            <path d="M198.832 158.632L197.028 156.013L199.623 152.324L201.884 156.685L198.832 158.632Z" fill="#E0719E"/>
            <path d="M23.5562 218.214L20.4053 218.482L18.4949 214.388L23.3964 214.587L23.5562 218.214Z" fill="#E0719E"/>
            <path d="M334.659 74.6116L331.531 75.108L329.332 71.1674L334.225 70.9994L334.659 74.6116Z" fill="#E0719E"/>
            <path d="M23.9215 161.022L23.6399 164.184L19.2939 165.368L20.3367 160.556L23.9215 161.022Z" fill="#E0719E"/>
            <path d="M5.12987 170.805L4.4601 173.913L0 174.547L1.62116 169.904L5.12987 170.805Z" fill="#E0719E"/>
            <path d="M12.1471 163.084L11.6296 166.223L7.20752 167.078L8.60035 162.359L12.1471 163.084Z" fill="#E0719E"/>
            <path d="M279.936 242.217L277.607 240.529L278.703 236.589L283.939 238.704L279.936 242.217Z" fill="#F6E049"/>
            <path d="M292.502 253L290.15 251.358L291.17 247.395L296.444 249.411L292.502 253Z" fill="#F6E049"/>
            <path d="M310.745 182.734L308.059 181.718L308.089 177.632L313.691 178.297L310.745 182.734Z" fill="#F6E049"/>
            <path d="M302.617 154.859L299.778 154.432L298.94 150.43L304.565 149.895L302.617 154.859Z" fill="#F6E049"/>
            <path d="M286.42 151.453L283.642 150.751L283.201 146.688L288.84 146.703L286.42 151.453Z" fill="#F6E049"/>
            <path d="M289.564 166.437L286.847 165.513L286.732 161.419L292.357 161.9L289.564 166.437Z" fill="#F6E049"/>
            <path d="M266.997 131.911L264.302 130.925L264.295 126.832L269.904 127.443L266.997 131.911Z" fill="#F6E049"/>
            <path d="M331.128 119.05L328.266 119.195L326.652 115.438L332.056 113.796L331.128 119.05Z" fill="#F6E049"/>
            <path d="M341.159 158.647L338.29 158.571L336.981 154.691L342.499 153.485L341.159 158.647Z" fill="#F6E049"/>
            <path d="M226.27 140.105L223.423 139.731L222.518 135.744L228.127 135.111L226.27 140.105Z" fill="#F6E049"/>
            <path d="M231.887 158.006L229.04 157.624L228.142 153.637L233.751 153.011L231.887 158.006Z" fill="#F6E049"/>
            <path d="M179.462 125.007L176.608 124.694L175.618 120.723L181.212 119.974L179.462 125.007Z" fill="#F6E049"/>
            <path d="M173.578 146.016L170.724 145.703L169.735 141.732L175.329 140.976L173.578 146.016Z" fill="#F6E049"/>
            <path d="M183.815 138.348L180.961 138.035L179.971 134.064L185.566 133.316L183.815 138.348Z" fill="#F6E049"/>
            <path d="M114.197 133.476L113.04 130.842L115.97 128.001L119.418 132.483L114.197 133.476Z" fill="#F6E049"/>
            <path d="M81.7126 123.625L82.7325 120.929L86.8121 120.967L86.1271 126.588L81.7126 123.625Z" fill="#F6E049"/>
            <path d="M37.0506 119.44L36.9136 116.561L40.6658 114.957L42.2794 120.387L37.0506 119.44Z" fill="#F6E049"/>
            <path d="M42.386 139.127L43.109 136.34L47.1657 135.935L47.0972 141.594L42.386 139.127Z" fill="#F6E049"/>
            <path d="M23.0157 140.235L22.3079 137.44L25.672 135.126L28.3282 140.12L23.0157 140.235Z" fill="#F6E049"/>
            <path d="M7.21517 125.289L4.93945 123.541L6.14962 119.631L11.3175 121.899L7.21517 125.289Z" fill="#F6E049"/>
            <path d="M60.8809 207.133L59.176 204.812L61.4137 201.39L65.7596 205.003L60.8809 207.133Z" fill="#F6E049"/>
            <path d="M50.1572 221.315L47.4629 220.345L47.4248 216.252L53.0418 216.84L50.1572 221.315Z" fill="#F6E049"/>
            <path d="M64.6714 230.594L61.9619 229.647L61.8857 225.561L67.5027 226.08L64.6714 230.594Z" fill="#F6E049"/>
            <path d="M83.8816 219.123L81.5679 217.42L82.6867 213.487L87.9079 215.641L83.8816 219.123Z" fill="#F6E049"/>
            <path d="M25.0709 49.12L22.2167 48.8069L21.2273 44.8358L26.8214 44.0797L25.0709 49.12Z" fill="#F6E049"/>
            <path d="M23.6856 74.6575L20.839 74.3368L19.8496 70.3656L25.4438 69.6249L23.6856 74.6575Z" fill="#F6E049"/>
            <path d="M55.1956 56.4131L52.3415 56.1L51.3521 52.1289L56.9462 51.3728L55.1956 56.4131Z" fill="#F6E049"/>
            <path d="M78.9192 140.09L76.2706 139.8L76.0879 137.409L79.9619 137.753L78.9192 140.09Z" fill="#AAC6FC"/>
            <path d="M59.6934 145.779L57.0447 145.489L56.8621 143.099L60.7361 143.45L59.6934 145.779Z" fill="#AAC6FC"/>
            <path d="M55.8347 183.872L53.1785 183.581L53.0034 181.191L56.8699 181.542L55.8347 183.872Z" fill="#AAC6FC"/>
            <path d="M72.1148 191.211L69.4585 190.92L69.2834 188.53L73.1499 188.881L72.1148 191.211Z" fill="#AAC6FC"/>
            <path d="M3.64553 189.194L0.989264 188.904L0.814209 186.514L4.68064 186.865L3.64553 189.194Z" fill="#AAC6FC"/>
            <path d="M20.6563 221.994L18.0076 221.704L17.825 219.314L21.699 219.665L20.6563 221.994Z" fill="#AAC6FC"/>
            <path d="M38.2683 230.479L35.6121 230.189L35.437 227.798L39.3034 228.15L38.2683 230.479Z" fill="#AAC6FC"/>
            <path d="M26.9126 232.358L24.264 232.068L24.0813 229.677L27.9553 230.029L26.9126 232.358Z" fill="#AAC6FC"/>
            <path d="M182.856 154.126L180.208 153.836L180.025 151.446L183.899 151.797L182.856 154.126Z" fill="#AAC6FC"/>
            <path d="M301.566 126.618L298.91 126.328L298.735 123.938L302.601 124.289L301.566 126.618Z" fill="#AAC6FC"/>
            <path d="M313.158 151.461L310.509 151.171L310.327 148.78L314.201 149.132L313.158 151.461Z" fill="#AAC6FC"/>
            <path d="M304.177 152.08L301.528 151.789L301.345 149.399L305.219 149.75L304.177 152.08Z" fill="#AAC6FC"/>
            <path d="M333.442 135.271L330.785 134.981L330.61 132.59L334.477 132.934L333.442 135.271Z" fill="#AAC6FC"/>
            <path d="M327.718 90.9239L325.062 90.6337L324.887 88.2434L328.753 88.5947L327.718 90.9239Z" fill="#AAC6FC"/>
            <path d="M334.637 209.569L331.988 209.279L331.805 206.889L335.679 207.24L334.637 209.569Z" fill="#AAC6FC"/>
            <path d="M305.509 206.538L302.853 206.248L302.677 203.857L306.544 204.201L305.509 206.538Z" fill="#AAC6FC"/>
            <path d="M280.651 184.208L277.995 183.917L277.82 181.527L281.686 181.878L280.651 184.208Z" fill="#AAC6FC"/>
            <path d="M270.719 168.743L268.07 168.453L267.887 166.062L271.761 166.414L270.719 168.743Z" fill="#AAC6FC"/>
            <path d="M330.854 242.965L328.205 242.675L328.022 240.285L331.897 240.628L330.854 242.965Z" fill="#AAC6FC"/>
            <path d="M108.077 71.3584L105.421 71.0682L105.246 68.6779L109.112 69.0291L108.077 71.3584Z" fill="#AAC6FC"/>
            <path d="M96.3179 55.6953L93.6616 55.4051L93.4866 53.0148L97.353 53.3661L96.3179 55.6953Z" fill="#AAC6FC"/>
            <path d="M95.3743 60.2621L92.7256 59.9719L92.543 57.5815L96.417 57.9328L95.3743 60.2621Z" fill="#AAC6FC"/>
            <path d="M71.8255 60.2009L69.1692 59.9107L68.9941 57.5204L72.8606 57.8717L71.8255 60.2009Z" fill="#AAC6FC"/>
            <path d="M47.5769 31.4789L44.9207 31.1887L44.7456 28.7983L48.612 29.1496L47.5769 31.4789Z" fill="#AAC6FC"/>
            <path d="M37.2791 55.5884L34.6304 55.2982L34.4478 52.9078L38.3218 53.2591L37.2791 55.5884Z" fill="#AAC6FC"/>
            <path d="M29.2417 91.5119L26.5855 91.2217L26.4104 88.8314L30.2768 89.1827L29.2417 91.5119Z" fill="#AAC6FC"/>
            <path d="M145.227 87.7394L142.571 87.4492L142.396 85.0588L146.262 85.4101L145.227 87.7394Z" fill="#AAC6FC"/>
            <path d="M108.991 241.079L106.334 240.789L106.159 238.398L110.026 238.742L108.991 241.079Z" fill="#AAC6FC"/>
            <path d="M203.794 107.137L201.138 106.847L200.963 104.456L204.829 104.808L203.794 107.137Z" fill="#AAC6FC"/>
            <path d="M260.428 118.806L257.78 118.516L257.604 116.125L261.471 116.469L260.428 118.806Z" fill="#AAC6FC"/>
            <path d="M295.888 94.9944L293.232 94.7042L293.057 92.3138L296.924 92.6651L295.888 94.9944Z" fill="#AAC6FC"/>
            <path d="M294.107 53.2897L291.451 52.9995L291.276 50.6091L295.143 50.9604L294.107 53.2897Z" fill="#AAC6FC"/>
            <path d="M233.112 32.907L230.463 32.6168L230.281 30.2264L234.155 30.5777L233.112 32.907Z" fill="#AAC6FC"/>
            <path d="M221.209 2.68052L218.552 2.39032L218.377 0L222.244 0.351293L221.209 2.68052Z" fill="#AAC6FC"/>
            <path d="M216.984 194.158L216.033 191.653L218.08 190.409L219.526 194.029L216.984 194.158Z" fill="#AAC6FC"/>
            <path d="M165.274 177.754L162.618 177.495L162.413 175.104L166.287 175.41L165.274 177.754Z" fill="#AAC6FC"/>
            <path d="M168.342 195.197L165.739 194.609L165.83 192.219L169.636 192.998L168.342 195.197Z" fill="#AAC6FC"/>
            <path d="M182.46 206.629L180.344 204.995L181.433 202.857L184.561 205.178L182.46 206.629Z" fill="#AAC6FC"/>
            <path d="M239.802 196.449L238.896 193.929L240.951 192.715L242.344 196.358L239.802 196.449Z" fill="#AAC6FC"/>
            <path d="M219.587 172.592L218.644 170.087L220.691 168.85L222.129 172.47L219.587 172.592Z" fill="#AAC6FC"/>
            <path d="M193.557 165.245L191.67 163.359L193.017 161.381L195.818 164.077L193.557 165.245Z" fill="#AAC6FC"/>
            <path d="M141.612 183.696L138.955 183.933L138.316 181.619L142.182 181.206L141.612 183.696Z" fill="#AAC6FC"/>
            <path d="M194.006 191.325L192.012 189.538L193.253 187.491L196.198 190.035L194.006 191.325Z" fill="#AAC6FC"/>
            <path d="M264.47 191.302L261.341 191.806L259.134 187.866L264.036 187.698L264.47 191.302Z" fill="#E0719E"/>
            <path d="M216.908 190.539L213.78 191.035L211.573 187.094L216.474 186.926L216.908 190.539Z" fill="#E0719E"/>
            <path d="M209.883 203.781L206.747 204.277L204.548 200.336L209.442 200.168L209.883 203.781Z" fill="#E0719E"/>
            <path d="M169.346 184.719L166.218 185.216L164.019 181.275L168.912 181.107L169.346 184.719Z" fill="#E0719E"/>
            <path d="M198.969 172.928L198.094 170.186L201.305 167.666L204.266 172.485L198.969 172.928Z" fill="#F6E049"/>
            <path d="M180.086 196.266L177.536 194.937L178.038 190.875L183.526 192.203L180.086 196.266Z" fill="#F6E049"/>
            <path d="M229.124 193.983L228.583 191.157L232.077 189.049L234.429 194.197L229.124 193.983Z" fill="#F6E049"/>
            <path d="M150.159 188.232L147.305 187.919L146.315 183.948L151.91 183.192L150.159 188.232Z" fill="#F6E049"/>
            <path d="M164.658 209.638L161.804 209.317L160.83 205.346L166.424 204.605L164.658 209.638Z" fill="#F6E049"/>
            <path d="M238.074 169.056L238.767 166.261L242.824 165.811L242.816 171.469L238.074 169.056Z" fill="#F6E049"/>
            <path d="M174.88 12.2341L172.026 11.921L171.036 7.94989L176.631 7.19385L174.88 12.2341Z" fill="#F6E049"/>
            <path d="M128.658 22.7271L125.804 22.414L124.814 18.4505L130.408 17.6945L128.658 22.7271Z" fill="#F6E049"/>
            <path d="M274.463 24.8654L271.609 24.56L270.62 20.5888L276.214 19.8328L274.463 24.8654Z" fill="#F6E049"/>
            <path d="M307.336 23.2617L304.207 23.7581L302 19.8175L306.902 19.6571L307.336 23.2617Z" fill="#E0719E"/>
            <path d="M246.911 17.4424L243.783 17.9388L241.576 13.9982L246.477 13.8302L246.911 17.4424Z" fill="#E0719E"/>
            <path d="M182.385 27.5536L179.249 28.0499L177.049 24.1093L181.943 23.949L182.385 27.5536Z" fill="#E0719E"/>
        </svg>
    );
};

export default Confetti;
