import { useSelector } from "react-redux";
import { Envs, TrackEventTypes } from "../utils/enums";
import { getPageNameByRoute } from "../utils/functions";
import { useLocation } from "react-router-dom";

const env = process.env.REACT_APP_ENV;

export const useTrackEvents = () => {
  const manageBenefits = useSelector((state) => state.manageBenefits);
  const location = useLocation();

  // env !== Envs.PROD && window?._satellite?.setDebug(false);

  const trackEventsAdobeAnalytics = (event) => {
    // Temporarily Commented till unsafe-inline and unsafe-eval solutions are not available.
    // setTimeout(() => {
    //   let digitalData = {};
    //   const currentPage = sessionStorage.getItem("currentPage");
    //   const previousPage = sessionStorage.getItem("previousPage");
    //   digitalData.pageName = getPageNameByRoute(currentPage);
    //   digitalData.linkPageName = getPageNameByRoute(previousPage);
    //   digitalData.link = "y";

    //   if (currentPage === "/Features/ManageFeatures/Login") {
    //     digitalData.previousPageName = "";
    //     digitalData.linkPageName = "";
    //     digitalData.link = "n";
      
    //     if (previousPage === "/Features/ManageFeatures/OTP" || event?.linkName === TrackEventTypes.YES_LOGOUT) {
    //       const pageName = getPageNameByRoute(previousPage);
    //       digitalData.previousPageName = pageName;
    //       digitalData.linkPageName = pageName;
    //       digitalData.link = "y";
    //     }
    //   } else {
    //     digitalData.previousPageName = getPageNameByRoute(previousPage);
    //   }
    //   digitalData.walletBalance = "";
    //   digitalData.benefitsName = "";
    //   digitalData.productServices = "";
    //   digitalData.ccCardSelected = localStorage.getItem("program");
    //   digitalData.leadID = manageBenefits.leadId;
    //   digitalData.custID =
    //     manageBenefits.accountId || localStorage.getItem("applicantId");
    //   digitalData.error = "";
    //   digitalData.errorEvent = "";

    //   if (event) {
    //     digitalData = { ...digitalData, ...event };
    //   }

    //   env !== Envs.PROD && console.log(digitalData);
    //   window.digitalData = digitalData;
    //   if (window.digitalData && window?._satellite && !digitalData.error) {
    //     window._satellite?.track("pageLoad");
    //   } else if (digitalData.error) {
    //     window.digitalData.errorEvent = "y";
    //     window._satellite?.track("error");
    //   }
    // }, 0);
  };

  return trackEventsAdobeAnalytics;
};
