const styles = {
  boxContainer: {
    p: "16px",
    alignSelf: "center",
    mt: "16px",
    width: "100%",
    bgColor: "#FFFFFF",
  },
};

export default styles;
