import { combineReducers } from "redux";
import theme from "./Theme";
import session from "./Session";
import preferences from "./Preferences";
import otp from "./Otp";
import manageBenefits from "./ManageBenefits";
import wheelProgram from "./WheelProgram";
import programConfigs from "./ProgramConfigs"

export default combineReducers({
  theme,
  session,
  preferences,
  otp,
  manageBenefits,
  wheelProgram,
  programConfigs
});
