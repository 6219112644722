import moment from "moment";
import store from "../store/Store";
import { ProgramTypes } from "./constants";
import { Envs, PageRoutes } from "./enums";
import { setErrorDetails } from "../store/actions/ManageBenefits";
import Paths from "../routes/Paths";

const env = process.env.REACT_APP_ENV;

export const consoleLog = (...args) => {
  if (env !== Envs.PROD) console.log(...args);
};

export const consoleError = (...args) => {
  if (env !== Envs.PROD) console.error(...args);
};

export const openLink = (url) => {
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const truncateString = (string = "", maxLength = 50) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

export const getTransactionDate = (date) => {
  const programType = store.getState().user.programType;
  const givenDate = moment(date).format("YYYY-MM-DD");
  const todayDate = moment().format("YYYY-MM-DD");
  const dateFormat =
    programType === ProgramTypes.CREDIT_CARD ? "DD MMM YYYY" : "MMM Do";

  // if not today
  if (!(givenDate === todayDate)) {
    return moment(date).format(dateFormat);
  }

  // if program is credit card
  if (programType === ProgramTypes.CREDIT_CARD) {
    return moment(date).format(dateFormat);
  } else {
    return "Today, " + moment(date).format("h:mm a");
  }
};

export const formatAmount = (amount) => {
  const preferences = store.getState().preferences;
  return (
    "₹ " +
    Number(amount).toLocaleString(
      preferences.locales,
      preferences.localeOptions
    )
  );
};

export const formatCardNumber = (num) =>
  String(num)
    .match(/.{1,4}/g)
    ?.join(" ");

export const copyToClipboard = (text) => {
  const textArea = document.createElement("textarea");
  document.body.appendChild(textArea);
  textArea.value = text;
  textArea.select();
  document.execCommand("copy");
  document.body.removeChild(textArea);
};

export const isApiSuccessful = (res) => res?.status === 200;

export const handleApiError = (res, navigate) => {
  if (res.status === 403) {
    if (res.data === "") {
      navigate("/Features/Forbidden");
    } else {
      store.dispatch(
        setErrorDetails({
          errorDetails: res.data,
        })
      );
      navigate("/Features/Error");
    }
  } else {
    navigate("/Features/GenericError", {
      state: { x_correlation_id: res.headers.x_correlation_id },
    });
  }
};

export const getHomeScreenPath = () => {
  const manageBenefits = store.getState().manageBenefits;
  return (
    Paths.MANAGE_BENEFITS +
    `?accountId=${manageBenefits.accountId}&channel=${manageBenefits.channel}`
  );
};

export const getPageNameByRoute = (route) => {
  const pageName = PageRoutes[route]

  const programName = localStorage.getItem("program");
  if (pageName) {
    if (route === "/Features/ManageFeatures/Login") {
      return `Standalone | ${programName} | ${pageName}`;
    } else {
      return `${programName} | ${pageName}`;
    }
  } else {
    return null;
  }
};

export const benefitNameWithId = (name, id) => {
  return `${name}-${id}`;
};

export const capitalizeFirstLetter = (text) => {
  return text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
};