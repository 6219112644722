import Service from "../services/BenefitService";
import { Envs, LogLevels } from "./enums";

const env = process.env.REACT_APP_ENV;

const isConsoleLoggingEnabled = [
  Envs.DEV,
  Envs.DEV_MOBILE,
  Envs.UAT,
  Envs.SANDBOX,
].includes(env);

const isServerLoggingEnabled = [Envs.UAT, Envs.SANDBOX, Envs.PROD].includes(
  env
);

const log = (level, initLog) => {
  let finalLog =
    typeof initLog !== "object"
      ? { log: initLog }
      : {
          ...initLog,
          env: env.toLowerCase(),
          service: "lit-pwa",
          timestamp: new Date().toISOString(),
          level,
        };

  if (isConsoleLoggingEnabled) {
    switch (level) {
      case LogLevels.INFO.value:
        console.info(finalLog);
        break;
      case LogLevels.ERROR.value:
        console.error(finalLog);
        break;
      default:
        break;
    }
  }

  if (isServerLoggingEnabled) {
    const headers = {};

    if (finalLog.correlationId) {
      headers.correlationId = finalLog.correlationId;
    }

    try {
      Service.post(`/pwa/log`, finalLog, { headers });
    } catch (error) {
      if (isConsoleLoggingEnabled) {
        console.error(
          `An exception occurred while sending log of level ${level} and content ${finalLog} to server. Error: \n`,
          error
        );
      }
    }
  }
};

const logger = {
  info: (initLog) => {
    log(LogLevels.INFO.value, initLog);
  },
  error: (initLog) => {
    log(LogLevels.ERROR.value, initLog);
  },
};

export default logger;
