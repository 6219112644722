const baseText = {
  fontFamily: "Quicksand",
  fontWeight: 700,
  fontSize: "28px",
  color: "#FEF2F0",
};

const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
  },
  headerContainer: {
    mt: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  backButtonContainer: {
    cursor: "pointer",
    ml: "15px",
    mt: "1px",
  },
  backButton: {
    fill: "#303030",
  },
  titleText: {
    ...baseText,
    fontSize: "18px",
    color: "#303030",
    ml: "-35px",
  },
  emojiContainer: {
    alignItems: "center",
    mt: "50px",
  },
  emojiBackground: {
    borderRadius: "50%",
    padding: "20px",
    bgColor: "#FFC7BC",
    width: "fit-content",
  },
  title2Container: {
    mt: "30px",
    alignItems: "center",
  },
  text2: {
    ...baseText,
    color: "#565750",
    textAlign: "center",
  },
  footerContainer: {
    alignItems: "center",
    mb: "140px",
  },
  submitButton: {
    width: "200px",
    bgColor: "#F37435",
  },
  submitButtonText: {
    color: "#FFFFFF",
  },
  footerText: {
    ...baseText,
    mt: "30px",
    fontSize: "16px",
  },
  footerText2: {
    ...baseText,
    fontSize: "16px",
    color: "#353535",
  },
};

export default styles;
