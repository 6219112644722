import React from "react";
import { View } from "native-base";

const CustomModal = ({
  isOpen,
  overlayColor = "rgba(48, 48, 48, 0.8)",
  children,
}) => {
  if (!isOpen) return null;

  return (
    <View
      position={"fixed"}
      top={0}
      left={0}
      right={0}
      bottom={0}
      backgroundColor={overlayColor}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {children}
    </View>
  );
};

export default CustomModal;
