import { Image, Modal, Text, View } from "native-base";
import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ManageBenefitService from "../../../services/ManageBenefitService";
import { useDispatch, useSelector } from "react-redux";
import { setWalletDetails } from "../../../store/actions/ManageBenefits";
import moment from "moment";
import SpinBanner from "../FortuneWheel/SpinBanner";
import logger from "../../../utils/logger";
import { ProgramConfigs } from "../../../utils/constants";
import { useTrackEvents } from "../../../hooks/useTrackEvents";
import { TrackEventTypes } from "../../../utils/enums";
import { getPageNameByRoute } from "../../../utils/functions";
import { ErrorReasons } from "../../../utils/enums";

const Header = (props) => {
  const [logoutOpen, setLogoutOpen] = useState(false);
  const [walletDetails, setWalletDetail] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const manageBenefits = useSelector((state) => state.manageBenefits);
  const wheelProgram = useSelector((state) => state.wheelProgram);
  const location = useLocation();

  const trackEventsAdobeAnalytics = useTrackEvents();

  const showSpinBanner =
    props.page === "manageFeatures" &&
    wheelProgram.buckets.length > 2 &&
    wheelProgram.availableSpins > 0 &&
    wheelProgram.availableRewardSpins > 0;

  useEffect(() => {
    (async () => {
      try {
        const isCardTransfer = manageBenefits?.errorDetails?.reason === ErrorReasons.CARD_TRANSFER;
        if (manageBenefits.channel !== "CCA" && !isCardTransfer) {
          const response = await ManageBenefitService.getSummary(
            manageBenefits.cardId
          );
          if (response.status === 200) {
            setWalletDetail(response.data.wallet);
            dispatch(setWalletDetails({ walletDetails: response.data.wallet }));
          } else {
            logger.error({
              message: `An error occurred while fetching summary for accountId ${manageBenefits?.cardId}`,
              responseData: response.data,
              accountId: manageBenefits?.cardId,
              correlationId: response.headers.x_correlation_id,
            });

            navigate("/Features/GenericError", {
              state: {
                x_correlation_id: response.headers.x_correlation_id,
              },
            });
          }
        }
      } catch (error) {
        logger.error({
          message: `An exception occurred while fetching summary for accountId ${manageBenefits?.cardId}`,
          error: error.message,
          accountId: manageBenefits?.cardId,
          stackTrace: error.stack,
        });
      }
    })();
  }, []);

  const logout = () => {
    var date = new Date();
    date.setTime(date.getTime());
    document.cookie = `features=; domain=${process.env.REACT_APP_BENEFIT_API_DOMAIN}; expires=${date}; path=/;`;

    navigate(ProgramConfigs[localStorage.getItem("program")].loginUrl, {
      state: {
        isLogoutTriggered: true,
      },
    });
  };

  const handleLogoutOpen = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.USER_LOGOUT,
      linkPageName: getPageNameByRoute(location.pathname),
    });
    setLogoutOpen(true);
  };

  const handleLogout = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.YES_LOGOUT,
    });
    logout();
  };

  const handleCancelLogout = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.CANCEL_LOGOUT,
      linkPageName: getPageNameByRoute(location.pathname),
    });
    setLogoutOpen(false);
  };

  const handleLogoutPopupClose = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.EXIT_LOGOUT_PAGE,
      linkPageName: getPageNameByRoute(location.pathname),
    });
    setLogoutOpen(false);
  };

  return manageBenefits.channel !== "MB" ? (
    <View alignItems="center" justifyContent="center">
      <View _web={{ maxW: "412px" }} width="100%" bgColor={"#FFFFFF"}>
        <Modal isOpen={logoutOpen} onClose={handleLogoutPopupClose} mt={12}>
          <Modal.Content maxWidth="550" w="90%">
            <Modal.CloseButton />

            <Modal.Body bgColor="#FFFFFF">
              <View bgColor="#FFFFFF" px="24px" py="40px">
                <View justifyContent="center" alignItems="center">
                  <Image
                    source={{
                      uri:
                        process.env.REACT_APP_AWS_S3_BUCKET_URL + "Escape.svg",
                    }}
                    height="160px"
                    width="160px"
                    alt=""
                  />
                </View>
                <View alignItems="center" mt="24px">
                  <Text
                    color="#303030"
                    fontWeight="700"
                    fontFamily="Quicksand"
                    fontSize="24px"
                    lineHeight="32px"
                  >
                    Are you sure you want to logout?
                  </Text>
                  <Text
                    mt="12px"
                    color="#636D7D"
                    fontWeight="500"
                    fontFamily="Quicksand"
                    fontSize="14px"
                    lineHeight="22px"
                  >
                    You will be directed back to the login page.
                  </Text>
                </View>
                <View
                  mt="32px"
                  alignItems="center"
                  justifyContent="center"
                  bgColor="#F37435"
                  cursor="pointer"
                  p="10px 140px"
                  borderRadius="4px"
                  onClick={handleLogout}
                >
                  <Text
                    color="#FFFFFF"
                    fontWeight="700"
                    fontFamily="Quicksand"
                    fontSize="14px"
                    lineHeight="22px"
                  >
                    Yes, Logout
                  </Text>
                </View>
                <View
                  mt="12px"
                  alignItems="center"
                  justifyContent="center"
                  cursor="pointer"
                  bgColor="#FFFFFF"
                  p="10px 140px"
                  borderRadius="4px"
                  onClick={handleCancelLogout}
                >
                  <Text
                    color="#F37435"
                    fontWeight="700"
                    fontFamily="Quicksand"
                    fontSize="14px"
                    lineHeight="22px"
                  >
                    Cancel
                  </Text>
                </View>
              </View>
            </Modal.Body>
          </Modal.Content>
        </Modal>
        <View
          p="8px 24px"
          backgroundColor={"#fff2f0"}
          justifyContent="space-between"
          flexDir={"row"}
        >
          <View
            w="15%"
            flexDirection={"row"}
            alignItems="center"
            justifyContent={"center"}
          >
            <Image
              source={{
                uri: process.env.REACT_APP_AWS_S3_BUCKET_URL + "AU0101Logo.png",
              }}
              height="63px"
              width="60px"
              alt=""
            />
          </View>
          <View
            w="78%"
            alignSelf={
              walletDetails?.totalBalanceAmount >= 1 &&
              walletDetails?.expiryOn >= moment().format("YYYY-MM-DD") &&
              props.page !== "Checkout"
                ? "flex-end"
                : "center"
            }
          >
            <View w="100%" flexDir={"row"}>
              <View w="70%">
                <View ml="2px">
                  <Text
                    color="#6d3078"
                    fontWeight="600"
                    fontFamily="Quicksand"
                    fontSize="18px"
                    lineHeight="26px"
                  >
                    {ProgramConfigs[localStorage.getItem("program")].pageHeader}
                  </Text>
                </View>
              </View>
              <View ml="16px" w="30%">
                <View flexDir={"row"} alignItems="center">
                  {walletDetails?.totalBalanceAmount >= 1 &&
                    walletDetails?.expiryOn >= moment().format("YYYY-MM-DD") &&
                    props.page !== "Checkout" && (
                      <View>
                        <View mr="16px" flexDir={"row"} alignItems="center">
                          <View>
                            <Image
                              source={{
                                uri:
                                  process.env.REACT_APP_AWS_S3_BUCKET_URL +
                                  "Wallet.svg",
                              }}
                              height="24px"
                              width="24px"
                              alt=""
                            />
                          </View>
                          <View ml="4px">
                            <Text
                              color="#6d3078"
                              fontWeight="700"
                              fontFamily="Quicksand"
                              fontSize="14px"
                              lineHeight="22px"
                            >
                              {`₹${walletDetails.totalBalanceAmount}`}
                            </Text>
                          </View>
                        </View>
                      </View>
                    )}
                </View>
              </View>
            </View>
            <View w="100%" flexDir={"row"}>
              <View w={"70%"}> {` `}</View>
              <View ml="16px" w={"30%"}>
                {walletDetails?.totalBalanceAmount >= 1 &&
                  walletDetails?.expiryOn >= moment().format("YYYY-MM-DD") &&
                  props.page !== "Checkout" && (
                    <View>
                      <Text
                        color="#6d3078"
                        fontWeight="700"
                        fontFamily="Quicksand"
                        fontSize="11px"
                        lineHeight="22px"
                      >
                        Wallet
                      </Text>
                    </View>
                  )}
              </View>
            </View>
          </View>
          <View w="7%" alignSelf={"center"}>
            {manageBenefits.channel === "CCS" && (
              <View alignItems="center" onClick={handleLogoutOpen}>
                <Image
                  source={{
                    uri: process.env.REACT_APP_AWS_S3_BUCKET_URL + "Logout.svg",
                  }}
                  height="28px"
                  width="28px"
                  alt=""
                />
              </View>
            )}
          </View>
        </View>
        {showSpinBanner && <SpinBanner />}
      </View>
    </View>
  ) : (
    <View>
      {showSpinBanner && <SpinBanner />}
      {walletDetails?.totalBalanceAmount >= 1 &&
        walletDetails?.expiryOn >= moment().format("YYYY-MM-DD") &&
        props.page === "manageFeatures" && (
          <View
            p="14px"
            m="16px"
            borderRadius={"8px"}
            alignItems={"center"}
            justifyContent="space-between"
            flexDirection={"row"}
            bgColor="#6D3078"
          >
            <View alignItems={"center"} flexDirection={"row"}>
              <View>
                <Image
                  source={{
                    uri:
                      process.env.REACT_APP_AWS_S3_BUCKET_URL +
                      "WhiteWallet.svg",
                  }}
                  height="28px"
                  width="28px"
                  alt=""
                />
              </View>
              <View ml="12px">
                <Text
                  color={"#FFFFFF"}
                  fontWeight="600"
                  fontFamily="Quicksand"
                  fontSize="16px"
                  lineHeight="26px"
                >
                  Wallet
                </Text>
              </View>
            </View>
            <View>
              <Text
                color="#ffffff"
                fontWeight="600"
                fontFamily="Quicksand"
                fontSize="16px"
                lineHeight="26px"
              >{`₹${walletDetails.totalBalanceAmount}`}</Text>
            </View>
          </View>
        )}
    </View>
  );
};

export default Header;
