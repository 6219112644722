import { View, Text, Image } from "native-base";
import React from "react";
import { DECIMAL_PLACES } from "../../../utils/constants";
import moment from "moment";
import ProgressiveRewardIcon from "../../svg/halfCashBack";

const CashBack = (props) => {
  return (
    <View w="100%" bgColor="#FFFFFF">
      <View
        mx="16px"
        py="16px"
        borderBottomWidth="1px"
        borderBottomColor="#B1B5BB"
        borderBottomStyle="dotted"
        borderRadius="2px"
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="row"
      >
        <View mr="12px" p="8px">
          {
            <ProgressiveRewardIcon
              color={props.data.benefit.color}
              size="60px"
            />
          }
        </View>
        <View>
          <Text
            fontFamily="Quicksand"
            color="#303030"
            fontWeight="700"
            fontSize="18px"
            lineHeight="30px"
          >
            {`₹${props.data.currentCycleCashbackAccrued
              .toFixed(DECIMAL_PLACES)
              .toLocaleString("en-IN")}`}
          </Text>
          <Text
            fontFamily="Quicksand"
            color="#303030"
            fontWeight="500"
            fontSize="14px"
            lineHeight="22px"
            mt="4px"
          >
            Fuel Surcharge Waived
          </Text>
        </View>
      </View>
      <View my="16px" px="16px">
        <Text
          fontFamily="Quicksand"
          color="#303030"
          fontWeight="600"
          fontSize="12px"
          lineHeight="20px"
        >
          {`Continue to enjoy this feature till`}
          <Text
            ml="4px"
            fontFamily="Quicksand"
            color="#303030"
            fontWeight="700"
            fontSize="12px"
            lineHeight="20px"
          >
            {moment(props.data.endDate).format("DD MMM 'YY")}
          </Text>
        </Text>
      </View>
    </View>
  );
};

export default CashBack;
