import React from "react";
import { useSelector } from "react-redux";
import { Icon } from "native-base";

const Close = (props) => {
  const theme = useSelector((state) => state.theme);
  return (
    <Icon viewBox="0 0 24 24" size={`${props.size}px` || "28px"}>
      <svg
        width={props.size || "28"}
        height={props.size || "28"}
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.2434 6.90551C20.0231 6.9107 19.814 7.00287 19.6615 7.16185L14.0007 12.8227L8.33987 7.16185C8.26212 7.08193 8.16912 7.01842 8.06638 6.97508C7.96363 6.93175 7.85324 6.90947 7.74173 6.90958C7.57602 6.90978 7.41414 6.95938 7.27675 7.05204C7.13937 7.14469 7.03273 7.2762 6.97045 7.42976C6.90817 7.58332 6.89308 7.75196 6.92711 7.91414C6.96114 8.07631 7.04274 8.22466 7.16149 8.34024L12.8223 14.001L7.16149 19.6619C7.08151 19.7386 7.01766 19.8306 6.97367 19.9324C6.92968 20.0342 6.90644 20.1437 6.90532 20.2546C6.90419 20.3654 6.92519 20.4754 6.9671 20.5781C7.00901 20.6807 7.07098 20.774 7.14938 20.8524C7.22777 20.9308 7.32103 20.9927 7.42368 21.0346C7.52632 21.0765 7.6363 21.0975 7.74717 21.0964C7.85804 21.0953 7.96757 21.072 8.06934 21.0281C8.17111 20.9841 8.26309 20.9202 8.33987 20.8402L14.0007 15.1794L19.6615 20.8402C19.7383 20.9202 19.8303 20.9841 19.932 21.0281C20.0338 21.072 20.1433 21.0953 20.2542 21.0964C20.3651 21.0975 20.475 21.0765 20.5777 21.0346C20.6803 20.9927 20.7736 20.9308 20.852 20.8524C20.9304 20.774 20.9924 20.6807 21.0343 20.5781C21.0762 20.4754 21.0972 20.3654 21.0961 20.2546C21.0949 20.1437 21.0717 20.0342 21.0277 19.9324C20.9837 19.8306 20.9199 19.7386 20.8399 19.6619L15.1791 14.001L20.8399 8.34024C20.9611 8.22396 21.0444 8.07375 21.0788 7.90931C21.1132 7.74486 21.0971 7.57386 21.0326 7.41873C20.9681 7.26361 20.8582 7.13159 20.7174 7.04C20.5766 6.94842 20.4113 6.90154 20.2434 6.90551Z"
          fill={props.color || "#6D3078"}
          stroke={props.color || "#6D3078"}
          strokeWidth="0.4"
        />
      </svg>
    </Icon>
  );
};

export default Close;
