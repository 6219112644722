const baseText = {
	fontFamily: "Quicksand",
	fontWeight: 700,
	fontSize: "28px",
	color: "#FEF2F0",
};

const styles = {
	mainContainer: {
		display: "flex",
		flexDirection: "column"
	},
	headerContainer: {
		mt: "20px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between"
	},
	backButtonContainer: {
		cursor: "pointer",
		ml: "15px",
		mt: "1px"
	},
	backButton: {
		fill: "#303030"
	},
	titleText: {
		...baseText,
		fontSize: "18px",
		color: "#303030",
		ml: "-35px"
	},
	title2Container: {
		mt: "30px",
		alignItems: "center",
	},
	moneyImage: {
		width: "150px",
		alt: "",
		mr: "20px",
		mt: "4px",
	},
	confettiImage: {
		position: "absolute",
		width: "300px",
    top: "-40px",
		zIndex: -1
	},
	text2: {
		...baseText,
		color: "#6D3078"
	},
	text3: {
		...baseText,
		color: "#565750",
		fontWeight: 600,
		mt: "20px"
	},
	text4: {
		...baseText,
		color: "#6D3078",
		fontSize: "48px",
	},
	text5: {
		...baseText,
		textAlign: "center",
		color: "#565750",
		fontSize: "20px",
		marginTop: "25px"
	},
	footerContainer: {
		alignItems: "center",
		mb: "40px"
	},
	submitButton: {
		width: "250px",
		mt: "15px"
	},
	shareButtonContainer: {
		w: "250px"
	},
	shareButton: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: "10px 24px",
		boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
		borderRadius: "4px",
		height: "fit-content",
		backgroundColor: "#fff"
	},
	shareButtonContent: {
		display: "flex",
		flexDirection: "row"
	},
	shareButtonText: {
		...baseText,
		fontSize: "16px",
		color: "#F37435",
		mr: "7px"
	},
	shareButtonIcon: {
		fill: "#F37435"
	},
	footerText: {
		...baseText,
		fontFamily: "Bangers",
		color: "#6D3078",
		fontWeight: 400,
		mt: "30px",
		mb: "20px"
	},
};

export default styles;
