import axios from "axios";

const BenefitService = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BENEFIT_API_URL,
  // headers: {
  //   abc: "123",
  // },
  validateStatus: function (status) {
    // return status < 400; // resolve only if the status code is less than 400
    return true; // resolve for all status codes
  },
});

export default BenefitService;
