import Paths from "../routes/Paths";

export const Envs = Object.freeze({
  DEV: "dev",
  DEV_MOBILE: "dev-mobile",
  UAT: "benefits-uat",
  SANDBOX: "au-sandbox",
  PROD: "au-prod",
});

export const Channels = Object.freeze({
  IB: "IB",
  CCS: "CCS",
  MB: "MB",
  CCA: "CCA",
  CCO: "CCO",
});

export const ProgressBarType = Object.freeze({
  SPEND : "SPEND",
  TRANSACTION : "TRANSACTION",
})

export const BenefitIssuanceType = Object.freeze({
  END_OF_PERIOD : "END_OF_PERIOD",
  IMMEDIATE : "IMMEDIATE"
})

export const WheelBucketCategories = Object.freeze({
  HIT: "HIT",
  MISS: "MISS",
});

export const LogLevels = Object.freeze({
  INFO: { value: "info" },
  ERROR: { value: "error" },
});

export const ErrorReasons = Object.freeze({
  SAME_BENEEFIT_GROUP: "can not subscribe benefits from the same benefit group",
  CARD_TRANSFER: "Your credit card has been transferred",
  CARD_NOT_ACTIVE: "Card is not active. Please activate your card to proceed.",
});

export const TrackEventTypes = Object.freeze({
  GENERATE_OTP: "Generate OTP",
  OTP_LOGIN: "OTP Login",
  LOGIN_OTP_FAILURE: "Login OTP Failure",
  ADD_MORE_BENEFITS: "Add more Benefits",
  EXIT_ADD_FEATURES: "Exit Add features",
  USER_LOGOUT: "User Logout",
  YES_LOGOUT: "Yes Logout",
  CANCEL_LOGOUT: "Cancel Logout",
  EXIT_LOGOUT_PAGE: "Exit Logout Page",
  EXIT_FEATURE_PAGE: "Exit Feature Page",
  VIEW_FAQ: "View FAQ",
  AUTO_RENEWAL_OFF: "Auto-Renewal Off",
  SAVE_AUTO_RENEWAL_OFF: "Save Auto-Renewal Off",
  CANCEL_AUTO_RENEWAL_OFF: "Cancel Auto-Renewal Off",
  EXIT_AUTO_RENEWAL_OFF: "Exit Auto Renewal Off",
  AUTO_RENEWAL_ON: "Auto-Renewal On",
  VIEW_DETAILS: "View Details",
  ADD_BENEFIT: "Add Benefit",
  TNC_CHECKBOX: "TnC Checkbox",
  TNC_REDIRECTION: "TnC Redirection",
  DELETE_BENEFIT: "Delete benefit",
  ACTIVATE_FEATURE: "Activate Feature",
  OTP_BENEFIT_ACTIVATION: "OTP Benefit Activation",
  WITHOUT_OTP_BENEFIT_ACTIVATION: "Without OTP Benefit Activation",
  BENEFIT_ACTIVATION_OTP_FAILURE: "Benefit Activation OTP failure",
  REMOVE_BENEFIT: "Remove benefit",
  SKIP_FOR_NOW: "Skip for now",
  BACK_TO_ADD_FEATURES_POPUP: "Back to Add Features Popup",
  SKIP_FEATURE_SELECTION_POPUP: "Skip Feature Selection Popup",
  CANCEL_POPUP: "Cancel Popup",
  PROCEED: "Proceed",
  RESEND_OTP: "Resend OTP",
  CANCEL_OTP_PAGE: "Cancel OTP Page",
  RIGHT_ARROW_CLICK: "Right Arrow Click",
  CANCEL_BENEFIT_DETAILS: "Cancel Benefit Details",
  LEFT_ARROW_CLICK: "Left Arrow Click",
  VIEW_CODE: "View Code",
  REDEEM_VOUCHER: "Redeem Voucher",
  EXIT_QR_CODE_PAGE: "Exit QR Code Page",
  SUMMARY_OF_CONDITIONS: "Summary of Conditions",
  VIEW_QR_CODE: "View QR Code",
  COPY_VOUCHER_CODE: "Copy Voucher Code",
  EXIT_SUCCESSFUL_POPUP: "Exit Successful Popup",
});

export const PageRoutes = Object.freeze({
  "/Features/ManageFeatures": "Manage Features Page",
  "/Features/ManageFeatures/Feature": "Activated Benefit Details Page",
  "/Features/ManageFeatures/Login": "Login Page",
  "/Features/ManageFeatures/WalletDisclaimer": "Wallet Disclaimer Page",
  "/Features/ManageFeatures/OTP": "OTP Page",
  "/Features/ManageFeatures/AddFeature": "Add Features Page",
  "/Features/Au/Tnc": "Terms and Conditions Page",
  "/Features/ManageFeatures/Checkout": "Summary Page",
  "/Features/ManageFeatures/FeatureDetails": "Benefit Details Page",
  "/Features/Onboarding/AddFeature": "Onboarding Add Benefit Page",
  "/Features/Onboarding/Checkout": "Onboarding Checkout Page",
  "/Features/Onboarding/FeatureDetails": "Onboarding Benefit Details Page",
  "/Features/Onboarding/PreLoader": "Pre Loader Page",
  "/Features/Form": "Form Page",
  "/Features/SpinWheel/SpinWheel": "Fortune Wheel Page",
  "/Features/SpinWheel/Congratulations": "Fortune Wheel Congratulations Page",
  "/Features/Error": "Error Page",
  "/Features/QR": "QR Screen Page",
  "/Features/GenericError": "Generic Error Page",
  "/Features/Forbidden": "Forbidden Page",
  "/Features/SameGroupError": "Same Group Error Page",
});