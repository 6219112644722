import { SET_FONT, SET_DESIGN } from '../ActionTypes';

const initialState = {
  fontFamily: 'Inter',
  color1: '#0D286C',
  color2: '#53B7ED',
  color3: '#F8F8F8',
  toastColor: '#FFFFFF',
  toastBgColor: '#303030',
  cardDesignUrl: null,
  cardOrientation: 'VERTICAL',
  title: 'My Card',
};

const ThemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FONT:
      return {
        ...state,
        fontFamily: action.payload.fontFamily,
      };
    case SET_DESIGN:
      return {
        ...state,
        color1: action.payload.color1,
        color2: action.payload.color2,
        color3: action.payload.color3,
        cardDesignUrl: action.payload.cardDesignUrl,
        cardOrientation: action.payload.cardOrientation,
        title: action.payload.title,
      };
    default:
      return state;
  }
};

export default ThemeReducer;