import moment from "moment";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  Image,
  Switch,
  Text,
  View,
  Modal,
  Button,
  Content,
  ScrollView,
  useToast,
  Toast,
} from "native-base";
import React, { useEffect, useState } from "react";
import { DECIMAL_PLACES } from "../../../utils/constants";
import Close from "../../svg/close";
import styles from "./styles";
import Details from "../Components/details";
import CashBack from "../Components/CashBack";
import UnlimitedCashBack from "../Components/UnlimitedCashback";
import ManageBenefitService from "../../../services/ManageBenefitService";
import { useLocation, useNavigate } from "react-router-dom";
import RewardPoints from "../Components/RewardPoints";
import { benefitNameWithId, consoleError, getPageNameByRoute } from "../../../utils/functions";
import CustomLoader from "../../core/loader";
import { useWindowDimensions } from "react-native-web";
import updateRenewalStatus from "../../../services/ManageBenefitService";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import Lounge from "../Components/Lounge";
import Poshvine from "../Components/poshvine";
import { setErrorDetails } from "../../../store/actions/ManageBenefits";
import { showToast1 } from "../../core/toast";
import Header from "../Components/Header";
import logger from "../../../utils/logger";
import useNavigateToPath from "../../../hooks/useNavigateToPath";
import { useTrackEvents } from "../../../hooks/useTrackEvents";
import { TrackEventTypes } from "../../../utils/enums";

const StaticBenefit = () => {
  const location = useLocation();
  const dimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const [benefitDetails, setBenefitDetails] = useState();
  const [benefitCycles, setBenefitCycles] = useState();
  const [loading, setLoading] = useState(true);
  const [autoRenewalError, setAutoRenewalError] = useState(false);
  const navigate = useNavigate();
  const queryParams = queryString.parse(location.search);
  const nativeToast = useToast();
  const navigateToPath = useNavigateToPath();

  const manageBenefits = useSelector((state) => state.manageBenefits);
  const programConfigsData = useSelector((state) => state.programConfigs.data);

  const date = queryParams.date ? queryParams.date : undefined;

  const [open, setOpen] = useState(false);
  const [showAllTransactions, setShowAllTransactions] = useState(false);
  const [isAutoRenewal, setIsAutoRenewal] = useState(false);
  const [isAutorenewalToggleVisible, setIsAutorenewalToggleVisible] =
    useState(false);
  const [modalText, setModalText] = useState("");

  const trackEventsAdobeAnalytics = useTrackEvents();

  const toast = (message) => {
    showToast1({ nativeToast, message });
  };

  const updateAutoRenewal = async (value) => {
    // setIsAutoRenewal(value);
    if (!value) {
      trackEventsAdobeAnalytics({
        linkName: TrackEventTypes.SAVE_AUTO_RENEWAL_OFF,
        benefitsName: [benefitNameWithId(benefitDetails?.benefit?.title, benefitDetails?.benefit?.id)],
        linkPageName: getPageNameByRoute(location.pathname),
      });
    }
    const response = await ManageBenefitService.updateRenewalStatus(
      benefitDetails.id,
      {
        isAutoRenewEnabled: value,
      }
    );

    if (response.status === 200) {
      setIsAutoRenewal(value);
      if (value) {
        trackEventsAdobeAnalytics({
          linkName: TrackEventTypes.AUTO_RENEWAL_ON,
          linkPageName: getPageNameByRoute(location.pathname),
          benefitsName: [benefitNameWithId(benefitDetails?.benefit?.title, benefitDetails?.benefit?.id)]
        });
      }
    } else if (response.status === 422) {
      setAutoRenewalError(true);
      setTimeout(() => {
        setAutoRenewalError(false);
      }, 5000);
    } else {
      toast("Unable to update status. Please retry");
    }
  };

  const onToggleOff = async () => {
    const response = await ManageBenefitService.getBenefitStats({
      type: "BENEFIT_STATS_FOR_AUTO_RENEWAL",
      accountId: queryParams.accountId || localStorage.getItem("cardId"),
      benefitIds: [benefitDetails?.benefit?.id],
    });

    if (response.status === 200) {
      trackEventsAdobeAnalytics({
        linkName: TrackEventTypes.AUTO_RENEWAL_OFF,
        linkPageName: getPageNameByRoute(location.pathname),
        benefitsName: [benefitNameWithId(benefitDetails?.benefit?.title, benefitDetails?.benefit?.id)]
      });
      setModalText(response.data.benefitNudges[0].text);
      setOpen(true);
    } else if (response.status === 422) {
    } else {
      toast("Unable to update status. Please retry");
    }
  };

  const handleCancelButton = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.CANCEL_AUTO_RENEWAL_OFF,
      linkPageName: getPageNameByRoute(location.pathname),
      benefitsName: [benefitNameWithId(benefitDetails?.benefit?.title, benefitDetails?.benefit?.id)]
    });
    setOpen(false);
    setIsAutoRenewal(true);
  };

  const handleModalClose = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.EXIT_AUTO_RENEWAL_OFF,
      linkPageName: getPageNameByRoute(location.pathname),
      benefitsName: [benefitNameWithId(benefitDetails?.benefit?.title, benefitDetails?.benefit?.id)]
    });
    setOpen(false);
  };

  const handleClose = () => {
    navigateToPath("/Features/ManageFeatures");
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.CANCEL_BENEFIT_DETAILS,
      benefitsName: [benefitNameWithId(benefitDetails?.benefit?.title, benefitDetails?.benefit?.id)]
    });
  }

  useEffect(() => {
    (async () => {
      const queryParams = queryString.parse(location.search);
      let accountId = queryParams.accountId || localStorage.getItem("cardId");
      try {
        const response = await ManageBenefitService.getBenefitDetails(
          location.state.id
        );
        if (response.status === 200) {
          let d = response.data.benefitCycles;
          let e = response.data.benefitCycles.sort((a, b) => b.id - a.id);
          setBenefitCycles(e);
          setBenefitDetails(response.data);
          setIsAutoRenewal(response.data.autoRenewal);
          setIsAutorenewalToggleVisible(response?.data?.isAutorenewalToggleVisible);
        } else {
          logger.error({
            message: `An error occurred while get Benefit Details for accountId ${accountId}`,
            responseData: response.data,
            accountId: accountId,
            correlationId: response.headers.x_correlation_id,
          });
          if (response.status === 403) {
            if (response.data === "") {
              navigate("/Features/Forbidden");
            } else {
              dispatch(
                setErrorDetails({
                  errorDetails: response.data,
                })
              );

              navigate("/Features/Error");
            }
          } else {
            navigate("/Features/GenericError", {
              state: {
                x_correlation_id: response.headers.x_correlation_id,
              },
            });
          }
          consoleError("Feature details fetching failed");
        }
      } catch (error) {
        logger.error({
          message: `An exception occurred while get Benefit Details for accountId ${accountId}`,
          error: error?.message,
          accountId: accountId,
          stackTrace: error?.stack,
        });
        consoleError(error);
      }
      setLoading(false);
    })();
  }, []);

  const header = (data) => {
    switch (data.benefit.type) {
      case "FUEL_SURCHARGE_WAIVER":
        return <CashBack data={data} />;
      case "CASHBACK":
        return <UnlimitedCashBack data={data} />;
      case "POSHVINE_VOUCHER":
        return <Poshvine data={data} />;

      case "REWARD_ACCELERATOR":
        return <RewardPoints data={data} />;

      case "AIRPORT_LOUNGE":
        return <Lounge data={data} />;

      default:
        return `continue earning  points earned`;
    }
  };

  const cycleMsg = (data) => {
    switch (data.benefit.type) {
      case "FUEL_SURCHARGE_WAIVER":
        return "No Waiver earned";
      case "CASHBACK":
        if (
          data.benefit.qualifyingThresholdValue <=
          data.currentCycleTotalQulaifyingSpend
        ) {
          return "No Cashback earned";
        } else {
          return "Target not Met";
        }

      case "REWARD_ACCELERATOR":
        return "No Reward earned";

      default:
        return `Target not Met`;
    }
  };

  return loading ? (
    <>
      <View
        w="100%"
        alignItems="center"
        justifyContent="center"
        height={dimensions.height}
      >
        <CustomLoader color={"#F37435"} width={120} height={120} />
      </View>
    </>
  ) : (
    <View alignItems={"center"} justifyContent="center" bgColor={"#FFFDFA"}>
      <ScrollView
        scrollEnabled={!open}
        _web={{ maxW: "412px" }}
        w="100%"
        bgColor={"#FFFFFF"}
      >
        {
          <>
            <Header page="Checkout" />
          </>
        }
        <View bgColor="#F5F7FA" height={dimensions.height}>
          <Modal isOpen={open} onClose={handleModalClose} mt={12}>
            <Modal.Content maxWidth="350">
              <Modal.CloseButton />
              <Modal.Header bgColor="#FEF2F0">
                <Text
                  color="#6D3078"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="20px"
                  lineHeight="24px"
                >
                  Disable Auto-Renewal?
                </Text>
              </Modal.Header>
              <Modal.Body>
                <Text>
                  {modalText ||
                    `Disabling auto-renewal means ${benefitDetails.benefit.title} will be discontinued on reaching the current feature end date. Although you will continue to enjoy feature for the remainder of your current feature period.
Are you sure you wish to disable auto-renewal for ${benefitDetails.benefit.title}?`}
                </Text>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group w="90%">
                  <Button
                    w="45%"
                    borderWidth="1px"
                    borderColor="#F37435"
                    bgColor="none"
                    px="24px"
                    py="10px"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    onPress={handleCancelButton}
                  >
                    <Text
                      ml="8px"
                      color="#F37435"
                      fontWeight="700"
                      fontFamily="Quicksand"
                      fontSize="14px"
                      lineHeight="22px"
                    >
                      Cancel
                    </Text>
                  </Button>
                  <Button
                    w="45%"
                    bgColor="#F37435"
                    px="24px"
                    py="10px"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    onPress={() => {
                      setOpen(false);
                      updateAutoRenewal(false);
                    }}
                  >
                    <Text
                      ml="8px"
                      color="#FFFFFF"
                      fontWeight="700"
                      fontFamily="Quicksand"
                      fontSize="14px"
                      lineHeight="22px"
                    >
                      Save
                    </Text>
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
          {
            <View
              alignItems="center"
              justifyContent="space-between"
              flexDirection="row"
              p="16px"
              bgColor="#FFFFFF"
              shadow="1"
            >
              <View w="90%">
                <Text
                  color="#303030"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="18px"
                  lineHeight="26px"
                >
                  {benefitDetails.benefit.title}
                </Text>
              </View>
              <View
                cursor="pointer"
                onClick={handleClose}
              >
                <Close size="24" color="#6D3078" />
              </View>
            </View>
          }

          <View w="100%">{header(benefitDetails)}</View>
          {benefitDetails.benefit.type !== "AIRPORT_LOUNGE" && (
            <View {...styles.boxContainer}>
              {benefitDetails.benefitCycles[0].accountTransactions.length ===
              0 ? (
                <View>
                  <Text
                    color="#303030"
                    fontWeight="600"
                    fontFamily="Quicksand"
                    fontSize="14px"
                    lineHeight="19px"
                  >
                    {benefitDetails.benefit.type === "POSHVINE_VOUCHER" &&
                    benefitDetails.benefit.benefitIssuanceType === "IMMEDIATE"
                      ? "You need to spend to avoid additional charges"
                      : "You need to spend to start earning"}
                  </Text>
                </View>
              ) : (
                <View>
                  <View>
                    <Text
                      color="#303030"
                      fontWeight="600"
                      fontFamily="Quicksand"
                      fontSize="15px"
                      lineHeight="19px"
                    >
                      {`Qualifying Spends (${benefitDetails.benefitCycles[0].accountTransactions.length})`}
                    </Text>
                  </View>
                  <View mt="4px">
                    {benefitDetails.benefitCycles[0].accountTransactions.map(
                      (transaction, index) =>
                        index <
                        (showAllTransactions
                          ? benefitDetails.benefitCycles[0].accountTransactions
                              .length
                          : 2) ? (
                          <View
                            alignItems="center"
                            justifyContent="space-between"
                            flexDirection="row"
                            py="12px"
                            borderBottomWidth="1px"
                            borderBottomColor="#F8F8F8"
                          >
                            <View>
                              <Text
                                color="#303030"
                                fontWeight="600"
                                fontFamily="Quicksand"
                                fontSize="14px"
                                lineHeight="20px"
                              >
                                {transaction.merchantDescription}
                              </Text>
                              <Text
                                mt="4px"
                                color="#303030"
                                fontWeight="600"
                                fontFamily="Quicksand"
                                fontSize="12px"
                                lineHeight="16px"
                              >
                                {moment(transaction.transactionDate).format(
                                  "DD MMM 'YY"
                                )}
                              </Text>
                            </View>
                            <View>
                              <Text
                                color="#303030"
                                fontWeight="600"
                                fontFamily="Quicksand"
                                fontSize="14px"
                                lineHeight="16px"
                              >
                                {`${
                                  transaction.transactionNature === "DEBIT"
                                    ? "-"
                                    : "+"
                                } `}
                                ₹
                                {Number(transaction.transactionAmount)
                                  .toFixed(DECIMAL_PLACES)
                                  .toLocaleString("en-IN")}
                              </Text>
                            </View>
                          </View>
                        ) : null
                    )}
                  </View>
                  {benefitDetails.benefitCycles[0].accountTransactions.length >
                    2 && (
                    <View alignItems="center" justifyContent="center">
                      <View
                        flexDirection="row"
                        cursor="pointer"
                        alignItems="center"
                        onClick={() =>
                          setShowAllTransactions(!showAllTransactions)
                        }
                      >
                        <Text
                          color="#FE7143"
                          fontWeight="700"
                          fontFamily="Quicksand"
                          fontSize="12px"
                          lineHeight="20px"
                        >
                          {!showAllTransactions ? `View More` : `View Less`}
                        </Text>{" "}
                        {!showAllTransactions ? (
                          <ChevronDownIcon color="#FE7143" size="24px" />
                        ) : (
                          <ChevronUpIcon color="#FE7143" size="24px" />
                        )}
                      </View>
                    </View>
                  )}
                </View>
              )}
            </View>
          )}

          {benefitDetails.benefit.type !== "AIRPORT_LOUNGE" && (
            <View {...styles.boxContainer}>
              <View
                alignItems="center"
                flexDirection="row"
                justifyContent="space-between"
              >
                <View>
                  <Text>Feature Period</Text>
                </View>
                <View py="4px" px="10px" bgColor="#F6F3F7" borderRadius="8px">
                  <Text>{`${moment(benefitDetails.startDate).format(
                    "DD MMM 'YY"
                  )} - ${moment(benefitDetails.endDate).format(
                    "DD MMM 'YY"
                  )}`}</Text>
                </View>
              </View>
              <View>
                {benefitCycles.map((cycle, index) => {
                  return (
                    <View
                      key={index}
                      my="16px"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <View flexDirection="row" alignItems="center">
                        <View>
                          <Text
                            color="#303030"
                            fontWeight="600"
                            fontFamily="Quicksand"
                            fontSize="14px"
                            lineHeight="22px"
                          >
                            {`${moment(cycle.cycleStartDate).format(
                              "DD MMM 'YY"
                            )} - ${moment(cycle.cycleEndDate).format(
                              "DD MMM 'YY"
                            )}`}
                          </Text>
                        </View>
                      </View>
                      <View>
                        {(moment(cycle.cycleEndDate).format("YYYYMMDD") >=
                          moment(date).format("YYYYMMDD") &&
                          moment(cycle.cycleStartDate).format("YYYYMMDD") <=
                            moment(date).format("YYYYMMDD")) ||
                        moment(cycle.cycleEndDate).format("YYYYMMDD") >
                          moment(date).format("YYYYMMDD") ? (
                          <Text
                            color="#E09600"
                            fontWeight="600"
                            fontFamily="Quicksand"
                            fontSize="12px"
                            lineHeight="20px"
                          >
                            In Progress
                          </Text>
                        ) : benefitDetails.benefit === "POSHVINE_VOUCHER" &&
                          cycle.totalQualifyingSpend === 0 ? (
                          <Text
                            color="#E05351"
                            fontWeight="600"
                            fontFamily="Quicksand"
                            fontSize="12px"
                            lineHeight="20px"
                          >
                            Spend Criteria Not Met
                          </Text>
                        ) : benefitDetails.benefit === "POSHVINE_VOUCHER" &&
                          cycle.totalQualifyingSpend > 0 ? (
                          <Text
                            color="#4DAE6D"
                            fontWeight="600"
                            fontFamily="Quicksand"
                            fontSize="12px"
                            lineHeight="20px"
                          >
                            {`Spend Criteria Met`}
                          </Text>
                        ) : cycle.cashbackAccrued === 0 ? (
                          <Text
                            color="#E05351"
                            fontWeight="600"
                            fontFamily="Quicksand"
                            fontSize="12px"
                            lineHeight="20px"
                          >
                            {cycleMsg(benefitDetails)}
                          </Text>
                        ) : cycle.cashbackAccrued > 0 ? (
                          <Text
                            color="#4DAE6D"
                            fontWeight="600"
                            fontFamily="Quicksand"
                            fontSize="12px"
                            lineHeight="20px"
                          >
                            {`+ ${cycle.cashbackAccrued} cashback`}
                          </Text>
                        ) : (
                          <></>
                        )}
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          )}

          <View w="100%" {...styles.boxContainer}>
            <View>
              <Text
                color="#303030"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="18px"
                lineHeight="26px"
                paddingBottom={"30px"}
              >
                FAQs
              </Text>
            </View>
            <Details
              data={benefitDetails.benefit.displaySections}
              benefitName={benefitNameWithId(
                benefitDetails?.benefit?.title,
                benefitDetails?.benefit?.id
              )}
            />
          </View>

          {isAutorenewalToggleVisible && (
            <View
              {...styles.boxContainer}
              flexDirection="row"
              flexDir={"column"}
              pb="20px"
            >
              <View
                w="100%"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <View>
                  <View>
                    <Text
                      color="#303030"
                      fontWeight="700"
                      fontFamily="Quicksand"
                      fontSize="14px"
                      lineHeight="16px"
                    >
                      Auto Renewal
                    </Text>
                  </View>
                  <View>
                    <Text
                      color="#303030"
                      fontWeight="400"
                      fontFamily="Quicksand"
                      fontSize="12px"
                      lineHeight="16px"
                      mt="4px"
                    >{`Renews on ${moment(
                      benefitDetails.nextRenewalDate
                    ).format("DD MMM 'YY")} for ₹${Number(
                      benefitDetails.benefit.price
                    ).toLocaleString("en-IN")}`}</Text>
                  </View>
                </View>
                <View>
                  {
                    <Switch
                      onToggle={(value) => {
                        if (!value) {
                          programConfigsData?.autoRenewalStatsConfig
                            ?.isEnabled && onToggleOff();
                        } else {
                          programConfigsData?.autoRenewalStatsConfig
                            ?.isEnabled && updateAutoRenewal(value);
                        }
                      }}
                      isChecked={isAutoRenewal}
                      onThumbColor="white"
                      onTrackColor="#ffd7b5"
                    />
                  }
                </View>
              </View>
              {autoRenewalError && (
                <View>
                  <Text
                    color="#FF3737"
                    fontWeight="600"
                    fontFamily="Quicksand"
                    fontSize="10px"
                    lineHeight="26px"
                  >
                    Can not update auto-renewal status please try again after
                    sometime
                  </Text>
                </View>
              )}
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  );
};

export default StaticBenefit;
