import { Image, Text, View } from "native-base";
import React, { useEffect } from "react";
import { useWindowDimensions } from "react-native";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ProgramConfigs } from "../../../utils/constants";

const GenericError = () => {
  const manageBenefits = useSelector((state) => state.manageBenefits);
  const dimensions = useWindowDimensions();
  const location = useLocation();
  const navigate = useNavigate();
  const redirect = () => {
    if (manageBenefits.channel === "CCS") {
      navigate(ProgramConfigs[localStorage.getItem("program")].loginUrl);
    } else {
      window.location.href = manageBenefits.callBackUrl;
    }
  };

  return (
    <View alignItems={"center"} justifyContent="center" bgColor={"#FFFDFA"}>
      <View
        h={dimensions.height}
        _web={{ maxW: "412px" }}
        width="100%"
        bgColor={"#FFFFFF"}
      >
        {manageBenefits.channel !== "MB" && (
          <View
            alignItems="center"
            justifyContent="space-between"
            flexDirection="row"
            p="16px"
            shadow="1"
          >
            <View flexDirection="row" alignItems="center">
              {manageBenefits.channel !== "IB" && (
                <View
                  mr="8px"
                  alignItems="center"
                  onClick={() => redirect()}
                  cursor={"pointer"}
                >
                  <Image
                    source={{
                      uri:
                        process.env.REACT_APP_AWS_S3_BUCKET_URL +
                        "backArrow.svg",
                    }}
                    height="24px"
                    width="24px"
                    alt=""
                  />
                </View>
              )}
              <Text
                color="#303030"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="18px"
                lineHeight="26px"
              >
                Manage Features
              </Text>
            </View>
            <View>{/* <Close size="24" color="#6D3078" /> */}</View>
          </View>
        )}

        <View alignItems="center" justify-content="center" mt="50%">
          <View>
            <Image
              source={{
                uri:
                  process.env.REACT_APP_AWS_S3_BUCKET_URL +
                  "accountBlocked.png",
              }}
              height="160px"
              width="160px"
              alt=""
            />
          </View>
          <View alignItems="center" w="90%">
            <View>
              <Text
                textAlign="center"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="24px"
                lineHeight="32px"
              >
                Something went wrong! Please retry after some time
              </Text>
            </View>
            <View alignItems="center" mt="20px">
              <Text
                textAlign="center"
                fontWeight="500"
                fontFamily="Quicksand"
                fontSize="14px"
                lineHeight="22px"
              >
                Contact customer care on 1800 1200 1500 or in case of any
                queries Email to creditcard.support@aubank.in
              </Text>
            </View>
            {location?.state?.x_correlation_id && (
              <View mt="4px">
                <Text
                  opacity={"0.4"}
                >{`Error ID: ${location.state.x_correlation_id}`}</Text>
              </View>
            )}
          </View>
          <View></View>
        </View>
      </View>
    </View>
  );
};

export default GenericError;
