import React from "react";
import { View, Text } from "native-base";
import { ProgressBarType } from "../../../../utils/enums";

const ProgressBar = (props) => {
  const { type, maxValue, currentValue, color } = props;
  return (
    <View w="100%" margin={"auto"}>
      <View>
        <View
          bgColor="#EBEDF0"
          height="16px"
          w="100%"
          borderRadius="16px"
          shadow="2"
        >
          <View
            bgColor={color}
            height="16px"
            w={`${(currentValue / maxValue) * 100}%`}
            maxW={"100%"}
            borderRadius="16px"
          />
        </View>
      </View>
      <View
        alignItems="center"
        mt="4px"
        justifyContent="space-between"
        flexDirection="row"
      >
        <View>
          <Text
            fontFamily="Quicksand"
            color="#303030"
            fontWeight="600"
            fontSize="12px"
            lineHeight="20px"
          >
            {type === ProgressBarType.SPEND
              ? `₹${Number(
                  currentValue > maxValue ? maxValue : currentValue
                ).toLocaleString("en-IN")} spent`
              : `${
                  currentValue > maxValue ? maxValue : currentValue
                } Transactions done`}
          </Text>
        </View>
        <View>
          <Text
            fontFamily="Quicksand"
            color="#303030"
            fontWeight="600"
            fontSize="12px"
            lineHeight="20px"
          >
            {type === ProgressBarType.SPEND
              ? `of ₹${Number(maxValue).toLocaleString("en-IN")}`
              : `of ${maxValue} Transactions`}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default ProgressBar;
