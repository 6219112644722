// import { SET_SESSION } from '../ActionTypes';

const initialState = {
    locales: 'en-IN',
    localeOptions: { minimumFractionDigits: 2 },
    dateFormat: 'DD MMM YYYY',
    timeFormat: 'hh:mm a',
}

const PreferencesReducer = (state = initialState, action) => {
    switch(action.type) {
        // case SET_SESSION: 
        //     return {
        //         ...state,
        //         sessionToken: action.payload.sessionToken,
        //     };
        default:
            return state;
    }
};

export default PreferencesReducer;