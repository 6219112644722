import {
  SET_BENEFITS_CARD_ID,
  SET_BENEFITS_ADDITION,
  SET_CURRENT_BENEFITS,
  SET_CALLBACK_URL,
  SET_TERMS_ACCEPTED,
  SET_LOUNGE_COUNT,
  SET_ERROR_DETAILS,
  SET_MAX_LOUNGE_COUNT,
  SET_CURRENT_VOUCHER,
  SET_CHANNEL,
  SET_WALLET_DETAILS,
  SET_QUERY_PARAMS
} from "../ActionTypes";

export const setBenefitsCardId = (data) => (dispatch) => {
  localStorage.setItem("cardId", data.cardId);
  dispatch({
    type: SET_BENEFITS_CARD_ID,
    payload: data,
  });
};
export const setChannel = (data) => (dispatch) => {
  dispatch({
    type: SET_CHANNEL,
    payload: data,
  });
};

export const setWalletDetails = (data) => (dispatch) => {
  dispatch({
    type: SET_WALLET_DETAILS,
    payload: data,
  });
};

export const setCallBackUrl = (data) => (dispatch) => {
  dispatch({
    type: SET_CALLBACK_URL,
    payload: data,
  });
};

export const setMaxLoungeCount = (data) => (dispatch) => {
  dispatch({
    type: SET_MAX_LOUNGE_COUNT,
    payload: data,
  });
};

export const setErrorDetails = (data) => (dispatch) => {
  dispatch({
    type: SET_ERROR_DETAILS,
    payload: data,
  });
};

export const setLoungeCount = (data) => (dispatch) => {
  dispatch({
    type: SET_LOUNGE_COUNT,
    payload: data,
  });
};

export const setIsTermsAccepted = (data) => (dispatch) => {
  dispatch({
    type: SET_TERMS_ACCEPTED,
    payload: data,
  });
};

export const setBenefitsAddition = (data) => (dispatch) => {
  dispatch({
    type: SET_BENEFITS_ADDITION,
    payload: data,
  });
};

export const setCurrentBenefit = (data) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_BENEFITS,
    payload: data,
  });
};

export const setCurrentVoucher = (data) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_VOUCHER,
    payload: data,
  });
};

export const setQueryParams = (data) => (dispatch) => {
  dispatch({
    type: SET_QUERY_PARAMS,
    payload: data,
  });
};
