import { Text, View } from "native-base";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import styles from "./styles";
import { Button } from "../../core";
import BackArrow2 from "../../../../assets/images/BackArrow2";
import VolumeUp from "../../../../assets/images/VolumeUp";
import VolumeMute from "../../../../assets/images/VolumeMute";
import WheelPointer from "../../../../assets/images/WheelPointer.svg";
import Pie from "../Pie/Pie";
import FortuneWheelService from "../../../../services/FortuneWheelService";
import {
  getHomeScreenPath,
  handleApiError,
  isApiSuccessful,
} from "../../../../utils/functions";
import Paths from "../../../../routes/Paths";
import { WheelBucketCategories } from "../../../../utils/enums";
import TryAgain from "../TryAgain/TryAgain";
import logger from "../../../../utils/logger";
import { setWheelSpins } from "../../../../store/actions/WheelProgram";

const audioId = "wheel-audio";

const FortuneWheel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isSpinning, setIsSpinning] = useState(false);
  const [spinCount, setSpinCount] = useState(0);
  const [targetBucket, setTargetBucket] = useState(null);
  const [targetIndex, setTargetIndex] = useState(-1);
  const [showTryAgain, setShowTryAgain] = useState(false);
  const [audioMuted, setAudioMuted] = useState(false);

  const manageBenefits = useSelector((state) => state.manageBenefits);
  const wheelProgram = useSelector((state) => state.wheelProgram);
  
  const {
    buckets,
    availableRewardSpins,
    availableSpins,
    expiryDate,
    maxReward,
  } = wheelProgram;

  const hasMoreSpins = availableRewardSpins > 0 && availableSpins > 0;

  const onSpinClick = async () => {
    if (hasMoreSpins) {
      setIsSpinning(true); // disable spin button
      try {
        const response = await FortuneWheelService.spin(
          manageBenefits.accountId
        );
        const result = response.data;

        if (isApiSuccessful(response)) {
          const targetBucket = result;
          const index = buckets.findIndex(
            (bucket) => bucket.id === targetBucket.id
          );

          if (index > -1) {
            setTargetBucket({ ...targetBucket });
            setTargetIndex(index);
            setSpinCount((count) => count + 1); // trigger spin

            const audio = document.getElementById(audioId);
            audio.play();
          }
        } else if (response.status !== 422) {
          logger.error({
            message: `An error occurred while Fortune Service wheel for accountId ${manageBenefits.accountId}`,
            responseData: result,
            accountId: manageBenefits.accountId,
            correlationId: response.headers.x_correlation_id,
          });

          setIsSpinning(false); // enable spin button
          handleApiError(response, navigate);
        }
      } catch (error) {
        logger.error({
          message: `An exception occurred while Fortune Service wheel for accountId ${manageBenefits.accountId}`,
          error: error.message,
          accountId: manageBenefits.accountId,
          stackTrace: error.stack,
        });

        setIsSpinning(false);
        navigate(Paths.GENERIC_ERROR);
      }
    }
  };

  const onSpinEnd = () => {
    const audio = document.getElementById(audioId);
    // no stop method available
    audio.pause();
    audio.currentTime = 0;

    setTimeout(() => {
      setIsSpinning(false);

      // update spin counts
      const newRewardSpins =
        targetBucket.category === WheelBucketCategories.HIT
          ? availableRewardSpins - 1
          : availableRewardSpins;
      dispatch(
        setWheelSpins({
          availableRewardSpins: newRewardSpins,
          availableSpins: availableSpins - 1,
        })
      );

      if (targetBucket.category === WheelBucketCategories.HIT) {
        navigate(Paths.FORTUNE_WHEEL_CONGRATULATIONS, {
          state: { targetBucket },
        });
      } else {
        setShowTryAgain(true);
      }
    }, 2000);
  };

  const onVolumeClick = () => {
    const audio = document.getElementById(audioId);
    audio.muted = !audioMuted;

    setAudioMuted((audioMuted) => !audioMuted);
  };

  return (
    <>
      <audio id={audioId}>
        <source
          src={process.env.REACT_APP_SPIN_WHEEL_AUDIO_URL}
          type="audio/mp3"
        />
      </audio>
      <View display={showTryAgain ? "block" : "none"}>
        <TryAgain
          hasMoreSpins={hasMoreSpins}
          setShowTryAgain={setShowTryAgain}
        />
      </View>
      {/*
        Showing and hiding components is less computative for the browser than adding them to DOM
        Also remounting the pie component will cause it to spin without click after the first spin has occurred
      */}
      <View
        bgColor="#FFDBD4"
        overflowY="auto"
        height="100%"
        display={showTryAgain ? "none" : "block"}
      >
        <View {...styles.mainContainer}>
          <View {...styles.headerContainer}>
            <View
              {...styles.backButtonContainer}
              onClick={() => navigate(getHomeScreenPath())}
            >
              <BackArrow2 {...styles.backButton} />
            </View>
            <Text {...styles.titleText}>Spin the Wheel</Text>
            <View {...styles.volumeContainer} onClick={onVolumeClick}>
              {audioMuted ? (
                <VolumeMute style={{ ...styles.volumeMute }} />
              ) : (
                <VolumeUp />
              )}
            </View>
          </View>
          <View {...styles.title2Container}>
            <Text {...styles.text2}>Play & Get</Text>
            <Text {...styles.text2}>
              Wallet Balance upto <Text {...styles.text3}>₹{maxReward}</Text>
            </Text>
          </View>
          <View
            {...styles.wheelContainer}
            // prevent pie from adding extra margin outside the conatiner
            overflow="hidden"
            // if padding bottom not added then wheel gets copped from base due to overflow hidden
            pb="30px"
          >
            <img alt="" src={WheelPointer} style={{ ...styles.wheelPointer }} />
            <Pie
              pieRadiusInPixels={130}
              spinDurationInSeconds={5}
              numberOfRotations={10}
              slices={buckets}
              targetIndex={targetIndex}
              spinCount={spinCount}
              onSpinEnd={onSpinEnd}
            />
          </View>
          <View flex="1">
            {/* div to occupy all remaining space and push submit button to bottom */}
          </View>
          <View {...styles.footerContainer}>
            {availableSpins === 1 && (
              <Text {...styles.attemptsLeftsText}>One attempt left</Text>
            )}
            <Button
              {...styles.submitButton}
              text="Spin To Win"
              isDisabled={isSpinning}
              onPress={onSpinClick}
            ></Button>
            {expiryDate && (
              <Text {...styles.footerText}>
                Hurry Up! Spin before:{" "}
                <Text {...styles.footerText2}>
                  {moment(expiryDate).format("Do MMM YYYY")}
                </Text>
              </Text>
            )}
          </View>
        </View>
      </View>
    </>
  );
};

export default FortuneWheel;
