import BenefitService from "./BenefitService";

const FortuneWheelService = {
  getProgram: (accountId) =>
    BenefitService.get(`/gamify/wheel/${accountId}/program`),
  
  spin: (accountId) =>
    BenefitService.post(`/gamify/wheel/${accountId}/spin`),
};

export default FortuneWheelService;
