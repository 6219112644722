import {
  SET_WHEEL_PROGRAM,
  SET_WHEEL_SPINS
} from "../ActionTypes";

export const setWheelProgram = (data) => (dispatch) => {
  dispatch({
    type: SET_WHEEL_PROGRAM,
    payload: data,
  });
};

export const setWheelSpins = (data) => (dispatch) => {
  dispatch({
    type: SET_WHEEL_SPINS,
    payload: data,
  });
};
