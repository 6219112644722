import React, { useEffect } from "react";
import { NativeBaseProvider, View } from "native-base";
import { useWindowDimensions } from "react-native-web";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import ManageBenefitRoutes from "./routes/ManageBenefitRoutes";
import ExternalRoutes from "./routes/ExternalRoutes";
import ExternalPaths from "./routes/ExternalPaths";
import { setQueryParams } from "./store/actions/ManageBenefits";
import * as Sentry from "@sentry/react";
import "./App.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ProgramConfigs, Programs } from "./utils/constants";
import ManageBenefitService from "./services/ManageBenefitService";
import { setProgramConfigs } from "./store/actions/ProgramConfigs";
import logger from "./utils/logger";
import { Envs } from "./utils/enums";

const env = process.env.REACT_APP_ENV;

const script = document.createElement("script");
script.src =
  env === Envs.PROD
    ? "//assets.adobedtm.com/8e068bb67c90/07a78f6404f1/launch-5e1bff55b076.min.js"
    : "//assets.adobedtm.com/8e068bb67c90/07a78f6404f1/launch-15106500ef68-staging.min.js";
script.async = true;
// Temporarily Commented till unsafe-inline and unsafe-eval solutions are not available.
// script.onload = () => {
//   if (window.digitalData) {
//     window._satellite?.track("pageLoad");
//   }
// };
document.body.appendChild(script);

function App() {
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      setQueryParams({ queryParams: queryString.parse(window.location.search) })
    );
    const queryParams = queryString.parse(location.search);
    document.title =
      ProgramConfigs[localStorage.getItem("program")]?.siteHeader || "AU LIT";

    if (queryParams.programId) {
      localStorage.setItem("programId", queryParams?.programId);
      console.log(queryParams?.programId?.toLocaleLowerCase());
      localStorage.setItem(
        "program",
        Programs[process.env.REACT_APP_ENV][
          queryParams?.programId?.toLocaleLowerCase()
        ]
          ? Programs[process.env.REACT_APP_ENV][
              queryParams?.programId?.toLocaleLowerCase()
            ]
          : "LIT"
      );
      console.log(
        ProgramConfigs[
          Programs[process.env.REACT_APP_ENV][
            queryParams?.programId?.toLocaleLowerCase()
          ]
        ]?.siteHeader,
        Programs[process.env.REACT_APP_ENV][
          queryParams?.programId?.toLocaleLowerCase()
        ],
        process.env.REACT_APP_ENV
      );
      document.title =
        ProgramConfigs[
          Programs[process.env.REACT_APP_ENV][
            queryParams?.programId?.toLocaleLowerCase()
          ]
        ]?.siteHeader || "AU LIT";
    }
    if (!localStorage.getItem("program")) {
      localStorage.setItem("program", "LIT");
    }
  }, []);

  useEffect(async () => {
    const queryParams = queryString.parse(location.search);
    const programId = queryParams?.programId;
    const accountId = queryParams?.accountId || localStorage.getItem("cardId");
    if (accountId && programId) {
      try {
        const response = await ManageBenefitService.getProgramConfigs(
          programId,
          accountId
        );
        if (response.status === 200) {
          dispatch(setProgramConfigs(response.data));
        } else {
          dispatch(setProgramConfigs(response.data));
          logger.error({
            message: `An exception occurred while getProgramConfigs for accountId ${accountId}`,
            responseData: response.data,
            accountId: accountId,
            correlationId: response.headers.x_correlation_id,
          });
          navigate("/Features/GenericError");
        }
      } catch (err) {
        logger.error({
          message: `An exception occurred while getProgramConfigs for accountId ${accountId}`,
          error: err.message,
          accountId: accountId,
          stackTrace: err.stack,
        });
        console.log(err);
        navigate("/Features/GenericError");
      }
    }
  }, []);

  return (
    // adding NativeBaseProvider in App component so its theme has access to store
    <NativeBaseProvider>
      <View
        w={windowDimensions.width}
        h={windowDimensions.height}
        maxW="412px"
        marginLeft="auto"
        marginRight="auto"
      >
        {Object.values(ExternalPaths).includes(window.location.pathname) ? (
          <ExternalRoutes />
        ) : (
          <ManageBenefitRoutes />
        )}
      </View>
    </NativeBaseProvider>
  );
}

export default Sentry.withProfiler(App);
