import React from "react";
import { Routes, Route } from "react-router-dom";
import ExternalPaths from "./ExternalPaths";
import GenericError from "../components/ManageBenefits/Error/4xxError";
import WheelShare from "../components/ManageBenefits/external/fortuneWheel/WheelShare";

const ExternalRoutes = () => {
  return (
    <Routes>
      <Route path={ExternalPaths.FORTUNE_WHEEL_SHARE} element={<WheelShare />} />

      <Route path="*" element={<GenericError />} />
    </Routes>
  );
};

export default ExternalRoutes;
