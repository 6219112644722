import { Image, Text, View } from "native-base";
import React from "react";
import Close from "../../svg/close";
import Details from "./details";

import { useNavigate } from "react-router-dom";
import { ProgramConfigs } from "../../../utils/constants";

const WalletDisclaimer = () => {
  const navigate = useNavigate();
  const headData = {
    title: "About Wallet",
    paragraphs: [
      "From time to time, AU Small Finance Bank Ltd. (“AU”) shall credit complimentary funds to your wallet. These funds can only be used to purchase features on your AU Small Finance Bank LIT Credit Card",
      "Funds shall have an expiry date and will be forfeited if not utilised by the cardholder before expiry.",
    ],
  };
  const data = {
    contents: [
      {
        title: "How to use Wallet to purchase features",
        paragraphs: [
          "Assume that Rs. 200 is credited to your AU Small Finance Bank LIT Wallet and are scheduled to be expired on 31st December 2022.",
          "In a scenario where cost of all features selected is Rs.298, customer is first charged with Rs. 298 + GST amount of Rs. 53.64 and then receives an instant Cashback of Rs. 200 from his wallet balance (Cashback received will always be less than or equal to the sum of fees of the features selected). If cost of all features selected is Rs. 99, customer will be charged with Rs. 99 + GST amount of Rs.17.82 and receives an instant Cashback of Rs. 99 from his wallet balance. The remaining Rs. 101 in the wallet can be used to purchase another feature before the expiry date of 31st December 2022.",
        ],
      },
      // { title: "Terms & Conditions", paragraphs: ["", "", ""] },
    ],
  };
  return (
    <View alignItems="center" justifyContent="center">
      <View _web={{ maxW: "412px" }} width="100%" bgColor={"#FFFFFF"}>
        <View
          flexDirection={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          p="16px"
          bgColor={"#FEF2F0"}
        >
          <View>
            <Text
              color="#6D3078"
              fontWeight="700"
              fontFamily="Quicksand"
              fontSize="20px"
              lineHeight="28px"
            >
              {
                ProgramConfigs[localStorage.getItem("program")]
                  .walletTermsHeader
              }
            </Text>
          </View>
          <View
            cursor="pointer"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Close size="24" />
          </View>
        </View>
        <View>
          <View p="16px" shadow={1}>
            <Text
              color="#303030"
              fontWeight="700"
              fontFamily="Quicksand"
              fontSize="18px"
              lineHeight="26px"
            >
              About Wallet
            </Text>
          </View>
          <View
            px="16px"
            mt="24px"
            //   borderTopWidth="1px"
            //   borderTopColor="#EBEDF0"
          >
            <View borderBottomWidth="1px" borderBottomColor="#E5E5E5" w="100%">
              {ProgramConfigs[
                localStorage.getItem("program")
              ].aboutWalletContent.paragraphs.map((point) => (
                <View
                  flexDirection="row"
                  w="100%"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  mb="8px"
                >
                  <View w="10%">
                    <Image
                      source={{
                        uri:
                          process.env.REACT_APP_AWS_S3_BUCKET_URL +
                          "Pointer.svg",
                      }}
                      width="28px"
                      height="28px"
                      alt="Alternate Text "
                    />
                  </View>
                  <View ml="4px" w="80%" mt="2px">
                    <Text
                      color="#303030"
                      fontWeight="600"
                      fontSize="12px"
                      lineHeight="20px"
                    >
                      {point}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <Details
              data={
                ProgramConfigs[localStorage.getItem("program")].walletContent
              }
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default WalletDisclaimer;
