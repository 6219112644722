import { Image, Input, ScrollView, Text, useToast, View } from "native-base";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AuLoginBanner from "../../../assets/images/auLoginBannerNew.png";
import ABCLLoginBanner from "../../../assets/images/ABCL-banner.jpg";
import { setOtpState } from "../../../store/actions/Otp";
import ManageBenefitService from "../../../services/ManageBenefitService";
import { showToast1 } from "../../core/toast";
import { useWindowDimensions } from "react-native";
import { CarouselProvider, Slide, Slider } from "pure-react-carousel";
import AccRewardsCarousel from "../../../assets/images/AccRewardsCarousel.svg";
import CategoryBenefitsCarousel from "../../../assets/images/CategoryBenefitsCarousel.svg";
import LoungeAccessCarousel from "../../../assets/images/LoungeAccessCarousel.svg";
import MembershipsCarousel from "../../../assets/images/MembershipsCarousel.svg";
import MilestoneBenefitsCarousel from "../../../assets/images/MilestoneBenefitsCarousel.svg";
import logger from "../../../utils/logger";
import queryString from "query-string";
import { ProgramConfigs, RequestingPage } from "../../../utils/constants";
import { useTrackEvents } from "../../../hooks/useTrackEvents";
import { TrackEventTypes } from "../../../utils/enums";
import usePageTracker from "../../../hooks/usePageTracker";

const Login = (props) => {
  const [mobileVerificationRefId, setMobileVerificationRefId] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [errorMsgOpen, setErrorMsgOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [number, setNumber] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const nativeToast = useToast();
  const windowDimensions = useWindowDimensions();
  const ac = new AbortController();
  const location = useLocation();
  const { program } = useParams();

  const trackEventsAdobeAnalytics = useTrackEvents();

  const otpStore = useSelector((state) => state.otp);

  const carouselItems = [
    {
      image: AccRewardsCarousel,
      Title: "Accelarated Rewards",
      subTitle: "Get 5X/10X rewards on online and offline spends",
    },
    {
      image: CategoryBenefitsCarousel,
      Title: "Category Benefits",
      subTitle: "Get 5% cashback on grocery and travel spends",
    },
    {
      image: LoungeAccessCarousel,
      Title: "Lounge Access",
      subTitle: "Fly comfortably with domestic airport lounge access",
    },
    {
      image: MembershipsCarousel,
      Title: "Memberships",
      subTitle: "Enjoy exciting memberships",
    },
    {
      image: MilestoneBenefitsCarousel,
      Title: "Milestone Benefits",
      subTitle: "Get additional 2%/5% cashback on all spends",
    },
  ];
  const bannerImage = {
    ABCLLoginBanner: ABCLLoginBanner,
    AuLoginBanner: AuLoginBanner,
  };

  useEffect(() => {
    dispatch(
      setOtpState({
        onSuccess: null,
        onError: null,
        onCancel: null,
        reason: null,
        scope: null,
        mobileNumber: null,
        mobileVerificationRefId: null,
      })
    );
  }, []);

  localStorage.setItem("program", program ? program?.toUpperCase() : "LIT");
  console.log(localStorage.getItem("program"));

  useEffect(() => {
    if (
      !location?.state?.isLogoutTriggered &&
      !location?.state?.isCancelTriggered
    ) {
      trackEventsAdobeAnalytics();
    }
  }, []);
  const toast = (message) => {
    showToast1({ nativeToast, message });
  };
  const loginClick = async () => {
    setButtonLoading(true);
    const queryParams = queryString.parse(location.search);
    let accountId = queryParams.accountId || localStorage.getItem("cardId");
    try {
      if (number.length !== 10) {
        setNumberError(true);
      } else {
        const response = await ManageBenefitService.generateOtp({
          mobileNumber: number,
          scope: "LOGIN",
          cobrandId:
            ProgramConfigs[localStorage.getItem("program")].cobrandId[
              process.env.REACT_APP_ENV
            ],
        });

        if (response.status === 200) {
          setErrorMsgOpen(false);
          setMobileVerificationRefId(response.data.mobileVerificationRefId);
          dispatch(
            setOtpState({
              onSuccess: onOtpSuccess,
              onError: onOtpError,
              onCancel: onOtpCancel,
              scope: "LOGIN",
              reason: otpReason,
              mobileNumber: number,
              mobileVerificationRefId: response.data.mobileVerificationRefId,
            })
          );
          trackEventsAdobeAnalytics({
            linkName: TrackEventTypes.GENERATE_OTP,
            otpRequested: "y",
          });
          navigate("/Features/ManageFeatures/OTP", {
            state: { requestingPage: RequestingPage.LOGIN },
          });
        } else {
          logger.error({
            message: `An error occurred while generateLoginOtp for accountId ${accountId}`,
            responseData: response.data,
            accountId: accountId,
            correlationId: response.headers.x_correlation_id,
          });
          trackEventsAdobeAnalytics({
            error: response?.data?.reason,
          });
          setErrorMsgOpen(true);
          setNumber("");
        }
      }
    } catch (err) {
      logger.error({
        message: `An exception occurred while generateLoginOtp for accountId ${accountId}`,
        error: err.message,
        accountId: accountId,
        stackTrace: err.stack,
      });
      trackEventsAdobeAnalytics({
        error: err?.reason,
      });
      console.log(err);
      navigate("/Features/GenericError");
    }
    setButtonLoading(false);
  };

  const onOtpSuccess = async () => {};

  const onOtpError = (_error) => {
    navigate(ProgramConfigs[localStorage.getItem("program")].loginUrl);
  };

  const onOtpCancel = () => {
    navigate(ProgramConfigs[localStorage.getItem("program")].loginUrl, {
      state: { isCancelTriggered: true },
    });
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.CANCEL_OTP_PAGE,
    });
  };

  const otpReason = `to login to ${
    ProgramConfigs[localStorage.getItem("program")].pageHeader
  }`;

  return (
    <View alignItems="center" justifyContent="center" p="0px 16px">
      <ScrollView minHeight={"750px"} height={windowDimensions.height}>
        <View
          _web={{ maxW: "412px" }}
          width="100vw"
          height={"100%"}
          bgColor={"#FFFFFF"}
        >
          {/* <Header /> */}
          <View
            width="100%"
            p="16px 24px"
            backgroundColor={"#fff2f0"}
            flexDirection="row"
            alignItems={"center"}
            justifyContent="center"
          >
            <View>
              <Image
                source={{
                  uri:
                    process.env.REACT_APP_AWS_S3_BUCKET_URL + "AU0101Logo.png",
                }}
                height="63px"
                width="60px"
                alt=""
              />
            </View>
            <View ml="6px" alignItems={"center"}>
              <Text
                color="#6d3078"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="24px"
                lineHeight="26px"
              >
                {ProgramConfigs[localStorage.getItem("program")].loginHeader}
              </Text>
            </View>
          </View>
          <View w={"100%"}>
            <View alignSelf={"center"} alignItems="center" mb="4px">
              <View width="100%" style={{ aspectRatio: 415 / 200 }}>
                <Image
                  source={{
                    uri: bannerImage[
                      ProgramConfigs[localStorage.getItem("program")]
                        .loginBanner
                    ],
                  }}
                  flex={"1"}
                  width="100%"
                  alt=""
                />
              </View>
              <Text
                w="80%"
                textAlign={"center"}
                color="#212529"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="16px"
                lineHeight="20px"
                mt="8px"
              >
                {ProgramConfigs[localStorage.getItem("program")].loginHeroTitle}
              </Text>
            </View>
            <View alignItems={"center"} w={"100%"}>
              <View
                w="90%"
                // ml={windowDimensions.width > 400 ? "0px" : "28px"}
                mx="4px"
                p="16px 24px"
                shadow={"3"}
                borderRadius="16px"
                borderWidth={"0.5px"}
                borderColor="#8C8C8C"
              >
                <View>
                  <Text
                    color="#303030"
                    fontWeight="600"
                    fontFamily="Quicksand"
                    fontSize="16px"
                    lineHeight="26px"
                  >
                    Mobile Number
                  </Text>
                </View>
                <View mt="16px">
                  <Input
                    placeholder=""
                    id="single-factor-code-text-field"
                    name="password"
                    autoComplete="one-time-code"
                    secureTextEntry={false}
                    variant={mobileVerificationRefId ? "filled" : "outline"}
                    isReadOnly={Boolean(mobileVerificationRefId)}
                    isInvalid={numberError}
                    borderColor="#303030"
                    borderWidth="1px"
                    padding={"12px"}
                    InputLeftElement={`  +91`}
                    value={number}
                    onChangeText={(contact) => {
                      if (contact !== "") {
                        if (Number(contact) && contact.length <= 10) {
                          setNumber(contact);
                          if (numberError && contact.length === 10) {
                            setNumberError(false);
                          }
                        }
                      } else {
                        setNumber("");
                      }
                    }}
                    _focus={{ borderColor: "#303030" }}
                  ></Input>
                </View>
                {errorMsgOpen && (
                  <View>
                    <Text
                      color="#FF3737"
                      fontWeight="600"
                      fontFamily="Quicksand"
                      fontSize="10px"
                      lineHeight="26px"
                    >
                      Please enter your registered mobile number
                    </Text>
                  </View>
                )}
              </View>
            </View>
            {/* <View
            mt="16px"
            mx="16px"
            p="16px 24px"
            shadow={"3"}
            borderRadius="16px"
          >
            <View>
              <Text
                color="#303030"
                fontWeight="600"
                fontFamily="Quicksand"
                fontSize="16px"
                lineHeight="26px"
              >
                Enter OTP
              </Text>
            </View>
            <View mt="16px">
              
            </View>
          </View> */}
            <View alignItems={"center"} mt="16px">
              <Text
                color="#6D3078"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="22px"
                lineHeight="20px"
              >
                Unlock the Power of Choice
              </Text>
            </View>
            <View mt={windowDimensions.width > 500 ? "12px" : "32px"}>
              <CarouselProvider
                naturalSlideWidth={320}
                naturalSlideHeight={114}
                totalSlides={
                  ProgramConfigs[localStorage.getItem("program")].loginCarousel
                    .length
                }
                visibleSlides={1.2}
                isPlaying={true}
              >
                <Slider>
                  {ProgramConfigs[
                    localStorage.getItem("program")
                  ].loginCarousel.map((item, index) => (
                    <Slide index={index}>
                      <View alignItems={"center"} flexDirection="row">
                        <View>
                          <Image
                            source={{ uri: item.image }}
                            height="114px"
                            width="137px"
                            alt=""
                          />
                        </View>
                        <View w="180px" ml="12px">
                          <View>
                            <Text
                              color="#6D3078"
                              fontWeight="700"
                              fontFamily="Quicksand"
                              fontSize="16px"
                              lineHeight="20px"
                            >
                              {item.Title}
                            </Text>
                          </View>
                          <View mt="8px">
                            <Text
                              color="#6D3078"
                              fontWeight="500"
                              fontFamily="Quicksand"
                              fontSize="14px"
                              lineHeight="16px"
                            >
                              {item.subTitle}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </Slide>
                  ))}
                </Slider>
              </CarouselProvider>
            </View>
          </View>
          <View display={"flex"} alignItems={"center"}>
            <View
              alignItems="center"
              justifyContent="center"
              // position="fixed"
              width="100%"
              // _web={{ maxW: "412px" }}
              mt="64px"
            >
              <View
                // ml={windowDimensions.width > 400 ? "0px" : "0px"}
                cursor="pointer"
                onClick={() => {
                  buttonLoading ? console.log() : loginClick();
                }}
                w="90%"
                alignItems="center"
                justifyContent="center"
                bgColor="#F37435"
                p="10px 140px"
                borderRadius="4px"
                opacity={buttonLoading ? "0.6" : "1"}
              >
                <Text
                  color="#FFFFFF"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="14px"
                  lineHeight="22px"
                >
                  Login
                </Text>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default Login;
