import React, { useEffect, useState } from "react";
import { Image, Text, View, Modal } from "native-base";
import { SummaryCard } from "../Components/BenefitsCards";
import { useLocation, useNavigate } from "react-router-dom";
import { benefitNameWithId, consoleError, getPageNameByRoute } from "../../../utils/functions";
import ManageBenefitService from "../../../services/ManageBenefitService";
import { useDispatch, useSelector } from "react-redux";
import {
  setBenefitsAddition,
  setIsTermsAccepted,
  setLoungeCount,
} from "../../../store/actions/ManageBenefits";
import Skip from "../Components/Skip";
import { useWindowDimensions } from "react-native-web";
import { setOtpState } from "../../../store/actions/Otp";
import Header from "../Components/Header";
import CustomLoader from "../../core/loader";
import logger from "../../../utils/logger";
import queryString from "query-string";
import { ErrorReasons, TrackEventTypes } from "../../../utils/enums";
import useNavigateToPath from "../../../hooks/useNavigateToPath";
import { useTrackEvents } from "../../../hooks/useTrackEvents";

const OnboardingCheckout = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [cardIssuanceModalOpen, setCardIssuanceModalOpen] = useState(false);
  const [ButtonLoading, setButtonLoading] = useState(false);
  const [pageProcessing, setPageProcessing] = useState(false);
  const [selectedItems, setSelectedItems] = useState([""]);
  const [selectedItemsPrice, setSelectedItemsPrice] = useState([""]);
  const manageBenefits = useSelector((state) => state.manageBenefits);
  const programConfigsData = useSelector((state) => state.programConfigs.data);
  const [initialBenefitList] = useState(manageBenefits.benefitsList);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowDimensions = useWindowDimensions();
  const navigateToPath = useNavigateToPath();
  const trackEventsAdobeAnalytics = useTrackEvents();
  useEffect(() => {
    setTermsAccepted(manageBenefits.termsAccepted);
    setSelectedItems(manageBenefits.benefitsList);
  }, []);

  useEffect(() => {
    if (manageBenefits.benefitsListId.length === 0) {
      navigateToPath("/Features/Onboarding/AddFeature");
    } else {
      var tempSum = 0;
      for (var i = 0; i < manageBenefits.benefitsList.length; i++) {
        tempSum = tempSum + manageBenefits.benefitsList[i].price;
      }
      setSelectedItemsPrice(tempSum);
    }
  }, [manageBenefits.benefitsListId]);
  const activateBenefits = async () => {
    const queryParams = queryString.parse(location.search);
    let accountId = queryParams.accountId || localStorage.getItem("cardId");
    if (!ButtonLoading) {
      try {
        setButtonLoading(true);
        setPageProcessing(true);
        const data = [];

        for (var i = 0; i < manageBenefits.benefitsList.length; i++) {
          data.push(manageBenefits.benefitsList[i].id);
        }
        if (manageBenefits.channel === "CCA") {
          const response = await ManageBenefitService.generateCcaOtp({
            accountId: localStorage.getItem("applicantId"),
            benefits: data,
          });
          if (response.status === 200) {
            dispatch(
              setOtpState({
                onSuccess: onOtpSuccess,
                onError: onOtpError,
                onCancel: onOtpCancel,
                reason: "CCA",
                mobileNumber: response.data.mobileNumber,
                mobileVerificationRefId: response.data.mobileVerificationRefId,
              })
            );
            navigate("/Features/ManageFeatures/OTP");
            trackEventsAdobeAnalytics({
              linkName: TrackEventTypes.ACTIVATE_FEATURE,
              benefitsName: manageBenefits.benefitsList.map((benefit) => {
                return benefitNameWithId(benefit?.title, benefit?.id);
              }),
              otpRequested:"y",
              linkPageName: getPageNameByRoute(location.pathname)
            });
          } else if (
            response.status === 409 &&
            response?.data?.reason === ErrorReasons.SAME_BENEEFIT_GROUP
          ) {
            logger.error({
              message: `An error occurred while generateCcaOtp for accountId ${accountId}`,
              responseData: response.data,
              accountId: accountId,
              correlationId: response.headers.x_correlation_id,
            });
            navigate("/Features/SameGroupError", { replace: true });
            setPageProcessing(false);
          } else {
            logger.error({
              message: `An error occurred while generateCcaOtp for accountId ${accountId}`,
              responseData: response.data,
              accountId: accountId,
              correlationId: response.headers.x_correlation_id,
            });
            navigate("/Features/GenericError", {
              state: {
                x_correlation_id: response.headers.x_correlation_id,
              },
            });
          }
        } else {
          handleActivateFeatures();
        }
      } catch (error) {
        logger.error({
          message: `An exception occurred while generateCcaOtps or activateOnboardingBenefits for accountId ${accountId}`,
          error: error.message,
          accountId: accountId,
          stackTrace: error.stack,
        });
        consoleError(error);
      }
    }
    setPageProcessing(false);
  };

  const onOtpSuccess = async () => {
    dispatch(setIsTermsAccepted({ termsAccepted: false }));
    setTimeout(() => {
      dispatch(
        setBenefitsAddition({
          benefits: [],
          benefitsListId: [],
          benefitsGroupId: [],
        })
      );
      dispatch(
        setLoungeCount({
          benefitsGroupIdCount: 0,
          benefitsListIdCount: {},
        })
      );
      window.location.href = manageBenefits.callBackUrl;
    }, 5000);

    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.OTP_BENEFIT_ACTIVATION,
      otpSubmitted: "y",
    })
  };

  const onCcoOtpSuccess = (mobileVerificationRefId) => {
    navigate("/Features/Onboarding/Checkout", {
      state: {
        otpVerified: true,
        mobileVerificationRefId: mobileVerificationRefId,
      },
      replace: true,
    });
  };

  useEffect(() => {
    if (location?.state?.otpVerified) {
      setTermsAccepted(true);
      handleActivateFeatures();
    }
  }, []);

  const onOtpError = (_error) => {
    navigate("/Features/GenericError");
  };

  const onOtpCancel = () => {
    navigate("/Features/Onboarding/Checkout");
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.CANCEL_OTP_PAGE,
    });
  };
  const benefitIds = () =>
    manageBenefits?.benefitsList?.map((benefit) => benefit.id);

  const handleActivateFeatures = async () => {
    if (
      programConfigsData?.benefitActivationAuthConfig?.isEnabled === false ||
      programConfigsData?.benefitActivationAuthConfig?.featureCount >
        manageBenefits?.benefitsList?.length ||
      location?.state?.otpVerified
    ) {
      activateCcoBenefits();
      if(!location?.state?.otpVerified){
        trackEventsAdobeAnalytics({
          linkName: TrackEventTypes.ACTIVATE_FEATURE,
          benefitsName: manageBenefits.benefitsList.map((benefit) => {
            return benefitNameWithId(benefit?.title, benefit?.id);
          }),
          linkPageName: getPageNameByRoute(location.pathname)
        });
      }
    } else if (
      programConfigsData?.benefitActivationAuthConfig?.isEnabled &&
      programConfigsData?.benefitActivationAuthConfig?.featureCount <=
        manageBenefits.benefitsList.length
    ) {
      const accountId = localStorage.getItem("applicantId");
      try {
        const response = await ManageBenefitService.generateOtp({
          accountId: accountId,
          scope: "CCO_BENEFIT_ACTIVATION",
          benefitIds: benefitIds(),
        });
        if (response.status === 200) {
          dispatch(
            setOtpState({
              onSuccess: onCcoOtpSuccess,
              onError: onOtpError,
              onCancel: onOtpCancel,
              Scope: "CCO_BENEFIT_ACTIVATION",
              reason:
                "OTP Authentication - Feature Activation in Onboarding Flow",
              mobileNumber: response.data.maskedMobileNumber,
              mobileVerificationRefId: response.data.mobileVerificationRefId,
            })
          );
          navigate("/Features/ManageFeatures/OTP");
          trackEventsAdobeAnalytics({
            linkName: TrackEventTypes.ACTIVATE_FEATURE,
            benefitsName: manageBenefits.benefitsList.map((benefit) => {
              return benefitNameWithId(benefit?.title, benefit?.id);
            }),
            otpRequested:"y",
            linkPageName: getPageNameByRoute(location.pathname)
          });
        } else {
          logger.error({
            message: `An error occurred while generateOtp CCO for accountId ${accountId}`,
            responseData: response.data,
            accountId: accountId,
            correlationId: response.headers.x_correlation_id,
          });
          navigate("/Features/GenericError", {
            state: {
              x_correlation_id: response.headers.x_correlation_id,
            },
          });
        }
      } catch (err) {
        logger.error({
          message: `An exception occurred while generateOtp CCO for accountId ${accountId}`,
          error: err.message,
          accountId: accountId,
          stackTrace: err.stack,
        });

        console.log(err);
        navigate("/Features/GenericError");
      }
    }
  };

  const activateCcoBenefits = async () => {
    const accountId = localStorage.getItem("applicantId");
    try {
      let activateBenefitsData = {};
      activateBenefitsData.benefits = benefitIds();
      activateBenefitsData.channel = manageBenefits.channel;
      if (programConfigsData?.benefitActivationAuthConfig?.isEnabled) {
        activateBenefitsData.mobileVerificationRefId =
          location?.state?.mobileVerificationRefId;
      }

      const response = await ManageBenefitService.activateOnboardingBenefits(
        activateBenefitsData,
        localStorage.getItem("applicantId")
      );
      if (response.status === 200) {
        dispatch(setIsTermsAccepted({ termsAccepted: false }));
        setCardIssuanceModalOpen(true);
        
        setTimeout(() => {
          dispatch(
            setBenefitsAddition({
              benefits: [],
              benefitsListId: [],
              benefitsGroupId: [],
            })
          );
          dispatch(
            setLoungeCount({
              benefitsGroupIdCount: 0,
              benefitsListIdCount: {},
            })
          );
          window.location.href = manageBenefits.callBackUrl;
        }, 5000);
        if (location?.state?.otpVerified) {
          trackEventsAdobeAnalytics({
            linkName: TrackEventTypes.OTP_BENEFIT_ACTIVATION,
            otpSubmitted: "y",
            linkPageName: getPageNameByRoute(location.pathname),
          });
        } else {
          trackEventsAdobeAnalytics({
            linkName: TrackEventTypes.WITHOUT_OTP_BENEFIT_ACTIVATION,
            linkPageName: getPageNameByRoute(location.pathname),
          });
        }
      } else if (
        response.status === 409 &&
        response?.data?.reason === ErrorReasons.SAME_BENEEFIT_GROUP
      ) {
        logger.error({
          message: `An error occurred while CCO activateOnboardingBenefits for accountId ${accountId}`,
          responseData: response.data,
          accountId: accountId,
          correlationId: response.headers.x_correlation_id,
        });
        navigate("/Features/SameGroupError", { replace: true });
        setPageProcessing(false);
      } else {
        logger.error({
          message: `An error occurred while CCO activateOnboardingBenefits for accountId ${accountId}`,
          responseData: response.data,
          accountId: accountId,
          correlationId: response.headers.x_correlation_id,
        });
        navigate("/Features/GenericError", {
          state: {
            x_correlation_id: response.headers.x_correlation_id,
          },
        });
      }
    } catch (error) {
      logger.error({
        message: `An exception occurred while CCO activateOnboardingBenefits for accountId ${accountId}`,
        error: error.message,
        accountId: accountId,
        stackTrace: error.stack,
      });
      consoleError(error);
    }
  };

  const goBack = () => {
    dispatch(setIsTermsAccepted({ termsAccepted: false }));
    navigateToPath("/Features/Onboarding/AddFeature");
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.LEFT_ARROW_CLICK,
    });
  };

  const handleTncCheckbox = () => {
    if (!termsAccepted) {
      trackEventsAdobeAnalytics({
        linkName: TrackEventTypes.TNC_CHECKBOX,
        linkPageName: getPageNameByRoute(location.pathname),
      });
    }
    setTermsAccepted(!termsAccepted);
  };

  const handleTncRedirection = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.TNC_REDIRECTION,
      linkPageName: getPageNameByRoute(location.pathname),
    });
    dispatch(setIsTermsAccepted({ termsAccepted: termsAccepted }));
    window.open(programConfigsData?.tncConfig?.redirectionLink);
  };

  return (
    <View alignItems={"center"} justifyContent="center" bgColor={"#FFFDFA"}>
      <View _web={{ maxW: "412px" }} width="100%" bgColor={"#FFFFFF"}>
        {manageBenefits.channel === "CCA" && <Header />}
        <Skip />
        <View
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          px="16px"
          py="20px"
        >
          <View flexDirection="row" alignItems="center">
            <View
              mr="8px"
              alignItems="center"
              cursor="pointer"
              onClick={() => goBack()}
            >
              <Image
                source={{
                  uri:
                    process.env.REACT_APP_AWS_S3_BUCKET_URL + "backArrow.svg",
                }}
                height="24px"
                width="24px"
                alt=""
              />
            </View>
            <Text
              color="#303030"
              fontWeight="700"
              fontFamily="Quicksand"
              fontSize="18px"
              lineHeight="26px"
            >
              Summary
            </Text>
          </View>
          <View>{/* <Close size="24" color="#6D3078" /> */}</View>
        </View>
        <View mx="16px">
          <Modal
            isOpen={cardIssuanceModalOpen}
            onClose={() => {
              setCardIssuanceModalOpen(false);
            }}
            mt={12}
            closeOnOverlayClick={false}
            maxHeight={"90vh"}
          >
            <Modal.Content maxWidth="600px" w="90%">
              {/* <Modal.CloseButton /> */}

              <Modal.Body bgColor="#ffffff">
                <View bgColor="#ffffff" px="24px" pb="40px" pt={"5px"}>
                  <View>
                    <Image
                      source={{
                        uri:
                          process.env.REACT_APP_AWS_S3_BUCKET_URL +
                          "benefitActivateSuccessfull.png",
                      }}
                      height="100px"
                      width="195px"
                      alt=""
                    />
                  </View>
                  <View alignItems="center" mt="24px">
                    <Text
                      color="#303030"
                      fontWeight="700"
                      fontFamily="Quicksand"
                      fontSize="24px"
                      lineHeight="32px"
                    >
                      Your Feature(s) selection has been captured
                    </Text>
                    <Text
                      color="#303030"
                      fontWeight="500"
                      fontFamily="Quicksand"
                      fontSize="14px"
                      lineHeight="22px"
                    >
                      Features will be activated automatically once card is
                      issued. You can always modify, add, track and manage
                      features on your Card through AU 0101 NetBanking/Mobile
                      Banking. You will now be re-directed to complete the LIT
                      Credit Card application process.
                    </Text>
                  </View>
                </View>
                <View
                  cursor="pointer"
                  onClick={() => {
                    setCardIssuanceModalOpen(false);
                    dispatch(setIsTermsAccepted({ termsAccepted: false }));
                    window.location.href = manageBenefits.callBackUrl;
                  }}
                  w="90%"
                  alignItems="center"
                  justifyContent="center"
                  bgColor="#F37435"
                  p="10px 140px"
                  borderRadius="4px"
                  margin="auto"
                >
                  <Text
                    color="#FFFFFF"
                    fontWeight="700"
                    fontFamily="Quicksand"
                    fontSize="14px"
                    lineHeight="22px"
                  >
                    OK
                  </Text>
                </View>
              </Modal.Body>
            </Modal.Content>
          </Modal>
          {!pageProcessing ? (
            <View mb="140px">
              <View>
                {manageBenefits.benefitsList.map((item, index) => (
                  <View mt="12px" key={index}>
                    <SummaryCard
                      id={item.id}
                      data={item}
                      disclaimerText="*Rewards will be credited post statement generation"
                    />
                  </View>
                ))}
              </View>

              {manageBenefits.benefitsList.length !== 0 && (
                <View
                  mt="32px"
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  alignSelf="center"
                  bgColor="#FEF2F0"
                  p="8px"
                  borderRadius="4px"
                >
                  <View
                    cursor="pointer"
                    onClick={handleTncCheckbox}
                  >
                    {/* <Checkbox
              value="TC"
              id="1"
              name="TC"
              accessibilityLabel="Terms"
              isChecked={termsAccepted}
              onChange={(isSelected) => setTermsAccepted(isSelected)}
              _checked={{ bgColor: "none" }}
            /> */}

                    <View display={termsAccepted ? "none" : "flex"}>
                      <Image
                        source={{
                          uri:
                            process.env.REACT_APP_AWS_S3_BUCKET_URL +
                            "UnTickBox.svg",
                        }}
                        height="24px"
                        width="24px"
                        alt=""
                      />
                    </View>

                    <View display={termsAccepted ? "flex" : "none"}>
                      <Image
                        source={{
                          uri:
                            process.env.REACT_APP_AWS_S3_BUCKET_URL +
                            "TickBox.svg",
                        }}
                        height="24px"
                        width="24px"
                        alt=""
                      />
                    </View>
                  </View>
                  <View ml="12px" w="90%">
                    <Text color="#000">
                      By ticking the box, I confirm to accept the{" "}
                      <Text
                        onPress={handleTncRedirection}
                        fontWeight="700"
                        textDecoration="underline"
                      >
                        Terms & Conditions
                      </Text>
                    </Text>
                  </View>
                </View>
              )}
            </View>
          ) : (
            <View
              w="100%"
              _web={{ maxW: "412px" }}
              alignItems="center"
              justifyContent="center"
              bgColor={"#FFFFFF"}
              mt="40%"
            >
              <View>
                <CustomLoader color={"#F37435"} width={120} height={120} />
              </View>
              <View mt="12px">
                <Text
                  color="#303030"
                  fontWeight="500"
                  fontFamily="Quicksand"
                  fontSize="12px"
                  lineHeight="24px"
                >
                  Activating your selected benefits. Please wait...
                </Text>
              </View>
            </View>
          )}

          <View
            alignItems="center"
            justifyContent="center"
            position="fixed"
            _web={{ maxW: "412px" }}
            width="100%"
            bottom="0px"
            borderTopWidth="1px"
            borderTopColor="#EBEDF0"
          >
            {
              <View
                bgColor="#FFFFFF"
                opacity={ButtonLoading ? "0.4" : "1"}
                width="100%"
                pt="16px"
                mx="16px"
                pb="36px"
                alignItems="center"
                justifyContent="space-between"
                flexDirection="row"
              >
                <View w="40%">
                  <View>
                    <Text
                      color="#303030"
                      fontWeight="700"
                      fontFamily="Quicksand"
                      fontSize="20px"
                      lineHeight="24px"
                      display="flex"
                    >
                      {`₹${selectedItemsPrice} `}
                      <Text fontSize="10px" fontWeight="600" color="#94A0B4">
                        (GST Payable)
                      </Text>
                    </Text>
                  </View>
                  <View>
                    <Text
                      color="#303030"
                      fontWeight="500"
                      fontFamily="Quicksand"
                      fontSize="12px"
                      lineHeight="20px"
                    >{`${manageBenefits.benefitsList.length} Feature(s) selected`}</Text>
                  </View>
                </View>
                <View w="60%" alignItems="center" justifyContent="center">
                  <View
                    px="30px"
                    py="10px"
                    bgColor={termsAccepted ? "#F37435" : "#D7DBE0"}
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    onClick={() => {
                      termsAccepted ? activateBenefits() : console.log("5");
                    }}
                  >
                    <Text
                      color="#FFFFFF"
                      fontWeight="700"
                      fontFamily="Quicksand"
                      fontSize="14px"
                      lineHeight="22px"
                    >
                      Activate Features
                    </Text>
                  </View>
                </View>
              </View>
            }
          </View>
        </View>
      </View>
    </View>
  );
};

export default OnboardingCheckout;
