import { Text, View, Modal, Image } from "native-base";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useNavigateToPath from "../../../hooks/useNavigateToPath";
import { useTrackEvents } from "../../../hooks/useTrackEvents";
import { TrackEventTypes } from "../../../utils/enums";
import { getPageNameByRoute } from "../../../utils/functions";

const Skip = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const manageBenefits = useSelector((state) => state.manageBenefits);
  const programConfigsData = useSelector((state) => state.programConfigs?.data);
  const isSkipEnabled = !programConfigsData?.onboardConfig?.isEnabled;
  const navigateToPath = useNavigateToPath();
  const location = useLocation();

  const trackEventsAdobeAnalytics = useTrackEvents();

  const handleSkipForNow = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.SKIP_FOR_NOW,
      linkPageName: getPageNameByRoute(location.pathname),
    })
    setOpen(true)
  }

  const handleBackToAddFeatures = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.BACK_TO_ADD_FEATURES_POPUP,
    })
    setOpen(false);
    navigateToPath("/Features/Onboarding/AddFeature");
  }

  const handleSkipAndProceed = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.SKIP_FEATURE_SELECTION_POPUP,
    })
    setOpen(false);
    window.location.href = manageBenefits.callBackUrl;
  }

  const handlePopupClose = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.CANCEL_POPUP,
      linkPageName: getPageNameByRoute(location.pathname),
    })
    setOpen(false);
  }

  return manageBenefits.channel === "CCA" ? (
    <></>
  ) : (
    <View
      alignItems="center"
      justifyContent="space-between"
      flexDirection="row"
      px="12px"
      py="16px"
      backgroundColor="#FEFAF9"
    >
      <Modal
        isOpen={open}
        onClose={handlePopupClose}
        mt={12}
      >
        <Modal.Content maxWidth="550" w="90%">
          <Modal.CloseButton />

          <Modal.Body bgColor="#FFFFFF">
            <View bgColor="#FFFFFF" px="24px" py="40px">
              <View justifyContent="center" alignItems="center">
                <Image
                  source={{
                    uri:
                      process.env.REACT_APP_AWS_S3_BUCKET_URL +
                      "skipWarning.png",
                  }}
                  height="160px"
                  width="160px"
                  alt=""
                />
              </View>
              <View alignItems="center" mt="24px">
                <Text
                  color="#303030"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="24px"
                  lineHeight="32px"
                >
                  To make the most of the features, we suggest you to add
                  features and then proceed
                </Text>
                <Text
                  color="#636D7D"
                  fontWeight="500"
                  fontFamily="Quicksand"
                  fontSize="14px"
                  lineHeight="22px"
                >
                  You can also login to AU 0101 app and add features anytime
                </Text>
              </View>
              <View
                onClick={handleBackToAddFeatures}
                mt="32px"
                alignItems="center"
                justifyContent="center"
                bgColor="#F37435"
                cursor="pointer"
                p="10px 140px"
                borderRadius="4px"
              >
                <Text
                  color="#FFFFFF"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="14px"
                  lineHeight="22px"
                >
                  Back to Add Features
                </Text>
              </View>
              <View
                onClick={handleSkipAndProceed}
                mt="12px"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                bgColor="#FFFFFF"
                p="10px 140px"
                borderRadius="4px"
              >
                <Text
                  color="#F37435"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="14px"
                  lineHeight="22px"
                >
                  Skip feature selection and proceed
                </Text>
              </View>
            </View>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <View>
        <View>
          <Text
            color="#6D3078"
            fontWeight="600"
            fontFamily="Quicksand"
            fontSize="16px"
            lineHeight="24px"
          >
            Select Features
          </Text>
        </View>
        {/* <View>
          <Text
            color="#8F6396"
            fontWeight="500"
            fontFamily="Quicksand"
            fontSize="12px"
            lineHeight="20px"
          >
            Step 4 of 5
          </Text>
        </View> */}
      </View>
      {isSkipEnabled && (
        <View>
          <View onClick={handleSkipForNow}>
            <Text
              color="#F37435"
              fontWeight="700"
              fontFamily="Quicksand"
              fontSize="14px"
              lineHeight="22px"
              cursor="pointer"
              underline
            >
              Skip for now
            </Text>
          </View>
        </View>
      )}
    </View>
  );
};

export default Skip;
