import { View, Text, Image } from "native-base";
import React from "react";
import styles from "./styles";
import cashback from "../../../assets/images/Cashback.svg";
import RewardIcon from "../../svg/halfCashBack";
import { DECIMAL_PLACES } from "../../../utils/constants";
import moment from "moment";

const RewardPoints = (props) => {
  var totalRewards = 0;
  for (
    var i = 0;
    i < props.data.benefitCycles[0].accountTransactions.length;
    i++
  ) {
    totalRewards =
      totalRewards +
      props.data.benefitCycles[0].accountTransactions[i].rewardPoints;
  }

  const maxBenefitValue = props.data.benefit.maxBenefitValue;
  totalRewards = totalRewards >= maxBenefitValue ? maxBenefitValue : totalRewards;

  return (
    <View w="100%" bgColor="#FFFFFF">
      <View
        px="16px"
        py="16px"
        borderBottomWidth="1px"
        borderBottomColor="#B1B5BB"
        borderBottomStyle="dotted"
        borderRadius="2px"
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="row"
      >
        <View mr="12px" p="8px">
          <RewardIcon
            percent={
              1 -
              totalRewards /
              maxBenefitValue
            }
            color={props.data.benefit.color}
            size="38px"
          />
        </View>
        <View w="80%">
          <Text
            fontFamily="Quicksand"
            color="#303030"
            fontWeight="700"
            fontSize="18px"
            lineHeight="30px"
          >
            {`${Number(totalRewards)
              .toFixed(0)
              .toLocaleString("en-IN")} Points`}
          </Text>
          <Text
            fontFamily="Quicksand"
            color="#303030"
            fontWeight="500"
            fontSize="12px"
            lineHeight="14px"
            mt="4px"
          >
            Accelerated rewards earned
          </Text>
        </View>
      </View>
      <View my="16px" px="16px">
        <Text
          fontFamily="Quicksand"
          color="#303030"
          fontWeight="600"
          fontSize="12px"
          lineHeight="20px"
        >
          {`Continue to enjoy this feature till`}
          <Text
            ml="4px"
            fontFamily="Quicksand"
            color="#303030"
            fontWeight="700"
            fontSize="12px"
            lineHeight="20px"
          >
            {moment(props.data.endDate).format("DD MMM 'YY")}
          </Text>
        </Text>
      </View>
    </View>
  );
};

export default RewardPoints;
