import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useNavigateToPath = () => {
  const navigate = useNavigate();
  const manageBenefits = useSelector((state) => state.manageBenefits);
  const navigateToPath = (pathname, state = null) => {
    let accountId;
    if (pathname==="/Features/Onboarding/AddFeature") {
        accountId = localStorage.getItem("applicantId");
    }else{
        accountId = localStorage.getItem("cardId");
    }
    const programId = localStorage.getItem("programId");
    let path = {
      pathname,
      search: `?accountId=${accountId}&programId=${programId}&channel=${manageBenefits.channel}`,
    };
    if (state) {
      path.state = state;
    }
    navigate(path);
  };
  return navigateToPath;
};

export default useNavigateToPath;