import React from "react";
import { useNavigate } from "react-router-dom";
import { Text, View } from "native-base";
import { useSelector } from "react-redux";
import Paths from "../../../routes/Paths";
import Wheel from "../../../assets/images/Wheel";

const SpinBanner = () => {
  const navigate = useNavigate();

  const wheelProgram = useSelector((state) => state.wheelProgram);

  return (
    <View
      bgColor="#6D276D"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      py="12px"
      cursor="pointer"
      onClick={() => navigate(Paths.FORTUNE_WHEEL)}
    >
      <Wheel />
      <Text
        fontFamily="Quicksand"
        fontSize="14px"
        fontWeight={700}
        color="#fff"
        ml="15px"
        mt="2px"
      >
        Spin & Win upto <Text color="#F37435">₹{wheelProgram.maxReward}</Text>{" "}
        in your Wallet
      </Text>
    </View>
  );
};

export default SpinBanner;
