import { Text, View } from "native-base";
import React from "react";

const Table = ({ state }) => {

  // State prop format
  //   const state = {
  //     tableHead: ["Transactions", "Amount", "Reward Points"],
  //     tableData: [
  //       ["Fuel", "5541,5542,5172,5983", "0 Reward points"],
  //       ["Electric Vehicle Charging", "5552", "0 Reward points"],
  //       ["Rent transactions", "6513", "1 Reward point"],
  //       ["Utilities", "4900", "1 Reward point"],
  //       ["Schools & Educational Services", "8299", "1 Reward Point"],
  //     ],
  //   };

  return (
    <View mt="15px">
      <View flexDir={"row"}>
        {state?.tableHead?.map((td, index) => {
          return (
            <Text
              w={`${100 / state.tableHead.length}%`}
              fontSize={"11px"}
              borderColor={"black"}
              borderWidth={"1px"}
              p={"2px"}
              borderRightWidth={index == state.tableHead.length - 1 ? "1px" : "0"}
              borderBottomWidth={"0"}
              key={index}
            >
              {td}
            </Text>
          );
        })}
      </View>
      <View>
        {state?.tableData?.map((row, rowIndex) => {
          return (
            <View flexDir={"row"} key={rowIndex}>
              {row.map((td, index) => {
                return (
                  <Text
                    w={`${100 / row.length}%`}
                    fontSize={"11px"}
                    borderColor={"black"}
                    borderWidth={"1px"}
                    p={"2px"}
                    borderRightWidth={index === row.length - 1 ? "1px" : "0"}
                    borderBottomWidth={
                      rowIndex === state.tableData.length - 1 ? "1px" : "0"
                    }
                    borderLeftWidth={
                      td === "" &&
                      row[index - 1] === "" &&
                      rowIndex == state.tableData.length - 1
                        ? 0
                        : ""
                    }
                    key={index}
                  >
                    {td}
                  </Text>
                );
              })}
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default Table;
