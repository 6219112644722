import { SET_PROGRAM_CONFIGS } from "../ActionTypes";

const initialState = {
  data: null,
  error: null,
  status: null,
};

const ProgramConfigsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROGRAM_CONFIGS:
      return {
        ...state,
        data: action.payload.status === "SUCCESS" ? action.payload.data : null,
        error:
          action.payload.status === "SUCCESS" ? null : action.payload.error,
        status: action.payload.status,
      };
    default:
      return state;
  }
};

export default ProgramConfigsReducer;