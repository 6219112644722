import { View, Text, Image, useToast } from "native-base";
import React, { useEffect, useState } from "react";
import moment from "moment";

import { DECIMAL_PLACES } from "../../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import {
  benefitNameWithId,
  consoleError,
  copyToClipboard,
  getPageNameByRoute,
} from "../../../utils/functions";
import ManageBenefitService from "../../../services/ManageBenefitService";
import CustomLoader from "../../core/loader";
import { showToast1 } from "../../core/toast";
import logger from "../../../utils/logger";
import queryString from "query-string";
import { useTrackEvents } from "../../../hooks/useTrackEvents";
import {
  BenefitIssuanceType,
  ProgressBarType,
  TrackEventTypes,
} from "../../../utils/enums";
import ProgressBar from "./ProgressBar/ProgressBar";
import LockedVoucher from "./CustomVoucher/LockedVoucher";
import VoucherBottomText from "./CustomVoucher/VoucherBottomText";
import CongratulationsVoucher from "./CustomVoucher/CongratulationsVoucher";
import CustomVoucherBackground from "./CustomVoucher/CustomVoucherBackground";

const Poshvine = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [ButtonLoading, setButtonLoading] = useState(false);
  const [codeAvailable, setCodeAvailable] = useState(false);
  const [voucher, setVoucher] = useState();
  const nativeToast = useToast();
  const location = useLocation();

  const isImmediateIssuance =
    props.data?.benefit?.benefitIssuanceType === BenefitIssuanceType.IMMEDIATE
      ? true
      : false;
  const isTargetAchieved =
    props.data.benefit.qualifyingThresholdValue <=
    props.data.currentCycleTotalQulaifyingSpend;

  const trackEventsAdobeAnalytics = useTrackEvents();

  const toast = (message) => {
    showToast1({ nativeToast, message });
  };

  useEffect(() => {
    if (!props.data?.voucher) {
      setCodeAvailable(false);
    } else {
      setCodeAvailable(true);
      setVoucher(props.data.voucher);
    }

    setLoading(false);
  }, []);

  const handleViewCodeAndRedeem = () => {
    if (!ButtonLoading) {
      if (codeAvailable) {
        trackEventsAdobeAnalytics({
          linkName: TrackEventTypes.REDEEM_VOUCHER,
          benefitsName: benefitNameWithId(
            props?.data?.benefit?.title,
            props?.data?.benefit?.id
          ),
          linkPageName: getPageNameByRoute(location.pathname),
        });
        window.open(voucher.redemptionURL);
      } else {
        viewCode();
        trackEventsAdobeAnalytics({
          linkName: TrackEventTypes.VIEW_CODE,
          benefitsName: benefitNameWithId(
            props?.data?.benefit?.title,
            props?.data?.benefit?.id
          ),
          linkPageName: getPageNameByRoute(location.pathname),
        });
      }
    }
  };

  const handleCopyVoucherCode = () => {
    copyToClipboard(voucher.details.voucherCode);
    toast("Voucher Code copied!");
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.COPY_VOUCHER_CODE,
      benefitsName: benefitNameWithId(
        props?.data?.benefit?.title,
        props?.data?.benefit?.id
      ),
      linkPageName: getPageNameByRoute(location.pathname),
    });
  };
  const viewCode = async () => {
    setButtonLoading(true);
    const queryParams = queryString.parse(location.search);
    let accountId = queryParams.accountId || localStorage.getItem("cardId");
    try {
      const response = await ManageBenefitService.fetchMembershipVoucher(
        props.data.id
      );
      if (response.status === 200) {
        setVoucher(response.data);
        setCodeAvailable(true);
      } else if (response.status !== 422) {
        logger.error({
          message: `An error occurred while fetching membership voucher for accountId ${accountId}`,
          responseData: response.data,
          accountId: accountId,
          correlationId: response.headers.x_correlation_id,
        });
        navigate("/Features/GenericError", {
          state: {
            x_correlation_id: response.headers.x_correlation_id,
          },
        });
      }
    } catch (error) {
      logger.error({
        message: `An exception occurred while fetching vouchers for accountId ${accountId}`,
        error: error.message,
        accountId: accountId,
        stackTrace: error.stack,
      });
      navigate("/Features/GenericError");
      consoleError(error);
    }
    setButtonLoading(false);
  };

  return loading ? (
    <></>
  ) : (
    <View>
      <View w="100%" bgColor="#FFFFFF">
        <View
          mx="16px"
          py="16px"
          borderBottomWidth={isTargetAchieved ? "1px" : "0"}
          borderBottomColor="#B1B5BB"
          borderBottomStyle="dotted"
          alignItems="center"
          justifyContent={isTargetAchieved ? "flex-start" : "center"}
          flexDirection={isTargetAchieved ? "row" : "column"}
        >
          {isTargetAchieved ? (
            <View width={"100%"}>
              <View w="90%" flexDirection="row" alignItems="center">
                <View
                  mr="12px"
                  h="56px"
                  w="56px"
                  borderRadius="56pc"
                  bgColor={"#636D7D"}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image
                    source={{
                      uri:
                        process.env.REACT_APP_AWS_S3_BUCKET_URL + "ticket.svg",
                    }}
                    height="24px"
                    width="24px"
                    alt=""
                  />
                </View>
                <View w="80%">
                  <Text
                    fontFamily="Quicksand"
                    color="#303030"
                    fontWeight="700"
                    fontSize="24px"
                    lineHeight="32px"
                  >
                    {`${props.data.currentCycleTotalQulaifyingSpend
                      .toFixed(0)
                      .toLocaleString("en-IN")} `}
                  </Text>
                  <Text
                    fontFamily="Quicksand"
                    color="#303030"
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="22px"
                    mt="4px"
                  >
                    Spend Target Achieved
                  </Text>
                </View>
              </View>
            </View>
          ) : (
            <>
              <View alignItems="center">
                {!isImmediateIssuance ? (
                  <Text
                    fontFamily="Quicksand"
                    color="#303030"
                    fontWeight="700"
                    fontSize="24px"
                    lineHeight="32px"
                  >
                    {`Spend ₹${(
                      props.data.benefit.qualifyingThresholdValue -
                      props.data.currentCycleTotalQulaifyingSpend
                    )
                      .toFixed(DECIMAL_PLACES)
                      .toLocaleString(
                        "en-IN"
                      )} more to unlock membership voucher`}
                  </Text>
                ) : (
                  <Text
                    fontFamily="Quicksand"
                    color="#303030"
                    fontWeight="700"
                    fontSize="24px"
                    lineHeight="32px"
                  >
                    {`Spend ₹${(
                      props.data.benefit.qualifyingThresholdValue -
                      props.data.currentCycleTotalQulaifyingSpend
                    )
                      .toFixed(DECIMAL_PLACES)
                      .toLocaleString(
                        "en-IN"
                      )} more to avoid additional charges`}
                  </Text>
                )}
              </View>
              <View mt={"16px"} width={"100%"}>
                <ProgressBar
                  maxValue={props.data.benefit.qualifyingThresholdValue}
                  currentValue={props.data.currentCycleTotalQulaifyingSpend}
                  color={props.data.benefit.color}
                  type={ProgressBarType.SPEND}
                />
              </View>
              <View
                width={"100%"}
                borderTopWidth={"1px"}
                borderColor={"#D7DBE0"}
                borderStyle={"dotted"}
                mt={"16px"}
              >
                {!isImmediateIssuance ? (
                  !isTargetAchieved && (
                    <Text
                      fontFamily="Quicksand"
                      color="#303030"
                      fontWeight="600"
                      fontSize="12px"
                      lineHeight="20px"
                      pt={"16px"}
                      textAlign={"start"}
                      width={"100%"}
                    >
                      {`This benefit Expires on`}
                      <Text
                        ml="4px"
                        fontFamily="Quicksand"
                        color="#303030"
                        fontWeight="700"
                        fontSize="12px"
                        lineHeight="20px"
                      >
                        {moment(props.data.currentCycleEndDate).format(
                          "DD MMM 'YY"
                        )}
                      </Text>
                    </Text>
                  )
                ) : (
                  <Text
                    fontFamily="Quicksand"
                    color="#303030"
                    fontWeight="600"
                    fontSize="12px"
                    lineHeight="20px"
                    pt={"16px"}
                  >
                    {`Meet the spend target by`}
                    <Text
                      ml="4px"
                      fontFamily="Quicksand"
                      color="#303030"
                      fontWeight="700"
                      fontSize="12px"
                      lineHeight="20px"
                    >
                      {moment(props.data.currentCycleEndDate).format(
                        "DD MMM 'YY"
                      )}
                    </Text>
                    {` to avoid penalty of ₹${props.data.benefit.membershipPenalty}.`}
                  </Text>
                )}
              </View>
            </>
          )}
        </View>
      </View>

      <View mt="8px" w="100%" bgColor="#FFFFFF">
        <View px="16px" py="16px">
          <View mb="12px">
            <Text
              fontFamily="Quicksand"
              color="#303030"
              fontWeight="700"
              fontSize="14px"
              lineHeight="22px"
            >
              Voucher
            </Text>
          </View>
          <View>
            {isImmediateIssuance ? (
              <View>
                {codeAvailable ? (
                  <CustomVoucherBackground>
                    <Text
                      fontFamily="Quicksand"
                      color="#303030"
                      fontWeight="700"
                      fontSize="14px"
                      lineHeight="22px"
                    >
                      {voucher.details.voucherCode}
                    </Text>
                    <View onClick={handleCopyVoucherCode}>
                      <Image
                        source={{
                          uri:
                            process.env.REACT_APP_AWS_S3_BUCKET_URL +
                            "copyOrange.svg",
                        }}
                        height="24px"
                        width="24px"
                        alt=""
                      />
                    </View>
                  </CustomVoucherBackground>
                ) : (
                  <></>
                )}
                <View
                  mt="16px"
                  py="10px"
                  w="100%"
                  bgColor={"#F37435"}
                  alignItems="center"
                  justifyContent={"center"}
                  shadow="1"
                  onClick={handleViewCodeAndRedeem}
                >
                  {ButtonLoading ? (
                    <>
                      <CustomLoader color="#FFFFFF" width={20} height={20} />
                    </>
                  ) : (
                    <Text
                      fontFamily="Quicksand"
                      color="#FFFFFF"
                      fontWeight="700"
                      fontSize="14px"
                      lineHeight="22px"
                      cursor={"pointer"}
                    >
                      {codeAvailable ? "Redeem Voucher" : "View Code"}
                    </Text>
                  )}
                </View>
                <View alignItems="center" mt="12px" flexDirection="row">
                  <Text
                    fontFamily="Quicksand"
                    color="#303030"
                    fontWeight="700"
                    fontSize="12px"
                    lineHeight="20px"
                  >{`Note: `}</Text>
                  <Text
                    fontFamily="Quicksand"
                    color="#303030"
                    fontWeight="600"
                    fontSize="12px"
                    lineHeight="20px"
                  >
                    {`Redeem the voucher by ${moment(props.data.endDate).format(
                      "DD MMM 'YY"
                    )}`}
                  </Text>
                </View>
              </View>
            ) : isTargetAchieved ? (
              <View width={"100%"}>
                <CongratulationsVoucher
                  VoucherTitle="Voucher"
                  content={
                    "Your voucher code will be sent to your registered E-mail after benefit cycle end date."
                  }
                />
                <VoucherBottomText showLock={false}>
                  Use the voucher within 30 days after receiving
                </VoucherBottomText>
              </View>
            ) : (
              <View width={"100%"}>
                <LockedVoucher />
                <VoucherBottomText showLock={true}>
                  Meet spend target by{" "}
                  <Text
                    font={"Quicksand"}
                    color={"#303030"}
                    fontSize={"12px"}
                    lineHeight={"20px"}
                    fontWeight={"700"}
                  >
                    {moment(props.data.currentCycleEndDate).format(
                      "DD MMM 'YY"
                    )}
                  </Text>{" "}
                  to unlock benefit
                </VoucherBottomText>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default Poshvine;
