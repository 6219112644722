import { View, Text, Image, Modal } from "native-base";
import React, { useEffect, useState } from "react";
import ToastTick from "../../svg/toastTick";
import moment from "moment";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentVoucher } from "../../../store/actions/ManageBenefits";
import { benefitNameWithId, consoleError } from "../../../utils/functions";
import ManageBenefitService from "../../../services/ManageBenefitService";
import logger from "../../../utils/logger";
import queryString from "query-string";
import {
  BenefitIssuanceType,
  ProgressBarType,
  TrackEventTypes,
} from "../../../utils/enums";
import { useTrackEvents } from "../../../hooks/useTrackEvents";
import ProgressBar from "./ProgressBar/ProgressBar";
import CustomVoucherBackground from "./CustomVoucher/CustomVoucherBackground";
import LockedVoucher from "./CustomVoucher/LockedVoucher";
import VoucherBottomText from "./CustomVoucher/VoucherBottomText";
import CongratulationsVoucher from "./CustomVoucher/CongratulationsVoucher";
import oksvg from "../../../assets/images/Ok.svg";

const Lounge = (props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [currentQr, setCurrentQr] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const demoArray = new Array(props.data.benefit.vouchersToBeIssued);
  demoArray.fill("", 0, props.data.benefit.vouchersToBeIssued);

  const isEndOfPeriod =
    props.data?.benefit?.benefitIssuanceType ===
    BenefitIssuanceType.END_OF_PERIOD
      ? true
      : false;
  const isTargetReached =
    props.data.benefit.qualifyingThresholdValue <=
    props.data.currentCycleTotalQulaifyingSpend;

  const isMilestone = props.data.benefit.qualifyingThresholdValue > 0;

  const trackEventsAdobeAnalytics = useTrackEvents();

  useEffect(() => {
    if (!props.data?.voucher) {
      navigate("/Features/GenericError");
    }
    setLoading(false);
  });

  const getVoucher = async () => {
    const queryParams = queryString.parse(location.search);
    let accountId = queryParams.accountId || localStorage.getItem("cardId");
    if (!buttonLoading) {
      try {
        setButtonLoading(true);
        const response = await ManageBenefitService.fetchVoucher(props.data.id);
        if (response?.status === 200) {
          dispatch(
            setCurrentVoucher({
              currentVoucher: {
                name: props.data.accountholderName,
                ...response.data,
              },
            })
          );

          navigate("/Features/QR");
          viewQRCodeTrackEvent();
        } else if (response?.status !== 422) {
          logger.error({
            message: `An error occurred while fetching voucher for accountId ${accountId}`,
            responseData: response.data,
            accountId: accountId,
            correlationId: response.headers.x_correlation_id,
          });

          navigate("/Features/GenericError", {
            state: {
              x_correlation_id: response.headers.x_correlation_id,
            },
          });
        }
      } catch (error) {
        logger.error({
          message: `An exception occurred while fetching vouchers for accountId ${accountId}`,
          error: error.message,
          accountId: accountId,
          stackTrace: error.stack,
        });
        navigate("/Features/GenericError");
        consoleError(error);
      }
      setButtonLoading(false);
    }
  };

  const viewQRCodeTrackEvent = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.VIEW_QR_CODE,
      benefitsName: benefitNameWithId(
        props?.data?.benefit?.title,
        props?.data?.benefit?.id
      ),
    });
  };

  return loading ? (
    <></>
  ) : (
    <View>
      <View w="100%" bgColor="#FFFFFF">
        <View
          borderBottomWidth="1px"
          borderBottomColor="#B1B5BB"
          borderBottomStyle="dotted"
          borderRadius="2px"
        >
          <View
            px="16px"
            py="16px"
            alignItems="center"
            justifyContent="flex-start"
            flexDirection="row"
          >
            <View
              mr="12px"
              h="56px"
              w="56px"
              borderRadius="56pc"
              bgColor={"#636D7D"}
              alignItems="center"
              justifyContent="center"
            >
              <Image
                source={{
                  uri: process.env.REACT_APP_AWS_S3_BUCKET_URL + "ticket.svg",
                }}
                height="24px"
                width="24px"
                alt=""
              />
            </View>
            <View w="80%">
              <Text
                fontFamily="Quicksand"
                color="#303030"
                fontWeight="700"
                fontSize="24px"
                lineHeight="32px"
              >
                {isMilestone && isEndOfPeriod
                  ? isTargetReached
                    ? "1"
                    : "0"
                  : `${(
                      props.data.benefit.vouchersToBeIssued -
                      Number(
                        props.data.voucher.filter(
                          (item) => item?.status === "REDEEMED"
                        ).length
                      )
                    )
                      .toFixed(0)
                      .toLocaleString("en-IN")} `}
              </Text>
              <Text
                fontFamily="Quicksand"
                color="#303030"
                fontWeight="500"
                fontSize="12px"
                lineHeight="14px"
                mt="4px"
              >
                {`Voucher(s) Available`}
              </Text>
            </View>
          </View>
          {isMilestone && !isTargetReached && (
            <View margin={"auto"} width={"90%"} paddingBottom={"16px"}>
              <ProgressBar
                maxValue={props.data?.benefit?.qualifyingThresholdValue}
                currentValue={props.data?.currentCycleTotalQulaifyingSpend}
                color={props.data?.benefit?.color}
                type={ProgressBarType.SPEND}
              />
            </View>
          )}
        </View>
        {isMilestone && isTargetReached && (
          <View
            borderRadius="44px"
            py="8px"
            px="10px"
            width={"95%"}
            margin={"auto"}
            marginTop={"16px"}
            fontFamily={"Quicksand"}
            fontSize={"14px"}
            lineHeight={"22px"}
            fontWeight={"500"}
            flex={1}
            flexDir={"row"}
            gap="5px"
            alignItems={"center"}
            background={"#FEF2F0"}
          >
            <Image
              source={{
                uri: oksvg,
              }}
              height="20px"
              width="20px"
              alt=""
            />
            <Text
              fontFamily="Quicksand"
              color="#303030"
              fontWeight="600"
              fontSize="12px"
              lineHeight="20px"
            >
              Target Reached
            </Text>
          </View>
        )}
        <View my="16px" px="16px">
          <Text
            fontFamily="Quicksand"
            color="#303030"
            fontWeight="600"
            fontSize="12px"
            lineHeight="20px"
          >
            {`Visit domestic airport lounges till`}
            <Text
              ml="4px"
              fontFamily="Quicksand"
              color="#303030"
              fontWeight="700"
              fontSize="12px"
              lineHeight="20px"
            >
              {moment(props.data.endDate).format("DD MMM 'YY")}
            </Text>
          </Text>
        </View>
      </View>
      <View mt="8px" w="100%" bgColor="#FFFFFF">
        <View px="16px" py="16px">
          <View mb="12px">
            <Text
              fontFamily="Quicksand"
              color="#303030"
              fontWeight="700"
              fontSize="14px"
              lineHeight="22px"
            >
              Voucher
            </Text>
          </View>

          {!isEndOfPeriod && (
            <View>
              {demoArray.map((item, index) =>
                index < props.data.voucher.length ? (
                  <CustomVoucherBackground>
                    <View>
                      <Text
                        fontFamily="Quicksand"
                        color="#303030"
                        fontWeight="700"
                        fontSize="14px"
                        lineHeight="22px"
                      >{`Voucher ${index + 1}`}</Text>{" "}
                      {index !== 0 &&
                        props.data.voucher[index - 1]?.status === "ACTIVE" && (
                          <Text
                            fontFamily="Quicksand"
                            color="#303030"
                            fontWeight="500"
                            fontSize="12px"
                            lineHeight="20px"
                          >{`Available once Voucher ${index} is redeemed`}</Text>
                        )}
                    </View>
                    <View>
                      {index !== 0 &&
                      props.data.voucher[index - 1]?.status === "ACTIVE" ? (
                        <View>
                          <Image
                            source={{
                              uri:
                                process.env.REACT_APP_AWS_S3_BUCKET_URL +
                                "lock.svg",
                            }}
                            height="24px"
                            width="24px"
                            alt=""
                          />
                        </View>
                      ) : props.data.voucher[index]?.status === "REDEEMED" ? (
                        <View flexDirection={"row"} alignItems="center">
                          <ToastTick />

                          <Text
                            ml="4px"
                            fontFamily="Quicksand"
                            color="#303030"
                            fontWeight="700"
                            fontSize="14px"
                            cursor={"pointer"}
                            lineHeight="22px"
                          >{`Redeemed`}</Text>
                        </View>
                      ) : (
                        <View
                          onClick={() => {
                            dispatch(
                              setCurrentVoucher({
                                currentVoucher: {
                                  name: props.data.accountholderName,
                                  ...props.data.voucher[index],
                                },
                              })
                            );

                            navigate("/Features/QR");
                            viewQRCodeTrackEvent();
                          }}
                        >
                          <Text
                            fontFamily="Quicksand"
                            color="#F37435"
                            fontWeight="700"
                            fontSize="14px"
                            cursor={"pointer"}
                            lineHeight="22px"
                          >{`View QR Code`}</Text>
                        </View>
                      )}
                    </View>
                  </CustomVoucherBackground>
                ) : (
                  <CustomVoucherBackground>
                    {
                      <View>
                        {props.data.benefit.vouchersToBeIssued === 1 ? (
                          <Text
                            fontFamily="Quicksand"
                            color="#303030"
                            fontWeight="700"
                            fontSize="14px"
                            lineHeight="22px"
                          >{`Voucher`}</Text>
                        ) : (
                          <Text
                            fontFamily="Quicksand"
                            color="#303030"
                            fontWeight="700"
                            fontSize="14px"
                            lineHeight="22px"
                          >{`Voucher ${index + 1}`}</Text>
                        )}
                        {index !== 0 ? (
                          <Text
                            fontFamily="Quicksand"
                            color="#303030"
                            fontWeight="500"
                            fontSize="12px"
                            lineHeight="20px"
                          >{`Available once Voucher ${index} is redeemed`}</Text>
                        ) : index === props.data.voucher.length - 1 &&
                          props.data.voucher[props.data.voucher.length - 1]
                            ?.status === "REDEEMED" ? (
                          <Text
                            fontFamily="Quicksand"
                            color="#303030"
                            fontWeight="500"
                            fontSize="12px"
                            lineHeight="20px"
                          >{`Available once Voucher ${index} is redeemed`}</Text>
                        ) : null}
                      </View>
                    }
                    <View>
                      {index === 0 ? (
                        <View
                          onClick={() => {
                            // setOpen(true);
                            // setCurrentQr(props.data.voucher[index].voucherCode);
                            getVoucher();
                          }}
                        >
                          <Text
                            fontFamily="Quicksand"
                            color="#F37435"
                            fontWeight="700"
                            fontSize="14px"
                            lineHeight="22px"
                            cursor={"pointer"}
                          >{`View QR Code`}</Text>
                        </View>
                      ) : index === props.data.voucher.length &&
                        props.data.voucher[props.data.voucher.length - 1]
                          ?.status === "REDEEMED" ? (
                        <View
                          onClick={() => {
                            // setOpen(true);
                            // setCurrentQr(props.data.voucher[index].voucherCode);
                            getVoucher();
                          }}
                        >
                          <Text
                            fontFamily="Quicksand"
                            color="#F37435"
                            fontWeight="700"
                            fontSize="14px"
                            lineHeight="22px"
                            cursor={"pointer"}
                          >{`View QR Code`}</Text>
                        </View>
                      ) : (
                        <View>
                          <Image
                            source={{
                              uri:
                                process.env.REACT_APP_AWS_S3_BUCKET_URL +
                                "lock.svg",
                            }}
                            height="24px"
                            width="24px"
                            alt=""
                          />
                        </View>
                      )}
                    </View>
                  </CustomVoucherBackground>
                )
              )}
            </View>
          )}

          {isEndOfPeriod &&
            (!isTargetReached ? (
              <View>
                <LockedVoucher />
                <VoucherBottomText showLock={true}>
                  Meet spend target by{" "}
                  <Text
                    font={"Quicksand"}
                    color={"#303030"}
                    fontSize={"12px"}
                    lineHeight={"20px"}
                    fontWeight={"700"}
                  >
                    {moment(props.data.currentCycleEndDate).format("DD MMM")}
                  </Text>{" "}
                  to unlock benefit
                </VoucherBottomText>
              </View>
            ) : (
              <View>
                <CongratulationsVoucher
                  VoucherTitle="Voucher"
                  content={
                    "Congratulations! Your QR code will be sent to your registered E-mail after Benefit Cycle end date."
                  }
                />
              </View>
            ))}
        </View>
      </View>
    </View>
  );
};

export default Lounge;
