import { View } from "native-base";
import React from "react";

const Form = () => {
  
  return (
    <View alignItems="center" justifyContent="center" mt="20%">
      <View _web={{ maxW: "412px" }} bgColor="#FFFFFF">
        <form action="https://api-cf-credit-card.aubank.in/customFeatures" method="post" target="_blank">
          <input type="text" id="fname" name="data" /><br />
          <input type="submit" value="Submit" />
        </form>
      </View>
    </View>
  );
};

export default Form;