import {
  SET_SESSION,
  SET_REDIRECT_URL,
  SET_SESSION_EXPIRY,
  SET_REPAYMENT_DETAILS,
} from "../ActionTypes";

const initialState = {
  sessionToken: null,
  redirectUrl: "/",
  deviceToken: null,
  sessionExpired: false,
  repaymentFlow: false,
  repaymentUrl: "",
};

const SessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION:
      return {
        ...state,
        sessionToken: action.payload.sessionToken,
        deviceToken: action.payload.deviceToken,
      };
    case SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.payload.redirectUrl,
      };
    case SET_REPAYMENT_DETAILS:
      return {
        ...state,
        repaymentFlow: action.payload.repaymentFlow,
        repaymentUrl: action.payload.repaymentUrl,
      };
    case SET_SESSION_EXPIRY:
      return {
        ...state,
        sessionExpired: action.payload.sessionExpired,
      };
    default:
      return state;
  }
};

export default SessionReducer;
