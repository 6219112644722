import React from "react";
import { Text, Button } from "native-base";
import { useSelector } from "react-redux";

const btnStyles = {
  alignItems: "center",
  justifyContent: "center",
  borderWidth: "1",
  width: "90%",
  py: "14px",
  borderRadius: "6px",
};

const ColorButton = (props) => {
  const theme = useSelector((state) => state.theme);
  return (
    <Button
      borderColor={props.color ? props.color : theme.color1}
      {...props}
      backgroundColor={props.color ? props.color : theme.color1}
      {...btnStyles}
    >
      <Text
        color="#fff"
        fontFamily={theme.fontFamily}
        fontSize="16px"
        fontWeight="600"
      >
        {props.text}
      </Text>
    </Button>
  );
};

const WhiteButton = (props) => {
  const theme = useSelector((state) => state.theme);
  return (
    <Button
      onPress={() => props.onPress()}
      mt="8px"
      backgroundColor="#ffffff"
      {...btnStyles}
      borderColor={props.color ? props.color : theme.color1}
    >
      <Text
        color={props.color ? props.color : theme.color1}
        fontFamily={theme.fontFamily}
        fontSize="16px"
        fontWeight="600"
      >
        {props.text}
      </Text>
    </Button>
  );
};

export { ColorButton, WhiteButton };
