import React from "react";
import { Routes, Route } from "react-router-dom";
import Paths from "./Paths";
import AddBenefit from "../components/ManageBenefits/AddBenefit/AddBenefit";
import BenefitDetails from "../components/ManageBenefits/BenefitDetails/BenefitDetails";
import Checkout from "../components/ManageBenefits/Checkout/Checkout";
import ManageBenefits from "../components/ManageBenefits/ManageBenefit";
import StaticBenefit from "../components/ManageBenefits/StaticBenefit/StaticBenefit";
import OnboardingAddBenefit from "../components/ManageBenefits/Onboarding/AddBenefit";
import OnboardingCheckout from "../components/ManageBenefits/Onboarding/Checkout";
import OnboardingBenefitDetails from "../components/ManageBenefits/Onboarding/BenefitDetails";
import Error from "../components/ManageBenefits/Error/Error";
import GenericError from "../components/ManageBenefits/Error/4xxError";
import PreLoader from "../components/ManageBenefits/Onboarding/PreLoader";
import Tnc from "../components/ManageBenefits/TnC/Tnc";
import Forbidden from "../components/ManageBenefits/Error/Forbidden";
import QrScreen from "../components/ManageBenefits/Components/QrScreen/QrScreen";
import Form from "../components/ManageBenefits/Form";
import Login from "../components/ManageBenefits/Login/login";
import Otp from "../components/ManageBenefits/Otp/Otp";
import WalletDisclaimer from "../components/ManageBenefits/Components/WalletDisclaimer";
import FortuneWheel from "../components/ManageBenefits/FortuneWheel/FortuneWheel/FortuneWheel";
import Congratulations from "../components/ManageBenefits/FortuneWheel/Congratulations/Congratulations";
import SameGroupError from "../components/ManageBenefits/Error/SameGroupError";
import usePageTracker from "../hooks/usePageTracker";

const ManageBenefitRoutes = () => {
  usePageTracker();
  return (
    <Routes>
      <Route path={Paths.MANAGE_BENEFITS} element={<ManageBenefits />} />

      <Route
        path="/Features/ManageFeatures/Feature"
        element={<StaticBenefit />}
      />
      <Route
        path="/Features/ManageFeatures/Login"
        element={<Login page={"LIT"} />}
      />
      <Route
        path="/Features/ManageFeatures/:program/Login"
        element={<Login page={"ABCL"} />}
      />
      <Route
        path="/Features/ManageFeatures/WalletDisclaimer"
        element={<WalletDisclaimer />}
      />
      <Route path="/Features/ManageFeatures/OTP" element={<Otp />} />
      <Route
        path="/Features/ManageFeatures/AddFeature"
        element={<AddBenefit />}
      />
      <Route path="/Features/Au/Tnc" element={<Tnc />} />

      <Route path="/Features/ManageFeatures/Checkout" element={<Checkout />} />
      <Route
        path="/Features/ManageFeatures/FeatureDetails"
        element={<BenefitDetails />}
      />
      <Route
        path="/Features/Onboarding/AddFeature"
        element={<OnboardingAddBenefit />}
      />
      <Route
        path="/Features/Onboarding/Checkout"
        element={<OnboardingCheckout />}
      />
      <Route
        path="/Features/Onboarding/FeatureDetails"
        element={<OnboardingBenefitDetails />}
      />

      <Route path="/Features/Onboarding/PreLoader" element={<PreLoader />} />

      <Route path="/Features/Form" element={<Form />} />

      {/* Spin the Wheel */}
      <Route path={Paths.FORTUNE_WHEEL} element={<FortuneWheel />} />
      <Route
        path={Paths.FORTUNE_WHEEL_CONGRATULATIONS}
        element={<Congratulations />}
      />

      <Route path="/Features/Error" element={<Error />} />
      <Route path="/Features/QR" element={<QrScreen />} />
      <Route path={Paths.GENERIC_ERROR} element={<GenericError />} />
      <Route path="/Features/Forbidden" element={<Forbidden />} />
      <Route path="/Features/SameGroupError" element={<SameGroupError />} />
      <Route path="*" element={<ManageBenefits />} />
    </Routes>
  );
};

export default ManageBenefitRoutes;
