import React from "react";

const SadEmoji = ({
    ...otherProps
}) => {
	return (
		<svg
			width="91"
			height="91"
			viewBox="0 0 91 91"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...otherProps}
		>
			<g clip-path="url(#clip0_257_83751)">
				<path d="M45.5 0C20.3714 0 0 20.3714 0 45.5C0 70.6286 20.3714 91 45.5 91C70.6286 91 91 70.6286 91 45.5C91 20.3714 70.6286 0 45.5 0ZM45.5 87.9666C22.0467 87.9666 3.03339 68.9533 3.03339 45.5C3.03339 22.0467 22.0467 3.03339 45.5 3.03339C68.9533 3.03339 87.9666 22.0467 87.9666 45.5C87.9666 68.9533 68.9533 87.9666 45.5 87.9666Z" fill="#6D3078"/>
				<path d="M28.8166 45.5001C32.4531 45.5001 34.8832 40.6397 34.8832 34.8835C34.8832 29.127 32.4531 24.2668 28.8166 24.2668C25.18 24.2668 22.75 29.1272 22.75 34.8835C22.75 40.6397 25.1802 45.5001 28.8166 45.5001ZM28.8166 27.3001C30.2063 27.3001 31.85 30.5873 31.85 34.8835C31.85 39.1796 30.2063 42.4668 28.8166 42.4668C27.4269 42.4668 25.7832 39.1796 25.7832 34.8835C25.7832 30.5873 27.4269 27.3001 28.8166 27.3001Z" fill="#6D3078"/>
				<path d="M62.1834 24.2666C58.5468 24.2666 56.1168 29.1269 56.1168 34.8832C56.1168 40.6397 58.547 45.4998 62.1834 45.4998C65.8199 45.4998 68.25 40.6395 68.25 34.8832C68.25 29.1269 65.8199 24.2666 62.1834 24.2666ZM62.1834 42.4666C60.7937 42.4666 59.15 39.1794 59.15 34.8832C59.15 30.587 60.7937 27.2998 62.1834 27.2998C63.5731 27.2998 65.2168 30.587 65.2168 34.8832C65.2168 39.1794 63.5731 42.4666 62.1834 42.4666Z" fill="#6D3078"/>
				<path d="M69.7666 21.2334C66.4122 21.2334 63.7 18.5211 63.7 15.1668C63.7 14.3291 63.021 13.6501 62.1834 13.6501C61.3457 13.6501 60.6667 14.3291 60.6667 15.1668C60.6667 20.1965 64.737 24.2668 69.7667 24.2668C70.6044 24.2668 71.2833 23.5878 71.2833 22.7501C71.2833 21.9125 70.6042 21.2334 69.7666 21.2334Z" fill="#6D3078"/>
				<path d="M30.3334 15.1666C30.3334 14.329 29.6544 13.65 28.8167 13.65C27.9791 13.65 27.3001 14.329 27.3001 15.1666C27.3001 18.521 24.5879 21.2332 21.2335 21.2332C20.3959 21.2332 19.7169 21.9122 19.7169 22.7498C19.7169 23.5875 20.3959 24.2665 21.2335 24.2665C26.2631 24.2666 30.3334 20.1963 30.3334 15.1666Z" fill="#6D3078"/>
				<path d="M45.5 53.0834C37.3286 53.0834 30.052 57.4404 25.9876 64.4564C25.5678 65.1812 25.815 66.1092 26.5398 66.529C27.2646 66.9488 28.1926 66.7016 28.6124 65.9768C32.1436 59.8808 38.4306 56.1166 45.5 56.1166C52.5694 56.1166 58.8563 59.8808 62.3876 65.9768C62.8074 66.7016 63.7353 66.9488 64.4601 66.529C65.1849 66.1092 65.4322 65.1812 65.0124 64.4564C60.9479 57.4402 53.6713 53.0834 45.5 53.0834Z" fill="#6D3078"/>
			</g>
			<defs>
				<clipPath id="clip0_257_83751">
					<rect width="91" height="91" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default SadEmoji;
