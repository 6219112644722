import React from "react";
import CustomVoucherBackground from "./CustomVoucherBackground";
import { Image, Text, View } from "native-base";

const CongratulationsVoucher = ({ VoucherTitle = "Voucher 1", content }) => {
  return (
    <CustomVoucherBackground>
      <View width={"100%"}>
        <View
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Text fontSize={"14px"} lineHeight={"22px"} fontWeight={"700"}>{VoucherTitle}</Text>
          <Image
            source={{
              uri: process.env.REACT_APP_AWS_S3_BUCKET_URL + "lock.svg",
            }}
            height="20px"
            width="20px"
            alt=""
          />
        </View>
        <View fontSize={"12px"} color={"#636D7D"} width={"85%"}lineHeight={"20px"}>
            "{content}"
        </View>
      </View>
    </CustomVoucherBackground>
  );
};

export default CongratulationsVoucher;
