import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "../src/store/Store";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from '@sentry/react'
import { Envs } from "./utils/enums";

const env = process.env.REACT_APP_ENV

env !== Envs.DEV  &&
  Sentry.init({
    dsn: "https://ec4806bb20a044a3b256ae08ee5d2e6d@o4505131282006016.ingest.sentry.io/4505147789541376",
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.1,
    environment: env,
  })


ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  // </React.StrictMode>
  document.getElementById("root")
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
