import React from "react";

const Money = ({
    ...otherProps
}) => {
    return (
			<svg
				width="143"
				height="89"
				viewBox="0 0 143 89"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...otherProps}
			>
				<path d="M48.5573 53.4042V38.0695H42.8543V53.4042H48.5573Z" fill="#383A48"/>
				<path d="M42.6244 53.4058V38.0711C42.6244 37.9456 42.7239 37.8469 42.8507 37.8469H48.5536C48.6803 37.8469 48.7799 37.9456 48.7799 38.0711V53.4058C48.7799 53.5313 48.6803 53.63 48.5536 53.63H42.8507C42.7239 53.63 42.6244 53.5313 42.6244 53.4058ZM43.077 38.2953V53.1816H48.3273V38.2953H43.077Z" fill="#383A48"/>
				<path d="M142.436 74.6761H14.7809V75.699H142.436V74.6761Z" fill="#383A48"/>
				<path d="M43.1615 45.7383H13.5088V53.1409H43.1615V45.7383Z" fill="#F3F6E6"/>
				<path d="M43.1577 53.6298H13.5051C13.4407 53.6304 13.3767 53.6182 13.3171 53.5939C13.2574 53.5695 13.2032 53.5336 13.1577 53.488C13.1121 53.4425 13.0761 53.3883 13.0517 53.3287C13.0273 53.2691 13.0151 53.2052 13.0158 53.1409V45.7383C13.0151 45.6739 13.0273 45.61 13.0517 45.5504C13.0761 45.4908 13.1121 45.4367 13.1577 45.3911C13.2032 45.3456 13.2574 45.3096 13.3171 45.2853C13.3767 45.2609 13.4407 45.2487 13.5051 45.2494H43.1577C43.2222 45.2487 43.2861 45.2609 43.3458 45.2853C43.4054 45.3096 43.4596 45.3456 43.5052 45.3911C43.5507 45.4367 43.5868 45.4908 43.6111 45.5504C43.6355 45.61 43.6477 45.6739 43.6471 45.7383V53.1409C43.6467 53.2704 43.595 53.3946 43.5033 53.4862C43.4116 53.5778 43.2874 53.6294 43.1577 53.6298ZM13.9944 52.6519H42.6684V46.2272H13.9944V52.6519Z" fill="#383A48"/>
				<path d="M43.1534 38.3359H13.5007V45.7385H43.1534V38.3359Z" fill="#F3F6E6"/>
				<path d="M43.1576 46.2274H13.505C13.4405 46.228 13.3766 46.2158 13.317 46.1915C13.2573 46.1671 13.2031 46.1312 13.1575 46.0856C13.112 46.0401 13.076 45.9859 13.0516 45.9263C13.0272 45.8667 13.015 45.8028 13.0156 45.7385V38.3359C13.015 38.2715 13.0272 38.2076 13.0516 38.148C13.076 38.0884 13.112 38.0343 13.1575 37.9887C13.2031 37.9432 13.2573 37.9072 13.317 37.8829C13.3766 37.8585 13.4405 37.8463 13.505 37.8469H43.1576C43.2221 37.8463 43.286 37.8585 43.3456 37.8829C43.4053 37.9072 43.4595 37.9432 43.5051 37.9887C43.5506 38.0343 43.5866 38.0884 43.611 38.148C43.6354 38.2076 43.6476 38.2715 43.6469 38.3359V45.7385C43.6466 45.868 43.5949 45.9922 43.5032 46.0838C43.4115 46.1754 43.2873 46.227 43.1576 46.2274ZM13.9943 45.2495H42.6683V38.8248H13.9943V45.2495Z" fill="#383A48"/>
				<path d="M69.2512 52.9936H39.8823V60.3118H69.2512V52.9936Z" fill="#F6E049"/>
				<path d="M69.2509 60.7936H39.882C39.8182 60.7942 39.7549 60.7821 39.6958 60.7581C39.6367 60.734 39.583 60.6984 39.5379 60.6534C39.4928 60.6084 39.4571 60.5549 39.433 60.4959C39.4088 60.437 39.3967 60.3738 39.3974 60.3102V52.992C39.3967 52.9283 39.4088 52.8652 39.433 52.8063C39.4571 52.7473 39.4928 52.6938 39.5379 52.6488C39.583 52.6038 39.6367 52.5682 39.6958 52.5441C39.7549 52.5201 39.8182 52.508 39.882 52.5086H69.2509C69.3147 52.508 69.378 52.5201 69.4371 52.5441C69.4962 52.5682 69.5499 52.6038 69.595 52.6488C69.6401 52.6938 69.6758 52.7473 69.6999 52.8063C69.7241 52.8652 69.7362 52.9283 69.7355 52.992V60.3102C69.7351 60.4383 69.684 60.561 69.5932 60.6516C69.5024 60.7421 69.3793 60.7932 69.2509 60.7936ZM40.3666 59.8268H68.7663V53.4754H40.3666V59.8268Z" fill="#383A48"/>
				<path d="M69.2512 45.6747H39.8823V52.9929H69.2512V45.6747Z" fill="#F6E049"/>
				<path d="M69.2509 53.4746H39.882C39.8182 53.4753 39.7549 53.4632 39.6958 53.4391C39.6367 53.4151 39.583 53.3795 39.5379 53.3345C39.4928 53.2895 39.4571 53.2359 39.433 53.177C39.4088 53.1181 39.3967 53.0549 39.3974 52.9913V45.6731C39.3967 45.6094 39.4088 45.5463 39.433 45.4873C39.4571 45.4284 39.4928 45.3749 39.5379 45.3299C39.583 45.2848 39.6367 45.2493 39.6958 45.2252C39.7549 45.2011 39.8182 45.1891 39.882 45.1897H69.2509C69.3147 45.1891 69.378 45.2011 69.4371 45.2252C69.4962 45.2493 69.5499 45.2848 69.595 45.3299C69.6401 45.3749 69.6758 45.4284 69.6999 45.4873C69.7241 45.5463 69.7362 45.6094 69.7355 45.6731V52.9913C69.7351 53.1193 69.684 53.2421 69.5932 53.3326C69.5024 53.4232 69.3793 53.4742 69.2509 53.4746ZM40.3666 52.5079H68.7663V46.1564H40.3666V52.5079Z" fill="#383A48"/>
				<path d="M69.2512 38.3557H39.8823V45.6739H69.2512V38.3557Z" fill="#E0719E"/>
				<path d="M69.2509 46.1597H39.882C39.8182 46.1603 39.7549 46.1482 39.6958 46.1242C39.6367 46.1001 39.583 46.0645 39.5379 46.0195C39.4928 45.9745 39.4571 45.921 39.433 45.862C39.4088 45.8031 39.3967 45.74 39.3974 45.6763V38.3581C39.3967 38.2945 39.4088 38.2313 39.433 38.1724C39.4571 38.1135 39.4928 38.0599 39.5379 38.0149C39.583 37.9699 39.6367 37.9343 39.6958 37.9103C39.7549 37.8862 39.8182 37.8741 39.882 37.8747H69.2509C69.3147 37.8741 69.378 37.8862 69.4371 37.9103C69.4962 37.9343 69.5499 37.9699 69.595 38.0149C69.6401 38.0599 69.6758 38.1135 69.6999 38.1724C69.7241 38.2313 69.7362 38.2945 69.7355 38.3581V45.6763C69.7351 45.8044 69.684 45.9271 69.5932 46.0177C69.5024 46.1083 69.3793 46.1593 69.2509 46.1597ZM40.3666 45.1929H68.7663V38.8415H40.3666V45.1929Z" fill="#383A48"/>
				<path d="M69.2512 31.0408H39.8823V38.359H69.2512V31.0408Z" fill="#E0719E"/>
				<path d="M69.2509 38.8408H39.882C39.8182 38.8414 39.7549 38.8293 39.6958 38.8053C39.6367 38.7812 39.583 38.7456 39.5379 38.7006C39.4928 38.6556 39.4571 38.6021 39.433 38.5431C39.4088 38.4842 39.3967 38.4211 39.3974 38.3574V31.0392C39.3967 30.9756 39.4088 30.9124 39.433 30.8535C39.4571 30.7946 39.4928 30.741 39.5379 30.696C39.583 30.651 39.6367 30.6154 39.6958 30.5913C39.7549 30.5673 39.8182 30.5552 39.882 30.5558H69.2509C69.3147 30.5552 69.378 30.5673 69.4371 30.5913C69.4962 30.6154 69.5499 30.651 69.595 30.696C69.6401 30.741 69.6758 30.7946 69.6999 30.8535C69.7241 30.9124 69.7362 30.9756 69.7355 31.0392V38.3574C69.7351 38.4855 69.684 38.6082 69.5932 38.6988C69.5024 38.7893 69.3793 38.8404 69.2509 38.8408ZM40.3666 37.874H68.7663V31.5226H40.3666V37.874Z" fill="#383A48"/>
				<path d="M69.2512 23.7179H39.8823V31.0361H69.2512V23.7179Z" fill="#F6E049"/>
				<path d="M69.2508 31.5219H39.8819C39.8181 31.5225 39.7548 31.5104 39.6957 31.4864C39.6366 31.4623 39.5829 31.4267 39.5378 31.3817C39.4926 31.3367 39.457 31.2831 39.4328 31.2242C39.4087 31.1653 39.3966 31.1021 39.3972 31.0385V23.7203C39.3966 23.6566 39.4087 23.5935 39.4328 23.5345C39.457 23.4756 39.4926 23.4221 39.5378 23.3771C39.5829 23.3321 39.6366 23.2965 39.6957 23.2724C39.7548 23.2483 39.8181 23.2363 39.8819 23.2369H69.2508C69.3146 23.2363 69.3779 23.2483 69.437 23.2724C69.4961 23.2965 69.5497 23.3321 69.5949 23.3771C69.64 23.4221 69.6757 23.4756 69.6998 23.5345C69.7239 23.5935 69.736 23.6566 69.7354 23.7203V31.0385C69.735 31.1666 69.6838 31.2893 69.593 31.3799C69.5022 31.4704 69.3792 31.5215 69.2508 31.5219ZM40.3665 30.5551H68.7661V24.2036H40.3665V30.5551Z" fill="#383A48"/>
				<path d="M102.908 53.8802H45.9487V74.8662H102.908V53.8802Z" fill="#383A48"/>
				<path d="M102.906 75.7008H45.9466C45.4803 75.7008 45.1139 75.3344 45.1139 74.868V53.8821C45.1139 53.4157 45.4803 53.0493 45.9466 53.0493H102.906C103.372 53.0493 103.738 53.4157 103.738 53.8821V74.868C103.738 75.3344 103.372 75.7008 102.906 75.7008ZM46.7794 74.0353H102.073V54.7149H46.7794V74.0353Z" fill="#383A48"/>
				<path d="M45.6512 67.8382H16.1404V75.2102H45.6512V67.8382Z" fill="#F6E049"/>
				<path d="M45.6532 75.6991H16.1424C16.0783 75.6997 16.0147 75.6876 15.9553 75.6633C15.8959 75.6391 15.842 75.6032 15.7966 75.5579C15.7513 75.5126 15.7154 75.4586 15.6912 75.3993C15.6669 75.3399 15.6548 75.2763 15.6554 75.2122V67.8401C15.6548 67.776 15.6669 67.7124 15.6912 67.653C15.7154 67.5937 15.7513 67.5397 15.7966 67.4944C15.842 67.4491 15.8959 67.4132 15.9553 67.389C16.0147 67.3647 16.0783 67.3526 16.1424 67.3532H45.6532C45.7173 67.3526 45.7809 67.3647 45.8403 67.389C45.8996 67.4132 45.9536 67.4491 45.9989 67.4944C46.0443 67.5397 46.0801 67.5937 46.1044 67.653C46.1286 67.7124 46.1408 67.776 46.1401 67.8401V75.2122C46.1398 75.3412 46.0883 75.4648 45.9971 75.5561C45.9058 75.6473 45.7822 75.6987 45.6532 75.6991ZM16.6294 74.7252H45.1662V68.3271H16.6294V74.7252Z" fill="#383A48"/>
				<path d="M45.6512 60.4676H16.1404V67.8397H45.6512V60.4676Z" fill="#F6E049"/>
				<path d="M45.6532 68.3245H16.1424C16.0783 68.3251 16.0147 68.313 15.9553 68.2887C15.8959 68.2645 15.842 68.2286 15.7966 68.1833C15.7513 68.138 15.7154 68.084 15.6912 68.0247C15.6669 67.9653 15.6548 67.9017 15.6554 67.8376V60.4656C15.6548 60.4014 15.6669 60.3378 15.6912 60.2785C15.7154 60.2191 15.7513 60.1652 15.7966 60.1198C15.842 60.0745 15.8959 60.0386 15.9553 60.0144C16.0147 59.9902 16.0783 59.978 16.1424 59.9786H45.6532C45.7173 59.978 45.7809 59.9902 45.8403 60.0144C45.8996 60.0386 45.9536 60.0745 45.9989 60.1198C46.0443 60.1652 46.0801 60.2191 46.1044 60.2785C46.1286 60.3378 46.1408 60.4014 46.1401 60.4656V67.8376C46.1398 67.9666 46.0883 68.0902 45.9971 68.1815C45.9058 68.2727 45.7822 68.3241 45.6532 68.3245ZM16.6294 67.3506H45.1662V60.9525H16.6294V67.3506Z" fill="#383A48"/>
				<path d="M45.6512 53.093H16.1404V60.4651H45.6512V53.093Z" fill="#F6E049"/>
				<path d="M45.6532 60.9539H16.1424C16.0783 60.9546 16.0147 60.9424 15.9553 60.9182C15.8959 60.8939 15.842 60.8581 15.7966 60.8127C15.7513 60.7674 15.7154 60.7134 15.6912 60.6541C15.6669 60.5947 15.6548 60.5311 15.6554 60.467V53.095C15.6548 53.0308 15.6669 52.9672 15.6912 52.9079C15.7154 52.8485 15.7513 52.7946 15.7966 52.7492C15.842 52.7039 15.8959 52.668 15.9553 52.6438C16.0147 52.6195 16.0783 52.6074 16.1424 52.608H45.6532C45.7173 52.6074 45.7809 52.6195 45.8403 52.6438C45.8996 52.668 45.9536 52.7039 45.9989 52.7492C46.0443 52.7946 46.0801 52.8485 46.1044 52.9079C46.1286 52.9672 46.1408 53.0308 46.1401 53.095V60.467C46.1398 60.596 46.0883 60.7196 45.9971 60.8109C45.9058 60.9021 45.7822 60.9535 45.6532 60.9539ZM16.6294 59.9801H45.1662V53.5819H16.6294V59.9801Z" fill="#383A48"/>
				<path d="M1.05227 34.3427C0.922863 34.3427 0.798753 34.2912 0.707246 34.1996C0.615739 34.1079 0.564331 33.9837 0.564331 33.8541C0.564331 33.7245 0.615739 33.6002 0.707246 33.5085C0.798753 33.4169 0.922863 33.3654 1.05227 33.3654C15.0172 33.3654 25.7519 27.179 38.1749 20.0251C46.4114 15.2852 54.9309 10.3791 65.5192 6.52846C77.63 2.13056 90.3067 2.39375e-05 104.281 2.39375e-05C104.346 -0.000616139 104.409 0.0115862 104.469 0.0359168C104.528 0.0602473 104.582 0.0962173 104.628 0.141721C104.673 0.187225 104.709 0.241349 104.733 0.300924C104.758 0.3605 104.77 0.42433 104.769 0.488679C104.77 0.553028 104.758 0.616859 104.733 0.676434C104.709 0.736009 104.673 0.790133 104.628 0.835637C104.582 0.881141 104.528 0.917111 104.469 0.941441C104.409 0.965772 104.346 0.977974 104.281 0.977334C73.2092 0.977334 54.8528 11.5421 38.6629 20.8656C32.4758 24.423 26.64 27.7947 20.57 30.2673C13.7681 33.0527 7.56143 34.3427 1.05227 34.3427Z" fill="#383A48"/>
				<path d="M104.104 74.7267C121.381 74.7267 135.386 60.7212 135.386 43.4445C135.386 26.1679 121.381 12.1624 104.104 12.1624C86.8273 12.1624 72.8218 26.1679 72.8218 43.4445C72.8218 60.7212 86.8273 74.7267 104.104 74.7267Z" fill="#F6E049"/>
				<path d="M104.107 74.7267C95.5772 74.7236 87.3973 71.4268 81.3656 65.5609C75.3338 59.6951 71.9438 51.7401 71.9406 43.4445C71.9396 37.2575 73.8255 31.2091 77.3597 26.0645C80.8939 20.92 85.9177 16.9103 91.7956 14.5428C97.6734 12.1753 104.141 11.5563 110.381 12.7641C116.621 13.9719 122.352 16.9523 126.849 21.3282C131.347 25.703 134.409 31.2764 135.65 37.3436C136.89 43.4108 136.253 49.6996 133.819 55.4148C131.385 61.13 127.263 66.0151 121.975 69.4525C116.686 72.8899 110.468 74.7253 104.107 74.7267ZM104.107 13.1181C97.9399 13.1191 91.911 14.8983 86.7831 18.2309C81.6551 21.5635 77.6583 26.2999 75.2979 31.8412C72.9375 37.3825 72.3195 43.48 73.5219 49.3628C74.7244 55.2457 77.6934 60.6498 82.0535 64.8919C87.9041 70.5773 95.8366 73.771 104.107 73.771C112.378 73.771 120.311 70.5773 126.161 64.8919C132.058 59.1669 135.292 51.5494 135.292 43.4445C135.302 39.4603 134.501 35.5134 132.934 31.8321C131.367 28.1508 129.065 24.8081 126.161 21.9972C120.274 16.2626 112.432 13.1181 104.107 13.1181Z" fill="#383A48"/>
				<path d="M134.064 34.192C134.308 34.192 134.505 33.9948 134.505 33.7514C134.505 33.5081 134.308 33.3109 134.064 33.3109C133.821 33.3109 133.624 33.5081 133.624 33.7514C133.624 33.9948 133.821 34.192 134.064 34.192Z" fill="#383A48"/>
				<path d="M134.064 37.7168C134.308 37.7168 134.505 37.3223 134.505 36.8356C134.505 36.349 134.308 35.9544 134.064 35.9544C133.821 35.9544 133.624 36.349 133.624 36.8356C133.624 37.3223 133.821 37.7168 134.064 37.7168Z" fill="#383A48"/>
				<path d="M134.064 39.4792C134.308 39.4792 134.505 39.2819 134.505 39.0386C134.505 38.7953 134.308 38.598 134.064 38.598C133.821 38.598 133.624 38.7953 133.624 39.0386C133.624 39.2819 133.821 39.4792 134.064 39.4792Z" fill="#383A48"/>
				<path d="M134.064 41.2415C134.308 41.2415 134.505 41.0443 134.505 40.8009C134.505 40.5576 134.308 40.3604 134.064 40.3604C133.821 40.3604 133.624 40.5576 133.624 40.8009C133.624 41.0443 133.821 41.2415 134.064 41.2415Z" fill="#383A48"/>
				<path d="M134.064 44.7663C134.308 44.7663 134.505 44.5691 134.505 44.3257C134.505 44.0824 134.308 43.8851 134.064 43.8851C133.821 43.8851 133.624 44.0824 133.624 44.3257C133.624 44.5691 133.821 44.7663 134.064 44.7663Z" fill="#383A48"/>
				<path d="M134.064 46.5287C134.308 46.5287 134.505 46.3314 134.505 46.0881C134.505 45.8448 134.308 45.6475 134.064 45.6475C133.821 45.6475 133.624 45.8448 133.624 46.0881C133.624 46.3314 133.821 46.5287 134.064 46.5287Z" fill="#383A48"/>
				<path d="M134.064 48.2911C134.308 48.2911 134.505 48.0938 134.505 47.8505C134.505 47.6071 134.308 47.4099 134.064 47.4099C133.821 47.4099 133.624 47.6071 133.624 47.8505C133.624 48.0938 133.821 48.2911 134.064 48.2911Z" fill="#383A48"/>
				<path d="M134.064 51.8158C134.308 51.8158 134.505 51.6186 134.505 51.3752C134.505 51.1319 134.308 50.9346 134.064 50.9346C133.821 50.9346 133.624 51.1319 133.624 51.3752C133.624 51.6186 133.821 51.8158 134.064 51.8158Z" fill="#383A48"/>
				<path d="M134.064 53.5782C134.308 53.5782 134.505 53.3809 134.505 53.1376C134.505 52.8943 134.308 52.697 134.064 52.697C133.821 52.697 133.624 52.8943 133.624 53.1376C133.624 53.3809 133.821 53.5782 134.064 53.5782Z" fill="#383A48"/>
				<path d="M130.54 27.1425C130.783 27.1425 130.98 26.9453 130.98 26.7019C130.98 26.4586 130.783 26.2614 130.54 26.2614C130.296 26.2614 130.099 26.4586 130.099 26.7019C130.099 26.9453 130.296 27.1425 130.54 27.1425Z" fill="#383A48"/>
				<path d="M130.54 32.4297C130.783 32.4297 130.98 32.2324 130.98 31.9891C130.98 31.7458 130.783 31.5485 130.54 31.5485C130.296 31.5485 130.099 31.7458 130.099 31.9891C130.099 32.2324 130.296 32.4297 130.54 32.4297Z" fill="#383A48"/>
				<path d="M130.54 34.192C130.783 34.192 130.98 33.9948 130.98 33.7514C130.98 33.5081 130.783 33.3109 130.54 33.3109C130.296 33.3109 130.099 33.5081 130.099 33.7514C130.099 33.9948 130.296 34.192 130.54 34.192Z" fill="#383A48"/>
				<path d="M130.54 37.7168C130.783 37.7168 130.98 37.3223 130.98 36.8356C130.98 36.349 130.783 35.9544 130.54 35.9544C130.296 35.9544 130.099 36.349 130.099 36.8356C130.099 37.3223 130.296 37.7168 130.54 37.7168Z" fill="#383A48"/>
				<path d="M130.54 39.4792C130.783 39.4792 130.98 39.2819 130.98 39.0386C130.98 38.7953 130.783 38.598 130.54 38.598C130.296 38.598 130.099 38.7953 130.099 39.0386C130.099 39.2819 130.296 39.4792 130.54 39.4792Z" fill="#383A48"/>
				<path d="M130.54 41.2415C130.783 41.2415 130.98 41.0443 130.98 40.8009C130.98 40.5576 130.783 40.3604 130.54 40.3604C130.296 40.3604 130.099 40.5576 130.099 40.8009C130.099 41.0443 130.296 41.2415 130.54 41.2415Z" fill="#383A48"/>
				<path d="M130.54 44.7663C130.783 44.7663 130.98 44.5691 130.98 44.3257C130.98 44.0824 130.783 43.8851 130.54 43.8851C130.296 43.8851 130.099 44.0824 130.099 44.3257C130.099 44.5691 130.296 44.7663 130.54 44.7663Z" fill="#383A48"/>
				<path d="M130.54 46.5287C130.783 46.5287 130.98 46.3314 130.98 46.0881C130.98 45.8448 130.783 45.6475 130.54 45.6475C130.296 45.6475 130.099 45.8448 130.099 46.0881C130.099 46.3314 130.296 46.5287 130.54 46.5287Z" fill="#383A48"/>
				<path d="M130.54 48.2911C130.783 48.2911 130.98 48.0938 130.98 47.8505C130.98 47.6071 130.783 47.4099 130.54 47.4099C130.296 47.4099 130.099 47.6071 130.099 47.8505C130.099 48.0938 130.296 48.2911 130.54 48.2911Z" fill="#383A48"/>
				<path d="M130.54 51.8158C130.783 51.8158 130.98 51.6186 130.98 51.3752C130.98 51.1319 130.783 50.9346 130.54 50.9346C130.296 50.9346 130.099 51.1319 130.099 51.3752C130.099 51.6186 130.296 51.8158 130.54 51.8158Z" fill="#383A48"/>
				<path d="M130.54 53.5782C130.783 53.5782 130.98 53.3809 130.98 53.1376C130.98 52.8943 130.783 52.697 130.54 52.697C130.296 52.697 130.099 52.8943 130.099 53.1376C130.099 53.3809 130.296 53.5782 130.54 53.5782Z" fill="#383A48"/>
				<path d="M130.54 55.3406C130.783 55.3406 130.98 55.1433 130.98 54.9C130.98 54.6566 130.783 54.4594 130.54 54.4594C130.296 54.4594 130.099 54.6566 130.099 54.9C130.099 55.1433 130.296 55.3406 130.54 55.3406Z" fill="#383A48"/>
				<path d="M130.54 58.8653C130.783 58.8653 130.98 58.6681 130.98 58.4247C130.98 58.1814 130.783 57.9841 130.54 57.9841C130.296 57.9841 130.099 58.1814 130.099 58.4247C130.099 58.6681 130.296 58.8653 130.54 58.8653Z" fill="#383A48"/>
				<path d="M128.777 25.3802C129.021 25.3802 129.218 25.1829 129.218 24.9396C129.218 24.6963 129.021 24.499 128.777 24.499C128.534 24.499 128.337 24.6963 128.337 24.9396C128.337 25.1829 128.534 25.3802 128.777 25.3802Z" fill="#383A48"/>
				<path d="M128.777 27.1425C129.021 27.1425 129.218 26.9453 129.218 26.7019C129.218 26.4586 129.021 26.2614 128.777 26.2614C128.534 26.2614 128.337 26.4586 128.337 26.7019C128.337 26.9453 128.534 27.1425 128.777 27.1425Z" fill="#383A48"/>
				<path d="M128.777 32.4297C129.021 32.4297 129.218 32.2324 129.218 31.9891C129.218 31.7458 129.021 31.5485 128.777 31.5485C128.534 31.5485 128.337 31.7458 128.337 31.9891C128.337 32.2324 128.534 32.4297 128.777 32.4297Z" fill="#383A48"/>
				<path d="M128.777 34.192C129.021 34.192 129.218 33.9948 129.218 33.7514C129.218 33.5081 129.021 33.3109 128.777 33.3109C128.534 33.3109 128.337 33.5081 128.337 33.7514C128.337 33.9948 128.534 34.192 128.777 34.192Z" fill="#383A48"/>
				<path d="M128.777 37.7168C129.021 37.7168 129.218 37.3223 129.218 36.8356C129.218 36.349 129.021 35.9544 128.777 35.9544C128.534 35.9544 128.337 36.349 128.337 36.8356C128.337 37.3223 128.534 37.7168 128.777 37.7168Z" fill="#383A48"/>
				<path d="M128.777 39.4792C129.021 39.4792 129.218 39.2819 129.218 39.0386C129.218 38.7953 129.021 38.598 128.777 38.598C128.534 38.598 128.337 38.7953 128.337 39.0386C128.337 39.2819 128.534 39.4792 128.777 39.4792Z" fill="#383A48"/>
				<path d="M128.777 41.2415C129.021 41.2415 129.218 41.0443 129.218 40.8009C129.218 40.5576 129.021 40.3604 128.777 40.3604C128.534 40.3604 128.337 40.5576 128.337 40.8009C128.337 41.0443 128.534 41.2415 128.777 41.2415Z" fill="#383A48"/>
				<path d="M128.777 44.7663C129.021 44.7663 129.218 44.5691 129.218 44.3257C129.218 44.0824 129.021 43.8851 128.777 43.8851C128.534 43.8851 128.337 44.0824 128.337 44.3257C128.337 44.5691 128.534 44.7663 128.777 44.7663Z" fill="#383A48"/>
				<path d="M128.777 46.5287C129.021 46.5287 129.218 46.3314 129.218 46.0881C129.218 45.8448 129.021 45.6475 128.777 45.6475C128.534 45.6475 128.337 45.8448 128.337 46.0881C128.337 46.3314 128.534 46.5287 128.777 46.5287Z" fill="#383A48"/>
				<path d="M128.777 48.2911C129.021 48.2911 129.218 48.0938 129.218 47.8505C129.218 47.6071 129.021 47.4099 128.777 47.4099C128.534 47.4099 128.337 47.6071 128.337 47.8505C128.337 48.0938 128.534 48.2911 128.777 48.2911Z" fill="#383A48"/>
				<path d="M128.777 51.8158C129.021 51.8158 129.218 51.6186 129.218 51.3752C129.218 51.1319 129.021 50.9346 128.777 50.9346C128.534 50.9346 128.337 51.1319 128.337 51.3752C128.337 51.6186 128.534 51.8158 128.777 51.8158Z" fill="#383A48"/>
				<path d="M128.777 53.5782C129.021 53.5782 129.218 53.3809 129.218 53.1376C129.218 52.8943 129.021 52.697 128.777 52.697C128.534 52.697 128.337 52.8943 128.337 53.1376C128.337 53.3809 128.534 53.5782 128.777 53.5782Z" fill="#383A48"/>
				<path d="M128.777 55.3406C129.021 55.3406 129.218 55.1433 129.218 54.9C129.218 54.6566 129.021 54.4594 128.777 54.4594C128.534 54.4594 128.337 54.6566 128.337 54.9C128.337 55.1433 128.534 55.3406 128.777 55.3406Z" fill="#383A48"/>
				<path d="M128.777 58.8653C129.021 58.8653 129.218 58.6681 129.218 58.4247C129.218 58.1814 129.021 57.9841 128.777 57.9841C128.534 57.9841 128.337 58.1814 128.337 58.4247C128.337 58.6681 128.534 58.8653 128.777 58.8653Z" fill="#383A48"/>
				<path d="M128.777 60.6277C129.021 60.6277 129.218 60.4304 129.218 60.1871C129.218 59.9438 129.021 59.7465 128.777 59.7465C128.534 59.7465 128.337 59.9438 128.337 60.1871C128.337 60.4304 128.534 60.6277 128.777 60.6277Z" fill="#383A48"/>
				<path d="M123.49 20.093C123.733 20.093 123.931 19.8958 123.931 19.6524C123.931 19.4091 123.733 19.2119 123.49 19.2119C123.247 19.2119 123.05 19.4091 123.05 19.6524C123.05 19.8958 123.247 20.093 123.49 20.093Z" fill="#383A48"/>
				<path d="M123.49 21.8554C123.733 21.8554 123.931 21.6582 123.931 21.4148C123.931 21.1715 123.733 20.9742 123.49 20.9742C123.247 20.9742 123.05 21.1715 123.05 21.4148C123.05 21.6582 123.247 21.8554 123.49 21.8554Z" fill="#383A48"/>
				<path d="M123.49 25.3802C123.733 25.3802 123.931 25.1829 123.931 24.9396C123.931 24.6963 123.733 24.499 123.49 24.499C123.247 24.499 123.05 24.6963 123.05 24.9396C123.05 25.1829 123.247 25.3802 123.49 25.3802Z" fill="#383A48"/>
				<path d="M123.49 27.1425C123.733 27.1425 123.931 26.9453 123.931 26.7019C123.931 26.4586 123.733 26.2614 123.49 26.2614C123.247 26.2614 123.05 26.4586 123.05 26.7019C123.05 26.9453 123.247 27.1425 123.49 27.1425Z" fill="#383A48"/>
				<path d="M123.49 32.4297C123.733 32.4297 123.931 32.2324 123.931 31.9891C123.931 31.7458 123.733 31.5485 123.49 31.5485C123.247 31.5485 123.05 31.7458 123.05 31.9891C123.05 32.2324 123.247 32.4297 123.49 32.4297Z" fill="#383A48"/>
				<path d="M123.49 34.192C123.733 34.192 123.931 33.9948 123.931 33.7514C123.931 33.5081 123.733 33.3109 123.49 33.3109C123.247 33.3109 123.05 33.5081 123.05 33.7514C123.05 33.9948 123.247 34.192 123.49 34.192Z" fill="#383A48"/>
				<path d="M123.49 37.7168C123.733 37.7168 123.931 37.3223 123.931 36.8356C123.931 36.349 123.733 35.9544 123.49 35.9544C123.247 35.9544 123.05 36.349 123.05 36.8356C123.05 37.3223 123.247 37.7168 123.49 37.7168Z" fill="#383A48"/>
				<path d="M123.49 39.4792C123.733 39.4792 123.931 39.2819 123.931 39.0386C123.931 38.7953 123.733 38.598 123.49 38.598C123.247 38.598 123.05 38.7953 123.05 39.0386C123.05 39.2819 123.247 39.4792 123.49 39.4792Z" fill="#383A48"/>
				<path d="M123.49 41.2415C123.733 41.2415 123.931 41.0443 123.931 40.8009C123.931 40.5576 123.733 40.3604 123.49 40.3604C123.247 40.3604 123.05 40.5576 123.05 40.8009C123.05 41.0443 123.247 41.2415 123.49 41.2415Z" fill="#383A48"/>
				<path d="M123.49 44.7663C123.733 44.7663 123.931 44.5691 123.931 44.3257C123.931 44.0824 123.733 43.8851 123.49 43.8851C123.247 43.8851 123.05 44.0824 123.05 44.3257C123.05 44.5691 123.247 44.7663 123.49 44.7663Z" fill="#383A48"/>
				<path d="M123.49 46.5287C123.733 46.5287 123.931 46.3314 123.931 46.0881C123.931 45.8448 123.733 45.6475 123.49 45.6475C123.247 45.6475 123.05 45.8448 123.05 46.0881C123.05 46.3314 123.247 46.5287 123.49 46.5287Z" fill="#383A48"/>
				<path d="M123.49 48.2911C123.733 48.2911 123.931 48.0938 123.931 47.8505C123.931 47.6071 123.733 47.4099 123.49 47.4099C123.247 47.4099 123.05 47.6071 123.05 47.8505C123.05 48.0938 123.247 48.2911 123.49 48.2911Z" fill="#383A48"/>
				<path d="M123.49 51.8158C123.733 51.8158 123.931 51.6186 123.931 51.3752C123.931 51.1319 123.733 50.9346 123.49 50.9346C123.247 50.9346 123.05 51.1319 123.05 51.3752C123.05 51.6186 123.247 51.8158 123.49 51.8158Z" fill="#383A48"/>
				<path d="M123.49 53.5782C123.733 53.5782 123.931 53.3809 123.931 53.1376C123.931 52.8943 123.733 52.697 123.49 52.697C123.247 52.697 123.05 52.8943 123.05 53.1376C123.05 53.3809 123.247 53.5782 123.49 53.5782Z" fill="#383A48"/>
				<path d="M123.49 55.3406C123.733 55.3406 123.931 55.1433 123.931 54.9C123.931 54.6566 123.733 54.4594 123.49 54.4594C123.247 54.4594 123.05 54.6566 123.05 54.9C123.05 55.1433 123.247 55.3406 123.49 55.3406Z" fill="#383A48"/>
				<path d="M123.49 58.8653C123.733 58.8653 123.931 58.6681 123.931 58.4247C123.931 58.1814 123.733 57.9841 123.49 57.9841C123.247 57.9841 123.05 58.1814 123.05 58.4247C123.05 58.6681 123.247 58.8653 123.49 58.8653Z" fill="#383A48"/>
				<path d="M123.49 60.6277C123.733 60.6277 123.931 60.4304 123.931 60.1871C123.931 59.9438 123.733 59.7465 123.49 59.7465C123.247 59.7465 123.05 59.9438 123.05 60.1871C123.05 60.4304 123.247 60.6277 123.49 60.6277Z" fill="#383A48"/>
				<path d="M123.49 65.9148C123.733 65.9148 123.931 65.7176 123.931 65.4742C123.931 65.2309 123.733 65.0336 123.49 65.0336C123.247 65.0336 123.05 65.2309 123.05 65.4742C123.05 65.7176 123.247 65.9148 123.49 65.9148Z" fill="#383A48"/>
				<path d="M123.49 67.6772C123.733 67.6772 123.931 67.4799 123.931 67.2366C123.931 66.9933 123.733 66.796 123.49 66.796C123.247 66.796 123.05 66.9933 123.05 67.2366C123.05 67.4799 123.247 67.6772 123.49 67.6772Z" fill="#383A48"/>
				<path d="M121.728 20.093C121.971 20.093 122.168 19.8958 122.168 19.6524C122.168 19.4091 121.971 19.2119 121.728 19.2119C121.484 19.2119 121.287 19.4091 121.287 19.6524C121.287 19.8958 121.484 20.093 121.728 20.093Z" fill="#383A48"/>
				<path d="M121.728 21.8554C121.971 21.8554 122.168 21.6582 122.168 21.4148C122.168 21.1715 121.971 20.9742 121.728 20.9742C121.484 20.9742 121.287 21.1715 121.287 21.4148C121.287 21.6582 121.484 21.8554 121.728 21.8554Z" fill="#383A48"/>
				<path d="M121.728 25.3802C121.971 25.3802 122.168 25.1829 122.168 24.9396C122.168 24.6963 121.971 24.499 121.728 24.499C121.484 24.499 121.287 24.6963 121.287 24.9396C121.287 25.1829 121.484 25.3802 121.728 25.3802Z" fill="#383A48"/>
				<path d="M121.728 27.1425C121.971 27.1425 122.168 26.9453 122.168 26.7019C122.168 26.4586 121.971 26.2614 121.728 26.2614C121.484 26.2614 121.287 26.4586 121.287 26.7019C121.287 26.9453 121.484 27.1425 121.728 27.1425Z" fill="#383A48"/>
				<path d="M121.728 32.4297C121.971 32.4297 122.168 32.2324 122.168 31.9891C122.168 31.7458 121.971 31.5485 121.728 31.5485C121.484 31.5485 121.287 31.7458 121.287 31.9891C121.287 32.2324 121.484 32.4297 121.728 32.4297Z" fill="#383A48"/>
				<path d="M121.728 34.192C121.971 34.192 122.168 33.9948 122.168 33.7514C122.168 33.5081 121.971 33.3109 121.728 33.3109C121.484 33.3109 121.287 33.5081 121.287 33.7514C121.287 33.9948 121.484 34.192 121.728 34.192Z" fill="#383A48"/>
				<path d="M121.728 37.7168C121.971 37.7168 122.168 37.3223 122.168 36.8356C122.168 36.349 121.971 35.9544 121.728 35.9544C121.484 35.9544 121.287 36.349 121.287 36.8356C121.287 37.3223 121.484 37.7168 121.728 37.7168Z" fill="#383A48"/>
				<path d="M121.728 39.4792C121.971 39.4792 122.168 39.2819 122.168 39.0386C122.168 38.7953 121.971 38.598 121.728 38.598C121.484 38.598 121.287 38.7953 121.287 39.0386C121.287 39.2819 121.484 39.4792 121.728 39.4792Z" fill="#383A48"/>
				<path d="M121.728 41.2415C121.971 41.2415 122.168 41.0443 122.168 40.8009C122.168 40.5576 121.971 40.3604 121.728 40.3604C121.484 40.3604 121.287 40.5576 121.287 40.8009C121.287 41.0443 121.484 41.2415 121.728 41.2415Z" fill="#383A48"/>
				<path d="M121.728 44.7663C121.971 44.7663 122.168 44.5691 122.168 44.3257C122.168 44.0824 121.971 43.8851 121.728 43.8851C121.484 43.8851 121.287 44.0824 121.287 44.3257C121.287 44.5691 121.484 44.7663 121.728 44.7663Z" fill="#383A48"/>
				<path d="M121.728 46.5287C121.971 46.5287 122.168 46.3314 122.168 46.0881C122.168 45.8448 121.971 45.6475 121.728 45.6475C121.484 45.6475 121.287 45.8448 121.287 46.0881C121.287 46.3314 121.484 46.5287 121.728 46.5287Z" fill="#383A48"/>
				<path d="M121.728 48.2911C121.971 48.2911 122.168 48.0938 122.168 47.8505C122.168 47.6071 121.971 47.4099 121.728 47.4099C121.484 47.4099 121.287 47.6071 121.287 47.8505C121.287 48.0938 121.484 48.2911 121.728 48.2911Z" fill="#383A48"/>
				<path d="M121.728 51.8158C121.971 51.8158 122.168 51.6186 122.168 51.3752C122.168 51.1319 121.971 50.9346 121.728 50.9346C121.484 50.9346 121.287 51.1319 121.287 51.3752C121.287 51.6186 121.484 51.8158 121.728 51.8158Z" fill="#383A48"/>
				<path d="M121.728 53.5782C121.971 53.5782 122.168 53.3809 122.168 53.1376C122.168 52.8943 121.971 52.697 121.728 52.697C121.484 52.697 121.287 52.8943 121.287 53.1376C121.287 53.3809 121.484 53.5782 121.728 53.5782Z" fill="#383A48"/>
				<path d="M121.728 55.3406C121.971 55.3406 122.168 55.1433 122.168 54.9C122.168 54.6566 121.971 54.4594 121.728 54.4594C121.484 54.4594 121.287 54.6566 121.287 54.9C121.287 55.1433 121.484 55.3406 121.728 55.3406Z" fill="#383A48"/>
				<path d="M121.728 58.8653C121.971 58.8653 122.168 58.6681 122.168 58.4247C122.168 58.1814 121.971 57.9841 121.728 57.9841C121.484 57.9841 121.287 58.1814 121.287 58.4247C121.287 58.6681 121.484 58.8653 121.728 58.8653Z" fill="#383A48"/>
				<path d="M121.728 60.6277C121.971 60.6277 122.168 60.4304 122.168 60.1871C122.168 59.9438 121.971 59.7465 121.728 59.7465C121.484 59.7465 121.287 59.9438 121.287 60.1871C121.287 60.4304 121.484 60.6277 121.728 60.6277Z" fill="#383A48"/>
				<path d="M121.728 65.9148C121.971 65.9148 122.168 65.7176 122.168 65.4742C122.168 65.2309 121.971 65.0336 121.728 65.0336C121.484 65.0336 121.287 65.2309 121.287 65.4742C121.287 65.7176 121.484 65.9148 121.728 65.9148Z" fill="#383A48"/>
				<path d="M121.728 67.6772C121.971 67.6772 122.168 67.4799 122.168 67.2366C122.168 66.9933 121.971 66.796 121.728 66.796C121.484 66.796 121.287 66.9933 121.287 67.2366C121.287 67.4799 121.484 67.6772 121.728 67.6772Z" fill="#383A48"/>
				<path d="M118.203 18.3307C118.446 18.3307 118.644 18.1334 118.644 17.8901C118.644 17.6467 118.446 17.4495 118.203 17.4495C117.96 17.4495 117.762 17.6467 117.762 17.8901C117.762 18.1334 117.96 18.3307 118.203 18.3307Z" fill="#383A48"/>
				<path d="M118.203 20.093C118.446 20.093 118.644 19.8958 118.644 19.6524C118.644 19.4091 118.446 19.2119 118.203 19.2119C117.96 19.2119 117.762 19.4091 117.762 19.6524C117.762 19.8958 117.96 20.093 118.203 20.093Z" fill="#383A48"/>
				<path d="M118.203 21.8554C118.446 21.8554 118.644 21.6582 118.644 21.4148C118.644 21.1715 118.446 20.9742 118.203 20.9742C117.96 20.9742 117.762 21.1715 117.762 21.4148C117.762 21.6582 117.96 21.8554 118.203 21.8554Z" fill="#383A48"/>
				<path d="M118.203 25.3802C118.446 25.3802 118.644 25.1829 118.644 24.9396C118.644 24.6963 118.446 24.499 118.203 24.499C117.96 24.499 117.762 24.6963 117.762 24.9396C117.762 25.1829 117.96 25.3802 118.203 25.3802Z" fill="#383A48"/>
				<path d="M118.203 27.1425C118.446 27.1425 118.644 26.9453 118.644 26.7019C118.644 26.4586 118.446 26.2614 118.203 26.2614C117.96 26.2614 117.762 26.4586 117.762 26.7019C117.762 26.9453 117.96 27.1425 118.203 27.1425Z" fill="#383A48"/>
				<path d="M118.203 32.4297C118.446 32.4297 118.644 32.2324 118.644 31.9891C118.644 31.7458 118.446 31.5485 118.203 31.5485C117.96 31.5485 117.762 31.7458 117.762 31.9891C117.762 32.2324 117.96 32.4297 118.203 32.4297Z" fill="#383A48"/>
				<path d="M118.203 34.192C118.446 34.192 118.644 33.9948 118.644 33.7514C118.644 33.5081 118.446 33.3109 118.203 33.3109C117.96 33.3109 117.762 33.5081 117.762 33.7514C117.762 33.9948 117.96 34.192 118.203 34.192Z" fill="#383A48"/>
				<path d="M118.203 37.7168C118.446 37.7168 118.644 37.3223 118.644 36.8356C118.644 36.349 118.446 35.9544 118.203 35.9544C117.96 35.9544 117.762 36.349 117.762 36.8356C117.762 37.3223 117.96 37.7168 118.203 37.7168Z" fill="#383A48"/>
				<path d="M118.203 39.4792C118.446 39.4792 118.644 39.2819 118.644 39.0386C118.644 38.7953 118.446 38.598 118.203 38.598C117.96 38.598 117.762 38.7953 117.762 39.0386C117.762 39.2819 117.96 39.4792 118.203 39.4792Z" fill="#383A48"/>
				<path d="M118.203 41.2415C118.446 41.2415 118.644 41.0443 118.644 40.8009C118.644 40.5576 118.446 40.3604 118.203 40.3604C117.96 40.3604 117.762 40.5576 117.762 40.8009C117.762 41.0443 117.96 41.2415 118.203 41.2415Z" fill="#383A48"/>
				<path d="M118.203 44.7663C118.446 44.7663 118.644 44.5691 118.644 44.3257C118.644 44.0824 118.446 43.8851 118.203 43.8851C117.96 43.8851 117.762 44.0824 117.762 44.3257C117.762 44.5691 117.96 44.7663 118.203 44.7663Z" fill="#383A48"/>
				<path d="M118.203 46.5287C118.446 46.5287 118.644 46.3314 118.644 46.0881C118.644 45.8448 118.446 45.6475 118.203 45.6475C117.96 45.6475 117.762 45.8448 117.762 46.0881C117.762 46.3314 117.96 46.5287 118.203 46.5287Z" fill="#383A48"/>
				<path d="M118.203 48.2911C118.446 48.2911 118.644 48.0938 118.644 47.8505C118.644 47.6071 118.446 47.4099 118.203 47.4099C117.96 47.4099 117.762 47.6071 117.762 47.8505C117.762 48.0938 117.96 48.2911 118.203 48.2911Z" fill="#383A48"/>
				<path d="M118.203 51.8158C118.446 51.8158 118.644 51.6186 118.644 51.3752C118.644 51.1319 118.446 50.9346 118.203 50.9346C117.96 50.9346 117.762 51.1319 117.762 51.3752C117.762 51.6186 117.96 51.8158 118.203 51.8158Z" fill="#383A48"/>
				<path d="M118.203 53.5782C118.446 53.5782 118.644 53.3809 118.644 53.1376C118.644 52.8943 118.446 52.697 118.203 52.697C117.96 52.697 117.762 52.8943 117.762 53.1376C117.762 53.3809 117.96 53.5782 118.203 53.5782Z" fill="#383A48"/>
				<path d="M118.203 55.3406C118.446 55.3406 118.644 55.1433 118.644 54.9C118.644 54.6566 118.446 54.4594 118.203 54.4594C117.96 54.4594 117.762 54.6566 117.762 54.9C117.762 55.1433 117.96 55.3406 118.203 55.3406Z" fill="#383A48"/>
				<path d="M118.203 58.8653C118.446 58.8653 118.644 58.6681 118.644 58.4247C118.644 58.1814 118.446 57.9841 118.203 57.9841C117.96 57.9841 117.762 58.1814 117.762 58.4247C117.762 58.6681 117.96 58.8653 118.203 58.8653Z" fill="#383A48"/>
				<path d="M118.203 60.6277C118.446 60.6277 118.644 60.4304 118.644 60.1871C118.644 59.9438 118.446 59.7465 118.203 59.7465C117.96 59.7465 117.762 59.9438 117.762 60.1871C117.762 60.4304 117.96 60.6277 118.203 60.6277Z" fill="#383A48"/>
				<path d="M118.203 65.9148C118.446 65.9148 118.644 65.7176 118.644 65.4742C118.644 65.2309 118.446 65.0336 118.203 65.0336C117.96 65.0336 117.762 65.2309 117.762 65.4742C117.762 65.7176 117.96 65.9148 118.203 65.9148Z" fill="#383A48"/>
				<path d="M118.203 67.6772C118.446 67.6772 118.644 67.4799 118.644 67.2366C118.644 66.9933 118.446 66.796 118.203 66.796C117.96 66.796 117.762 66.9933 117.762 67.2366C117.762 67.4799 117.96 67.6772 118.203 67.6772Z" fill="#383A48"/>
				<path d="M118.203 71.202C118.446 71.202 118.644 71.0047 118.644 70.7614C118.644 70.518 118.446 70.3208 118.203 70.3208C117.96 70.3208 117.762 70.518 117.762 70.7614C117.762 71.0047 117.96 71.202 118.203 71.202Z" fill="#383A48"/>
				<path d="M116.441 18.3307C116.684 18.3307 116.881 18.1334 116.881 17.8901C116.881 17.6467 116.684 17.4495 116.441 17.4495C116.197 17.4495 116 17.6467 116 17.8901C116 18.1334 116.197 18.3307 116.441 18.3307Z" fill="#383A48"/>
				<path d="M116.441 20.093C116.684 20.093 116.881 19.8958 116.881 19.6524C116.881 19.4091 116.684 19.2119 116.441 19.2119C116.197 19.2119 116 19.4091 116 19.6524C116 19.8958 116.197 20.093 116.441 20.093Z" fill="#383A48"/>
				<path d="M116.441 21.8554C116.684 21.8554 116.881 21.6582 116.881 21.4148C116.881 21.1715 116.684 20.9742 116.441 20.9742C116.197 20.9742 116 21.1715 116 21.4148C116 21.6582 116.197 21.8554 116.441 21.8554Z" fill="#383A48"/>
				<path d="M116.441 25.3802C116.684 25.3802 116.881 25.1829 116.881 24.9396C116.881 24.6963 116.684 24.499 116.441 24.499C116.197 24.499 116 24.6963 116 24.9396C116 25.1829 116.197 25.3802 116.441 25.3802Z" fill="#383A48"/>
				<path d="M116.441 27.1425C116.684 27.1425 116.881 26.9453 116.881 26.7019C116.881 26.4586 116.684 26.2614 116.441 26.2614C116.197 26.2614 116 26.4586 116 26.7019C116 26.9453 116.197 27.1425 116.441 27.1425Z" fill="#383A48"/>
				<path d="M116.441 32.4297C116.684 32.4297 116.881 32.2324 116.881 31.9891C116.881 31.7458 116.684 31.5485 116.441 31.5485C116.197 31.5485 116 31.7458 116 31.9891C116 32.2324 116.197 32.4297 116.441 32.4297Z" fill="#383A48"/>
				<path d="M116.441 34.192C116.684 34.192 116.881 33.9948 116.881 33.7514C116.881 33.5081 116.684 33.3109 116.441 33.3109C116.197 33.3109 116 33.5081 116 33.7514C116 33.9948 116.197 34.192 116.441 34.192Z" fill="#383A48"/>
				<path d="M116.441 37.7168C116.684 37.7168 116.881 37.3223 116.881 36.8356C116.881 36.349 116.684 35.9544 116.441 35.9544C116.197 35.9544 116 36.349 116 36.8356C116 37.3223 116.197 37.7168 116.441 37.7168Z" fill="#383A48"/>
				<path d="M116.441 39.4792C116.684 39.4792 116.881 39.2819 116.881 39.0386C116.881 38.7953 116.684 38.598 116.441 38.598C116.197 38.598 116 38.7953 116 39.0386C116 39.2819 116.197 39.4792 116.441 39.4792Z" fill="#383A48"/>
				<path d="M116.441 41.2415C116.684 41.2415 116.881 41.0443 116.881 40.8009C116.881 40.5576 116.684 40.3604 116.441 40.3604C116.197 40.3604 116 40.5576 116 40.8009C116 41.0443 116.197 41.2415 116.441 41.2415Z" fill="#383A48"/>
				<path d="M116.441 44.7663C116.684 44.7663 116.881 44.5691 116.881 44.3257C116.881 44.0824 116.684 43.8851 116.441 43.8851C116.197 43.8851 116 44.0824 116 44.3257C116 44.5691 116.197 44.7663 116.441 44.7663Z" fill="#383A48"/>
				<path d="M116.441 46.5287C116.684 46.5287 116.881 46.3314 116.881 46.0881C116.881 45.8448 116.684 45.6475 116.441 45.6475C116.197 45.6475 116 45.8448 116 46.0881C116 46.3314 116.197 46.5287 116.441 46.5287Z" fill="#383A48"/>
				<path d="M116.441 48.2911C116.684 48.2911 116.881 48.0938 116.881 47.8505C116.881 47.6071 116.684 47.4099 116.441 47.4099C116.197 47.4099 116 47.6071 116 47.8505C116 48.0938 116.197 48.2911 116.441 48.2911Z" fill="#383A48"/>
				<path d="M116.441 51.8158C116.684 51.8158 116.881 51.6186 116.881 51.3752C116.881 51.1319 116.684 50.9346 116.441 50.9346C116.197 50.9346 116 51.1319 116 51.3752C116 51.6186 116.197 51.8158 116.441 51.8158Z" fill="#383A48"/>
				<path d="M116.441 53.5782C116.684 53.5782 116.881 53.3809 116.881 53.1376C116.881 52.8943 116.684 52.697 116.441 52.697C116.197 52.697 116 52.8943 116 53.1376C116 53.3809 116.197 53.5782 116.441 53.5782Z" fill="#383A48"/>
				<path d="M116.441 55.3406C116.684 55.3406 116.881 55.1433 116.881 54.9C116.881 54.6566 116.684 54.4594 116.441 54.4594C116.197 54.4594 116 54.6566 116 54.9C116 55.1433 116.197 55.3406 116.441 55.3406Z" fill="#383A48"/>
				<path d="M116.441 58.8653C116.684 58.8653 116.881 58.6681 116.881 58.4247C116.881 58.1814 116.684 57.9841 116.441 57.9841C116.197 57.9841 116 58.1814 116 58.4247C116 58.6681 116.197 58.8653 116.441 58.8653Z" fill="#383A48"/>
				<path d="M116.441 60.6277C116.684 60.6277 116.881 60.4304 116.881 60.1871C116.881 59.9438 116.684 59.7465 116.441 59.7465C116.197 59.7465 116 59.9438 116 60.1871C116 60.4304 116.197 60.6277 116.441 60.6277Z" fill="#383A48"/>
				<path d="M116.441 65.9148C116.684 65.9148 116.881 65.7176 116.881 65.4742C116.881 65.2309 116.684 65.0336 116.441 65.0336C116.197 65.0336 116 65.2309 116 65.4742C116 65.7176 116.197 65.9148 116.441 65.9148Z" fill="#383A48"/>
				<path d="M116.441 67.6772C116.684 67.6772 116.881 67.4799 116.881 67.2366C116.881 66.9933 116.684 66.796 116.441 66.796C116.197 66.796 116 66.9933 116 67.2366C116 67.4799 116.197 67.6772 116.441 67.6772Z" fill="#383A48"/>
				<path d="M116.441 71.202C116.684 71.202 116.881 71.0047 116.881 70.7614C116.881 70.518 116.684 70.3208 116.441 70.3208C116.197 70.3208 116 70.518 116 70.7614C116 71.0047 116.197 71.202 116.441 71.202Z" fill="#383A48"/>
				<path d="M114.678 14.8059C114.922 14.8059 115.119 14.6086 115.119 14.3653C115.119 14.122 114.922 13.9247 114.678 13.9247C114.435 13.9247 114.238 14.122 114.238 14.3653C114.238 14.6086 114.435 14.8059 114.678 14.8059Z" fill="#383A48"/>
				<path d="M114.678 18.3307C114.922 18.3307 115.119 18.1334 115.119 17.8901C115.119 17.6467 114.922 17.4495 114.678 17.4495C114.435 17.4495 114.238 17.6467 114.238 17.8901C114.238 18.1334 114.435 18.3307 114.678 18.3307Z" fill="#383A48"/>
				<path d="M114.678 20.093C114.922 20.093 115.119 19.8958 115.119 19.6524C115.119 19.4091 114.922 19.2119 114.678 19.2119C114.435 19.2119 114.238 19.4091 114.238 19.6524C114.238 19.8958 114.435 20.093 114.678 20.093Z" fill="#383A48"/>
				<path d="M114.678 21.8554C114.922 21.8554 115.119 21.6582 115.119 21.4148C115.119 21.1715 114.922 20.9742 114.678 20.9742C114.435 20.9742 114.238 21.1715 114.238 21.4148C114.238 21.6582 114.435 21.8554 114.678 21.8554Z" fill="#383A48"/>
				<path d="M114.678 25.3802C114.922 25.3802 115.119 25.1829 115.119 24.9396C115.119 24.6963 114.922 24.499 114.678 24.499C114.435 24.499 114.238 24.6963 114.238 24.9396C114.238 25.1829 114.435 25.3802 114.678 25.3802Z" fill="#383A48"/>
				<path d="M114.678 27.1425C114.922 27.1425 115.119 26.9453 115.119 26.7019C115.119 26.4586 114.922 26.2614 114.678 26.2614C114.435 26.2614 114.238 26.4586 114.238 26.7019C114.238 26.9453 114.435 27.1425 114.678 27.1425Z" fill="#383A48"/>
				<path d="M114.678 32.4297C114.922 32.4297 115.119 32.2324 115.119 31.9891C115.119 31.7458 114.922 31.5485 114.678 31.5485C114.435 31.5485 114.238 31.7458 114.238 31.9891C114.238 32.2324 114.435 32.4297 114.678 32.4297Z" fill="#383A48"/>
				<path d="M114.678 34.192C114.922 34.192 115.119 33.9948 115.119 33.7514C115.119 33.5081 114.922 33.3109 114.678 33.3109C114.435 33.3109 114.238 33.5081 114.238 33.7514C114.238 33.9948 114.435 34.192 114.678 34.192Z" fill="#383A48"/>
				<path d="M114.678 37.7168C114.922 37.7168 115.119 37.3223 115.119 36.8356C115.119 36.349 114.922 35.9544 114.678 35.9544C114.435 35.9544 114.238 36.349 114.238 36.8356C114.238 37.3223 114.435 37.7168 114.678 37.7168Z" fill="#383A48"/>
				<path d="M114.678 39.4792C114.922 39.4792 115.119 39.2819 115.119 39.0386C115.119 38.7953 114.922 38.598 114.678 38.598C114.435 38.598 114.238 38.7953 114.238 39.0386C114.238 39.2819 114.435 39.4792 114.678 39.4792Z" fill="#383A48"/>
				<path d="M114.678 41.2415C114.922 41.2415 115.119 41.0443 115.119 40.8009C115.119 40.5576 114.922 40.3604 114.678 40.3604C114.435 40.3604 114.238 40.5576 114.238 40.8009C114.238 41.0443 114.435 41.2415 114.678 41.2415Z" fill="#383A48"/>
				<path d="M114.678 44.7663C114.922 44.7663 115.119 44.5691 115.119 44.3257C115.119 44.0824 114.922 43.8851 114.678 43.8851C114.435 43.8851 114.238 44.0824 114.238 44.3257C114.238 44.5691 114.435 44.7663 114.678 44.7663Z" fill="#383A48"/>
				<path d="M114.678 46.5287C114.922 46.5287 115.119 46.3314 115.119 46.0881C115.119 45.8448 114.922 45.6475 114.678 45.6475C114.435 45.6475 114.238 45.8448 114.238 46.0881C114.238 46.3314 114.435 46.5287 114.678 46.5287Z" fill="#383A48"/>
				<path d="M114.678 48.2911C114.922 48.2911 115.119 48.0938 115.119 47.8505C115.119 47.6071 114.922 47.4099 114.678 47.4099C114.435 47.4099 114.238 47.6071 114.238 47.8505C114.238 48.0938 114.435 48.2911 114.678 48.2911Z" fill="#383A48"/>
				<path d="M114.678 51.8158C114.922 51.8158 115.119 51.6186 115.119 51.3752C115.119 51.1319 114.922 50.9346 114.678 50.9346C114.435 50.9346 114.238 51.1319 114.238 51.3752C114.238 51.6186 114.435 51.8158 114.678 51.8158Z" fill="#383A48"/>
				<path d="M114.678 53.5782C114.922 53.5782 115.119 53.3809 115.119 53.1376C115.119 52.8943 114.922 52.697 114.678 52.697C114.435 52.697 114.238 52.8943 114.238 53.1376C114.238 53.3809 114.435 53.5782 114.678 53.5782Z" fill="#383A48"/>
				<path d="M114.678 55.3406C114.922 55.3406 115.119 55.1433 115.119 54.9C115.119 54.6566 114.922 54.4594 114.678 54.4594C114.435 54.4594 114.238 54.6566 114.238 54.9C114.238 55.1433 114.435 55.3406 114.678 55.3406Z" fill="#383A48"/>
				<path d="M114.678 58.8653C114.922 58.8653 115.119 58.6681 115.119 58.4247C115.119 58.1814 114.922 57.9841 114.678 57.9841C114.435 57.9841 114.238 58.1814 114.238 58.4247C114.238 58.6681 114.435 58.8653 114.678 58.8653Z" fill="#383A48"/>
				<path d="M114.678 60.6277C114.922 60.6277 115.119 60.4304 115.119 60.1871C115.119 59.9438 114.922 59.7465 114.678 59.7465C114.435 59.7465 114.238 59.9438 114.238 60.1871C114.238 60.4304 114.435 60.6277 114.678 60.6277Z" fill="#383A48"/>
				<path d="M114.678 65.9148C114.922 65.9148 115.119 65.7176 115.119 65.4742C115.119 65.2309 114.922 65.0336 114.678 65.0336C114.435 65.0336 114.238 65.2309 114.238 65.4742C114.238 65.7176 114.435 65.9148 114.678 65.9148Z" fill="#383A48"/>
				<path d="M114.678 67.6772C114.922 67.6772 115.119 67.4799 115.119 67.2366C115.119 66.9933 114.922 66.796 114.678 66.796C114.435 66.796 114.238 66.9933 114.238 67.2366C114.238 67.4799 114.435 67.6772 114.678 67.6772Z" fill="#383A48"/>
				<path d="M114.678 71.202C114.922 71.202 115.119 71.0047 115.119 70.7614C115.119 70.518 114.922 70.3208 114.678 70.3208C114.435 70.3208 114.238 70.518 114.238 70.7614C114.238 71.0047 114.435 71.202 114.678 71.202Z" fill="#383A48"/>
				<path d="M114.678 72.9643C114.922 72.9643 115.119 72.7671 115.119 72.5237C115.119 72.2804 114.922 72.0831 114.678 72.0831C114.435 72.0831 114.238 72.2804 114.238 72.5237C114.238 72.7671 114.435 72.9643 114.678 72.9643Z" fill="#383A48"/>
				<path d="M111.153 14.8059C111.397 14.8059 111.594 14.6086 111.594 14.3653C111.594 14.122 111.397 13.9247 111.153 13.9247C110.91 13.9247 110.713 14.122 110.713 14.3653C110.713 14.6086 110.91 14.8059 111.153 14.8059Z" fill="#383A48"/>
				<path d="M111.153 18.3307C111.397 18.3307 111.594 18.1334 111.594 17.8901C111.594 17.6467 111.397 17.4495 111.153 17.4495C110.91 17.4495 110.713 17.6467 110.713 17.8901C110.713 18.1334 110.91 18.3307 111.153 18.3307Z" fill="#383A48"/>
				<path d="M111.153 20.093C111.397 20.093 111.594 19.8958 111.594 19.6524C111.594 19.4091 111.397 19.2119 111.153 19.2119C110.91 19.2119 110.713 19.4091 110.713 19.6524C110.713 19.8958 110.91 20.093 111.153 20.093Z" fill="#383A48"/>
				<path d="M111.153 21.8554C111.397 21.8554 111.594 21.6582 111.594 21.4148C111.594 21.1715 111.397 20.9742 111.153 20.9742C110.91 20.9742 110.713 21.1715 110.713 21.4148C110.713 21.6582 110.91 21.8554 111.153 21.8554Z" fill="#383A48"/>
				<path d="M111.153 25.3802C111.397 25.3802 111.594 25.1829 111.594 24.9396C111.594 24.6963 111.397 24.499 111.153 24.499C110.91 24.499 110.713 24.6963 110.713 24.9396C110.713 25.1829 110.91 25.3802 111.153 25.3802Z" fill="#383A48"/>
				<path d="M111.153 27.1425C111.397 27.1425 111.594 26.9453 111.594 26.7019C111.594 26.4586 111.397 26.2614 111.153 26.2614C110.91 26.2614 110.713 26.4586 110.713 26.7019C110.713 26.9453 110.91 27.1425 111.153 27.1425Z" fill="#383A48"/>
				<path d="M111.153 32.4297C111.397 32.4297 111.594 32.2324 111.594 31.9891C111.594 31.7458 111.397 31.5485 111.153 31.5485C110.91 31.5485 110.713 31.7458 110.713 31.9891C110.713 32.2324 110.91 32.4297 111.153 32.4297Z" fill="#383A48"/>
				<path d="M111.153 34.192C111.397 34.192 111.594 33.9948 111.594 33.7514C111.594 33.5081 111.397 33.3109 111.153 33.3109C110.91 33.3109 110.713 33.5081 110.713 33.7514C110.713 33.9948 110.91 34.192 111.153 34.192Z" fill="#383A48"/>
				<path d="M111.153 37.7168C111.397 37.7168 111.594 37.3223 111.594 36.8356C111.594 36.349 111.397 35.9544 111.153 35.9544C110.91 35.9544 110.713 36.349 110.713 36.8356C110.713 37.3223 110.91 37.7168 111.153 37.7168Z" fill="#383A48"/>
				<path d="M111.153 39.4792C111.397 39.4792 111.594 39.2819 111.594 39.0386C111.594 38.7953 111.397 38.598 111.153 38.598C110.91 38.598 110.713 38.7953 110.713 39.0386C110.713 39.2819 110.91 39.4792 111.153 39.4792Z" fill="#383A48"/>
				<path d="M111.153 41.2415C111.397 41.2415 111.594 41.0443 111.594 40.8009C111.594 40.5576 111.397 40.3604 111.153 40.3604C110.91 40.3604 110.713 40.5576 110.713 40.8009C110.713 41.0443 110.91 41.2415 111.153 41.2415Z" fill="#383A48"/>
				<path d="M111.153 44.7663C111.397 44.7663 111.594 44.5691 111.594 44.3257C111.594 44.0824 111.397 43.8851 111.153 43.8851C110.91 43.8851 110.713 44.0824 110.713 44.3257C110.713 44.5691 110.91 44.7663 111.153 44.7663Z" fill="#383A48"/>
				<path d="M111.153 46.5287C111.397 46.5287 111.594 46.3314 111.594 46.0881C111.594 45.8448 111.397 45.6475 111.153 45.6475C110.91 45.6475 110.713 45.8448 110.713 46.0881C110.713 46.3314 110.91 46.5287 111.153 46.5287Z" fill="#383A48"/>
				<path d="M111.153 48.2911C111.397 48.2911 111.594 48.0938 111.594 47.8505C111.594 47.6071 111.397 47.4099 111.153 47.4099C110.91 47.4099 110.713 47.6071 110.713 47.8505C110.713 48.0938 110.91 48.2911 111.153 48.2911Z" fill="#383A48"/>
				<path d="M111.153 51.8158C111.397 51.8158 111.594 51.6186 111.594 51.3752C111.594 51.1319 111.397 50.9346 111.153 50.9346C110.91 50.9346 110.713 51.1319 110.713 51.3752C110.713 51.6186 110.91 51.8158 111.153 51.8158Z" fill="#383A48"/>
				<path d="M111.153 53.5782C111.397 53.5782 111.594 53.3809 111.594 53.1376C111.594 52.8943 111.397 52.697 111.153 52.697C110.91 52.697 110.713 52.8943 110.713 53.1376C110.713 53.3809 110.91 53.5782 111.153 53.5782Z" fill="#383A48"/>
				<path d="M111.153 55.3406C111.397 55.3406 111.594 55.1433 111.594 54.9C111.594 54.6566 111.397 54.4594 111.153 54.4594C110.91 54.4594 110.713 54.6566 110.713 54.9C110.713 55.1433 110.91 55.3406 111.153 55.3406Z" fill="#383A48"/>
				<path d="M111.153 58.8653C111.397 58.8653 111.594 58.6681 111.594 58.4247C111.594 58.1814 111.397 57.9841 111.153 57.9841C110.91 57.9841 110.713 58.1814 110.713 58.4247C110.713 58.6681 110.91 58.8653 111.153 58.8653Z" fill="#383A48"/>
				<path d="M111.153 60.6277C111.397 60.6277 111.594 60.4304 111.594 60.1871C111.594 59.9438 111.397 59.7465 111.153 59.7465C110.91 59.7465 110.713 59.9438 110.713 60.1871C110.713 60.4304 110.91 60.6277 111.153 60.6277Z" fill="#383A48"/>
				<path d="M111.153 65.9148C111.397 65.9148 111.594 65.7176 111.594 65.4742C111.594 65.2309 111.397 65.0336 111.153 65.0336C110.91 65.0336 110.713 65.2309 110.713 65.4742C110.713 65.7176 110.91 65.9148 111.153 65.9148Z" fill="#383A48"/>
				<path d="M111.153 67.6772C111.397 67.6772 111.594 67.4799 111.594 67.2366C111.594 66.9933 111.397 66.796 111.153 66.796C110.91 66.796 110.713 66.9933 110.713 67.2366C110.713 67.4799 110.91 67.6772 111.153 67.6772Z" fill="#383A48"/>
				<path d="M111.153 71.202C111.397 71.202 111.594 71.0047 111.594 70.7614C111.594 70.518 111.397 70.3208 111.153 70.3208C110.91 70.3208 110.713 70.518 110.713 70.7614C110.713 71.0047 110.91 71.202 111.153 71.202Z" fill="#383A48"/>
				<path d="M111.153 72.9643C111.397 72.9643 111.594 72.7671 111.594 72.5237C111.594 72.2804 111.397 72.0831 111.153 72.0831C110.91 72.0831 110.713 72.2804 110.713 72.5237C110.713 72.7671 110.91 72.9643 111.153 72.9643Z" fill="#383A48"/>
				<path d="M109.391 14.8059C109.634 14.8059 109.832 14.6086 109.832 14.3653C109.832 14.122 109.634 13.9247 109.391 13.9247C109.148 13.9247 108.95 14.122 108.95 14.3653C108.95 14.6086 109.148 14.8059 109.391 14.8059Z" fill="#383A48"/>
				<path d="M109.391 18.3307C109.634 18.3307 109.832 18.1334 109.832 17.8901C109.832 17.6467 109.634 17.4495 109.391 17.4495C109.148 17.4495 108.95 17.6467 108.95 17.8901C108.95 18.1334 109.148 18.3307 109.391 18.3307Z" fill="#383A48"/>
				<path d="M109.391 20.093C109.634 20.093 109.832 19.8958 109.832 19.6524C109.832 19.4091 109.634 19.2119 109.391 19.2119C109.148 19.2119 108.95 19.4091 108.95 19.6524C108.95 19.8958 109.148 20.093 109.391 20.093Z" fill="#383A48"/>
				<path d="M109.391 21.8554C109.634 21.8554 109.832 21.6582 109.832 21.4148C109.832 21.1715 109.634 20.9742 109.391 20.9742C109.148 20.9742 108.95 21.1715 108.95 21.4148C108.95 21.6582 109.148 21.8554 109.391 21.8554Z" fill="#383A48"/>
				<path d="M109.391 25.3802C109.634 25.3802 109.832 25.1829 109.832 24.9396C109.832 24.6963 109.634 24.499 109.391 24.499C109.148 24.499 108.95 24.6963 108.95 24.9396C108.95 25.1829 109.148 25.3802 109.391 25.3802Z" fill="#383A48"/>
				<path d="M109.391 27.1425C109.634 27.1425 109.832 26.9453 109.832 26.7019C109.832 26.4586 109.634 26.2614 109.391 26.2614C109.148 26.2614 108.95 26.4586 108.95 26.7019C108.95 26.9453 109.148 27.1425 109.391 27.1425Z" fill="#383A48"/>
				<path d="M109.391 32.4297C109.634 32.4297 109.832 32.2324 109.832 31.9891C109.832 31.7458 109.634 31.5485 109.391 31.5485C109.148 31.5485 108.95 31.7458 108.95 31.9891C108.95 32.2324 109.148 32.4297 109.391 32.4297Z" fill="#383A48"/>
				<path d="M109.391 34.192C109.634 34.192 109.832 33.9948 109.832 33.7514C109.832 33.5081 109.634 33.3109 109.391 33.3109C109.148 33.3109 108.95 33.5081 108.95 33.7514C108.95 33.9948 109.148 34.192 109.391 34.192Z" fill="#383A48"/>
				<path d="M109.391 37.7168C109.634 37.7168 109.832 37.3223 109.832 36.8356C109.832 36.349 109.634 35.9544 109.391 35.9544C109.148 35.9544 108.95 36.349 108.95 36.8356C108.95 37.3223 109.148 37.7168 109.391 37.7168Z" fill="#383A48"/>
				<path d="M109.391 39.4792C109.634 39.4792 109.832 39.2819 109.832 39.0386C109.832 38.7953 109.634 38.598 109.391 38.598C109.148 38.598 108.95 38.7953 108.95 39.0386C108.95 39.2819 109.148 39.4792 109.391 39.4792Z" fill="#383A48"/>
				<path d="M109.391 41.2415C109.634 41.2415 109.832 41.0443 109.832 40.8009C109.832 40.5576 109.634 40.3604 109.391 40.3604C109.148 40.3604 108.95 40.5576 108.95 40.8009C108.95 41.0443 109.148 41.2415 109.391 41.2415Z" fill="#383A48"/>
				<path d="M109.391 44.7663C109.634 44.7663 109.832 44.5691 109.832 44.3257C109.832 44.0824 109.634 43.8851 109.391 43.8851C109.148 43.8851 108.95 44.0824 108.95 44.3257C108.95 44.5691 109.148 44.7663 109.391 44.7663Z" fill="#383A48"/>
				<path d="M109.391 46.5287C109.634 46.5287 109.832 46.3314 109.832 46.0881C109.832 45.8448 109.634 45.6475 109.391 45.6475C109.148 45.6475 108.95 45.8448 108.95 46.0881C108.95 46.3314 109.148 46.5287 109.391 46.5287Z" fill="#383A48"/>
				<path d="M109.391 48.2911C109.634 48.2911 109.832 48.0938 109.832 47.8505C109.832 47.6071 109.634 47.4099 109.391 47.4099C109.148 47.4099 108.95 47.6071 108.95 47.8505C108.95 48.0938 109.148 48.2911 109.391 48.2911Z" fill="#383A48"/>
				<path d="M109.391 51.8158C109.634 51.8158 109.832 51.6186 109.832 51.3752C109.832 51.1319 109.634 50.9346 109.391 50.9346C109.148 50.9346 108.95 51.1319 108.95 51.3752C108.95 51.6186 109.148 51.8158 109.391 51.8158Z" fill="#383A48"/>
				<path d="M109.391 53.5782C109.634 53.5782 109.832 53.3809 109.832 53.1376C109.832 52.8943 109.634 52.697 109.391 52.697C109.148 52.697 108.95 52.8943 108.95 53.1376C108.95 53.3809 109.148 53.5782 109.391 53.5782Z" fill="#383A48"/>
				<path d="M109.391 55.3406C109.634 55.3406 109.832 55.1433 109.832 54.9C109.832 54.6566 109.634 54.4594 109.391 54.4594C109.148 54.4594 108.95 54.6566 108.95 54.9C108.95 55.1433 109.148 55.3406 109.391 55.3406Z" fill="#383A48"/>
				<path d="M109.391 58.8653C109.634 58.8653 109.832 58.6681 109.832 58.4247C109.832 58.1814 109.634 57.9841 109.391 57.9841C109.148 57.9841 108.95 58.1814 108.95 58.4247C108.95 58.6681 109.148 58.8653 109.391 58.8653Z" fill="#383A48"/>
				<path d="M109.391 60.6277C109.634 60.6277 109.832 60.4304 109.832 60.1871C109.832 59.9438 109.634 59.7465 109.391 59.7465C109.148 59.7465 108.95 59.9438 108.95 60.1871C108.95 60.4304 109.148 60.6277 109.391 60.6277Z" fill="#383A48"/>
				<path d="M109.391 65.9148C109.634 65.9148 109.832 65.7176 109.832 65.4742C109.832 65.2309 109.634 65.0336 109.391 65.0336C109.148 65.0336 108.95 65.2309 108.95 65.4742C108.95 65.7176 109.148 65.9148 109.391 65.9148Z" fill="#383A48"/>
				<path d="M109.391 67.6772C109.634 67.6772 109.832 67.4799 109.832 67.2366C109.832 66.9933 109.634 66.796 109.391 66.796C109.148 66.796 108.95 66.9933 108.95 67.2366C108.95 67.4799 109.148 67.6772 109.391 67.6772Z" fill="#383A48"/>
				<path d="M109.391 71.202C109.634 71.202 109.832 71.0047 109.832 70.7614C109.832 70.518 109.634 70.3208 109.391 70.3208C109.148 70.3208 108.95 70.518 108.95 70.7614C108.95 71.0047 109.148 71.202 109.391 71.202Z" fill="#383A48"/>
				<path d="M109.391 72.9643C109.634 72.9643 109.832 72.7671 109.832 72.5237C109.832 72.2804 109.634 72.0831 109.391 72.0831C109.148 72.0831 108.95 72.2804 108.95 72.5237C108.95 72.7671 109.148 72.9643 109.391 72.9643Z" fill="#383A48"/>
				<path d="M107.629 13.0435C107.872 13.0435 108.069 12.8463 108.069 12.6029C108.069 12.3596 107.872 12.1624 107.629 12.1624C107.385 12.1624 107.188 12.3596 107.188 12.6029C107.188 12.8463 107.385 13.0435 107.629 13.0435Z" fill="#383A48"/>
				<path d="M107.629 14.8059C107.872 14.8059 108.069 14.6086 108.069 14.3653C108.069 14.122 107.872 13.9247 107.629 13.9247C107.385 13.9247 107.188 14.122 107.188 14.3653C107.188 14.6086 107.385 14.8059 107.629 14.8059Z" fill="#383A48"/>
				<path d="M107.629 18.3307C107.872 18.3307 108.069 18.1334 108.069 17.8901C108.069 17.6467 107.872 17.4495 107.629 17.4495C107.385 17.4495 107.188 17.6467 107.188 17.8901C107.188 18.1334 107.385 18.3307 107.629 18.3307Z" fill="#383A48"/>
				<path d="M107.629 20.093C107.872 20.093 108.069 19.8958 108.069 19.6524C108.069 19.4091 107.872 19.2119 107.629 19.2119C107.385 19.2119 107.188 19.4091 107.188 19.6524C107.188 19.8958 107.385 20.093 107.629 20.093Z" fill="#383A48"/>
				<path d="M107.629 21.8554C107.872 21.8554 108.069 21.6582 108.069 21.4148C108.069 21.1715 107.872 20.9742 107.629 20.9742C107.385 20.9742 107.188 21.1715 107.188 21.4148C107.188 21.6582 107.385 21.8554 107.629 21.8554Z" fill="#383A48"/>
				<path d="M107.629 25.3802C107.872 25.3802 108.069 25.1829 108.069 24.9396C108.069 24.6963 107.872 24.499 107.629 24.499C107.385 24.499 107.188 24.6963 107.188 24.9396C107.188 25.1829 107.385 25.3802 107.629 25.3802Z" fill="#383A48"/>
				<path d="M107.629 27.1425C107.872 27.1425 108.069 26.9453 108.069 26.7019C108.069 26.4586 107.872 26.2614 107.629 26.2614C107.385 26.2614 107.188 26.4586 107.188 26.7019C107.188 26.9453 107.385 27.1425 107.629 27.1425Z" fill="#383A48"/>
				<path d="M107.629 32.4297C107.872 32.4297 108.069 32.2324 108.069 31.9891C108.069 31.7458 107.872 31.5485 107.629 31.5485C107.385 31.5485 107.188 31.7458 107.188 31.9891C107.188 32.2324 107.385 32.4297 107.629 32.4297Z" fill="#383A48"/>
				<path d="M107.629 34.192C107.872 34.192 108.069 33.9948 108.069 33.7514C108.069 33.5081 107.872 33.3109 107.629 33.3109C107.385 33.3109 107.188 33.5081 107.188 33.7514C107.188 33.9948 107.385 34.192 107.629 34.192Z" fill="#383A48"/>
				<path d="M107.629 37.7168C107.872 37.7168 108.069 37.3223 108.069 36.8356C108.069 36.349 107.872 35.9544 107.629 35.9544C107.385 35.9544 107.188 36.349 107.188 36.8356C107.188 37.3223 107.385 37.7168 107.629 37.7168Z" fill="#383A48"/>
				<path d="M107.629 39.4792C107.872 39.4792 108.069 39.2819 108.069 39.0386C108.069 38.7953 107.872 38.598 107.629 38.598C107.385 38.598 107.188 38.7953 107.188 39.0386C107.188 39.2819 107.385 39.4792 107.629 39.4792Z" fill="#383A48"/>
				<path d="M107.629 41.2415C107.872 41.2415 108.069 41.0443 108.069 40.8009C108.069 40.5576 107.872 40.3604 107.629 40.3604C107.385 40.3604 107.188 40.5576 107.188 40.8009C107.188 41.0443 107.385 41.2415 107.629 41.2415Z" fill="#383A48"/>
				<path d="M107.629 44.7663C107.872 44.7663 108.069 44.5691 108.069 44.3257C108.069 44.0824 107.872 43.8851 107.629 43.8851C107.385 43.8851 107.188 44.0824 107.188 44.3257C107.188 44.5691 107.385 44.7663 107.629 44.7663Z" fill="#383A48"/>
				<path d="M107.629 46.5287C107.872 46.5287 108.069 46.3314 108.069 46.0881C108.069 45.8448 107.872 45.6475 107.629 45.6475C107.385 45.6475 107.188 45.8448 107.188 46.0881C107.188 46.3314 107.385 46.5287 107.629 46.5287Z" fill="#383A48"/>
				<path d="M107.629 48.2911C107.872 48.2911 108.069 48.0938 108.069 47.8505C108.069 47.6071 107.872 47.4099 107.629 47.4099C107.385 47.4099 107.188 47.6071 107.188 47.8505C107.188 48.0938 107.385 48.2911 107.629 48.2911Z" fill="#383A48"/>
				<path d="M107.629 51.8158C107.872 51.8158 108.069 51.6186 108.069 51.3752C108.069 51.1319 107.872 50.9346 107.629 50.9346C107.385 50.9346 107.188 51.1319 107.188 51.3752C107.188 51.6186 107.385 51.8158 107.629 51.8158Z" fill="#383A48"/>
				<path d="M107.629 53.5782C107.872 53.5782 108.069 53.3809 108.069 53.1376C108.069 52.8943 107.872 52.697 107.629 52.697C107.385 52.697 107.188 52.8943 107.188 53.1376C107.188 53.3809 107.385 53.5782 107.629 53.5782Z" fill="#383A48"/>
				<path d="M107.629 55.3406C107.872 55.3406 108.069 55.1433 108.069 54.9C108.069 54.6566 107.872 54.4594 107.629 54.4594C107.385 54.4594 107.188 54.6566 107.188 54.9C107.188 55.1433 107.385 55.3406 107.629 55.3406Z" fill="#383A48"/>
				<path d="M107.629 58.8653C107.872 58.8653 108.069 58.6681 108.069 58.4247C108.069 58.1814 107.872 57.9841 107.629 57.9841C107.385 57.9841 107.188 58.1814 107.188 58.4247C107.188 58.6681 107.385 58.8653 107.629 58.8653Z" fill="#383A48"/>
				<path d="M107.629 60.6277C107.872 60.6277 108.069 60.4304 108.069 60.1871C108.069 59.9438 107.872 59.7465 107.629 59.7465C107.385 59.7465 107.188 59.9438 107.188 60.1871C107.188 60.4304 107.385 60.6277 107.629 60.6277Z" fill="#383A48"/>
				<path d="M107.629 65.9148C107.872 65.9148 108.069 65.7176 108.069 65.4742C108.069 65.2309 107.872 65.0336 107.629 65.0336C107.385 65.0336 107.188 65.2309 107.188 65.4742C107.188 65.7176 107.385 65.9148 107.629 65.9148Z" fill="#383A48"/>
				<path d="M107.629 67.6772C107.872 67.6772 108.069 67.4799 108.069 67.2366C108.069 66.9933 107.872 66.796 107.629 66.796C107.385 66.796 107.188 66.9933 107.188 67.2366C107.188 67.4799 107.385 67.6772 107.629 67.6772Z" fill="#383A48"/>
				<path d="M107.629 71.202C107.872 71.202 108.069 71.0047 108.069 70.7614C108.069 70.518 107.872 70.3208 107.629 70.3208C107.385 70.3208 107.188 70.518 107.188 70.7614C107.188 71.0047 107.385 71.202 107.629 71.202Z" fill="#383A48"/>
				<path d="M107.629 72.9643C107.872 72.9643 108.069 72.7671 108.069 72.5237C108.069 72.2804 107.872 72.0831 107.629 72.0831C107.385 72.0831 107.188 72.2804 107.188 72.5237C107.188 72.7671 107.385 72.9643 107.629 72.9643Z" fill="#383A48"/>
				<path d="M107.629 74.7267C107.872 74.7267 108.069 74.5294 108.069 74.2861C108.069 74.0428 107.872 73.8455 107.629 73.8455C107.385 73.8455 107.188 74.0428 107.188 74.2861C107.188 74.5294 107.385 74.7267 107.629 74.7267Z" fill="#383A48"/>
				<path d="M104.104 13.0435C104.347 13.0435 104.545 12.8463 104.545 12.6029C104.545 12.3596 104.347 12.1624 104.104 12.1624C103.861 12.1624 103.663 12.3596 103.663 12.6029C103.663 12.8463 103.861 13.0435 104.104 13.0435Z" fill="#383A48"/>
				<path d="M104.104 14.8059C104.347 14.8059 104.545 14.6086 104.545 14.3653C104.545 14.122 104.347 13.9247 104.104 13.9247C103.861 13.9247 103.663 14.122 103.663 14.3653C103.663 14.6086 103.861 14.8059 104.104 14.8059Z" fill="#383A48"/>
				<path d="M104.104 18.3307C104.347 18.3307 104.545 18.1334 104.545 17.8901C104.545 17.6467 104.347 17.4495 104.104 17.4495C103.861 17.4495 103.663 17.6467 103.663 17.8901C103.663 18.1334 103.861 18.3307 104.104 18.3307Z" fill="#383A48"/>
				<path d="M104.104 20.093C104.347 20.093 104.545 19.8958 104.545 19.6524C104.545 19.4091 104.347 19.2119 104.104 19.2119C103.861 19.2119 103.663 19.4091 103.663 19.6524C103.663 19.8958 103.861 20.093 104.104 20.093Z" fill="#383A48"/>
				<path d="M104.104 21.8554C104.347 21.8554 104.545 21.6582 104.545 21.4148C104.545 21.1715 104.347 20.9742 104.104 20.9742C103.861 20.9742 103.663 21.1715 103.663 21.4148C103.663 21.6582 103.861 21.8554 104.104 21.8554Z" fill="#383A48"/>
				<path d="M104.104 25.3802C104.347 25.3802 104.545 25.1829 104.545 24.9396C104.545 24.6963 104.347 24.499 104.104 24.499C103.861 24.499 103.663 24.6963 103.663 24.9396C103.663 25.1829 103.861 25.3802 104.104 25.3802Z" fill="#383A48"/>
				<path d="M104.104 27.1425C104.347 27.1425 104.545 26.9453 104.545 26.7019C104.545 26.4586 104.347 26.2614 104.104 26.2614C103.861 26.2614 103.663 26.4586 103.663 26.7019C103.663 26.9453 103.861 27.1425 104.104 27.1425Z" fill="#383A48"/>
				<path d="M104.104 32.4297C104.347 32.4297 104.545 32.2324 104.545 31.9891C104.545 31.7458 104.347 31.5485 104.104 31.5485C103.861 31.5485 103.663 31.7458 103.663 31.9891C103.663 32.2324 103.861 32.4297 104.104 32.4297Z" fill="#383A48"/>
				<path d="M104.104 34.192C104.347 34.192 104.545 33.9948 104.545 33.7514C104.545 33.5081 104.347 33.3109 104.104 33.3109C103.861 33.3109 103.663 33.5081 103.663 33.7514C103.663 33.9948 103.861 34.192 104.104 34.192Z" fill="#383A48"/>
				<path d="M104.104 37.7168C104.347 37.7168 104.545 37.3223 104.545 36.8356C104.545 36.349 104.347 35.9544 104.104 35.9544C103.861 35.9544 103.663 36.349 103.663 36.8356C103.663 37.3223 103.861 37.7168 104.104 37.7168Z" fill="#383A48"/>
				<path d="M104.104 39.4792C104.347 39.4792 104.545 39.2819 104.545 39.0386C104.545 38.7953 104.347 38.598 104.104 38.598C103.861 38.598 103.663 38.7953 103.663 39.0386C103.663 39.2819 103.861 39.4792 104.104 39.4792Z" fill="#383A48"/>
				<path d="M104.104 41.2415C104.347 41.2415 104.545 41.0443 104.545 40.8009C104.545 40.5576 104.347 40.3604 104.104 40.3604C103.861 40.3604 103.663 40.5576 103.663 40.8009C103.663 41.0443 103.861 41.2415 104.104 41.2415Z" fill="#383A48"/>
				<path d="M104.104 44.7663C104.347 44.7663 104.545 44.5691 104.545 44.3257C104.545 44.0824 104.347 43.8851 104.104 43.8851C103.861 43.8851 103.663 44.0824 103.663 44.3257C103.663 44.5691 103.861 44.7663 104.104 44.7663Z" fill="#383A48"/>
				<path d="M104.104 46.5287C104.347 46.5287 104.545 46.3314 104.545 46.0881C104.545 45.8448 104.347 45.6475 104.104 45.6475C103.861 45.6475 103.663 45.8448 103.663 46.0881C103.663 46.3314 103.861 46.5287 104.104 46.5287Z" fill="#383A48"/>
				<path d="M104.104 48.2911C104.347 48.2911 104.545 48.0938 104.545 47.8505C104.545 47.6071 104.347 47.4099 104.104 47.4099C103.861 47.4099 103.663 47.6071 103.663 47.8505C103.663 48.0938 103.861 48.2911 104.104 48.2911Z" fill="#383A48"/>
				<path d="M104.104 51.8158C104.347 51.8158 104.545 51.6186 104.545 51.3752C104.545 51.1319 104.347 50.9346 104.104 50.9346C103.861 50.9346 103.663 51.1319 103.663 51.3752C103.663 51.6186 103.861 51.8158 104.104 51.8158Z" fill="#383A48"/>
				<path d="M104.104 53.5782C104.347 53.5782 104.545 53.3809 104.545 53.1376C104.545 52.8943 104.347 52.697 104.104 52.697C103.861 52.697 103.663 52.8943 103.663 53.1376C103.663 53.3809 103.861 53.5782 104.104 53.5782Z" fill="#383A48"/>
				<path d="M104.104 55.3406C104.347 55.3406 104.545 55.1433 104.545 54.9C104.545 54.6566 104.347 54.4594 104.104 54.4594C103.861 54.4594 103.663 54.6566 103.663 54.9C103.663 55.1433 103.861 55.3406 104.104 55.3406Z" fill="#383A48"/>
				<path d="M104.104 58.8653C104.347 58.8653 104.545 58.6681 104.545 58.4247C104.545 58.1814 104.347 57.9841 104.104 57.9841C103.861 57.9841 103.663 58.1814 103.663 58.4247C103.663 58.6681 103.861 58.8653 104.104 58.8653Z" fill="#383A48"/>
				<path d="M104.104 60.6277C104.347 60.6277 104.545 60.4304 104.545 60.1871C104.545 59.9438 104.347 59.7465 104.104 59.7465C103.861 59.7465 103.663 59.9438 103.663 60.1871C103.663 60.4304 103.861 60.6277 104.104 60.6277Z" fill="#383A48"/>
				<path d="M104.104 65.9148C104.347 65.9148 104.545 65.7176 104.545 65.4742C104.545 65.2309 104.347 65.0336 104.104 65.0336C103.861 65.0336 103.663 65.2309 103.663 65.4742C103.663 65.7176 103.861 65.9148 104.104 65.9148Z" fill="#383A48"/>
				<path d="M104.104 67.6772C104.347 67.6772 104.545 67.4799 104.545 67.2366C104.545 66.9933 104.347 66.796 104.104 66.796C103.861 66.796 103.663 66.9933 103.663 67.2366C103.663 67.4799 103.861 67.6772 104.104 67.6772Z" fill="#383A48"/>
				<path d="M104.104 71.202C104.347 71.202 104.545 71.0047 104.545 70.7614C104.545 70.518 104.347 70.3208 104.104 70.3208C103.861 70.3208 103.663 70.518 103.663 70.7614C103.663 71.0047 103.861 71.202 104.104 71.202Z" fill="#383A48"/>
				<path d="M104.104 72.9643C104.347 72.9643 104.545 72.7671 104.545 72.5237C104.545 72.2804 104.347 72.0831 104.104 72.0831C103.861 72.0831 103.663 72.2804 103.663 72.5237C103.663 72.7671 103.861 72.9643 104.104 72.9643Z" fill="#383A48"/>
				<path d="M104.104 74.7267C104.347 74.7267 104.545 74.5294 104.545 74.2861C104.545 74.0428 104.347 73.8455 104.104 73.8455C103.861 73.8455 103.663 74.0428 103.663 74.2861C103.663 74.5294 103.861 74.7267 104.104 74.7267Z" fill="#383A48"/>
				<path d="M102.342 13.0435C102.585 13.0435 102.782 12.8463 102.782 12.6029C102.782 12.3596 102.585 12.1624 102.342 12.1624C102.098 12.1624 101.901 12.3596 101.901 12.6029C101.901 12.8463 102.098 13.0435 102.342 13.0435Z" fill="#383A48"/>
				<path d="M102.342 14.8059C102.585 14.8059 102.782 14.6086 102.782 14.3653C102.782 14.122 102.585 13.9247 102.342 13.9247C102.098 13.9247 101.901 14.122 101.901 14.3653C101.901 14.6086 102.098 14.8059 102.342 14.8059Z" fill="#383A48"/>
				<path d="M102.342 18.3307C102.585 18.3307 102.782 18.1334 102.782 17.8901C102.782 17.6467 102.585 17.4495 102.342 17.4495C102.098 17.4495 101.901 17.6467 101.901 17.8901C101.901 18.1334 102.098 18.3307 102.342 18.3307Z" fill="#383A48"/>
				<path d="M102.342 20.093C102.585 20.093 102.782 19.8958 102.782 19.6524C102.782 19.4091 102.585 19.2119 102.342 19.2119C102.098 19.2119 101.901 19.4091 101.901 19.6524C101.901 19.8958 102.098 20.093 102.342 20.093Z" fill="#383A48"/>
				<path d="M102.342 21.8554C102.585 21.8554 102.782 21.6582 102.782 21.4148C102.782 21.1715 102.585 20.9742 102.342 20.9742C102.098 20.9742 101.901 21.1715 101.901 21.4148C101.901 21.6582 102.098 21.8554 102.342 21.8554Z" fill="#383A48"/>
				<path d="M102.342 25.3802C102.585 25.3802 102.782 25.1829 102.782 24.9396C102.782 24.6963 102.585 24.499 102.342 24.499C102.098 24.499 101.901 24.6963 101.901 24.9396C101.901 25.1829 102.098 25.3802 102.342 25.3802Z" fill="#383A48"/>
				<path d="M102.342 27.1425C102.585 27.1425 102.782 26.9453 102.782 26.7019C102.782 26.4586 102.585 26.2614 102.342 26.2614C102.098 26.2614 101.901 26.4586 101.901 26.7019C101.901 26.9453 102.098 27.1425 102.342 27.1425Z" fill="#383A48"/>
				<path d="M102.342 32.4297C102.585 32.4297 102.782 32.2324 102.782 31.9891C102.782 31.7458 102.585 31.5485 102.342 31.5485C102.098 31.5485 101.901 31.7458 101.901 31.9891C101.901 32.2324 102.098 32.4297 102.342 32.4297Z" fill="#383A48"/>
				<path d="M102.342 34.192C102.585 34.192 102.782 33.9948 102.782 33.7514C102.782 33.5081 102.585 33.3109 102.342 33.3109C102.098 33.3109 101.901 33.5081 101.901 33.7514C101.901 33.9948 102.098 34.192 102.342 34.192Z" fill="#383A48"/>
				<path d="M102.342 37.7168C102.585 37.7168 102.782 37.3223 102.782 36.8356C102.782 36.349 102.585 35.9544 102.342 35.9544C102.098 35.9544 101.901 36.349 101.901 36.8356C101.901 37.3223 102.098 37.7168 102.342 37.7168Z" fill="#383A48"/>
				<path d="M102.342 39.4792C102.585 39.4792 102.782 39.2819 102.782 39.0386C102.782 38.7953 102.585 38.598 102.342 38.598C102.098 38.598 101.901 38.7953 101.901 39.0386C101.901 39.2819 102.098 39.4792 102.342 39.4792Z" fill="#383A48"/>
				<path d="M102.342 41.2415C102.585 41.2415 102.782 41.0443 102.782 40.8009C102.782 40.5576 102.585 40.3604 102.342 40.3604C102.098 40.3604 101.901 40.5576 101.901 40.8009C101.901 41.0443 102.098 41.2415 102.342 41.2415Z" fill="#383A48"/>
				<path d="M102.342 44.7663C102.585 44.7663 102.782 44.5691 102.782 44.3257C102.782 44.0824 102.585 43.8851 102.342 43.8851C102.098 43.8851 101.901 44.0824 101.901 44.3257C101.901 44.5691 102.098 44.7663 102.342 44.7663Z" fill="#383A48"/>
				<path d="M102.342 46.5287C102.585 46.5287 102.782 46.3314 102.782 46.0881C102.782 45.8448 102.585 45.6475 102.342 45.6475C102.098 45.6475 101.901 45.8448 101.901 46.0881C101.901 46.3314 102.098 46.5287 102.342 46.5287Z" fill="#383A48"/>
				<path d="M102.342 48.2911C102.585 48.2911 102.782 48.0938 102.782 47.8505C102.782 47.6071 102.585 47.4099 102.342 47.4099C102.098 47.4099 101.901 47.6071 101.901 47.8505C101.901 48.0938 102.098 48.2911 102.342 48.2911Z" fill="#383A48"/>
				<path d="M102.342 51.8158C102.585 51.8158 102.782 51.6186 102.782 51.3752C102.782 51.1319 102.585 50.9346 102.342 50.9346C102.098 50.9346 101.901 51.1319 101.901 51.3752C101.901 51.6186 102.098 51.8158 102.342 51.8158Z" fill="#383A48"/>
				<path d="M102.342 53.5782C102.585 53.5782 102.782 53.3809 102.782 53.1376C102.782 52.8943 102.585 52.697 102.342 52.697C102.098 52.697 101.901 52.8943 101.901 53.1376C101.901 53.3809 102.098 53.5782 102.342 53.5782Z" fill="#383A48"/>
				<path d="M102.342 55.3406C102.585 55.3406 102.782 55.1433 102.782 54.9C102.782 54.6566 102.585 54.4594 102.342 54.4594C102.098 54.4594 101.901 54.6566 101.901 54.9C101.901 55.1433 102.098 55.3406 102.342 55.3406Z" fill="#383A48"/>
				<path d="M102.342 58.8653C102.585 58.8653 102.782 58.6681 102.782 58.4247C102.782 58.1814 102.585 57.9841 102.342 57.9841C102.098 57.9841 101.901 58.1814 101.901 58.4247C101.901 58.6681 102.098 58.8653 102.342 58.8653Z" fill="#383A48"/>
				<path d="M102.342 60.6277C102.585 60.6277 102.782 60.4304 102.782 60.1871C102.782 59.9438 102.585 59.7465 102.342 59.7465C102.098 59.7465 101.901 59.9438 101.901 60.1871C101.901 60.4304 102.098 60.6277 102.342 60.6277Z" fill="#383A48"/>
				<path d="M102.342 65.9148C102.585 65.9148 102.782 65.7176 102.782 65.4742C102.782 65.2309 102.585 65.0336 102.342 65.0336C102.098 65.0336 101.901 65.2309 101.901 65.4742C101.901 65.7176 102.098 65.9148 102.342 65.9148Z" fill="#383A48"/>
				<path d="M102.342 67.6772C102.585 67.6772 102.782 67.4799 102.782 67.2366C102.782 66.9933 102.585 66.796 102.342 66.796C102.098 66.796 101.901 66.9933 101.901 67.2366C101.901 67.4799 102.098 67.6772 102.342 67.6772Z" fill="#383A48"/>
				<path d="M102.342 71.202C102.585 71.202 102.782 71.0047 102.782 70.7614C102.782 70.518 102.585 70.3208 102.342 70.3208C102.098 70.3208 101.901 70.518 101.901 70.7614C101.901 71.0047 102.098 71.202 102.342 71.202Z" fill="#383A48"/>
				<path d="M102.342 72.9643C102.585 72.9643 102.782 72.7671 102.782 72.5237C102.782 72.2804 102.585 72.0831 102.342 72.0831C102.098 72.0831 101.901 72.2804 101.901 72.5237C101.901 72.7671 102.098 72.9643 102.342 72.9643Z" fill="#383A48"/>
				<path d="M102.342 74.7267C102.585 74.7267 102.782 74.5294 102.782 74.2861C102.782 74.0428 102.585 73.8455 102.342 73.8455C102.098 73.8455 101.901 74.0428 101.901 74.2861C101.901 74.5294 102.098 74.7267 102.342 74.7267Z" fill="#383A48"/>
				<path d="M97.0545 13.0435C97.2978 13.0435 97.4951 12.8463 97.4951 12.6029C97.4951 12.3596 97.2978 12.1624 97.0545 12.1624C96.8112 12.1624 96.6139 12.3596 96.6139 12.6029C96.6139 12.8463 96.8112 13.0435 97.0545 13.0435Z" fill="#383A48"/>
				<path d="M97.0545 14.8059C97.2978 14.8059 97.4951 14.6086 97.4951 14.3653C97.4951 14.122 97.2978 13.9247 97.0545 13.9247C96.8112 13.9247 96.6139 14.122 96.6139 14.3653C96.6139 14.6086 96.8112 14.8059 97.0545 14.8059Z" fill="#383A48"/>
				<path d="M97.0545 18.3307C97.2978 18.3307 97.4951 18.1334 97.4951 17.8901C97.4951 17.6467 97.2978 17.4495 97.0545 17.4495C96.8112 17.4495 96.6139 17.6467 96.6139 17.8901C96.6139 18.1334 96.8112 18.3307 97.0545 18.3307Z" fill="#383A48"/>
				<path d="M97.0545 20.093C97.2978 20.093 97.4951 19.8958 97.4951 19.6524C97.4951 19.4091 97.2978 19.2119 97.0545 19.2119C96.8112 19.2119 96.6139 19.4091 96.6139 19.6524C96.6139 19.8958 96.8112 20.093 97.0545 20.093Z" fill="#383A48"/>
				<path d="M97.0545 21.8554C97.2978 21.8554 97.4951 21.6582 97.4951 21.4148C97.4951 21.1715 97.2978 20.9742 97.0545 20.9742C96.8112 20.9742 96.6139 21.1715 96.6139 21.4148C96.6139 21.6582 96.8112 21.8554 97.0545 21.8554Z" fill="#383A48"/>
				<path d="M97.0545 25.3802C97.2978 25.3802 97.4951 25.1829 97.4951 24.9396C97.4951 24.6963 97.2978 24.499 97.0545 24.499C96.8112 24.499 96.6139 24.6963 96.6139 24.9396C96.6139 25.1829 96.8112 25.3802 97.0545 25.3802Z" fill="#383A48"/>
				<path d="M97.0545 27.1425C97.2978 27.1425 97.4951 26.9453 97.4951 26.7019C97.4951 26.4586 97.2978 26.2614 97.0545 26.2614C96.8112 26.2614 96.6139 26.4586 96.6139 26.7019C96.6139 26.9453 96.8112 27.1425 97.0545 27.1425Z" fill="#383A48"/>
				<path d="M97.0545 32.4297C97.2978 32.4297 97.4951 32.2324 97.4951 31.9891C97.4951 31.7458 97.2978 31.5485 97.0545 31.5485C96.8112 31.5485 96.6139 31.7458 96.6139 31.9891C96.6139 32.2324 96.8112 32.4297 97.0545 32.4297Z" fill="#383A48"/>
				<path d="M97.0545 34.192C97.2978 34.192 97.4951 33.9948 97.4951 33.7514C97.4951 33.5081 97.2978 33.3109 97.0545 33.3109C96.8112 33.3109 96.6139 33.5081 96.6139 33.7514C96.6139 33.9948 96.8112 34.192 97.0545 34.192Z" fill="#383A48"/>
				<path d="M97.0545 37.7168C97.2978 37.7168 97.4951 37.3223 97.4951 36.8356C97.4951 36.349 97.2978 35.9544 97.0545 35.9544C96.8112 35.9544 96.6139 36.349 96.6139 36.8356C96.6139 37.3223 96.8112 37.7168 97.0545 37.7168Z" fill="#383A48"/>
				<path d="M97.0545 39.4792C97.2978 39.4792 97.4951 39.2819 97.4951 39.0386C97.4951 38.7953 97.2978 38.598 97.0545 38.598C96.8112 38.598 96.6139 38.7953 96.6139 39.0386C96.6139 39.2819 96.8112 39.4792 97.0545 39.4792Z" fill="#383A48"/>
				<path d="M97.0545 41.2415C97.2978 41.2415 97.4951 41.0443 97.4951 40.8009C97.4951 40.5576 97.2978 40.3604 97.0545 40.3604C96.8112 40.3604 96.6139 40.5576 96.6139 40.8009C96.6139 41.0443 96.8112 41.2415 97.0545 41.2415Z" fill="#383A48"/>
				<path d="M97.0545 44.7663C97.2978 44.7663 97.4951 44.5691 97.4951 44.3257C97.4951 44.0824 97.2978 43.8851 97.0545 43.8851C96.8112 43.8851 96.6139 44.0824 96.6139 44.3257C96.6139 44.5691 96.8112 44.7663 97.0545 44.7663Z" fill="#383A48"/>
				<path d="M97.0545 46.5287C97.2978 46.5287 97.4951 46.3314 97.4951 46.0881C97.4951 45.8448 97.2978 45.6475 97.0545 45.6475C96.8112 45.6475 96.6139 45.8448 96.6139 46.0881C96.6139 46.3314 96.8112 46.5287 97.0545 46.5287Z" fill="#383A48"/>
				<path d="M97.0545 48.2911C97.2978 48.2911 97.4951 48.0938 97.4951 47.8505C97.4951 47.6071 97.2978 47.4099 97.0545 47.4099C96.8112 47.4099 96.6139 47.6071 96.6139 47.8505C96.6139 48.0938 96.8112 48.2911 97.0545 48.2911Z" fill="#383A48"/>
				<path d="M97.0545 51.8158C97.2978 51.8158 97.4951 51.6186 97.4951 51.3752C97.4951 51.1319 97.2978 50.9346 97.0545 50.9346C96.8112 50.9346 96.6139 51.1319 96.6139 51.3752C96.6139 51.6186 96.8112 51.8158 97.0545 51.8158Z" fill="#383A48"/>
				<path d="M97.0545 53.5782C97.2978 53.5782 97.4951 53.3809 97.4951 53.1376C97.4951 52.8943 97.2978 52.697 97.0545 52.697C96.8112 52.697 96.6139 52.8943 96.6139 53.1376C96.6139 53.3809 96.8112 53.5782 97.0545 53.5782Z" fill="#383A48"/>
				<path d="M97.0545 55.3406C97.2978 55.3406 97.4951 55.1433 97.4951 54.9C97.4951 54.6566 97.2978 54.4594 97.0545 54.4594C96.8112 54.4594 96.6139 54.6566 96.6139 54.9C96.6139 55.1433 96.8112 55.3406 97.0545 55.3406Z" fill="#383A48"/>
				<path d="M97.0545 58.8653C97.2978 58.8653 97.4951 58.6681 97.4951 58.4247C97.4951 58.1814 97.2978 57.9841 97.0545 57.9841C96.8112 57.9841 96.6139 58.1814 96.6139 58.4247C96.6139 58.6681 96.8112 58.8653 97.0545 58.8653Z" fill="#383A48"/>
				<path d="M97.0545 60.6277C97.2978 60.6277 97.4951 60.4304 97.4951 60.1871C97.4951 59.9438 97.2978 59.7465 97.0545 59.7465C96.8112 59.7465 96.6139 59.9438 96.6139 60.1871C96.6139 60.4304 96.8112 60.6277 97.0545 60.6277Z" fill="#383A48"/>
				<path d="M97.0545 65.9148C97.2978 65.9148 97.4951 65.7176 97.4951 65.4742C97.4951 65.2309 97.2978 65.0336 97.0545 65.0336C96.8112 65.0336 96.6139 65.2309 96.6139 65.4742C96.6139 65.7176 96.8112 65.9148 97.0545 65.9148Z" fill="#383A48"/>
				<path d="M97.0545 67.6772C97.2978 67.6772 97.4951 67.4799 97.4951 67.2366C97.4951 66.9933 97.2978 66.796 97.0545 66.796C96.8112 66.796 96.6139 66.9933 96.6139 67.2366C96.6139 67.4799 96.8112 67.6772 97.0545 67.6772Z" fill="#383A48"/>
				<path d="M97.0545 71.202C97.2978 71.202 97.4951 71.0047 97.4951 70.7614C97.4951 70.518 97.2978 70.3208 97.0545 70.3208C96.8112 70.3208 96.6139 70.518 96.6139 70.7614C96.6139 71.0047 96.8112 71.202 97.0545 71.202Z" fill="#383A48"/>
				<path d="M97.0545 72.9643C97.2978 72.9643 97.4951 72.7671 97.4951 72.5237C97.4951 72.2804 97.2978 72.0831 97.0545 72.0831C96.8112 72.0831 96.6139 72.2804 96.6139 72.5237C96.6139 72.7671 96.8112 72.9643 97.0545 72.9643Z" fill="#383A48"/>
				<path d="M97.0545 74.7267C97.2978 74.7267 97.4951 74.5294 97.4951 74.2861C97.4951 74.0428 97.2978 73.8455 97.0545 73.8455C96.8112 73.8455 96.6139 74.0428 96.6139 74.2861C96.6139 74.5294 96.8112 74.7267 97.0545 74.7267Z" fill="#383A48"/>
				<path d="M95.292 13.0435C95.5354 13.0435 95.7326 12.8463 95.7326 12.6029C95.7326 12.3596 95.5354 12.1624 95.292 12.1624C95.0487 12.1624 94.8514 12.3596 94.8514 12.6029C94.8514 12.8463 95.0487 13.0435 95.292 13.0435Z" fill="#383A48"/>
				<path d="M95.292 14.8059C95.5354 14.8059 95.7326 14.6086 95.7326 14.3653C95.7326 14.122 95.5354 13.9247 95.292 13.9247C95.0487 13.9247 94.8514 14.122 94.8514 14.3653C94.8514 14.6086 95.0487 14.8059 95.292 14.8059Z" fill="#383A48"/>
				<path d="M95.292 18.3307C95.5354 18.3307 95.7326 18.1334 95.7326 17.8901C95.7326 17.6467 95.5354 17.4495 95.292 17.4495C95.0487 17.4495 94.8514 17.6467 94.8514 17.8901C94.8514 18.1334 95.0487 18.3307 95.292 18.3307Z" fill="#383A48"/>
				<path d="M95.292 20.093C95.5354 20.093 95.7326 19.8958 95.7326 19.6524C95.7326 19.4091 95.5354 19.2119 95.292 19.2119C95.0487 19.2119 94.8514 19.4091 94.8514 19.6524C94.8514 19.8958 95.0487 20.093 95.292 20.093Z" fill="#383A48"/>
				<path d="M95.292 21.8554C95.5354 21.8554 95.7326 21.6582 95.7326 21.4148C95.7326 21.1715 95.5354 20.9742 95.292 20.9742C95.0487 20.9742 94.8514 21.1715 94.8514 21.4148C94.8514 21.6582 95.0487 21.8554 95.292 21.8554Z" fill="#383A48"/>
				<path d="M95.292 25.3802C95.5354 25.3802 95.7326 25.1829 95.7326 24.9396C95.7326 24.6963 95.5354 24.499 95.292 24.499C95.0487 24.499 94.8514 24.6963 94.8514 24.9396C94.8514 25.1829 95.0487 25.3802 95.292 25.3802Z" fill="#383A48"/>
				<path d="M95.292 27.1425C95.5354 27.1425 95.7326 26.9453 95.7326 26.7019C95.7326 26.4586 95.5354 26.2614 95.292 26.2614C95.0487 26.2614 94.8514 26.4586 94.8514 26.7019C94.8514 26.9453 95.0487 27.1425 95.292 27.1425Z" fill="#383A48"/>
				<path d="M95.292 32.4297C95.5354 32.4297 95.7326 32.2324 95.7326 31.9891C95.7326 31.7458 95.5354 31.5485 95.292 31.5485C95.0487 31.5485 94.8514 31.7458 94.8514 31.9891C94.8514 32.2324 95.0487 32.4297 95.292 32.4297Z" fill="#383A48"/>
				<path d="M95.292 34.192C95.5354 34.192 95.7326 33.9948 95.7326 33.7514C95.7326 33.5081 95.5354 33.3109 95.292 33.3109C95.0487 33.3109 94.8514 33.5081 94.8514 33.7514C94.8514 33.9948 95.0487 34.192 95.292 34.192Z" fill="#383A48"/>
				<path d="M95.292 37.7168C95.5354 37.7168 95.7326 37.3223 95.7326 36.8356C95.7326 36.349 95.5354 35.9544 95.292 35.9544C95.0487 35.9544 94.8514 36.349 94.8514 36.8356C94.8514 37.3223 95.0487 37.7168 95.292 37.7168Z" fill="#383A48"/>
				<path d="M95.292 39.4792C95.5354 39.4792 95.7326 39.2819 95.7326 39.0386C95.7326 38.7953 95.5354 38.598 95.292 38.598C95.0487 38.598 94.8514 38.7953 94.8514 39.0386C94.8514 39.2819 95.0487 39.4792 95.292 39.4792Z" fill="#383A48"/>
				<path d="M95.292 41.2415C95.5354 41.2415 95.7326 41.0443 95.7326 40.8009C95.7326 40.5576 95.5354 40.3604 95.292 40.3604C95.0487 40.3604 94.8514 40.5576 94.8514 40.8009C94.8514 41.0443 95.0487 41.2415 95.292 41.2415Z" fill="#383A48"/>
				<path d="M95.292 44.7663C95.5354 44.7663 95.7326 44.5691 95.7326 44.3257C95.7326 44.0824 95.5354 43.8851 95.292 43.8851C95.0487 43.8851 94.8514 44.0824 94.8514 44.3257C94.8514 44.5691 95.0487 44.7663 95.292 44.7663Z" fill="#383A48"/>
				<path d="M95.292 46.5287C95.5354 46.5287 95.7326 46.3314 95.7326 46.0881C95.7326 45.8448 95.5354 45.6475 95.292 45.6475C95.0487 45.6475 94.8514 45.8448 94.8514 46.0881C94.8514 46.3314 95.0487 46.5287 95.292 46.5287Z" fill="#383A48"/>
				<path d="M95.292 48.2911C95.5354 48.2911 95.7326 48.0938 95.7326 47.8505C95.7326 47.6071 95.5354 47.4099 95.292 47.4099C95.0487 47.4099 94.8514 47.6071 94.8514 47.8505C94.8514 48.0938 95.0487 48.2911 95.292 48.2911Z" fill="#383A48"/>
				<path d="M95.292 51.8158C95.5354 51.8158 95.7326 51.6186 95.7326 51.3752C95.7326 51.1319 95.5354 50.9346 95.292 50.9346C95.0487 50.9346 94.8514 51.1319 94.8514 51.3752C94.8514 51.6186 95.0487 51.8158 95.292 51.8158Z" fill="#383A48"/>
				<path d="M95.292 53.5782C95.5354 53.5782 95.7326 53.3809 95.7326 53.1376C95.7326 52.8943 95.5354 52.697 95.292 52.697C95.0487 52.697 94.8514 52.8943 94.8514 53.1376C94.8514 53.3809 95.0487 53.5782 95.292 53.5782Z" fill="#383A48"/>
				<path d="M95.292 55.3406C95.5354 55.3406 95.7326 55.1433 95.7326 54.9C95.7326 54.6566 95.5354 54.4594 95.292 54.4594C95.0487 54.4594 94.8514 54.6566 94.8514 54.9C94.8514 55.1433 95.0487 55.3406 95.292 55.3406Z" fill="#383A48"/>
				<path d="M95.292 58.8653C95.5354 58.8653 95.7326 58.6681 95.7326 58.4247C95.7326 58.1814 95.5354 57.9841 95.292 57.9841C95.0487 57.9841 94.8514 58.1814 94.8514 58.4247C94.8514 58.6681 95.0487 58.8653 95.292 58.8653Z" fill="#383A48"/>
				<path d="M95.292 60.6277C95.5354 60.6277 95.7326 60.4304 95.7326 60.1871C95.7326 59.9438 95.5354 59.7465 95.292 59.7465C95.0487 59.7465 94.8514 59.9438 94.8514 60.1871C94.8514 60.4304 95.0487 60.6277 95.292 60.6277Z" fill="#383A48"/>
				<path d="M95.292 65.9148C95.5354 65.9148 95.7326 65.7176 95.7326 65.4742C95.7326 65.2309 95.5354 65.0336 95.292 65.0336C95.0487 65.0336 94.8514 65.2309 94.8514 65.4742C94.8514 65.7176 95.0487 65.9148 95.292 65.9148Z" fill="#383A48"/>
				<path d="M95.292 67.6772C95.5354 67.6772 95.7326 67.4799 95.7326 67.2366C95.7326 66.9933 95.5354 66.796 95.292 66.796C95.0487 66.796 94.8514 66.9933 94.8514 67.2366C94.8514 67.4799 95.0487 67.6772 95.292 67.6772Z" fill="#383A48"/>
				<path d="M95.292 71.202C95.5354 71.202 95.7326 71.0047 95.7326 70.7614C95.7326 70.518 95.5354 70.3208 95.292 70.3208C95.0487 70.3208 94.8514 70.518 94.8514 70.7614C94.8514 71.0047 95.0487 71.202 95.292 71.202Z" fill="#383A48"/>
				<path d="M95.292 72.9643C95.5354 72.9643 95.7326 72.7671 95.7326 72.5237C95.7326 72.2804 95.5354 72.0831 95.292 72.0831C95.0487 72.0831 94.8514 72.2804 94.8514 72.5237C94.8514 72.7671 95.0487 72.9643 95.292 72.9643Z" fill="#383A48"/>
				<path d="M95.292 74.7267C95.5354 74.7267 95.7326 74.5294 95.7326 74.2861C95.7326 74.0428 95.5354 73.8455 95.292 73.8455C95.0487 73.8455 94.8514 74.0428 94.8514 74.2861C94.8514 74.5294 95.0487 74.7267 95.292 74.7267Z" fill="#383A48"/>
				<path d="M97.0544 74.7267C114.331 74.7267 128.337 60.7212 128.337 43.4445C128.337 26.1679 114.331 12.1624 97.0544 12.1624C79.7777 12.1624 65.7722 26.1679 65.7722 43.4445C65.7722 60.7212 79.7777 74.7267 97.0544 74.7267Z" fill="#F6E049"/>
				<path d="M97.0545 74.7267C88.5252 74.7236 80.3462 71.4268 74.3151 65.5609C68.284 59.6951 64.8943 51.7401 64.8911 43.4445C64.8943 35.1489 68.284 27.194 74.3151 21.3281C80.3462 15.4623 88.5252 12.1655 97.0545 12.1624C105.584 12.1655 113.763 15.4623 119.794 21.3281C125.825 27.194 129.215 35.1489 129.218 43.4445C129.215 51.7401 125.825 59.6951 119.794 65.5609C113.763 71.4268 105.584 74.7236 97.0545 74.7267ZM97.0545 13.1181C90.8876 13.1191 84.8594 14.8983 79.732 18.2309C74.6046 21.5635 70.6083 26.2999 68.2481 31.8412C65.888 37.3825 65.27 43.48 66.4723 49.3628C67.6746 55.2457 70.6433 60.6497 75.003 64.8919C80.8549 70.5638 88.7817 73.7455 97.0433 73.7385C105.305 73.7316 113.226 70.5365 119.068 64.8548C124.91 59.173 128.195 51.4689 128.202 43.4336C128.209 35.3984 124.938 27.6888 119.106 21.9972C116.215 19.1741 112.778 16.936 108.994 15.412C105.209 13.888 101.151 13.1084 97.0545 13.1181Z" fill="#383A48"/>
				<path d="M97.0544 67.6772C110.438 67.6772 121.287 57.0251 121.287 43.8851C121.287 30.7451 110.438 20.093 97.0544 20.093C83.6711 20.093 72.8218 30.7451 72.8218 43.8851C72.8218 57.0251 83.6711 67.6772 97.0544 67.6772Z" fill="#E0719E"/>
				<path d="M30.5601 67.9336H1.04932V75.3523H30.5601V67.9336Z" fill="#E0719E"/>
				<path d="M30.5621 75.8434H1.05133C0.987203 75.844 0.923592 75.8318 0.864221 75.8074C0.804851 75.783 0.750913 75.7469 0.705565 75.7013C0.660218 75.6556 0.624371 75.6014 0.600124 75.5416C0.575877 75.4819 0.563717 75.4179 0.564355 75.3534V67.9346C0.563717 67.8701 0.575877 67.8061 0.600124 67.7464C0.624371 67.6866 0.660218 67.6324 0.705565 67.5867C0.750913 67.5411 0.804851 67.505 0.864221 67.4806C0.923592 67.4562 0.987203 67.444 1.05133 67.4446H30.5621C30.6262 67.444 30.6898 67.4562 30.7492 67.4806C30.8086 67.505 30.8625 67.5411 30.9079 67.5867C30.9532 67.6324 30.989 67.6866 31.0133 67.7464C31.0375 67.8061 31.0497 67.8701 31.0491 67.9346V75.3534C31.0487 75.4832 30.9972 75.6076 30.906 75.6994C30.8148 75.7912 30.6911 75.843 30.5621 75.8434ZM1.53831 74.8634H30.0751V68.4247H1.53831V74.8634Z" fill="#383A48"/>
				<path d="M30.5601 60.5153H1.04932V67.934H30.5601V60.5153Z" fill="#E0719E"/>
				<path d="M30.5621 68.4252H1.05133C0.987203 68.4258 0.923592 68.4136 0.864221 68.3892C0.804851 68.3648 0.750913 68.3287 0.705565 68.2831C0.660218 68.2374 0.624371 68.1832 0.600124 68.1234C0.575877 68.0637 0.563717 67.9997 0.564355 67.9352V60.5164C0.563717 60.4519 0.575877 60.3879 0.600124 60.3282C0.624371 60.2684 0.660218 60.2141 0.705565 60.1685C0.750913 60.1229 0.804851 60.0868 0.864221 60.0624C0.923592 60.038 0.987203 60.0258 1.05133 60.0264H30.5621C30.6262 60.0258 30.6898 60.038 30.7492 60.0624C30.8086 60.0868 30.8625 60.1229 30.9079 60.1685C30.9532 60.2141 30.989 60.2684 31.0133 60.3282C31.0375 60.3879 31.0497 60.4519 31.0491 60.5164V67.9352C31.0487 68.065 30.9972 68.1894 30.906 68.2812C30.8148 68.373 30.6911 68.4248 30.5621 68.4252ZM1.53831 67.4451H30.0751V61.0064H1.53831V67.4451Z" fill="#383A48"/>
				<path d="M30.5601 53.097H1.04932V60.5157H30.5601V53.097Z" fill="#E0719E"/>
				<path d="M30.5621 61.0067H1.05133C0.987203 61.0074 0.923592 60.9951 0.864221 60.9707C0.804851 60.9463 0.750913 60.9103 0.705565 60.8646C0.660218 60.819 0.624371 60.7647 0.600124 60.705C0.575877 60.6453 0.563717 60.5813 0.564355 60.5167V53.098C0.563717 53.0335 0.575877 52.9695 0.600124 52.9098C0.624371 52.85 0.660218 52.7957 0.705565 52.7501C0.750913 52.7045 0.804851 52.6684 0.864221 52.644C0.923592 52.6196 0.987203 52.6074 1.05133 52.608H30.5621C30.6262 52.6074 30.6898 52.6196 30.7492 52.644C30.8086 52.6684 30.8625 52.7045 30.9079 52.7501C30.9532 52.7957 30.9891 52.85 31.0133 52.9098C31.0376 52.9695 31.0497 53.0335 31.0491 53.098V60.5167C31.0487 60.6466 30.9973 60.771 30.906 60.8628C30.8148 60.9546 30.6911 61.0064 30.5621 61.0067ZM1.53831 60.0267H30.0751V53.588H1.53831V60.0267Z" fill="#383A48"/>
				<path d="M28.9833 63.5069C29.2523 63.5069 29.4703 63.2875 29.4703 63.0169C29.4703 62.7463 29.2523 62.5269 28.9833 62.5269C28.7144 62.5269 28.4963 62.7463 28.4963 63.0169C28.4963 63.2875 28.7144 63.5069 28.9833 63.5069Z" fill="#383A48"/>
				<path d="M28.9833 65.928C29.2523 65.928 29.4703 65.7086 29.4703 65.438C29.4703 65.1674 29.2523 64.948 28.9833 64.948C28.7144 64.948 28.4963 65.1674 28.4963 65.438C28.4963 65.7086 28.7144 65.928 28.9833 65.928Z" fill="#383A48"/>
				<path d="M26.5821 63.5069C26.851 63.5069 27.0691 63.2875 27.0691 63.0169C27.0691 62.7463 26.851 62.5269 26.5821 62.5269C26.3131 62.5269 26.0951 62.7463 26.0951 63.0169C26.0951 63.2875 26.3131 63.5069 26.5821 63.5069Z" fill="#383A48"/>
				<path d="M26.5821 65.928C26.851 65.928 27.0691 65.7086 27.0691 65.438C27.0691 65.1674 26.851 64.948 26.5821 64.948C26.3131 64.948 26.0951 65.1674 26.0951 65.438C26.0951 65.7086 26.3131 65.928 26.5821 65.928Z" fill="#383A48"/>
				<path d="M24.1729 63.5069C24.4418 63.5069 24.6599 63.2875 24.6599 63.0169C24.6599 62.7463 24.4418 62.5269 24.1729 62.5269C23.9039 62.5269 23.6859 62.7463 23.6859 63.0169C23.6859 63.2875 23.9039 63.5069 24.1729 63.5069Z" fill="#383A48"/>
				<path d="M24.1729 65.928C24.4418 65.928 24.6599 65.7086 24.6599 65.438C24.6599 65.1674 24.4418 64.948 24.1729 64.948C23.9039 64.948 23.6859 65.1674 23.6859 65.438C23.6859 65.7086 23.9039 65.928 24.1729 65.928Z" fill="#383A48"/>
				<path d="M21.7797 63.5069C22.0486 63.5069 22.2667 63.2875 22.2667 63.0169C22.2667 62.7463 22.0486 62.5269 21.7797 62.5269C21.5108 62.5269 21.2927 62.7463 21.2927 63.0169C21.2927 63.2875 21.5108 63.5069 21.7797 63.5069Z" fill="#383A48"/>
				<path d="M21.7797 65.928C22.0486 65.928 22.2667 65.7086 22.2667 65.438C22.2667 65.1674 22.0486 64.948 21.7797 64.948C21.5108 64.948 21.2927 65.1674 21.2927 65.438C21.2927 65.7086 21.5108 65.928 21.7797 65.928Z" fill="#383A48"/>
				<path d="M19.3705 63.5069C19.6395 63.5069 19.8575 63.2875 19.8575 63.0169C19.8575 62.7463 19.6395 62.5269 19.3705 62.5269C19.1016 62.5269 18.8835 62.7463 18.8835 63.0169C18.8835 63.2875 19.1016 63.5069 19.3705 63.5069Z" fill="#383A48"/>
				<path d="M19.3705 65.928C19.6395 65.928 19.8575 65.7086 19.8575 65.438C19.8575 65.1674 19.6395 64.948 19.3705 64.948C19.1016 64.948 18.8835 65.1674 18.8835 65.438C18.8835 65.7086 19.1016 65.928 19.3705 65.928Z" fill="#383A48"/>
				<path d="M16.9693 63.5069C17.2382 63.5069 17.4563 63.2875 17.4563 63.0169C17.4563 62.7463 17.2382 62.5269 16.9693 62.5269C16.7003 62.5269 16.4823 62.7463 16.4823 63.0169C16.4823 63.2875 16.7003 63.5069 16.9693 63.5069Z" fill="#383A48"/>
				<path d="M16.9693 65.928C17.2382 65.928 17.4563 65.7086 17.4563 65.438C17.4563 65.1674 17.2382 64.948 16.9693 64.948C16.7003 64.948 16.4823 65.1674 16.4823 65.438C16.4823 65.7086 16.7003 65.928 16.9693 65.928Z" fill="#383A48"/>
				<path d="M14.568 63.5069C14.837 63.5069 15.055 63.2875 15.055 63.0169C15.055 62.7463 14.837 62.5269 14.568 62.5269C14.2991 62.5269 14.0811 62.7463 14.0811 63.0169C14.0811 63.2875 14.2991 63.5069 14.568 63.5069Z" fill="#383A48"/>
				<path d="M14.568 65.928C14.837 65.928 15.055 65.7086 15.055 65.438C15.055 65.1674 14.837 64.948 14.568 64.948C14.2991 64.948 14.0811 65.1674 14.0811 65.438C14.0811 65.7086 14.2991 65.928 14.568 65.928Z" fill="#383A48"/>
				<path d="M12.1669 63.5069C12.4359 63.5069 12.6539 63.2875 12.6539 63.0169C12.6539 62.7463 12.4359 62.5269 12.1669 62.5269C11.898 62.5269 11.6799 62.7463 11.6799 63.0169C11.6799 63.2875 11.898 63.5069 12.1669 63.5069Z" fill="#383A48"/>
				<path d="M12.1669 65.928C12.4359 65.928 12.6539 65.7086 12.6539 65.438C12.6539 65.1674 12.4359 64.948 12.1669 64.948C11.898 64.948 11.6799 65.1674 11.6799 65.438C11.6799 65.7086 11.898 65.928 12.1669 65.928Z" fill="#383A48"/>
				<path d="M9.75773 63.5069C10.0267 63.5069 10.2447 63.2875 10.2447 63.0169C10.2447 62.7463 10.0267 62.5269 9.75773 62.5269C9.48878 62.5269 9.27075 62.7463 9.27075 63.0169C9.27075 63.2875 9.48878 63.5069 9.75773 63.5069Z" fill="#383A48"/>
				<path d="M9.75773 65.928C10.0267 65.928 10.2447 65.7086 10.2447 65.438C10.2447 65.1674 10.0267 64.948 9.75773 64.948C9.48878 64.948 9.27075 65.1674 9.27075 65.438C9.27075 65.7086 9.48878 65.928 9.75773 65.928Z" fill="#383A48"/>
				<path d="M7.36442 63.5069C7.63337 63.5069 7.85139 63.2875 7.85139 63.0169C7.85139 62.7463 7.63337 62.5269 7.36442 62.5269C7.09547 62.5269 6.87744 62.7463 6.87744 63.0169C6.87744 63.2875 7.09547 63.5069 7.36442 63.5069Z" fill="#383A48"/>
				<path d="M7.36442 65.928C7.63337 65.928 7.85139 65.7086 7.85139 65.438C7.85139 65.1674 7.63337 64.948 7.36442 64.948C7.09547 64.948 6.87744 65.1674 6.87744 65.438C6.87744 65.7086 7.09547 65.928 7.36442 65.928Z" fill="#383A48"/>
				<path d="M4.95524 63.5069C5.22419 63.5069 5.44221 63.2875 5.44221 63.0169C5.44221 62.7463 5.22419 62.5269 4.95524 62.5269C4.68629 62.5269 4.46826 62.7463 4.46826 63.0169C4.46826 63.2875 4.68629 63.5069 4.95524 63.5069Z" fill="#383A48"/>
				<path d="M4.95524 65.928C5.22419 65.928 5.44221 65.7086 5.44221 65.438C5.44221 65.1674 5.22419 64.948 4.95524 64.948C4.68629 64.948 4.46826 65.1674 4.46826 65.438C4.46826 65.7086 4.68629 65.928 4.95524 65.928Z" fill="#383A48"/>
				<path d="M2.55411 63.5069C2.82306 63.5069 3.04109 63.2875 3.04109 63.0169C3.04109 62.7463 2.82306 62.5269 2.55411 62.5269C2.28517 62.5269 2.06714 62.7463 2.06714 63.0169C2.06714 63.2875 2.28517 63.5069 2.55411 63.5069Z" fill="#383A48"/>
				<path d="M2.55411 65.928C2.82306 65.928 3.04109 65.7086 3.04109 65.438C3.04109 65.1674 2.82306 64.948 2.55411 64.948C2.28517 64.948 2.06714 65.1674 2.06714 65.438C2.06714 65.7086 2.28517 65.928 2.55411 65.928Z" fill="#383A48"/>
				<path d="M28.9833 56.1165C29.2523 56.1165 29.4703 55.8971 29.4703 55.6264C29.4703 55.3558 29.2523 55.1364 28.9833 55.1364C28.7144 55.1364 28.4963 55.3558 28.4963 55.6264C28.4963 55.8971 28.7144 56.1165 28.9833 56.1165Z" fill="#383A48"/>
				<path d="M28.9833 58.5375C29.2523 58.5375 29.4703 58.3181 29.4703 58.0475C29.4703 57.7769 29.2523 57.5575 28.9833 57.5575C28.7144 57.5575 28.4963 57.7769 28.4963 58.0475C28.4963 58.3181 28.7144 58.5375 28.9833 58.5375Z" fill="#383A48"/>
				<path d="M26.5821 56.1165C26.851 56.1165 27.0691 55.8971 27.0691 55.6264C27.0691 55.3558 26.851 55.1364 26.5821 55.1364C26.3131 55.1364 26.0951 55.3558 26.0951 55.6264C26.0951 55.8971 26.3131 56.1165 26.5821 56.1165Z" fill="#383A48"/>
				<path d="M26.5821 58.5375C26.851 58.5375 27.0691 58.3181 27.0691 58.0475C27.0691 57.7769 26.851 57.5575 26.5821 57.5575C26.3131 57.5575 26.0951 57.7769 26.0951 58.0475C26.0951 58.3181 26.3131 58.5375 26.5821 58.5375Z" fill="#383A48"/>
				<path d="M24.1729 56.1165C24.4418 56.1165 24.6599 55.8971 24.6599 55.6264C24.6599 55.3558 24.4418 55.1364 24.1729 55.1364C23.9039 55.1364 23.6859 55.3558 23.6859 55.6264C23.6859 55.8971 23.9039 56.1165 24.1729 56.1165Z" fill="#383A48"/>
				<path d="M24.1729 58.5375C24.4418 58.5375 24.6599 58.3181 24.6599 58.0475C24.6599 57.7769 24.4418 57.5575 24.1729 57.5575C23.9039 57.5575 23.6859 57.7769 23.6859 58.0475C23.6859 58.3181 23.9039 58.5375 24.1729 58.5375Z" fill="#383A48"/>
				<path d="M21.7797 56.1165C22.0486 56.1165 22.2667 55.8971 22.2667 55.6264C22.2667 55.3558 22.0486 55.1364 21.7797 55.1364C21.5108 55.1364 21.2927 55.3558 21.2927 55.6264C21.2927 55.8971 21.5108 56.1165 21.7797 56.1165Z" fill="#383A48"/>
				<path d="M21.7797 58.5375C22.0486 58.5375 22.2667 58.3181 22.2667 58.0475C22.2667 57.7769 22.0486 57.5575 21.7797 57.5575C21.5108 57.5575 21.2927 57.7769 21.2927 58.0475C21.2927 58.3181 21.5108 58.5375 21.7797 58.5375Z" fill="#383A48"/>
				<path d="M19.3705 56.1165C19.6395 56.1165 19.8575 55.8971 19.8575 55.6264C19.8575 55.3558 19.6395 55.1364 19.3705 55.1364C19.1016 55.1364 18.8835 55.3558 18.8835 55.6264C18.8835 55.8971 19.1016 56.1165 19.3705 56.1165Z" fill="#383A48"/>
				<path d="M19.3705 58.5375C19.6395 58.5375 19.8575 58.3181 19.8575 58.0475C19.8575 57.7769 19.6395 57.5575 19.3705 57.5575C19.1016 57.5575 18.8835 57.7769 18.8835 58.0475C18.8835 58.3181 19.1016 58.5375 19.3705 58.5375Z" fill="#383A48"/>
				<path d="M16.9693 56.1165C17.2382 56.1165 17.4563 55.8971 17.4563 55.6264C17.4563 55.3558 17.2382 55.1364 16.9693 55.1364C16.7003 55.1364 16.4823 55.3558 16.4823 55.6264C16.4823 55.8971 16.7003 56.1165 16.9693 56.1165Z" fill="#383A48"/>
				<path d="M16.9693 58.5375C17.2382 58.5375 17.4563 58.3181 17.4563 58.0475C17.4563 57.7769 17.2382 57.5575 16.9693 57.5575C16.7003 57.5575 16.4823 57.7769 16.4823 58.0475C16.4823 58.3181 16.7003 58.5375 16.9693 58.5375Z" fill="#383A48"/>
				<path d="M14.568 56.1165C14.837 56.1165 15.055 55.8971 15.055 55.6264C15.055 55.3558 14.837 55.1364 14.568 55.1364C14.2991 55.1364 14.0811 55.3558 14.0811 55.6264C14.0811 55.8971 14.2991 56.1165 14.568 56.1165Z" fill="#383A48"/>
				<path d="M14.568 58.5375C14.837 58.5375 15.055 58.3181 15.055 58.0475C15.055 57.7769 14.837 57.5575 14.568 57.5575C14.2991 57.5575 14.0811 57.7769 14.0811 58.0475C14.0811 58.3181 14.2991 58.5375 14.568 58.5375Z" fill="#383A48"/>
				<path d="M12.1669 56.1165C12.4359 56.1165 12.6539 55.8971 12.6539 55.6264C12.6539 55.3558 12.4359 55.1364 12.1669 55.1364C11.898 55.1364 11.6799 55.3558 11.6799 55.6264C11.6799 55.8971 11.898 56.1165 12.1669 56.1165Z" fill="#383A48"/>
				<path d="M12.1669 58.5375C12.4359 58.5375 12.6539 58.3181 12.6539 58.0475C12.6539 57.7769 12.4359 57.5575 12.1669 57.5575C11.898 57.5575 11.6799 57.7769 11.6799 58.0475C11.6799 58.3181 11.898 58.5375 12.1669 58.5375Z" fill="#383A48"/>
				<path d="M9.75773 56.1165C10.0267 56.1165 10.2447 55.8971 10.2447 55.6264C10.2447 55.3558 10.0267 55.1364 9.75773 55.1364C9.48878 55.1364 9.27075 55.3558 9.27075 55.6264C9.27075 55.8971 9.48878 56.1165 9.75773 56.1165Z" fill="#383A48"/>
				<path d="M9.75773 58.5375C10.0267 58.5375 10.2447 58.3181 10.2447 58.0475C10.2447 57.7769 10.0267 57.5575 9.75773 57.5575C9.48878 57.5575 9.27075 57.7769 9.27075 58.0475C9.27075 58.3181 9.48878 58.5375 9.75773 58.5375Z" fill="#383A48"/>
				<path d="M7.36442 56.1165C7.63337 56.1165 7.85139 55.8971 7.85139 55.6264C7.85139 55.3558 7.63337 55.1364 7.36442 55.1364C7.09547 55.1364 6.87744 55.3558 6.87744 55.6264C6.87744 55.8971 7.09547 56.1165 7.36442 56.1165Z" fill="#383A48"/>
				<path d="M7.36442 58.5375C7.63337 58.5375 7.85139 58.3181 7.85139 58.0475C7.85139 57.7769 7.63337 57.5575 7.36442 57.5575C7.09547 57.5575 6.87744 57.7769 6.87744 58.0475C6.87744 58.3181 7.09547 58.5375 7.36442 58.5375Z" fill="#383A48"/>
				<path d="M4.95524 56.1165C5.22419 56.1165 5.44221 55.8971 5.44221 55.6264C5.44221 55.3558 5.22419 55.1364 4.95524 55.1364C4.68629 55.1364 4.46826 55.3558 4.46826 55.6264C4.46826 55.8971 4.68629 56.1165 4.95524 56.1165Z" fill="#383A48"/>
				<path d="M4.95524 58.5375C5.22419 58.5375 5.44221 58.3181 5.44221 58.0475C5.44221 57.7769 5.22419 57.5575 4.95524 57.5575C4.68629 57.5575 4.46826 57.7769 4.46826 58.0475C4.46826 58.3181 4.68629 58.5375 4.95524 58.5375Z" fill="#383A48"/>
				<path d="M2.55411 56.1165C2.82306 56.1165 3.04109 55.8971 3.04109 55.6264C3.04109 55.3558 2.82306 55.1364 2.55411 55.1364C2.28517 55.1364 2.06714 55.3558 2.06714 55.6264C2.06714 55.8971 2.28517 56.1165 2.55411 56.1165Z" fill="#383A48"/>
				<path d="M2.55411 58.5375C2.82306 58.5375 3.04109 58.3181 3.04109 58.0475C3.04109 57.7769 2.82306 57.5575 2.55411 57.5575C2.28517 57.5575 2.06714 57.7769 2.06714 58.0475C2.06714 58.3181 2.28517 58.5375 2.55411 58.5375Z" fill="#383A48"/>
				<path d="M28.9833 70.9252C29.2523 70.9252 29.4703 70.7058 29.4703 70.4352C29.4703 70.1646 29.2523 69.9452 28.9833 69.9452C28.7144 69.9452 28.4963 70.1646 28.4963 70.4352C28.4963 70.7058 28.7144 70.9252 28.9833 70.9252Z" fill="#383A48"/>
				<path d="M28.9833 73.3463C29.2523 73.3463 29.4703 73.1269 29.4703 72.8563C29.4703 72.5857 29.2523 72.3663 28.9833 72.3663C28.7144 72.3663 28.4963 72.5857 28.4963 72.8563C28.4963 73.1269 28.7144 73.3463 28.9833 73.3463Z" fill="#383A48"/>
				<path d="M26.5821 70.9252C26.851 70.9252 27.0691 70.7058 27.0691 70.4352C27.0691 70.1646 26.851 69.9452 26.5821 69.9452C26.3131 69.9452 26.0951 70.1646 26.0951 70.4352C26.0951 70.7058 26.3131 70.9252 26.5821 70.9252Z" fill="#383A48"/>
				<path d="M26.5821 73.3463C26.851 73.3463 27.069 73.1269 27.069 72.8563C27.069 72.5857 26.851 72.3663 26.5821 72.3663C26.3131 72.3663 26.0951 72.5857 26.0951 72.8563C26.0951 73.1269 26.3131 73.3463 26.5821 73.3463Z" fill="#383A48"/>
				<path d="M24.1729 70.9252C24.4418 70.9252 24.6599 70.7058 24.6599 70.4352C24.6599 70.1646 24.4418 69.9452 24.1729 69.9452C23.9039 69.9452 23.6859 70.1646 23.6859 70.4352C23.6859 70.7058 23.9039 70.9252 24.1729 70.9252Z" fill="#383A48"/>
				<path d="M24.1729 73.3463C24.4418 73.3463 24.6599 73.1269 24.6599 72.8563C24.6599 72.5857 24.4418 72.3663 24.1729 72.3663C23.9039 72.3663 23.6859 72.5857 23.6859 72.8563C23.6859 73.1269 23.9039 73.3463 24.1729 73.3463Z" fill="#383A48"/>
				<path d="M21.7797 70.9252C22.0486 70.9252 22.2667 70.7058 22.2667 70.4352C22.2667 70.1646 22.0486 69.9452 21.7797 69.9452C21.5108 69.9452 21.2927 70.1646 21.2927 70.4352C21.2927 70.7058 21.5108 70.9252 21.7797 70.9252Z" fill="#383A48"/>
				<path d="M21.7797 73.3463C22.0486 73.3463 22.2667 73.1269 22.2667 72.8563C22.2667 72.5857 22.0486 72.3663 21.7797 72.3663C21.5108 72.3663 21.2927 72.5857 21.2927 72.8563C21.2927 73.1269 21.5108 73.3463 21.7797 73.3463Z" fill="#383A48"/>
				<path d="M19.3705 70.9252C19.6395 70.9252 19.8575 70.7058 19.8575 70.4352C19.8575 70.1646 19.6395 69.9452 19.3705 69.9452C19.1016 69.9452 18.8835 70.1646 18.8835 70.4352C18.8835 70.7058 19.1016 70.9252 19.3705 70.9252Z" fill="#383A48"/>
				<path d="M19.3705 73.3463C19.6395 73.3463 19.8575 73.1269 19.8575 72.8563C19.8575 72.5857 19.6395 72.3663 19.3705 72.3663C19.1016 72.3663 18.8835 72.5857 18.8835 72.8563C18.8835 73.1269 19.1016 73.3463 19.3705 73.3463Z" fill="#383A48"/>
				<path d="M16.9693 70.9252C17.2382 70.9252 17.4563 70.7058 17.4563 70.4352C17.4563 70.1646 17.2382 69.9452 16.9693 69.9452C16.7003 69.9452 16.4823 70.1646 16.4823 70.4352C16.4823 70.7058 16.7003 70.9252 16.9693 70.9252Z" fill="#383A48"/>
				<path d="M16.9693 73.3463C17.2382 73.3463 17.4563 73.1269 17.4563 72.8563C17.4563 72.5857 17.2382 72.3663 16.9693 72.3663C16.7003 72.3663 16.4823 72.5857 16.4823 72.8563C16.4823 73.1269 16.7003 73.3463 16.9693 73.3463Z" fill="#383A48"/>
				<path d="M14.568 70.9252C14.837 70.9252 15.055 70.7058 15.055 70.4352C15.055 70.1646 14.837 69.9452 14.568 69.9452C14.2991 69.9452 14.0811 70.1646 14.0811 70.4352C14.0811 70.7058 14.2991 70.9252 14.568 70.9252Z" fill="#383A48"/>
				<path d="M14.568 73.3463C14.837 73.3463 15.055 73.1269 15.055 72.8563C15.055 72.5857 14.837 72.3663 14.568 72.3663C14.2991 72.3663 14.0811 72.5857 14.0811 72.8563C14.0811 73.1269 14.2991 73.3463 14.568 73.3463Z" fill="#383A48"/>
				<path d="M12.1669 70.9252C12.4359 70.9252 12.6539 70.7058 12.6539 70.4352C12.6539 70.1646 12.4359 69.9452 12.1669 69.9452C11.898 69.9452 11.6799 70.1646 11.6799 70.4352C11.6799 70.7058 11.898 70.9252 12.1669 70.9252Z" fill="#383A48"/>
				<path d="M12.1669 73.3463C12.4359 73.3463 12.6539 73.1269 12.6539 72.8563C12.6539 72.5857 12.4359 72.3663 12.1669 72.3663C11.898 72.3663 11.6799 72.5857 11.6799 72.8563C11.6799 73.1269 11.898 73.3463 12.1669 73.3463Z" fill="#383A48"/>
				<path d="M9.75773 70.9252C10.0267 70.9252 10.2447 70.7058 10.2447 70.4352C10.2447 70.1646 10.0267 69.9452 9.75773 69.9452C9.48878 69.9452 9.27075 70.1646 9.27075 70.4352C9.27075 70.7058 9.48878 70.9252 9.75773 70.9252Z" fill="#383A48"/>
				<path d="M9.75773 73.3463C10.0267 73.3463 10.2447 73.1269 10.2447 72.8563C10.2447 72.5857 10.0267 72.3663 9.75773 72.3663C9.48878 72.3663 9.27075 72.5857 9.27075 72.8563C9.27075 73.1269 9.48878 73.3463 9.75773 73.3463Z" fill="#383A48"/>
				<path d="M7.36442 70.9252C7.63337 70.9252 7.85139 70.7058 7.85139 70.4352C7.85139 70.1646 7.63337 69.9452 7.36442 69.9452C7.09547 69.9452 6.87744 70.1646 6.87744 70.4352C6.87744 70.7058 7.09547 70.9252 7.36442 70.9252Z" fill="#383A48"/>
				<path d="M7.36442 73.3463C7.63337 73.3463 7.85139 73.1269 7.85139 72.8563C7.85139 72.5857 7.63337 72.3663 7.36442 72.3663C7.09547 72.3663 6.87744 72.5857 6.87744 72.8563C6.87744 73.1269 7.09547 73.3463 7.36442 73.3463Z" fill="#383A48"/>
				<path d="M4.95524 70.9252C5.22419 70.9252 5.44221 70.7058 5.44221 70.4352C5.44221 70.1646 5.22419 69.9452 4.95524 69.9452C4.68629 69.9452 4.46826 70.1646 4.46826 70.4352C4.46826 70.7058 4.68629 70.9252 4.95524 70.9252Z" fill="#383A48"/>
				<path d="M4.95524 73.3463C5.22419 73.3463 5.44221 73.1269 5.44221 72.8563C5.44221 72.5857 5.22419 72.3663 4.95524 72.3663C4.68629 72.3663 4.46826 72.5857 4.46826 72.8563C4.46826 73.1269 4.68629 73.3463 4.95524 73.3463Z" fill="#383A48"/>
				<path d="M2.55411 70.9252C2.82306 70.9252 3.04109 70.7058 3.04109 70.4352C3.04109 70.1646 2.82306 69.9452 2.55411 69.9452C2.28517 69.9452 2.06714 70.1646 2.06714 70.4352C2.06714 70.7058 2.28517 70.9252 2.55411 70.9252Z" fill="#383A48"/>
				<path d="M2.55411 73.3463C2.82306 73.3463 3.04109 73.1269 3.04109 72.8563C3.04109 72.5857 2.82306 72.3663 2.55411 72.3663C2.28517 72.3663 2.06714 72.5857 2.06714 72.8563C2.06714 73.1269 2.28517 73.3463 2.55411 73.3463Z" fill="#383A48"/>
				<path d="M22.1555 11.5449C22.0601 11.5453 21.9666 11.5173 21.8867 11.4645C21.8068 11.4117 21.7438 11.3364 21.7056 11.2478C21.6551 11.1268 21.6534 10.9906 21.701 10.8684C21.7485 10.7461 21.8415 10.6477 21.9599 10.5941C27.378 8.19714 32.669 5.11677 38.2729 1.84821L39.3976 1.19449C39.5091 1.12963 39.6414 1.11211 39.7656 1.14576C39.8898 1.17941 39.9958 1.2615 40.0604 1.37407C40.125 1.48664 40.143 1.62055 40.1104 1.74651C40.0779 1.87248 39.9974 1.98026 39.8866 2.0463L38.7619 2.70001C33.1384 5.97848 27.8181 9.06876 22.3511 11.4954C22.2923 11.5314 22.2241 11.5486 22.1555 11.5449Z" fill="#F3F6E6"/>
				<path d="M38.8309 4.23629C38.771 4.23609 38.7115 4.22606 38.6548 4.20658C38.5944 4.18325 38.5391 4.14808 38.4922 4.10307C38.4452 4.05806 38.4074 4.00411 38.3811 3.9443C38.3547 3.88449 38.3403 3.82 38.3386 3.75453C38.3369 3.68906 38.348 3.62389 38.3712 3.56277L38.9678 1.99782L37.4128 1.71058C37.3497 1.69908 37.2894 1.67507 37.2355 1.63993C37.1815 1.60479 37.135 1.55922 37.0985 1.50581C37.062 1.45241 37.0362 1.39224 37.0227 1.32874C37.0093 1.26524 37.0083 1.19967 37.0199 1.13579C37.0314 1.0719 37.0554 1.01097 37.0903 0.956468C37.1252 0.901971 37.1703 0.854985 37.2232 0.818207C37.276 0.781428 37.3356 0.755581 37.3983 0.742147C37.461 0.728712 37.5258 0.727956 37.5888 0.73992L39.7306 1.13611C39.8017 1.15075 39.8688 1.18037 39.9278 1.22303C39.9868 1.26569 40.0362 1.32044 40.0729 1.38373C40.1064 1.44708 40.1263 1.51688 40.1314 1.58856C40.1365 1.66024 40.1265 1.73219 40.1023 1.79973L39.2905 3.91934C39.2541 4.0127 39.1907 4.09283 39.1088 4.14934C39.0268 4.20585 38.93 4.23614 38.8309 4.23629Z" fill="#F3F6E6"/>
				<path d="M21.8651 17.389C21.7689 17.3893 21.6747 17.3614 21.5941 17.3086C21.5136 17.2558 21.4501 17.1804 21.4116 17.0918C21.3607 16.9708 21.359 16.8346 21.407 16.7124C21.4549 16.5902 21.5486 16.4917 21.6679 16.4381C27.129 14.0412 32.462 10.9608 38.1104 7.69223L39.244 7.03852C39.3564 6.97366 39.4897 6.95614 39.6149 6.98979C39.7401 7.02344 39.847 7.10552 39.9121 7.21809C39.9772 7.33067 39.9954 7.46457 39.9625 7.59054C39.9297 7.71651 39.8486 7.82429 39.7369 7.89033L38.6033 8.55394C32.9352 11.8324 27.5726 14.9227 22.0622 17.3494C22.0004 17.3776 21.933 17.3912 21.8651 17.389Z" fill="#383A48"/>
				<path d="M38.6705 10.0803C38.6102 10.0801 38.5502 10.07 38.4931 10.0506C38.4322 10.0272 38.3765 9.99206 38.3291 9.94705C38.2818 9.90205 38.2437 9.84809 38.2172 9.78828C38.1906 9.72847 38.176 9.66398 38.1743 9.59852C38.1726 9.53305 38.1838 9.46788 38.2072 9.40676L38.8085 7.84181L37.2412 7.55458C37.1775 7.54307 37.1168 7.51906 37.0624 7.48392C37.0081 7.44879 36.9612 7.40321 36.9244 7.34981C36.8876 7.2964 36.8616 7.23623 36.848 7.17273C36.8344 7.10923 36.8334 7.04366 36.8451 6.97978C36.8568 6.9159 36.8809 6.85496 36.9161 6.80046C36.9512 6.74596 36.9968 6.69898 37.0501 6.6622C37.1033 6.62542 37.1633 6.59957 37.2266 6.58614C37.2898 6.57271 37.3551 6.57195 37.4186 6.58391L39.5774 6.9801C39.649 6.99474 39.7167 7.02436 39.7762 7.06702C39.8356 7.10968 39.8854 7.16443 39.9224 7.22772C39.9562 7.29108 39.9763 7.36087 39.9814 7.43255C39.9865 7.50423 39.9765 7.57619 39.952 7.64372L39.1338 9.76333C39.0971 9.85669 39.0332 9.93681 38.9506 9.99332C38.868 10.0498 38.7705 10.0801 38.6705 10.0803Z" fill="#383A48"/>
				<path d="M73.178 15.3541C71.9951 15.3541 71.8706 14.2609 71.8706 13.7996C71.8706 13.6666 71.8159 13.539 71.7187 13.445C71.6214 13.3509 71.4894 13.2981 71.3518 13.2981C71.2142 13.2981 71.0823 13.3509 70.985 13.445C70.8877 13.539 70.833 13.6666 70.833 13.7996C70.833 14.2709 70.7085 15.3541 69.5256 15.3541C69.388 15.3541 69.2561 15.4069 69.1588 15.5009C69.0615 15.595 69.0068 15.7225 69.0068 15.8555C69.0068 15.9885 69.0615 16.1161 69.1588 16.2101C69.2561 16.3042 69.388 16.357 69.5256 16.357C70.7085 16.357 70.833 17.4502 70.833 17.9115C70.833 18.0445 70.8877 18.1721 70.985 18.2661C71.0823 18.3601 71.2142 18.413 71.3518 18.413C71.4894 18.413 71.6214 18.3601 71.7187 18.2661C71.8159 18.1721 71.8706 18.0445 71.8706 17.9115C71.8706 17.4401 71.9951 16.357 73.178 16.357C73.2463 16.3576 73.3141 16.3451 73.3773 16.3202C73.4406 16.2952 73.498 16.2583 73.5463 16.2116C73.5947 16.1649 73.6328 16.1093 73.6587 16.0482C73.6845 15.9871 73.6975 15.9216 73.6968 15.8555C73.6964 15.7227 73.6416 15.5953 73.5444 15.5014C73.4472 15.4074 73.3155 15.3545 73.178 15.3541Z" fill="#F3F6E6"/>
				<path d="M132.532 70.2153C131.35 70.2153 131.225 69.0909 131.225 68.6164C131.225 68.4796 131.17 68.3484 131.073 68.2517C130.976 68.155 130.844 68.1006 130.706 68.1006C130.569 68.1006 130.437 68.155 130.339 68.2517C130.242 68.3484 130.188 68.4796 130.188 68.6164C130.188 69.1012 130.063 70.2153 128.88 70.2153C128.743 70.2153 128.611 70.2697 128.513 70.3664C128.416 70.4631 128.361 70.5943 128.361 70.7311C128.361 70.8679 128.416 70.9991 128.513 71.0958C128.611 71.1926 128.743 71.2469 128.88 71.2469C130.063 71.2469 130.188 72.3713 130.188 72.8458C130.188 72.9826 130.242 73.1138 130.339 73.2106C130.437 73.3073 130.569 73.3616 130.706 73.3616C130.844 73.3616 130.976 73.3073 131.073 73.2106C131.17 73.1138 131.225 72.9826 131.225 72.8458C131.225 72.361 131.35 71.2469 132.532 71.2469C132.601 71.2476 132.669 71.2347 132.732 71.209C132.795 71.1833 132.853 71.1454 132.901 71.0973C132.949 71.0493 132.987 70.9922 133.013 70.9293C133.039 70.8664 133.052 70.799 133.051 70.7311C133.051 70.5945 132.996 70.4635 132.899 70.3669C132.802 70.2702 132.67 70.2157 132.532 70.2153Z" fill="#F3F6E6"/>
				<path d="M10.3013 47.0667C9.11841 47.0667 8.9939 45.9736 8.9939 45.5122C8.9939 45.3792 8.93924 45.2517 8.84195 45.1576C8.74466 45.0636 8.6127 45.0108 8.4751 45.0108C8.33751 45.0108 8.20555 45.0636 8.10826 45.1576C8.01096 45.2517 7.9563 45.3792 7.9563 45.5122C7.9563 45.9836 7.83179 47.0667 6.64893 47.0667C6.51133 47.0667 6.37937 47.1196 6.28208 47.2136C6.18479 47.3077 6.13013 47.4352 6.13013 47.5682C6.13013 47.7012 6.18479 47.8287 6.28208 47.9228C6.37937 48.0168 6.51133 48.0697 6.64893 48.0697C7.83179 48.0697 7.9563 49.1628 7.9563 49.6242C7.9563 49.7572 8.01096 49.8847 8.10826 49.9788C8.20555 50.0728 8.33751 50.1256 8.4751 50.1256C8.6127 50.1256 8.74466 50.0728 8.84195 49.9788C8.93924 49.8847 8.9939 49.7572 8.9939 49.6242C8.9939 49.1528 9.11841 48.0697 10.3013 48.0697C10.3696 48.0703 10.4374 48.0578 10.5006 48.0328C10.5639 48.0079 10.6213 47.9709 10.6696 47.9242C10.718 47.8776 10.7561 47.822 10.782 47.7609C10.8078 47.6997 10.8208 47.6342 10.8201 47.5682C10.8197 47.4353 10.7649 47.308 10.6677 47.2141C10.5705 47.1201 10.4387 47.0671 10.3013 47.0667Z" fill="#F3F6E6"/>
				<path d="M103.209 41.905C102.803 43.9193 101.906 45.5697 100.518 46.8561C99.1469 48.1426 97.2764 49.0651 94.9066 49.6237L105.088 61.0495H96.5062L87.1371 50.0046L88.1019 45.028H89.4476C92.0036 45.028 93.6963 44.7403 94.5258 44.1647C95.3721 43.5723 95.9476 42.819 96.2523 41.905H87.1371V36.6745H96.1762C95.9053 36.0143 95.5245 35.4727 95.0336 35.0495C94.5596 34.6263 93.9841 34.3386 93.307 34.1862C92.6299 34.0169 91.5805 33.9323 90.1586 33.9323H87.1371V28.7018H110.801V33.9323H100.975C101.754 34.5417 102.38 35.4557 102.854 36.6745H110.801V41.905H103.209Z" fill="#F6E049"/>
				<path d="M71.3503 75.0599C82.3315 75.0599 91.2336 66.1546 91.2336 55.1693C91.2336 44.184 82.3315 35.2787 71.3503 35.2787C60.369 35.2787 51.4669 44.184 51.4669 55.1693C51.4669 66.1546 60.369 75.0599 71.3503 75.0599Z" fill="#F3F6E6"/>
				<path d="M71.3481 75.5487C60.1109 75.5487 50.974 66.4085 50.974 55.1672C50.974 43.926 60.1109 34.7857 71.3481 34.7857C82.5852 34.7857 91.7221 43.926 91.7221 55.1672C91.7221 66.4085 82.5852 75.5487 71.3481 75.5487ZM71.3481 35.7577C60.6507 35.7577 51.9554 44.466 51.9554 55.1574C51.9554 65.8489 60.6605 74.5571 71.3481 74.5571C82.0454 74.5571 90.7407 65.8489 90.7407 55.1574C90.7407 44.466 82.0454 35.7577 71.3481 35.7577Z" fill="#383A48"/>
				<path d="M90.1227 51.1448C90.3937 51.1448 90.6134 50.925 90.6134 50.6539C90.6134 50.3828 90.3937 50.1631 90.1227 50.1631C89.8517 50.1631 89.632 50.3828 89.632 50.6539C89.632 50.925 89.8517 51.1448 90.1227 51.1448Z" fill="#383A48"/>
				<path d="M90.1227 53.5699C90.3937 53.5699 90.6134 53.3501 90.6134 53.079C90.6134 52.8079 90.3937 52.5881 90.1227 52.5881C89.8517 52.5881 89.632 52.8079 89.632 53.079C89.632 53.3501 89.8517 53.5699 90.1227 53.5699Z" fill="#383A48"/>
				<path d="M90.1227 55.983C90.3937 55.983 90.6134 55.7632 90.6134 55.4921C90.6134 55.221 90.3937 55.0013 90.1227 55.0013C89.8517 55.0013 89.632 55.221 89.632 55.4921C89.632 55.7632 89.8517 55.983 90.1227 55.983Z" fill="#383A48"/>
				<path d="M90.1227 58.4081C90.3937 58.4081 90.6134 58.1883 90.6134 57.9172C90.6134 57.6461 90.3937 57.4263 90.1227 57.4263C89.8517 57.4263 89.632 57.6461 89.632 57.9172C89.632 58.1883 89.8517 58.4081 90.1227 58.4081Z" fill="#383A48"/>
				<path d="M87.7135 46.3026C87.9845 46.3026 88.2042 46.0829 88.2042 45.8117C88.2042 45.5406 87.9845 45.3209 87.7135 45.3209C87.4425 45.3209 87.2228 45.5406 87.2228 45.8117C87.2228 46.0829 87.4425 46.3026 87.7135 46.3026Z" fill="#383A48"/>
				<path d="M87.7135 48.7197C87.9845 48.7197 88.2042 48.5 88.2042 48.2289C88.2042 47.9578 87.9845 47.738 87.7135 47.738C87.4425 47.738 87.2228 47.9578 87.2228 48.2289C87.2228 48.5 87.4425 48.7197 87.7135 48.7197Z" fill="#383A48"/>
				<path d="M87.7135 51.1448C87.9845 51.1448 88.2042 50.925 88.2042 50.6539C88.2042 50.3828 87.9845 50.1631 87.7135 50.1631C87.4425 50.1631 87.2228 50.3828 87.2228 50.6539C87.2228 50.925 87.4425 51.1448 87.7135 51.1448Z" fill="#383A48"/>
				<path d="M87.7135 53.5699C87.9845 53.5699 88.2042 53.3501 88.2042 53.079C88.2042 52.8079 87.9845 52.5881 87.7135 52.5881C87.4425 52.5881 87.2228 52.8079 87.2228 53.079C87.2228 53.3501 87.4425 53.5699 87.7135 53.5699Z" fill="#383A48"/>
				<path d="M87.7135 55.983C87.9845 55.983 88.2042 55.7632 88.2042 55.4921C88.2042 55.221 87.9845 55.0013 87.7135 55.0013C87.4425 55.0013 87.2228 55.221 87.2228 55.4921C87.2228 55.7632 87.4425 55.983 87.7135 55.983Z" fill="#383A48"/>
				<path d="M87.7135 58.4081C87.9845 58.4081 88.2042 58.1883 88.2042 57.9172C88.2042 57.6461 87.9845 57.4263 87.7135 57.4263C87.4425 57.4263 87.2228 57.6461 87.2228 57.9172C87.2228 58.1883 87.4425 58.4081 87.7135 58.4081Z" fill="#383A48"/>
				<path d="M87.7135 60.8331C87.9845 60.8331 88.2042 60.6133 88.2042 60.3422C88.2042 60.0711 87.9845 59.8513 87.7135 59.8513C87.4425 59.8513 87.2228 60.0711 87.2228 60.3422C87.2228 60.6133 87.4425 60.8331 87.7135 60.8331Z" fill="#383A48"/>
				<path d="M87.7135 63.2503C87.9845 63.2503 88.2042 63.0305 88.2042 62.7594C88.2042 62.4883 87.9845 62.2685 87.7135 62.2685C87.4425 62.2685 87.2228 62.4883 87.2228 62.7594C87.2228 63.0305 87.4425 63.2503 87.7135 63.2503Z" fill="#383A48"/>
				<path d="M85.2883 43.8776C85.5593 43.8776 85.779 43.6578 85.779 43.3867C85.779 43.1156 85.5593 42.8958 85.2883 42.8958C85.0173 42.8958 84.7976 43.1156 84.7976 43.3867C84.7976 43.6578 85.0173 43.8776 85.2883 43.8776Z" fill="#383A48"/>
				<path d="M85.2883 46.3026C85.5593 46.3026 85.779 46.0829 85.779 45.8117C85.779 45.5406 85.5593 45.3209 85.2883 45.3209C85.0173 45.3209 84.7976 45.5406 84.7976 45.8117C84.7976 46.0829 85.0173 46.3026 85.2883 46.3026Z" fill="#383A48"/>
				<path d="M85.2883 48.7197C85.5593 48.7197 85.779 48.5 85.779 48.2289C85.779 47.9578 85.5593 47.738 85.2883 47.738C85.0173 47.738 84.7976 47.9578 84.7976 48.2289C84.7976 48.5 85.0173 48.7197 85.2883 48.7197Z" fill="#383A48"/>
				<path d="M85.2883 51.1448C85.5593 51.1448 85.779 50.925 85.779 50.6539C85.779 50.3828 85.5593 50.1631 85.2883 50.1631C85.0173 50.1631 84.7976 50.3828 84.7976 50.6539C84.7976 50.925 85.0173 51.1448 85.2883 51.1448Z" fill="#383A48"/>
				<path d="M85.2883 53.5699C85.5593 53.5699 85.779 53.3501 85.779 53.079C85.779 52.8079 85.5593 52.5881 85.2883 52.5881C85.0173 52.5881 84.7976 52.8079 84.7976 53.079C84.7976 53.3501 85.0173 53.5699 85.2883 53.5699Z" fill="#383A48"/>
				<path d="M85.2883 55.983C85.5593 55.983 85.779 55.7632 85.779 55.4921C85.779 55.221 85.5593 55.0013 85.2883 55.0013C85.0173 55.0013 84.7976 55.221 84.7976 55.4921C84.7976 55.7632 85.0173 55.983 85.2883 55.983Z" fill="#383A48"/>
				<path d="M85.2883 58.4081C85.5593 58.4081 85.779 58.1883 85.779 57.9172C85.779 57.6461 85.5593 57.4263 85.2883 57.4263C85.0173 57.4263 84.7976 57.6461 84.7976 57.9172C84.7976 58.1883 85.0173 58.4081 85.2883 58.4081Z" fill="#383A48"/>
				<path d="M85.2883 60.8331C85.5593 60.8331 85.779 60.6133 85.779 60.3422C85.779 60.0711 85.5593 59.8513 85.2883 59.8513C85.0173 59.8513 84.7976 60.0711 84.7976 60.3422C84.7976 60.6133 85.0173 60.8331 85.2883 60.8331Z" fill="#383A48"/>
				<path d="M85.2883 63.2503C85.5593 63.2503 85.779 63.0305 85.779 62.7594C85.779 62.4883 85.5593 62.2685 85.2883 62.2685C85.0173 62.2685 84.7976 62.4883 84.7976 62.7594C84.7976 63.0305 85.0173 63.2503 85.2883 63.2503Z" fill="#383A48"/>
				<path d="M85.2883 65.6753C85.5593 65.6753 85.779 65.4555 85.779 65.1844C85.779 64.9133 85.5593 64.6935 85.2883 64.6935C85.0173 64.6935 84.7976 64.9133 84.7976 65.1844C84.7976 65.4555 85.0173 65.6753 85.2883 65.6753Z" fill="#383A48"/>
				<path d="M85.2883 68.1004C85.5593 68.1004 85.779 67.8806 85.779 67.6095C85.779 67.3384 85.5593 67.1186 85.2883 67.1186C85.0173 67.1186 84.7976 67.3384 84.7976 67.6095C84.7976 67.8806 85.0173 68.1004 85.2883 68.1004Z" fill="#383A48"/>
				<path d="M82.8633 41.4525C83.1343 41.4525 83.354 41.2327 83.354 40.9616C83.354 40.6905 83.1343 40.4707 82.8633 40.4707C82.5923 40.4707 82.3726 40.6905 82.3726 40.9616C82.3726 41.2327 82.5923 41.4525 82.8633 41.4525Z" fill="#383A48"/>
				<path d="M82.8633 43.8776C83.1343 43.8776 83.354 43.6578 83.354 43.3867C83.354 43.1156 83.1343 42.8958 82.8633 42.8958C82.5923 42.8958 82.3726 43.1156 82.3726 43.3867C82.3726 43.6578 82.5923 43.8776 82.8633 43.8776Z" fill="#383A48"/>
				<path d="M82.8633 46.3026C83.1343 46.3026 83.354 46.0829 83.354 45.8117C83.354 45.5406 83.1343 45.3209 82.8633 45.3209C82.5923 45.3209 82.3726 45.5406 82.3726 45.8117C82.3726 46.0829 82.5923 46.3026 82.8633 46.3026Z" fill="#383A48"/>
				<path d="M82.8633 48.7197C83.1343 48.7197 83.354 48.5 83.354 48.2289C83.354 47.9578 83.1343 47.738 82.8633 47.738C82.5923 47.738 82.3726 47.9578 82.3726 48.2289C82.3726 48.5 82.5923 48.7197 82.8633 48.7197Z" fill="#383A48"/>
				<path d="M82.8633 51.1448C83.1343 51.1448 83.354 50.925 83.354 50.6539C83.354 50.3828 83.1343 50.1631 82.8633 50.1631C82.5923 50.1631 82.3726 50.3828 82.3726 50.6539C82.3726 50.925 82.5923 51.1448 82.8633 51.1448Z" fill="#383A48"/>
				<path d="M82.8633 53.5699C83.1343 53.5699 83.354 53.3501 83.354 53.079C83.354 52.8079 83.1343 52.5881 82.8633 52.5881C82.5923 52.5881 82.3726 52.8079 82.3726 53.079C82.3726 53.3501 82.5923 53.5699 82.8633 53.5699Z" fill="#383A48"/>
				<path d="M82.8633 55.983C83.1343 55.983 83.354 55.7632 83.354 55.4921C83.354 55.221 83.1343 55.0013 82.8633 55.0013C82.5923 55.0013 82.3726 55.221 82.3726 55.4921C82.3726 55.7632 82.5923 55.983 82.8633 55.983Z" fill="#383A48"/>
				<path d="M82.8633 58.4081C83.1343 58.4081 83.354 58.1883 83.354 57.9172C83.354 57.6461 83.1343 57.4263 82.8633 57.4263C82.5923 57.4263 82.3726 57.6461 82.3726 57.9172C82.3726 58.1883 82.5923 58.4081 82.8633 58.4081Z" fill="#383A48"/>
				<path d="M82.8633 60.8331C83.1343 60.8331 83.354 60.6133 83.354 60.3422C83.354 60.0711 83.1343 59.8513 82.8633 59.8513C82.5923 59.8513 82.3726 60.0711 82.3726 60.3422C82.3726 60.6133 82.5923 60.8331 82.8633 60.8331Z" fill="#383A48"/>
				<path d="M82.8633 63.2503C83.1343 63.2503 83.354 63.0305 83.354 62.7594C83.354 62.4883 83.1343 62.2685 82.8633 62.2685C82.5923 62.2685 82.3726 62.4883 82.3726 62.7594C82.3726 63.0305 82.5923 63.2503 82.8633 63.2503Z" fill="#383A48"/>
				<path d="M82.8633 65.6753C83.1343 65.6753 83.354 65.4555 83.354 65.1844C83.354 64.9133 83.1343 64.6935 82.8633 64.6935C82.5923 64.6935 82.3726 64.9133 82.3726 65.1844C82.3726 65.4555 82.5923 65.6753 82.8633 65.6753Z" fill="#383A48"/>
				<path d="M82.8633 68.1004C83.1343 68.1004 83.354 67.8806 83.354 67.6095C83.354 67.3384 83.1343 67.1186 82.8633 67.1186C82.5923 67.1186 82.3726 67.3384 82.3726 67.6095C82.3726 67.8806 82.5923 68.1004 82.8633 68.1004Z" fill="#383A48"/>
				<path d="M82.8633 70.5135C83.1343 70.5135 83.354 70.2938 83.354 70.0226C83.354 69.7515 83.1343 69.5317 82.8633 69.5317C82.5923 69.5317 82.3726 69.7515 82.3726 70.0226C82.3726 70.2938 82.5923 70.5135 82.8633 70.5135Z" fill="#383A48"/>
				<path d="M80.4463 39.0394C80.7173 39.0394 80.937 38.8196 80.937 38.5485C80.937 38.2774 80.7173 38.0576 80.4463 38.0576C80.1753 38.0576 79.9556 38.2774 79.9556 38.5485C79.9556 38.8196 80.1753 39.0394 80.4463 39.0394Z" fill="#383A48"/>
				<path d="M80.4463 41.4525C80.7173 41.4525 80.937 41.2327 80.937 40.9616C80.937 40.6905 80.7173 40.4707 80.4463 40.4707C80.1753 40.4707 79.9556 40.6905 79.9556 40.9616C79.9556 41.2327 80.1753 41.4525 80.4463 41.4525Z" fill="#383A48"/>
				<path d="M80.4463 43.8776C80.7173 43.8776 80.937 43.6578 80.937 43.3867C80.937 43.1156 80.7173 42.8958 80.4463 42.8958C80.1753 42.8958 79.9556 43.1156 79.9556 43.3867C79.9556 43.6578 80.1753 43.8776 80.4463 43.8776Z" fill="#383A48"/>
				<path d="M80.4463 46.3026C80.7173 46.3026 80.937 46.0829 80.937 45.8117C80.937 45.5406 80.7173 45.3209 80.4463 45.3209C80.1753 45.3209 79.9556 45.5406 79.9556 45.8117C79.9556 46.0829 80.1753 46.3026 80.4463 46.3026Z" fill="#383A48"/>
				<path d="M80.4463 48.7197C80.7173 48.7197 80.937 48.5 80.937 48.2289C80.937 47.9578 80.7173 47.738 80.4463 47.738C80.1753 47.738 79.9556 47.9578 79.9556 48.2289C79.9556 48.5 80.1753 48.7197 80.4463 48.7197Z" fill="#383A48"/>
				<path d="M80.4463 51.1448C80.7173 51.1448 80.937 50.925 80.937 50.6539C80.937 50.3828 80.7173 50.1631 80.4463 50.1631C80.1753 50.1631 79.9556 50.3828 79.9556 50.6539C79.9556 50.925 80.1753 51.1448 80.4463 51.1448Z" fill="#383A48"/>
				<path d="M80.4463 53.5699C80.7173 53.5699 80.937 53.3501 80.937 53.079C80.937 52.8079 80.7173 52.5881 80.4463 52.5881C80.1753 52.5881 79.9556 52.8079 79.9556 53.079C79.9556 53.3501 80.1753 53.5699 80.4463 53.5699Z" fill="#383A48"/>
				<path d="M80.4463 55.983C80.7173 55.983 80.937 55.7632 80.937 55.4921C80.937 55.221 80.7173 55.0013 80.4463 55.0013C80.1753 55.0013 79.9556 55.221 79.9556 55.4921C79.9556 55.7632 80.1753 55.983 80.4463 55.983Z" fill="#383A48"/>
				<path d="M80.4463 58.4081C80.7173 58.4081 80.937 58.1883 80.937 57.9172C80.937 57.6461 80.7173 57.4263 80.4463 57.4263C80.1753 57.4263 79.9556 57.6461 79.9556 57.9172C79.9556 58.1883 80.1753 58.4081 80.4463 58.4081Z" fill="#383A48"/>
				<path d="M80.4463 60.8331C80.7173 60.8331 80.937 60.6133 80.937 60.3422C80.937 60.0711 80.7173 59.8513 80.4463 59.8513C80.1753 59.8513 79.9556 60.0711 79.9556 60.3422C79.9556 60.6133 80.1753 60.8331 80.4463 60.8331Z" fill="#383A48"/>
				<path d="M80.4463 63.2503C80.7173 63.2503 80.937 63.0305 80.937 62.7594C80.937 62.4883 80.7173 62.2685 80.4463 62.2685C80.1753 62.2685 79.9556 62.4883 79.9556 62.7594C79.9556 63.0305 80.1753 63.2503 80.4463 63.2503Z" fill="#383A48"/>
				<path d="M80.4463 65.6753C80.7173 65.6753 80.937 65.4555 80.937 65.1844C80.937 64.9133 80.7173 64.6935 80.4463 64.6935C80.1753 64.6935 79.9556 64.9133 79.9556 65.1844C79.9556 65.4555 80.1753 65.6753 80.4463 65.6753Z" fill="#383A48"/>
				<path d="M80.4463 68.1004C80.7173 68.1004 80.937 67.8806 80.937 67.6095C80.937 67.3384 80.7173 67.1186 80.4463 67.1186C80.1753 67.1186 79.9556 67.3384 79.9556 67.6095C79.9556 67.8806 80.1753 68.1004 80.4463 68.1004Z" fill="#383A48"/>
				<path d="M80.4463 70.5135C80.7173 70.5135 80.937 70.2938 80.937 70.0226C80.937 69.7515 80.7173 69.5317 80.4463 69.5317C80.1753 69.5317 79.9556 69.7515 79.9556 70.0226C79.9556 70.2938 80.1753 70.5135 80.4463 70.5135Z" fill="#383A48"/>
				<path d="M78.0211 36.6143C78.2921 36.6143 78.5118 36.3945 78.5118 36.1234C78.5118 35.8523 78.2921 35.6325 78.0211 35.6325C77.7501 35.6325 77.5304 35.8523 77.5304 36.1234C77.5304 36.3945 77.7501 36.6143 78.0211 36.6143Z" fill="#383A48"/>
				<path d="M78.0211 39.0394C78.2921 39.0394 78.5118 38.8196 78.5118 38.5485C78.5118 38.2774 78.2921 38.0576 78.0211 38.0576C77.7501 38.0576 77.5304 38.2774 77.5304 38.5485C77.5304 38.8196 77.7501 39.0394 78.0211 39.0394Z" fill="#383A48"/>
				<path d="M78.0211 41.4525C78.2921 41.4525 78.5118 41.2327 78.5118 40.9616C78.5118 40.6905 78.2921 40.4707 78.0211 40.4707C77.7501 40.4707 77.5304 40.6905 77.5304 40.9616C77.5304 41.2327 77.7501 41.4525 78.0211 41.4525Z" fill="#383A48"/>
				<path d="M78.0211 43.8776C78.2921 43.8776 78.5118 43.6578 78.5118 43.3867C78.5118 43.1156 78.2921 42.8958 78.0211 42.8958C77.7501 42.8958 77.5304 43.1156 77.5304 43.3867C77.5304 43.6578 77.7501 43.8776 78.0211 43.8776Z" fill="#383A48"/>
				<path d="M78.0211 46.3026C78.2921 46.3026 78.5118 46.0829 78.5118 45.8117C78.5118 45.5406 78.2921 45.3209 78.0211 45.3209C77.7501 45.3209 77.5304 45.5406 77.5304 45.8117C77.5304 46.0829 77.7501 46.3026 78.0211 46.3026Z" fill="#383A48"/>
				<path d="M78.0211 48.7197C78.2921 48.7197 78.5118 48.5 78.5118 48.2289C78.5118 47.9578 78.2921 47.738 78.0211 47.738C77.7501 47.738 77.5304 47.9578 77.5304 48.2289C77.5304 48.5 77.7501 48.7197 78.0211 48.7197Z" fill="#383A48"/>
				<path d="M78.0211 51.1448C78.2921 51.1448 78.5118 50.925 78.5118 50.6539C78.5118 50.3828 78.2921 50.1631 78.0211 50.1631C77.7501 50.1631 77.5304 50.3828 77.5304 50.6539C77.5304 50.925 77.7501 51.1448 78.0211 51.1448Z" fill="#383A48"/>
				<path d="M78.0211 53.5699C78.2921 53.5699 78.5118 53.3501 78.5118 53.079C78.5118 52.8079 78.2921 52.5881 78.0211 52.5881C77.7501 52.5881 77.5304 52.8079 77.5304 53.079C77.5304 53.3501 77.7501 53.5699 78.0211 53.5699Z" fill="#383A48"/>
				<path d="M78.0211 55.983C78.2921 55.983 78.5118 55.7632 78.5118 55.4921C78.5118 55.221 78.2921 55.0013 78.0211 55.0013C77.7501 55.0013 77.5304 55.221 77.5304 55.4921C77.5304 55.7632 77.7501 55.983 78.0211 55.983Z" fill="#383A48"/>
				<path d="M78.0211 58.4081C78.2921 58.4081 78.5118 58.1883 78.5118 57.9172C78.5118 57.6461 78.2921 57.4263 78.0211 57.4263C77.7501 57.4263 77.5304 57.6461 77.5304 57.9172C77.5304 58.1883 77.7501 58.4081 78.0211 58.4081Z" fill="#383A48"/>
				<path d="M78.0211 60.8331C78.2921 60.8331 78.5118 60.6133 78.5118 60.3422C78.5118 60.0711 78.2921 59.8513 78.0211 59.8513C77.7501 59.8513 77.5304 60.0711 77.5304 60.3422C77.5304 60.6133 77.7501 60.8331 78.0211 60.8331Z" fill="#383A48"/>
				<path d="M78.0211 63.2503C78.2921 63.2503 78.5118 63.0305 78.5118 62.7594C78.5118 62.4883 78.2921 62.2685 78.0211 62.2685C77.7501 62.2685 77.5304 62.4883 77.5304 62.7594C77.5304 63.0305 77.7501 63.2503 78.0211 63.2503Z" fill="#383A48"/>
				<path d="M78.0211 65.6753C78.2921 65.6753 78.5118 65.4555 78.5118 65.1844C78.5118 64.9133 78.2921 64.6935 78.0211 64.6935C77.7501 64.6935 77.5304 64.9133 77.5304 65.1844C77.5304 65.4555 77.7501 65.6753 78.0211 65.6753Z" fill="#383A48"/>
				<path d="M78.0211 68.1004C78.2921 68.1004 78.5118 67.8806 78.5118 67.6095C78.5118 67.3384 78.2921 67.1186 78.0211 67.1186C77.7501 67.1186 77.5304 67.3384 77.5304 67.6095C77.5304 67.8806 77.7501 68.1004 78.0211 68.1004Z" fill="#383A48"/>
				<path d="M78.0211 70.5135C78.2921 70.5135 78.5118 70.2938 78.5118 70.0226C78.5118 69.7515 78.2921 69.5317 78.0211 69.5317C77.7501 69.5317 77.5304 69.7515 77.5304 70.0226C77.5304 70.2938 77.7501 70.5135 78.0211 70.5135Z" fill="#383A48"/>
				<path d="M78.0211 72.9387C78.2921 72.9387 78.5118 72.7189 78.5118 72.4477C78.5118 72.1766 78.2921 71.9568 78.0211 71.9568C77.7501 71.9568 77.5304 72.1766 77.5304 72.4477C77.5304 72.7189 77.7501 72.9387 78.0211 72.9387Z" fill="#383A48"/>
				<path d="M75.604 36.6143C75.875 36.6143 76.0947 36.3945 76.0947 36.1234C76.0947 35.8523 75.875 35.6325 75.604 35.6325C75.333 35.6325 75.1133 35.8523 75.1133 36.1234C75.1133 36.3945 75.333 36.6143 75.604 36.6143Z" fill="#383A48"/>
				<path d="M75.5961 39.0394C75.8671 39.0394 76.0868 38.8196 76.0868 38.5485C76.0868 38.2774 75.8671 38.0576 75.5961 38.0576C75.325 38.0576 75.1053 38.2774 75.1053 38.5485C75.1053 38.8196 75.325 39.0394 75.5961 39.0394Z" fill="#383A48"/>
				<path d="M75.604 41.4525C75.875 41.4525 76.0947 41.2327 76.0947 40.9616C76.0947 40.6905 75.875 40.4707 75.604 40.4707C75.333 40.4707 75.1133 40.6905 75.1133 40.9616C75.1133 41.2327 75.333 41.4525 75.604 41.4525Z" fill="#383A48"/>
				<path d="M75.5961 43.8776C75.8671 43.8776 76.0868 43.6578 76.0868 43.3867C76.0868 43.1156 75.8671 42.8958 75.5961 42.8958C75.325 42.8958 75.1053 43.1156 75.1053 43.3867C75.1053 43.6578 75.325 43.8776 75.5961 43.8776Z" fill="#383A48"/>
				<path d="M75.5961 46.3026C75.8671 46.3026 76.0868 46.0829 76.0868 45.8117C76.0868 45.5406 75.8671 45.3209 75.5961 45.3209C75.325 45.3209 75.1053 45.5406 75.1053 45.8117C75.1053 46.0829 75.325 46.3026 75.5961 46.3026Z" fill="#383A48"/>
				<path d="M75.604 48.7197C75.875 48.7197 76.0947 48.5 76.0947 48.2289C76.0947 47.9578 75.875 47.738 75.604 47.738C75.333 47.738 75.1133 47.9578 75.1133 48.2289C75.1133 48.5 75.333 48.7197 75.604 48.7197Z" fill="#383A48"/>
				<path d="M75.5961 51.1448C75.8671 51.1448 76.0868 50.925 76.0868 50.6539C76.0868 50.3828 75.8671 50.1631 75.5961 50.1631C75.325 50.1631 75.1053 50.3828 75.1053 50.6539C75.1053 50.925 75.325 51.1448 75.5961 51.1448Z" fill="#383A48"/>
				<path d="M75.604 53.5699C75.875 53.5699 76.0947 53.3501 76.0947 53.079C76.0947 52.8079 75.875 52.5881 75.604 52.5881C75.333 52.5881 75.1133 52.8079 75.1133 53.079C75.1133 53.3501 75.333 53.5699 75.604 53.5699Z" fill="#383A48"/>
				<path d="M75.5961 55.983C75.8671 55.983 76.0868 55.7632 76.0868 55.4921C76.0868 55.221 75.8671 55.0013 75.5961 55.0013C75.325 55.0013 75.1053 55.221 75.1053 55.4921C75.1053 55.7632 75.325 55.983 75.5961 55.983Z" fill="#383A48"/>
				<path d="M75.5961 58.4081C75.8671 58.4081 76.0868 58.1883 76.0868 57.9172C76.0868 57.6461 75.8671 57.4263 75.5961 57.4263C75.325 57.4263 75.1053 57.6461 75.1053 57.9172C75.1053 58.1883 75.325 58.4081 75.5961 58.4081Z" fill="#383A48"/>
				<path d="M75.604 60.8331C75.875 60.8331 76.0947 60.6133 76.0947 60.3422C76.0947 60.0711 75.875 59.8513 75.604 59.8513C75.333 59.8513 75.1133 60.0711 75.1133 60.3422C75.1133 60.6133 75.333 60.8331 75.604 60.8331Z" fill="#383A48"/>
				<path d="M75.5961 63.2503C75.8671 63.2503 76.0868 63.0305 76.0868 62.7594C76.0868 62.4883 75.8671 62.2685 75.5961 62.2685C75.325 62.2685 75.1053 62.4883 75.1053 62.7594C75.1053 63.0305 75.325 63.2503 75.5961 63.2503Z" fill="#383A48"/>
				<path d="M75.604 65.6753C75.875 65.6753 76.0947 65.4555 76.0947 65.1844C76.0947 64.9133 75.875 64.6935 75.604 64.6935C75.333 64.6935 75.1133 64.9133 75.1133 65.1844C75.1133 65.4555 75.333 65.6753 75.604 65.6753Z" fill="#383A48"/>
				<path d="M75.604 68.1004C75.875 68.1004 76.0947 67.8806 76.0947 67.6095C76.0947 67.3384 75.875 67.1186 75.604 67.1186C75.333 67.1186 75.1133 67.3384 75.1133 67.6095C75.1133 67.8806 75.333 68.1004 75.604 68.1004Z" fill="#383A48"/>
				<path d="M75.5961 70.5135C75.8671 70.5135 76.0868 70.2938 76.0868 70.0226C76.0868 69.7515 75.8671 69.5317 75.5961 69.5317C75.325 69.5317 75.1053 69.7515 75.1053 70.0226C75.1053 70.2938 75.325 70.5135 75.5961 70.5135Z" fill="#383A48"/>
				<path d="M75.5961 72.9387C75.8671 72.9387 76.0868 72.7189 76.0868 72.4477C76.0868 72.1766 75.8671 71.9568 75.5961 71.9568C75.325 71.9568 75.1053 72.1766 75.1053 72.4477C75.1053 72.7189 75.325 72.9387 75.5961 72.9387Z" fill="#383A48"/>
				<path d="M73.1869 36.6143C73.4579 36.6143 73.6776 36.3945 73.6776 36.1234C73.6776 35.8523 73.4579 35.6325 73.1869 35.6325C72.9159 35.6325 72.6962 35.8523 72.6962 36.1234C72.6962 36.3945 72.9159 36.6143 73.1869 36.6143Z" fill="#383A48"/>
				<path d="M73.1869 39.0394C73.4579 39.0394 73.6776 38.8196 73.6776 38.5485C73.6776 38.2774 73.4579 38.0576 73.1869 38.0576C72.9159 38.0576 72.6962 38.2774 72.6962 38.5485C72.6962 38.8196 72.9159 39.0394 73.1869 39.0394Z" fill="#383A48"/>
				<path d="M73.1869 41.4525C73.4579 41.4525 73.6776 41.2327 73.6776 40.9616C73.6776 40.6905 73.4579 40.4707 73.1869 40.4707C72.9159 40.4707 72.6962 40.6905 72.6962 40.9616C72.6962 41.2327 72.9159 41.4525 73.1869 41.4525Z" fill="#383A48"/>
				<path d="M73.1869 43.8776C73.4579 43.8776 73.6776 43.6578 73.6776 43.3867C73.6776 43.1156 73.4579 42.8958 73.1869 42.8958C72.9159 42.8958 72.6962 43.1156 72.6962 43.3867C72.6962 43.6578 72.9159 43.8776 73.1869 43.8776Z" fill="#383A48"/>
				<path d="M73.1869 46.3026C73.4579 46.3026 73.6776 46.0829 73.6776 45.8117C73.6776 45.5406 73.4579 45.3209 73.1869 45.3209C72.9159 45.3209 72.6962 45.5406 72.6962 45.8117C72.6962 46.0829 72.9159 46.3026 73.1869 46.3026Z" fill="#383A48"/>
				<path d="M73.1869 48.7197C73.4579 48.7197 73.6776 48.5 73.6776 48.2289C73.6776 47.9578 73.4579 47.738 73.1869 47.738C72.9159 47.738 72.6962 47.9578 72.6962 48.2289C72.6962 48.5 72.9159 48.7197 73.1869 48.7197Z" fill="#383A48"/>
				<path d="M73.1869 51.1448C73.4579 51.1448 73.6776 50.925 73.6776 50.6539C73.6776 50.3828 73.4579 50.1631 73.1869 50.1631C72.9159 50.1631 72.6962 50.3828 72.6962 50.6539C72.6962 50.925 72.9159 51.1448 73.1869 51.1448Z" fill="#383A48"/>
				<path d="M73.1869 53.5699C73.4579 53.5699 73.6776 53.3501 73.6776 53.079C73.6776 52.8079 73.4579 52.5881 73.1869 52.5881C72.9159 52.5881 72.6962 52.8079 72.6962 53.079C72.6962 53.3501 72.9159 53.5699 73.1869 53.5699Z" fill="#383A48"/>
				<path d="M73.1869 55.983C73.4579 55.983 73.6776 55.7632 73.6776 55.4921C73.6776 55.221 73.4579 55.0013 73.1869 55.0013C72.9159 55.0013 72.6962 55.221 72.6962 55.4921C72.6962 55.7632 72.9159 55.983 73.1869 55.983Z" fill="#383A48"/>
				<path d="M73.1869 58.4081C73.4579 58.4081 73.6776 58.1883 73.6776 57.9172C73.6776 57.6461 73.4579 57.4263 73.1869 57.4263C72.9159 57.4263 72.6962 57.6461 72.6962 57.9172C72.6962 58.1883 72.9159 58.4081 73.1869 58.4081Z" fill="#383A48"/>
				<path d="M73.1869 60.8331C73.4579 60.8331 73.6776 60.6133 73.6776 60.3422C73.6776 60.0711 73.4579 59.8513 73.1869 59.8513C72.9159 59.8513 72.6962 60.0711 72.6962 60.3422C72.6962 60.6133 72.9159 60.8331 73.1869 60.8331Z" fill="#383A48"/>
				<path d="M73.1869 63.2503C73.4579 63.2503 73.6776 63.0305 73.6776 62.7594C73.6776 62.4883 73.4579 62.2685 73.1869 62.2685C72.9159 62.2685 72.6962 62.4883 72.6962 62.7594C72.6962 63.0305 72.9159 63.2503 73.1869 63.2503Z" fill="#383A48"/>
				<path d="M73.1869 65.6753C73.4579 65.6753 73.6776 65.4555 73.6776 65.1844C73.6776 64.9133 73.4579 64.6935 73.1869 64.6935C72.9159 64.6935 72.6962 64.9133 72.6962 65.1844C72.6962 65.4555 72.9159 65.6753 73.1869 65.6753Z" fill="#383A48"/>
				<path d="M73.1869 68.1004C73.4579 68.1004 73.6776 67.8806 73.6776 67.6095C73.6776 67.3384 73.4579 67.1186 73.1869 67.1186C72.9159 67.1186 72.6962 67.3384 72.6962 67.6095C72.6962 67.8806 72.9159 68.1004 73.1869 68.1004Z" fill="#383A48"/>
				<path d="M73.1869 70.5135C73.4579 70.5135 73.6776 70.2938 73.6776 70.0226C73.6776 69.7515 73.4579 69.5317 73.1869 69.5317C72.9159 69.5317 72.6962 69.7515 72.6962 70.0226C72.6962 70.2938 72.9159 70.5135 73.1869 70.5135Z" fill="#383A48"/>
				<path d="M73.1869 72.9387C73.4579 72.9387 73.6776 72.7189 73.6776 72.4477C73.6776 72.1766 73.4579 71.9568 73.1869 71.9568C72.9159 71.9568 72.6962 72.1766 72.6962 72.4477C72.6962 72.7189 72.9159 72.9387 73.1869 72.9387Z" fill="#383A48"/>
				<path d="M73.1869 75.3636C73.4579 75.3636 73.6776 75.1439 73.6776 74.8727C73.6776 74.6016 73.4579 74.3819 73.1869 74.3819C72.9159 74.3819 72.6962 74.6016 72.6962 74.8727C72.6962 75.1439 72.9159 75.3636 73.1869 75.3636Z" fill="#383A48"/>
				<path d="M70.7618 36.6143C71.0328 36.6143 71.2525 36.3945 71.2525 36.1234C71.2525 35.8523 71.0328 35.6325 70.7618 35.6325C70.4908 35.6325 70.2711 35.8523 70.2711 36.1234C70.2711 36.3945 70.4908 36.6143 70.7618 36.6143Z" fill="#383A48"/>
				<path d="M70.7618 39.0394C71.0328 39.0394 71.2525 38.8196 71.2525 38.5485C71.2525 38.2774 71.0328 38.0576 70.7618 38.0576C70.4908 38.0576 70.2711 38.2774 70.2711 38.5485C70.2711 38.8196 70.4908 39.0394 70.7618 39.0394Z" fill="#383A48"/>
				<path d="M70.7618 41.4525C71.0328 41.4525 71.2525 41.2327 71.2525 40.9616C71.2525 40.6905 71.0328 40.4707 70.7618 40.4707C70.4908 40.4707 70.2711 40.6905 70.2711 40.9616C70.2711 41.2327 70.4908 41.4525 70.7618 41.4525Z" fill="#383A48"/>
				<path d="M70.7618 43.8776C71.0328 43.8776 71.2525 43.6578 71.2525 43.3867C71.2525 43.1156 71.0328 42.8958 70.7618 42.8958C70.4908 42.8958 70.2711 43.1156 70.2711 43.3867C70.2711 43.6578 70.4908 43.8776 70.7618 43.8776Z" fill="#383A48"/>
				<path d="M70.7618 46.3026C71.0328 46.3026 71.2525 46.0829 71.2525 45.8117C71.2525 45.5406 71.0328 45.3209 70.7618 45.3209C70.4908 45.3209 70.2711 45.5406 70.2711 45.8117C70.2711 46.0829 70.4908 46.3026 70.7618 46.3026Z" fill="#383A48"/>
				<path d="M70.7618 48.7196C71.0328 48.7196 71.2525 48.4998 71.2525 48.2287C71.2525 47.9576 71.0328 47.7378 70.7618 47.7378C70.4908 47.7378 70.2711 47.9576 70.2711 48.2287C70.2711 48.4998 70.4908 48.7196 70.7618 48.7196Z" fill="#383A48"/>
				<path d="M70.7618 51.1448C71.0328 51.1448 71.2525 50.925 71.2525 50.6539C71.2525 50.3828 71.0328 50.1631 70.7618 50.1631C70.4908 50.1631 70.2711 50.3828 70.2711 50.6539C70.2711 50.925 70.4908 51.1448 70.7618 51.1448Z" fill="#383A48"/>
				<path d="M70.7618 53.5699C71.0328 53.5699 71.2525 53.3501 71.2525 53.079C71.2525 52.8079 71.0328 52.5881 70.7618 52.5881C70.4908 52.5881 70.2711 52.8079 70.2711 53.079C70.2711 53.3501 70.4908 53.5699 70.7618 53.5699Z" fill="#383A48"/>
				<path d="M70.7618 55.983C71.0328 55.983 71.2525 55.7632 71.2525 55.4921C71.2525 55.221 71.0328 55.0013 70.7618 55.0013C70.4908 55.0013 70.2711 55.221 70.2711 55.4921C70.2711 55.7632 70.4908 55.983 70.7618 55.983Z" fill="#383A48"/>
				<path d="M70.7618 58.4081C71.0328 58.4081 71.2525 58.1883 71.2525 57.9172C71.2525 57.6461 71.0328 57.4263 70.7618 57.4263C70.4908 57.4263 70.2711 57.6461 70.2711 57.9172C70.2711 58.1883 70.4908 58.4081 70.7618 58.4081Z" fill="#383A48"/>
				<path d="M70.7618 60.8331C71.0328 60.8331 71.2525 60.6133 71.2525 60.3422C71.2525 60.0711 71.0328 59.8513 70.7618 59.8513C70.4908 59.8513 70.2711 60.0711 70.2711 60.3422C70.2711 60.6133 70.4908 60.8331 70.7618 60.8331Z" fill="#383A48"/>
				<path d="M70.7618 63.2503C71.0328 63.2503 71.2525 63.0305 71.2525 62.7594C71.2525 62.4883 71.0328 62.2685 70.7618 62.2685C70.4908 62.2685 70.2711 62.4883 70.2711 62.7594C70.2711 63.0305 70.4908 63.2503 70.7618 63.2503Z" fill="#383A48"/>
				<path d="M70.7618 65.6753C71.0328 65.6753 71.2525 65.4555 71.2525 65.1844C71.2525 64.9133 71.0328 64.6935 70.7618 64.6935C70.4908 64.6935 70.2711 64.9133 70.2711 65.1844C70.2711 65.4555 70.4908 65.6753 70.7618 65.6753Z" fill="#383A48"/>
				<path d="M70.7618 68.1004C71.0328 68.1004 71.2525 67.8806 71.2525 67.6095C71.2525 67.3384 71.0328 67.1186 70.7618 67.1186C70.4908 67.1186 70.2711 67.3384 70.2711 67.6095C70.2711 67.8806 70.4908 68.1004 70.7618 68.1004Z" fill="#383A48"/>
				<path d="M70.7618 70.5135C71.0328 70.5135 71.2525 70.2938 71.2525 70.0226C71.2525 69.7515 71.0328 69.5317 70.7618 69.5317C70.4908 69.5317 70.2711 69.7515 70.2711 70.0226C70.2711 70.2938 70.4908 70.5135 70.7618 70.5135Z" fill="#383A48"/>
				<path d="M70.7618 72.9387C71.0328 72.9387 71.2525 72.7189 71.2525 72.4477C71.2525 72.1766 71.0328 71.9568 70.7618 71.9568C70.4908 71.9568 70.2711 72.1766 70.2711 72.4477C70.2711 72.7189 70.4908 72.9387 70.7618 72.9387Z" fill="#383A48"/>
				<path d="M70.7618 75.3636C71.0328 75.3636 71.2525 75.1439 71.2525 74.8727C71.2525 74.6016 71.0328 74.3819 70.7618 74.3819C70.4908 74.3819 70.2711 74.6016 70.2711 74.8727C70.2711 75.1439 70.4908 75.3636 70.7618 75.3636Z" fill="#383A48"/>
				<path d="M68.3367 36.6143C68.6077 36.6143 68.8274 36.3945 68.8274 36.1234C68.8274 35.8523 68.6077 35.6325 68.3367 35.6325C68.0658 35.6325 67.8461 35.8523 67.8461 36.1234C67.8461 36.3945 68.0658 36.6143 68.3367 36.6143Z" fill="#383A48"/>
				<path d="M68.3367 39.0394C68.6077 39.0394 68.8274 38.8196 68.8274 38.5485C68.8274 38.2774 68.6077 38.0576 68.3367 38.0576C68.0658 38.0576 67.8461 38.2774 67.8461 38.5485C67.8461 38.8196 68.0658 39.0394 68.3367 39.0394Z" fill="#383A48"/>
				<path d="M68.3367 41.4525C68.6077 41.4525 68.8274 41.2327 68.8274 40.9616C68.8274 40.6905 68.6077 40.4707 68.3367 40.4707C68.0658 40.4707 67.8461 40.6905 67.8461 40.9616C67.8461 41.2327 68.0658 41.4525 68.3367 41.4525Z" fill="#383A48"/>
				<path d="M68.3367 43.8776C68.6077 43.8776 68.8274 43.6578 68.8274 43.3867C68.8274 43.1156 68.6077 42.8958 68.3367 42.8958C68.0658 42.8958 67.8461 43.1156 67.8461 43.3867C67.8461 43.6578 68.0658 43.8776 68.3367 43.8776Z" fill="#383A48"/>
				<path d="M68.3367 46.3026C68.6077 46.3026 68.8274 46.0829 68.8274 45.8117C68.8274 45.5406 68.6077 45.3209 68.3367 45.3209C68.0658 45.3209 67.8461 45.5406 67.8461 45.8117C67.8461 46.0829 68.0658 46.3026 68.3367 46.3026Z" fill="#383A48"/>
				<path d="M68.3367 48.7197C68.6077 48.7197 68.8274 48.5 68.8274 48.2289C68.8274 47.9578 68.6077 47.738 68.3367 47.738C68.0658 47.738 67.8461 47.9578 67.8461 48.2289C67.8461 48.5 68.0658 48.7197 68.3367 48.7197Z" fill="#383A48"/>
				<path d="M68.3367 51.1448C68.6077 51.1448 68.8274 50.925 68.8274 50.6539C68.8274 50.3828 68.6077 50.1631 68.3367 50.1631C68.0658 50.1631 67.8461 50.3828 67.8461 50.6539C67.8461 50.925 68.0658 51.1448 68.3367 51.1448Z" fill="#383A48"/>
				<path d="M68.3367 53.5699C68.6077 53.5699 68.8274 53.3501 68.8274 53.079C68.8274 52.8079 68.6077 52.5881 68.3367 52.5881C68.0658 52.5881 67.8461 52.8079 67.8461 53.079C67.8461 53.3501 68.0658 53.5699 68.3367 53.5699Z" fill="#383A48"/>
				<path d="M68.3367 55.983C68.6077 55.983 68.8274 55.7632 68.8274 55.4921C68.8274 55.221 68.6077 55.0013 68.3367 55.0013C68.0658 55.0013 67.8461 55.221 67.8461 55.4921C67.8461 55.7632 68.0658 55.983 68.3367 55.983Z" fill="#383A48"/>
				<path d="M68.3367 58.4081C68.6077 58.4081 68.8274 58.1883 68.8274 57.9172C68.8274 57.6461 68.6077 57.4263 68.3367 57.4263C68.0658 57.4263 67.8461 57.6461 67.8461 57.9172C67.8461 58.1883 68.0658 58.4081 68.3367 58.4081Z" fill="#383A48"/>
				<path d="M68.3367 60.8331C68.6077 60.8331 68.8274 60.6133 68.8274 60.3422C68.8274 60.0711 68.6077 59.8513 68.3367 59.8513C68.0658 59.8513 67.8461 60.0711 67.8461 60.3422C67.8461 60.6133 68.0658 60.8331 68.3367 60.8331Z" fill="#383A48"/>
				<path d="M68.3367 63.2503C68.6077 63.2503 68.8274 63.0305 68.8274 62.7594C68.8274 62.4883 68.6077 62.2685 68.3367 62.2685C68.0658 62.2685 67.8461 62.4883 67.8461 62.7594C67.8461 63.0305 68.0658 63.2503 68.3367 63.2503Z" fill="#383A48"/>
				<path d="M68.3367 65.6753C68.6077 65.6753 68.8274 65.4555 68.8274 65.1844C68.8274 64.9133 68.6077 64.6935 68.3367 64.6935C68.0658 64.6935 67.8461 64.9133 67.8461 65.1844C67.8461 65.4555 68.0658 65.6753 68.3367 65.6753Z" fill="#383A48"/>
				<path d="M68.3367 68.1004C68.6077 68.1004 68.8274 67.8806 68.8274 67.6095C68.8274 67.3384 68.6077 67.1186 68.3367 67.1186C68.0658 67.1186 67.8461 67.3384 67.8461 67.6095C67.8461 67.8806 68.0658 68.1004 68.3367 68.1004Z" fill="#383A48"/>
				<path d="M68.3367 70.5135C68.6077 70.5135 68.8274 70.2938 68.8274 70.0226C68.8274 69.7515 68.6077 69.5317 68.3367 69.5317C68.0658 69.5317 67.8461 69.7515 67.8461 70.0226C67.8461 70.2938 68.0658 70.5135 68.3367 70.5135Z" fill="#383A48"/>
				<path d="M68.3367 72.9387C68.6077 72.9387 68.8274 72.7189 68.8274 72.4477C68.8274 72.1766 68.6077 71.9568 68.3367 71.9568C68.0658 71.9568 67.8461 72.1766 67.8461 72.4477C67.8461 72.7189 68.0658 72.9387 68.3367 72.9387Z" fill="#383A48"/>
				<path d="M68.3367 75.3636C68.6077 75.3636 68.8274 75.1439 68.8274 74.8728C68.8274 74.6016 68.6077 74.3819 68.3367 74.3819C68.0658 74.3819 67.8461 74.6016 67.8461 74.8728C67.8461 75.1439 68.0658 75.3636 68.3367 75.3636Z" fill="#383A48"/>
				<path d="M65.6414 75.0599C76.6227 75.0599 85.5248 66.1546 85.5248 55.1693C85.5248 44.184 76.6227 35.2787 65.6414 35.2787C54.6601 35.2787 45.7581 44.184 45.7581 55.1693C45.7581 66.1546 54.6601 75.0599 65.6414 75.0599Z" fill="#F3F6E6"/>
				<path d="M65.639 75.5506C54.4019 75.5506 45.265 66.4103 45.265 55.1691C45.265 43.9279 54.4019 34.7778 65.639 34.7778C76.8762 34.7778 86.0131 43.9181 86.0131 55.1593C86.0131 66.4005 76.8762 75.5506 65.639 75.5506ZM65.639 35.7596C54.9417 35.7596 46.2464 44.4678 46.2464 55.1593C46.2464 65.8507 54.9515 74.559 65.639 74.559C76.3364 74.559 85.0317 65.8507 85.0317 55.1593C85.0317 44.4678 76.3364 35.7596 65.639 35.7596Z" fill="#383A48"/>
				<path d="M65.1761 70.3847C73.5773 70.3847 80.3879 63.5717 80.3879 55.1673C80.3879 46.763 73.5773 39.95 65.1761 39.95C56.7748 39.95 49.9642 46.763 49.9642 55.1673C49.9642 63.5717 56.7748 70.3847 65.1761 70.3847Z" fill="#E0719E"/>
				<path d="M68.0348 53.7237C67.7848 54.9633 67.2327 55.9789 66.3786 56.7706C65.5348 57.5622 64.3838 58.1299 62.9254 58.4737L69.1911 65.5049H63.9098L58.1442 58.7081L58.7379 55.6456H59.5661C61.139 55.6456 62.1806 55.4685 62.6911 55.1143C63.2119 54.7497 63.5661 54.2862 63.7536 53.7237H58.1442V50.5049H63.7067C63.54 50.0987 63.3056 49.7654 63.0036 49.5049C62.7119 49.2445 62.3577 49.0674 61.9411 48.9737C61.5244 48.8695 60.8786 48.8174 60.0036 48.8174H58.1442V45.5987H72.7067V48.8174H66.6598C67.139 49.1924 67.5244 49.7549 67.8161 50.5049H72.7067V53.7237H68.0348Z" fill="#F3F6E6"/>
			</svg>
    );
};

export default Money;
