import React from "react";

const BackArrow2 = ({
	...otherProps
}) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="#303030"
			xmlns="http://www.w3.org/2000/svg"
			{...otherProps}
		>
			<path d="M10.7352 4.4925C10.5404 4.49818 10.3555 4.57943 10.2196 4.71906L3.21958 11.7191C3.07898 11.8597 3 12.0505 3 12.2493C3 12.4482 3.07898 12.639 3.21958 12.7796L10.2196 19.7796C10.2887 19.8516 10.3715 19.9091 10.4631 19.9486C10.5547 19.9882 10.6532 20.0091 10.753 20.0102C10.8528 20.0112 10.9518 19.9923 11.0442 19.9545C11.1365 19.9168 11.2205 19.8611 11.291 19.7905C11.3616 19.7199 11.4173 19.636 11.4551 19.5436C11.4928 19.4513 11.5117 19.3523 11.5107 19.2525C11.5097 19.1527 11.4887 19.0541 11.4492 18.9625C11.4096 18.8709 11.3521 18.7882 11.2801 18.7191L5.5604 12.9993H20.2499C20.3492 13.0007 20.4479 12.9824 20.5401 12.9453C20.6324 12.9083 20.7163 12.8532 20.7871 12.7835C20.8579 12.7137 20.9141 12.6305 20.9524 12.5388C20.9908 12.4471 21.0105 12.3487 21.0105 12.2493C21.0105 12.1499 20.9908 12.0515 20.9524 11.9598C20.9141 11.8682 20.8579 11.785 20.7871 11.7152C20.7163 11.6454 20.6324 11.5904 20.5401 11.5534C20.4479 11.5163 20.3492 11.4979 20.2499 11.4993H5.5604L11.2801 5.77961C11.3883 5.67423 11.4622 5.53864 11.4921 5.39059C11.5219 5.24254 11.5064 5.08892 11.4475 4.94983C11.3886 4.81075 11.2891 4.69268 11.162 4.61108C11.0349 4.52948 10.8862 4.48815 10.7352 4.4925Z" />
		</svg>
  );
};

export default BackArrow2;
