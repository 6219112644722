import { Image, Text, View } from "native-base";
import React, { useEffect, useState } from "react";
import { useWindowDimensions } from "react-native-web";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ManageBenefitService from "../../../services/ManageBenefitService";
import { consoleError } from "../../../utils/functions";
import Loader from "../../core/loader";
import AddBenefitSection from "../Components/AddBenefitSection";
import Skip from "../Components/Skip";
import queryString from "query-string";
import {
  setBenefitsAddition,
  setCallBackUrl,
  setChannel,
  setErrorDetails,
  setMaxLoungeCount,
} from "../../../store/actions/ManageBenefits";
import Header from "../Components/Header";
import logger from "../../../utils/logger";
import warningIcon from "../../../assets/images/warningIcon.svg";

const OnboardingAddBenefit = () => {
  const navigate = useNavigate();
  const dimensions = useWindowDimensions();
  const location = useLocation();
  const dispatch = useDispatch();
  const [availableBenefits, setAvailableBenefits] = useState([]);
  const manageBenefits = useSelector((state) => state.manageBenefits);
  const programConfigsData = useSelector((state) => state.programConfigs?.data);
  const isSkipEnabled = !programConfigsData?.onboardConfig?.isEnabled;
  //temp
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    let accountId = queryParams.accountId || localStorage.getItem("cardId");
    (async () => {
      try {
        const queryParams = queryString.parse(location.search);
        if (!queryParams.accountId || !queryParams.channel) {
          navigate("/Features/GenericError");
          return;
        }

        dispatch(
          setChannel({
            channel: queryParams.channel,
          })
        );
        localStorage.setItem("applicantId", queryParams.accountId);

        const response = await ManageBenefitService.getAllBenefits({
          subscriptionStatus: true,
          accountId: queryParams.accountId,
        });
        let response2 = null;
        if (programConfigsData?.featurePrioritisationConfig?.isEnabled) {
          response2 = await ManageBenefitService.getBenefitStats({
            type: "FEATURE_PRIORITISATION",
            accountId: queryParams.accountId,
          });
        }
        const result = response.data;
        const result2 = response2?.data;
        if (response.status === 200) {
          let modifiedAvailableBenefits = [];
          let modifiedBenefitStatObject = {};
          if (response2 && response2?.status === 200) {
            for (let j = 0; j < result2.benefitNudges.length; j++) {
              modifiedBenefitStatObject[result2.benefitNudges[j].benefitId] = {
                text: result2.benefitNudges[j].text,
                priority: result2.benefitNudges[j].priority,
              };
            }
          }
          for (let i = 0; i < result.benefits.length; i++) {
            let tempItem = result.benefits[i];
            tempItem.nudgeDetails =
              modifiedBenefitStatObject[result.benefits[i].id] || {};
            modifiedAvailableBenefits.push(tempItem);
          }
          modifiedAvailableBenefits = modifiedAvailableBenefits.sort(
            (a, b) => b.nudgeDetails.priority - a.nudgeDetails.priority
          );
          setAvailableBenefits(modifiedAvailableBenefits);
          let maxLoungeCount = 0;
          for (let i = 0; i < result.benefits.length; i++) {
            if (result.benefits[i].type === "AIRPORT_LOUNGE") {
              maxLoungeCount = result.benefits[i].maxConcurrentVouchers;
              break;
            }
          }
          dispatch(
            setMaxLoungeCount({
              leadId: result.leadId,
              maxLoungeCount:
                maxLoungeCount -
                Number(
                  result.countOfActiveLoungeVouchers
                    ? result.countOfActiveLoungeVouchers
                    : 0
                ),
            })
          );
          const temp = result.benefits.filter((item) => {
            return item.subscribed;
          });
          const tempGrouping = [];
          const tempId = [];
          for (let i = 0; i < temp.length; i++) {
            if (temp[i].benefitGroup) {
              if (!tempGrouping.includes(temp[i].benefitGroup.id)) {
                tempGrouping.push(temp[i].benefitGroup.id);
                tempId.push(temp[i].id);
              }
            }
          }
          dispatch(
            setBenefitsAddition({
              benefits: [...manageBenefits.benefitsList],
              benefitsListId: [...manageBenefits.benefitsListId],
              benefitsGroupId: [
                ...manageBenefits.benefitsGroupId,
                ...tempGrouping,
              ],
            })
          );
          if (result.callbackUrl) {
            dispatch(
              setCallBackUrl({
                callBackUrl: result.callbackUrl,
              })
            );
          } else {
            dispatch(
              setCallBackUrl({
                callBackUrl:
                  "https://www.aubank.in/personal-banking/credit-cards",
              })
            );
          }
        } else {
          logger.error({
            message: `An error occurred while getAllBenefits for accountId ${accountId}`,
            responseData: response.data,
            accountId: accountId,
            correlationId: response.headers.x_correlation_id,
          });
          if (response.status === 403) {
            if (response.data === "") {
              navigate("/Features/Forbidden");
            } else {
              dispatch(
                setErrorDetails({
                  errorDetails: response.data,
                })
              );

              navigate("/Features/Error");
            }
          } else {
            logger.error({
              message: `An error occurred while getAllBenefits for accountId ${accountId}`,
              responseData: response.data,
              accountId: accountId,
              correlationId: response.headers.x_correlation_id,
            });

            navigate("/Features/GenericError", {
              state: {
                x_correlation_id: response.headers.x_correlation_id,
              },
            });
          }
          consoleError("Fetching available and active Features failed");
        }
      } catch (error) {
        logger.error({
          message: `An exception occurred while getAllBenefits for accountId ${accountId}`,
          error: error.message,
          accountId: accountId,
          stackTrace: error.stack,
        });
        consoleError(error);
      }
      setLoading(false);
    })();
  }, []);
  return loading ? (
    <View alignItems="center" justifyContent="center" bgColor={"#FFFDFA"}>
      <View _web={{ maxW: "412px" }} bgColor="#FFFFFF">
        <View
          w="100%"
          _web={{ maxW: "412px" }}
          alignItems="center"
          justifyContent="center"
          bgColor={"#FFFFFF"}
          height={dimensions.height}
        >
          <Loader color={"#F37435"} width={120} height={120} />
        </View>
      </View>
    </View>
  ) : (
    <View alignItems={"center"} justifyContent="center" bgColor={"#FFFDFA"}>
      <View _web={{ maxW: "412px" }} width="100%" bgColor={"#FFFFFF"}>
        {manageBenefits.channel === "CCA" && <Header />}
        <Skip />
        {!isSkipEnabled && <View
          flexDir={"row"}
          alignItems={"center"}
          gap="4px"
          mt="16px"
          backgroundColor={"#FEF2F0"}
          _web={{ maxW: "412px" }}
          mr={"16px"}
          ml={"16px"}
          py={"6px"}
          px={"8px"}
          borderRadius={"6px"}
        >
          <Image
            source={warningIcon}
            height="12px"
            width="12px"
            alt="warningIcon"
          />
          <Text color={"#303030"}  fontSize={"12px"}>
            Please opt-in for at least one feature to proceed
          </Text>
        </View>}
        <View mt="16px">
          <AddBenefitSection
            availableBenefits={availableBenefits}
            isCategorized={true}
            isOnboarding={true}
          />
        </View>
      </View>
    </View>
  );
};

export default OnboardingAddBenefit;
