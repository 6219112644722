import { View, Image } from "native-base";
import React, { useState, useEffect } from "react";
import WheelBackground from "../../../../assets/images/WheelBackground.svg";

// get offset angle for current slice based on previous slices
const getSliceOffsetAngle = (slices, sliceIndex) => {
  let sliceOffsetAngle = 0;

  // add half angle of current slice
  sliceOffsetAngle += slices[sliceIndex].angle / 2;

  // add full angles of all previous slices
  for (let i = 0; i < sliceIndex; i++) {
    sliceOffsetAngle += slices[i].angle;
  }

  return sliceOffsetAngle;
};

// TODO: borders don't show properly if one slice's ratio is greater than 50%.
// Even current border UI fails for large width borders. Try superimposing a div
// containing borders on top of the pie div.
const Pie = ({
  pieRadiusInPixels = 150,
  pieBorderWidthInPixels = 0, // TODO: setting this causes the wheel to slighlty wobble
  pieBorderColor = "#000",
  secantBorderWidthInPixels = 0.5,
  secantBorderColor = "#000",
  pieOffsetAngle = 0,
  spinDurationInSeconds = 3,
  // to control the total rotation of the pie in a spin
  numberOfRotations = 6,
  slices: propSlices,
  targetIndex,
  spinCount,
  onSpinEnd
}) => {
  const pieStyle = {
    // add transition for spin
    transitionProperty: "transform",
    transitionDuration: `${spinDurationInSeconds}s`,
    // give position so that position absolute of slices works
    position: "relative",
    // border for pie
    borderRadius: "50%",
    border: `${pieBorderWidthInPixels}px solid ${pieBorderColor}`,
    width: `${(pieRadiusInPixels * 2) + (pieBorderWidthInPixels * 2)}px`,
    height: `${(pieRadiusInPixels * 2) + (pieBorderWidthInPixels * 2)}px`,
    // for any initial angular alignment on the pie (in the calculations we assume
    // initial angle 0 and hence are unaffected by this value)
    transform: `rotate(${pieOffsetAngle}deg)`
  };

  const sliceContainerStyle = {
    // position absolute so all slice containers overlap each other
    position: "absolute",
    width: `${pieRadiusInPixels * 2}px`,
    height: `${pieRadiusInPixels * 2}px`
  };

  const secantContainerStyle = {
    width: `${pieRadiusInPixels * 2}px`,
    height: `${pieRadiusInPixels * 2}px`
  };

  const secantStyle = {
    // give position so that secant border can be positioned relative to this secant
    position: "relative",
    height: `${pieRadiusInPixels * 2}px`,
    // hide the part of sector overflowing beyond the intersection
    overflow: "hidden",
  };

  const secantBorderStyle = {
    position: "absolute",
    background: secantBorderColor,
    right: "0px",
    width: `${secantBorderWidthInPixels}px`,
    height: `${pieRadiusInPixels}px`
  };

  const sectorStyle = {
    width: `${pieRadiusInPixels * 2}px`,
    height: `${pieRadiusInPixels * 2}px`,
    borderRadius: "50%"
  };

  const textStyle = {
    // TODO: text width should be proportionate to slice angle
    width: "55px",
    textAlign: "center",
    fontFamily: "Quicksand",
    fontSize: "12px",
    fontWeight: 700,
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "10px"
  };

  const [slices, setSlices] = useState(propSlices);
  const [lastSpunAngle, setLastSpunAngle] = useState(0);

  // on mount
  useEffect(() => {
    const slicesWithAngle = slices.map(slice => {
      // if slice ratios are not provided set equal ratio for all
      const sliceRatio = slice.ratio || (100 / slices?.length);
      slice.angle = (360 * sliceRatio) / 100;
      return slice;
    });
    setSlices(slicesWithAngle);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propSlices]);

  // on spin
  useEffect(() => {
    if (spinCount > 0) {
      const targetSliceOffsetAngle = getSliceOffsetAngle(slices, targetIndex);
      const pie = document.getElementById("pie");

      // by default transition always starts from initial position
      // to start next spin from where last spin was left set last spun angle
      pie.style.transform = `rotate(${lastSpunAngle}deg)`;

      // calculate final angle
      // start with last rotated angle
      let currentAngle = lastSpunAngle;
      // counter rotate to bring first slice to center
      currentAngle -= lastSpunAngle % 360;
      // counter rotate to bring target slice to center
      currentAngle -= targetSliceOffsetAngle;
      // do full rotations
      currentAngle += numberOfRotations * 360;

      // trigger the animation
      setTimeout(() => {
        pie.style.transform = `rotate(${currentAngle}deg)`;
        // update last spun angle
        setLastSpunAngle(currentAngle);
      });

      // on spin end
      setTimeout(() => {
        // callback
        onSpinEnd();
      }, spinDurationInSeconds * 1000);
    }

    // trigger this function on spin count change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spinCount]);

  return (
    <div
      id="pie"
      style={{ ...pieStyle }}
    >
      <Image
        position="absolute"
        source={{ uri: WheelBackground }}
        maxWidth="unset"
        width={(pieRadiusInPixels * 2) + 50}
        height={(pieRadiusInPixels * 2) + 50}
        top={-25}
        left={-25}
      />
      {slices.map((slice, sliceIndex) => {
        // if slice angle is greater than 180 then hide this slice behind all other slices
        const sliceZIndex = slice.angle < 180 ? 1 : -1;

        const sliceOffsetAngle = getSliceOffsetAngle(slices, sliceIndex);
        const firstSecantContainerAngle = slice.angle / 2;
        const secondSecantContainerAngle = 180 - slice.angle;

        // if slice angle is less than 180 then halve the width to intersect the colored descendant sector from
        // the center of pie. Subtract some pixels to make space for the secant border.
        const secantWidth = (
          slice.angle < 180 ? pieRadiusInPixels : (pieRadiusInPixels * 2)
          ) - secantBorderWidthInPixels;

        // if slice angle is greater than 180 then border goes outside the pie, so hide it
        const secantBorderDisplay = slice.angle < 180 ? "block" : "none";

        const sectorAngle = (180 - (slice.angle / 2)) * -1;

        return (
          // TODO: show error on less than 2 slices
          <View
            // slice container
            key={sliceIndex}
            style={{
              ...sliceContainerStyle,
              zIndex: sliceZIndex,
              // rotate slice container by offset angle to start from where the previous slice ended
              transform: `rotate(${sliceOffsetAngle}deg)`
            }}
          >
            <View
              // first secant container
              style={{
                ...secantContainerStyle,
                // rotate the secant container to rotate the secant div along the center of the slice container
                // direct rotation of the secant div will be along the center of the reduced width of the secant div and not the slice container's center
                transform: `rotate(${firstSecantContainerAngle}deg)`
              }}
            >
              <View
                // first secant div
                // the edge of this div will intersect the colored div to make the slice
                style={{
                  ...secantStyle,
                  width: `${secantWidth}px`
                }}
              >
                <View
                // second secant container
                style={{
                  ...secantContainerStyle,
                  transform: `rotate(${secondSecantContainerAngle}deg)`
                }}
                >
                  <View
                    // second secant div
                    // this div will do the second intersection on the colored div
                    style={{
                      ...secantStyle,
                      width: `${secantWidth}px`
                    }}
                  >
                    <View
                      // sector
                      // this div will contain the color and be intersected by secant divs to become the sector (the actual slice)
                      style={{
                        ...sectorStyle,
                        backgroundColor: slice.backgroundColor,
                        // rotate the sector to counter the rotations of parent secant containers
                        // this will align the sector div with the slice container div
                        // this is needed so that the child text div can be aligned to top center of the sector div and be visible in the center of the slice
                        transform: `rotate(${sectorAngle}deg)`
                      }}
                    >
                      <View
                        // text
                        style={{ ...textStyle }}
                      >
                        {slice.description}
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  // secant border
                  // this div will appear like the half heighted right border of the first secant div
                  style={{
                    ...secantBorderStyle,
                    display: secantBorderDisplay
                  }}
                ></View>
              </View>
            </View>
          </View>
        );
      })}
    </div>
  )
};

export default Pie;
