import React from "react";

const VolumeUp = ({
    ...otherProps
}) => {
    return (
			<svg
				width="23"
				height="20"
				viewBox="0 0 23 20"
				fill="#303030"
				xmlns="http://www.w3.org/2000/svg"
				{...otherProps}
			>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M11.0327 0.702695C11.1717 0.764091 11.2899 0.864563 11.3728 0.991889C11.4558 1.11921 11.5 1.26791 11.5 1.41988V18.6699C11.5001 18.8219 11.456 18.9706 11.3731 19.098C11.2902 19.2254 11.172 19.326 11.033 19.3875C10.894 19.449 10.7401 19.4687 10.5901 19.4443C10.44 19.4199 10.3003 19.3524 10.1879 19.2501L4.66272 14.2267H1.82954C1.34432 14.2267 0.878967 14.0339 0.535861 13.6908C0.192755 13.3477 0 12.8824 0 12.3971V7.6926C0 6.68269 0.819636 5.86306 1.82954 5.86306H4.66272L10.1879 0.83965C10.3005 0.737613 10.4402 0.670451 10.5901 0.646324C10.7401 0.622197 10.8938 0.642144 11.0327 0.703741V0.702695ZM9.93181 3.19192L5.49386 7.22737C5.34939 7.35868 5.16113 7.43138 4.9659 7.43124H1.82954C1.76023 7.43124 1.69375 7.45877 1.64473 7.50779C1.59572 7.5568 1.56818 7.62328 1.56818 7.6926V12.3971C1.56818 12.5414 1.68527 12.6585 1.82954 12.6585H4.9659C5.16113 12.6584 5.34939 12.7311 5.49386 12.8624L9.93181 16.8978V3.19192Z" />
				<path d="M18.5234 1.91333C18.6704 1.7665 18.8697 1.68402 19.0775 1.68402C19.2853 1.68402 19.4845 1.7665 19.6316 1.91333C24.1228 6.4046 24.1228 13.6851 19.6316 18.1764C19.4829 18.3149 19.2863 18.3903 19.0832 18.3867C18.8801 18.3831 18.6863 18.3009 18.5426 18.1572C18.3989 18.0135 18.3166 17.8197 18.3131 17.6166C18.3095 17.4135 18.3849 17.2169 18.5234 17.0682C19.4458 16.146 20.1775 15.051 20.6767 13.8459C21.1759 12.6409 21.4328 11.3493 21.4328 10.0449C21.4328 8.74049 21.1759 7.44888 20.6767 6.2438C20.1775 5.03872 19.4458 3.94379 18.5234 3.02151C18.3765 2.87449 18.2941 2.67521 18.2941 2.46742C18.2941 2.25964 18.3765 2.06035 18.5234 1.91333Z" />
				<path d="M15.9359 5.60902C15.863 5.53618 15.7766 5.47841 15.6814 5.43899C15.5863 5.39958 15.4843 5.37929 15.3813 5.37929C15.2783 5.37929 15.1763 5.39958 15.0811 5.43899C14.986 5.47841 14.8995 5.53618 14.8267 5.60902C14.7538 5.68185 14.696 5.76831 14.6566 5.86348C14.6172 5.95864 14.5969 6.06063 14.5969 6.16363C14.5969 6.26663 14.6172 6.36862 14.6566 6.46378C14.696 6.55894 14.7538 6.64541 14.8267 6.71824C15.2635 7.1551 15.6101 7.67373 15.8465 8.24451C16.0829 8.8153 16.2046 9.42706 16.2046 10.0449C16.2046 10.6627 16.0829 11.2745 15.8465 11.8452C15.6101 12.416 15.2635 12.9347 14.8267 13.3715C14.6882 13.5201 14.6127 13.7167 14.6163 13.9199C14.6199 14.123 14.7022 14.3168 14.8459 14.4605C14.9895 14.6041 15.1833 14.6864 15.3865 14.69C15.5896 14.6936 15.7862 14.6182 15.9348 14.4797C17.1111 13.3033 17.7719 11.7079 17.7719 10.0444C17.7719 8.3808 17.1111 6.78537 15.9348 5.60902H15.9359Z" />
			</svg>
    );
};

export default VolumeUp;
