import { Image, Text, View } from "native-base";
import React, { Children } from "react";

const VoucherBottomText = ({ showLock, children }) => {
  return (
    <View
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      mt={"-4px"}
    >
      {showLock ? (
        <Image
          source={{
            uri: process.env.REACT_APP_AWS_S3_BUCKET_URL + "lock.svg",
          }}
          height="20px"
          width="20px"
          alt=""
        />
      ) : (
        <Text
          font={"Quicksand"}
          color={"#303030"}
          fontSize={"12px"}
          lineHeight={"20px"}
          fontWeight={"700"}
        >
          Note:{" "}
        </Text>
      )}
      <Text
        ml={showLock ? "8px" : "0px"}
        font={"Quicksand"}
        color={"#303030"}
        fontSize={"12px"}
        lineHeight={"20px"}
      >
        {children}
      </Text>
    </View>
  );
};

export default VoucherBottomText;
