import { Image, Text, View } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import benefit from "../../../assets/images/benefit.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Close from "../../svg/close";
import Details from "../Components/details";
import { setBenefitsAddition } from "../../../store/actions/ManageBenefits";
import { setLoungeCount } from "../../../store/actions/ManageBenefits";
import Header from "../Components/Header";
import useNavigateToPath from "../../../hooks/useNavigateToPath";
import { useTrackEvents } from "../../../hooks/useTrackEvents";
import { TrackEventTypes } from "../../../utils/enums";
import {
  benefitNameWithId,
  getPageNameByRoute,
} from "../../../utils/functions";

const BenefitDetails = (props) => {
  const manageBenefits = useSelector((state) => state.manageBenefits);
  const [prevCat, setPrevCat] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const navigateToPath = useNavigateToPath();
  const trackEventsAdobeAnalytics = useTrackEvents();

  const addLoungeCount = () => {
    if (
      manageBenefits.benefitsListIdCount[manageBenefits.benefit.id.toString()]
    ) {
      let group =
        manageBenefits.benefitsGroupIdCount +
        Number(manageBenefits.benefit.vouchersToBeIssued);

      let item = manageBenefits.benefitsListIdCount;
      item[manageBenefits.benefit.id.toString()] =
        manageBenefits.benefitsListIdCount[
          manageBenefits.benefit.id.toString()
        ] + 1;
      dispatch(
        setLoungeCount({
          benefitsGroupIdCount: group,
          benefitsListIdCount: item,
        })
      );
      dispatch(
        setBenefitsAddition({
          benefits: [...manageBenefits.benefitsList, manageBenefits.benefit],
          benefitsListId: [
            ...manageBenefits.benefitsListId,
            manageBenefits.benefit.id,
          ],
          benefitsGroupId: [...manageBenefits.benefitsGroupId],
        })
      );
    } else {
      dispatch(
        setBenefitsAddition({
          benefits: [...manageBenefits.benefitsList, manageBenefits.benefit],
          benefitsListId: [
            ...manageBenefits.benefitsListId,
            manageBenefits.benefit.id,
          ],
          benefitsGroupId: [...manageBenefits.benefitsGroupId],
        })
      );

      let group =
        manageBenefits.benefitsGroupIdCount +
        Number(manageBenefits.benefit.vouchersToBeIssued);
      let item = manageBenefits.benefitsListIdCount;
      item[manageBenefits.benefit.id.toString()] = 1;
      dispatch(
        setLoungeCount({
          benefitsGroupIdCount: group,
          benefitsListIdCount: item,
        })
      );
    }
  };
  const handleAddBenefitEvent = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.ADD_BENEFIT,
      linkPageName: getPageNameByRoute(location.pathname),
      benefitsName: [
        benefitNameWithId(
          manageBenefits?.benefit?.title,
          manageBenefits?.benefit?.id
        ),
      ],
    });
  };
  const handleRemoveBenefitEvent = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.REMOVE_BENEFIT,
      linkPageName: getPageNameByRoute(location.pathname),
      benefitsName: [
        benefitNameWithId(
          manageBenefits?.benefit?.title,
          manageBenefits?.benefit?.id
        ),
      ],
      walletBalance: manageBenefits?.walletDetails?.totalBalanceAmount,
    });
  };
  const removeLoungeCount = () => {
    if (
      manageBenefits.benefitsListIdCount[
        manageBenefits.benefit.id.toString()
      ] !== 1
    ) {
      let group =
        manageBenefits.benefitsGroupIdCount -
        Number(manageBenefits.benefit.vouchersToBeIssued);
      let item = manageBenefits.benefitsListIdCount;
      item[manageBenefits.benefit.id.toString()] =
        manageBenefits.benefitsListIdCount[
          manageBenefits.benefit.id.toString()
        ] - 1;
      dispatch(
        setLoungeCount({
          benefitsGroupIdCount: group,
          benefitsListIdCount: item,
        })
      );
      let tempList = [];
      let tempListId = [];
      let tempListLoungeCount = 0;
      for (let i = 0; i < manageBenefits.benefitsList.length; i++) {
        if (manageBenefits.benefitsList[i].id !== manageBenefits.benefit.id) {
          tempList.push(manageBenefits.benefitsList[i]);
          tempListId.push(manageBenefits.benefitsList[i].id);
        } else {
          if (tempListLoungeCount < 1) {
            tempListLoungeCount = tempListLoungeCount + 1;
          } else {
            tempList.push(manageBenefits.benefitsList[i]);
            tempListId.push(manageBenefits.benefitsList[i].id);
          }
        }
      }
      dispatch(
        setBenefitsAddition({
          benefits: tempList,
          benefitsListId: tempListId,
          benefitsGroupId: [...manageBenefits.benefitsGroupId],
        })
      );
    } else {
      dispatch(
        setBenefitsAddition({
          benefits: manageBenefits.benefitsList.filter((item) => {
            return item.id !== manageBenefits.benefit.id;
          }),
          benefitsListId: manageBenefits.benefitsListId.filter((item) => {
            return item !== manageBenefits.benefit.id;
          }),
          benefitsGroupId: [...manageBenefits.benefitsGroupId],
        })
      );

      let group =
        manageBenefits.benefitsGroupIdCount -
        Number(manageBenefits.benefit.vouchersToBeIssued);
      let item = manageBenefits.benefitsListIdCount;
      item[manageBenefits.benefit.id.toString()] = undefined;
      dispatch(
        setLoungeCount({
          benefitsGroupIdCount: group,
          benefitsListIdCount: item,
        })
      );
    }
  };

  // const addLoungeCount = () => {
  //   if (
  //     manageBenefits.benefitsListIdCount[manageBenefits.benefit.id.toString()]
  //   ) {
  //     let group = manageBenefits.benefitsGroupIdCount;
  //     group[manageBenefits.benefit.benefitGroup.id.toString()] =
  //       manageBenefits.benefitsGroupIdCount[
  //         manageBenefits.benefit.benefitGroup.id.toString()
  //       ] + 1;
  //     let item = manageBenefits.benefitsListIdCount;
  //     item[manageBenefits.benefit.id.toString()] =
  //       manageBenefits.benefitsListIdCount[
  //         manageBenefits.benefit.id.toString()
  //       ] + 1;
  //     dispatch(
  //       setLoungeCount({
  //         benefitsGroupIdCount: group,
  //         benefitsListIdCount: item,
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       setBenefitsAddition({
  //         benefits: [...manageBenefits.benefitsList, manageBenefits.benefit],
  //         benefitsListId: [
  //           ...manageBenefits.benefitsListId,
  //           manageBenefits.benefit.id,
  //         ],
  //         benefitsGroupId: [
  //           ...manageBenefits.benefitsGroupId,
  //           manageBenefits.benefit.benefitGroup.id,
  //         ],
  //       })
  //     );

  //     let group = manageBenefits.benefitsGroupIdCount;
  //     group[manageBenefits.benefit.benefitGroup.id.toString()] = 1;
  //     let item = manageBenefits.benefitsListIdCount;
  //     item[manageBenefits.benefit.id.toString()] = 1;
  //     dispatch(
  //       setLoungeCount({
  //         benefitsGroupIdCount: group,
  //         benefitsListIdCount: item,
  //       })
  //     );
  //   }
  // };

  // const removeLoungeCount = () => {
  //   if (
  //     manageBenefits.benefitsListIdCount[
  //       manageBenefits.benefit.id.toString()
  //     ] !== 1
  //   ) {
  //     let group = manageBenefits.benefitsGroupIdCount;
  //     group[manageBenefits.benefit.benefitGroup.id.toString()] =
  //       manageBenefits.benefitsGroupIdCount[
  //         manageBenefits.benefit.benefitGroup.id.toString()
  //       ] - 1;
  //     let item = manageBenefits.benefitsListIdCount;
  //     item[manageBenefits.benefit.id.toString()] =
  //       manageBenefits.benefitsListIdCount[
  //         manageBenefits.benefit.id.toString()
  //       ] - 1;
  //     dispatch(
  //       setLoungeCount({
  //         benefitsGroupIdCount: group,
  //         benefitsListIdCount: item,
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       setBenefitsAddition({
  //         benefits: manageBenefits.benefitsList.filter((item) => {
  //           return item.id !== manageBenefits.benefit.id;
  //         }),
  //         benefitsListId: manageBenefits.benefitsListId.filter((item) => {
  //           return item !== manageBenefits.benefit.id;
  //         }),
  //         benefitsGroupId: manageBenefits.benefitsGroupId.filter((item) => {
  //           return item !== manageBenefits.benefit.benefitGroup.id;
  //         }),
  //       })
  //     );

  //     let group = manageBenefits.benefitsGroupIdCount;
  //     group[manageBenefits.benefit.benefitGroup.id.toString()] = undefined;
  //     let item = manageBenefits.benefitsListIdCount;
  //     item[manageBenefits.benefit.id.toString()] = undefined;
  //     dispatch(
  //       setLoungeCount({
  //         benefitsGroupIdCount: group,
  //         benefitsListIdCount: item,
  //       })
  //     );
  //   }
  // };
  useEffect(() => {
    if (location?.state?.category) {
      setPrevCat(location.state.category);
    }
  }, []);
  const goBack = () => {
    if (prevCat) {
      navigate("/Features/ManageFeatures/AddFeature", {
        state: { category: prevCat },
      });
    } else {
      navigateToPath("/Features/ManageFeatures", { category: null });
    }
    
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.LEFT_ARROW_CLICK,
      benefitsName: [
        benefitNameWithId(
          manageBenefits?.benefit?.title,
          manageBenefits?.benefit?.id
        ),
      ],
      walletBalance: manageBenefits?.walletDetails?.totalBalanceAmount,
    });
  };

  useEffect(() => {
    if (!manageBenefits.benefit.id) {
      navigate("/Features/Forbidden");
    }
    setLoading(false);
  }, []);

  return loading ? (
    <></>
  ) : (
    <View alignItems="center" justifyContent="center" bgColor={"#FFFDFA"}>
      <View _web={{ maxW: "412px" }} w="100%" bgColor={"#FFFFFF"}>
        {
          <>
            <Header />
          </>
        }
        <View alignItems="center" justifyConntent="center">
          <View
            alignItems="center"
            justifyConntent="center"
            bgColor={manageBenefits.benefit.color || "#C9E5BF"}
            w="100%"
          >
            <Image
              source={{
                uri:
                  manageBenefits.benefit.displayPrimaryImageLocation || benefit,
              }}
              height="160px"
              width="160px"
              alt=""
            />
          </View>
          <View
            mr="16px"
            alignItems="center"
            position="absolute"
            left="8px"
            top="8px"
            cursor="pointer"
            onClick={() => goBack()}
          >
            <Image
              source={{
                uri: process.env.REACT_APP_AWS_S3_BUCKET_URL + "backArrow.svg",
              }}
              height="24px"
              width="24px"
              alt=""
            />
          </View>
        </View>
        {manageBenefits.benefit.benefitGroup ? (
          <View
            w="100%"
            borderBottomColor="#E5E5E5"
            borderBottomWidth="2px"
            justifyContent="space-between"
            align-items="center"
            flexDirection="row"
            p="10px 8px"
          >
            <View w="70%">
              <Text
                mx="16px"
                my="12px"
                color="#303030"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="18px"
                lineHeight="26px"
              >
                {manageBenefits.benefit.title}
              </Text>
            </View>
            <View w="30%">
              {manageBenefits.benefit.type === "AIRPORT_LOUNGE" ? (
                <View
                  w="100%"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {!Boolean(
                    manageBenefits.benefitsListIdCount[
                      manageBenefits.benefit.id.toString()
                    ]
                  ) &&
                  !manageBenefits.benefitsListId.includes(
                    manageBenefits.benefit.id
                  ) ? (
                    <View
                      flexDirection="row"
                      alignItems="center"
                      backgroundColor="#ffffff"
                      cursor="pointer"
                      shadow="1"
                      borderRadius="4px"
                      px="26px"
                      py="12px"
                      onClick={() => {
                        handleAddBenefitEvent();
                        manageBenefits.benefitsGroupIdCount +
                          Number(manageBenefits.benefit.vouchersToBeIssued) <=
                        manageBenefits.maxLoungeCount
                          ? addLoungeCount()
                          : console.log("");
                      }}
                      opacity={
                        manageBenefits.benefitsGroupIdCount +
                          Number(manageBenefits.benefit.vouchersToBeIssued) <=
                        manageBenefits.maxLoungeCount
                          ? "1"
                          : "0.6"
                      }
                    >
                      <Image
                        source={{
                          uri:
                            process.env.REACT_APP_AWS_S3_BUCKET_URL + "add.svg",
                        }}
                        height="12px"
                        width="12px"
                        alt=""
                      />
                      <Text
                        ml="8px"
                        color="#F37435"
                        fontWeight="700"
                        fontFamily="Quicksand"
                        fontSize="14px"
                        lineHeight="22px"
                      >
                        Add
                      </Text>
                    </View>
                  ) : (
                    <View
                      flexDirection="row"
                      alignItems="center"
                      justifyContent={"space-between"}
                      backgroundColor="#ffffff"
                      cursor="pointer"
                      shadow="1"
                      borderRadius="4px"
                      px="26px"
                      py="12px"
                    >
                      <View onClick={() => removeLoungeCount()}>
                        <Image
                          source={{
                            uri:
                              process.env.REACT_APP_AWS_S3_BUCKET_URL +
                              "minus.svg",
                          }}
                          height="16px"
                          width="16px"
                          alt=""
                        />
                      </View>
                      <Text
                        mx="8px"
                        color="#F37435"
                        fontWeight="700"
                        fontFamily="Quicksand"
                        fontSize="14px"
                        lineHeight="22px"
                      >
                        {
                          manageBenefits.benefitsListIdCount[
                            manageBenefits.benefit.id.toString()
                          ]
                        }
                      </Text>
                      <View
                        onClick={() => {
                          manageBenefits.benefitsGroupIdCount +
                            Number(manageBenefits.benefit.vouchersToBeIssued) <=
                          manageBenefits.maxLoungeCount
                            ? addLoungeCount()
                            : console.log("");
                        }}
                        opacity={
                          manageBenefits.benefitsGroupIdCount +
                            Number(manageBenefits.benefit.vouchersToBeIssued) <=
                          manageBenefits.maxLoungeCount
                            ? "1"
                            : "0.6"
                        }
                      >
                        <Image
                          source={{
                            uri:
                              process.env.REACT_APP_AWS_S3_BUCKET_URL +
                              "add.svg",
                          }}
                          height="12px"
                          width="12px"
                          alt=""
                        />
                      </View>
                    </View>
                  )}
                </View>
              ) : !manageBenefits.benefitsListId.includes(
                  manageBenefits.benefit.id
                ) ? (
                <View alignItems="center" h="100%" justifyContent={"center"}>
                  <View
                    flexDirection="row"
                    alignItems="center"
                    backgroundColor="#ffffff"
                    borderColor="#F37435"
                    borderWidth="1px"
                    borderRadius="4px"
                    px="24px"
                    py="10px"
                    cursor="pointer"
                    onClick={() => {
                      handleAddBenefitEvent();
                      dispatch(
                        setBenefitsAddition({
                          benefits: [
                            ...manageBenefits.benefitsList,
                            manageBenefits.benefit,
                          ],
                          benefitsListId: [
                            ...manageBenefits.benefitsListId,
                            manageBenefits.benefit.id,
                          ],
                          benefitsGroupId: [
                            ...manageBenefits.benefitsGroupId,
                            manageBenefits.benefit.benefitGroup.id,
                          ],
                        })
                      );
                    }}
                  >
                    <Image
                      source={{
                        uri:
                          process.env.REACT_APP_AWS_S3_BUCKET_URL + "add.svg",
                      }}
                      height="12px"
                      width="12px"
                      alt=""
                    />
                    <Text
                      ml="8px"
                      color="#F37435"
                      fontWeight="700"
                      fontFamily="Quicksand"
                      fontSize="14px"
                      lineHeight="18px"
                    >
                      Add
                    </Text>
                  </View>
                </View>
              ) : (
                <View alignItems="center" h="100%" justifyContent={"center"}>
                  <View
                    flexDirection="row"
                    alignItems="center"
                    backgroundColor="#ffffff"
                    borderColor="#F37435"
                    borderRadius="4px"
                    px="16px"
                    cursor="pointer"
                    py="10px"
                    onClick={() => {
                      handleRemoveBenefitEvent();
                      dispatch(
                        setBenefitsAddition({
                          benefits: manageBenefits.benefitsList.filter(
                            (item) => {
                              return item.id !== manageBenefits.benefit.id;
                            }
                          ),
                          benefitsListId: manageBenefits.benefitsListId.filter(
                            (item) => {
                              return item !== manageBenefits.benefit.id;
                            }
                          ),
                          benefitsGroupId:
                            manageBenefits.benefitsGroupId.filter((item) => {
                              return (
                                item !== manageBenefits.benefit.benefitGroup.id
                              );
                            }),
                        })
                      );
                    }}
                  >
                    <Text
                      color="#F37435"
                      fontWeight="700"
                      fontFamily="Quicksand"
                      fontSize="14px"
                      lineHeight="18px"
                    >
                      Added
                    </Text>
                    <Close ml="4px" size="24" color="#F37435" />
                  </View>
                </View>
              )}
            </View>
          </View>
        ) : (
          <View
            w="100%"
            borderBottomColor="#E5E5E5"
            borderBottomWidth="2px"
            justifyContent="space-between"
            align-items="center"
            flexDirection="row"
            p="10px 8px"
          >
            <View w="70%">
              <Text
                mx="16px"
                my="12px"
                color="#303030"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="18px"
                lineHeight="26px"
              >
                {manageBenefits.benefit.title}
              </Text>
            </View>
            {manageBenefits.benefit.type === "AIRPORT_LOUNGE" ? (
              <View
                w="30%"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {!Boolean(
                  manageBenefits.benefitsListIdCount[
                    manageBenefits.benefit.id.toString()
                  ]
                ) &&
                !manageBenefits.benefitsListId.includes(
                  manageBenefits.benefit.id
                ) ? (
                  <View
                    flexDirection="row"
                    alignItems="center"
                    backgroundColor="#ffffff"
                    cursor="pointer"
                    shadow="1"
                    borderRadius="4px"
                    px="26px"
                    py="12px"
                    onClick={() => {
                      handleAddBenefitEvent();
                      manageBenefits.benefitsGroupIdCount +
                        Number(manageBenefits.benefit.vouchersToBeIssued) <=
                      manageBenefits.maxLoungeCount
                        ? addLoungeCount()
                        : console.log("");
                    }}
                    opacity={
                      manageBenefits.benefitsGroupIdCount +
                        Number(manageBenefits.benefit.vouchersToBeIssued) <=
                      manageBenefits.maxLoungeCount
                        ? "1"
                        : "0.6"
                    }
                  >
                    <Image
                      source={{
                        uri:
                          process.env.REACT_APP_AWS_S3_BUCKET_URL + "add.svg",
                      }}
                      height="12px"
                      width="12px"
                      alt=""
                    />
                    <Text
                      ml="8px"
                      color="#F37435"
                      fontWeight="700"
                      fontFamily="Quicksand"
                      fontSize="14px"
                      lineHeight="22px"
                    >
                      Add
                    </Text>
                  </View>
                ) : (
                  <View
                    flexDirection="row"
                    alignItems="center"
                    justifyContent={"space-between"}
                    backgroundColor="#ffffff"
                    cursor="pointer"
                    shadow="1"
                    borderRadius="4px"
                    px="26px"
                    py="12px"
                  >
                    <View onClick={() => removeLoungeCount()}>
                      <Image
                        source={{
                          uri:
                            process.env.REACT_APP_AWS_S3_BUCKET_URL +
                            "minus.svg",
                        }}
                        height="16px"
                        width="16px"
                        alt=""
                      />
                    </View>
                    <Text
                      mx="8px"
                      color="#F37435"
                      fontWeight="700"
                      fontFamily="Quicksand"
                      fontSize="14px"
                      lineHeight="22px"
                    >
                      {
                        manageBenefits.benefitsListIdCount[
                          manageBenefits.benefit.id.toString()
                        ]
                      }
                    </Text>
                    <View
                      onClick={() => {
                        manageBenefits.benefitsGroupIdCount +
                          Number(manageBenefits.benefit.vouchersToBeIssued) <=
                        manageBenefits.maxLoungeCount
                          ? addLoungeCount()
                          : console.log("");
                      }}
                      opacity={
                        manageBenefits.benefitsGroupIdCount +
                          Number(manageBenefits.benefit.vouchersToBeIssued) <=
                        manageBenefits.maxLoungeCount
                          ? "1"
                          : "0.6"
                      }
                    >
                      <Image
                        source={{
                          uri:
                            process.env.REACT_APP_AWS_S3_BUCKET_URL + "add.svg",
                        }}
                        height="12px"
                        width="12px"
                        alt=""
                      />
                    </View>
                  </View>
                )}
              </View>
            ) : !manageBenefits.benefitsListId.includes(
                manageBenefits.benefit.id
              ) ? (
              <View
                alignItems="center"
                h="100%"
                justifyContent={"center"}
                w="30%"
              >
                <View
                  flexDirection="row"
                  alignItems="center"
                  backgroundColor="#ffffff"
                  borderColor="#F37435"
                  borderWidth="1px"
                  borderRadius="4px"
                  px="24px"
                  py="10px"
                  cursor="pointer"
                  onClick={() => {
                    handleAddBenefitEvent();
                    dispatch(
                      setBenefitsAddition({
                        benefits: [
                          ...manageBenefits.benefitsList,
                          manageBenefits.benefit,
                        ],
                        benefitsListId: [
                          ...manageBenefits.benefitsListId,
                          manageBenefits.benefit.id,
                        ],
                        benefitsGroupId: [...manageBenefits.benefitsGroupId],
                      })
                    );
                  }}
                >
                  <Image
                    source={{
                      uri: process.env.REACT_APP_AWS_S3_BUCKET_URL + "add.svg",
                    }}
                    height="12px"
                    width="12px"
                    alt=""
                  />
                  <Text
                    ml="8px"
                    color="#F37435"
                    fontWeight="700"
                    fontFamily="Quicksand"
                    fontSize="14px"
                    lineHeight="18px"
                  >
                    Add
                  </Text>
                </View>
              </View>
            ) : (
              <View
                alignItems="center"
                h="100%"
                justifyContent={"center"}
                w="30%"
              >
                <View
                  flexDirection="row"
                  alignItems="center"
                  backgroundColor="#ffffff"
                  borderColor="#F37435"
                  borderRadius="4px"
                  px="16px"
                  cursor="pointer"
                  py="10px"
                  onClick={() => {
                    handleRemoveBenefitEvent();
                    dispatch(
                      setBenefitsAddition({
                        benefits: manageBenefits.benefitsList.filter((item) => {
                          return item.id !== manageBenefits.benefit.id;
                        }),
                        benefitsListId: manageBenefits.benefitsListId.filter(
                          (item) => {
                            return item !== manageBenefits.benefit.id;
                          }
                        ),
                        benefitsGroupId: [...manageBenefits.benefitsGroupId],
                      })
                    );
                  }}
                >
                  <Text
                    color="#F37435"
                    fontWeight="700"
                    fontFamily="Quicksand"
                    fontSize="14px"
                    lineHeight="18px"
                  >
                    Added
                  </Text>
                  <Close ml="4px" size="24" color="#F37435" />
                </View>
              </View>
            )}
          </View>
        )}
        <View p="16px" alignSelf="center" width="100%" bgColor="#FFFFFF">
          <Details
            data={manageBenefits.benefit.displaySections}
            benefitName={benefitNameWithId(
              manageBenefits?.benefit?.title,
              manageBenefits?.benefit?.id
            )}
          />
        </View>
      </View>
    </View>
  );
};

export default BenefitDetails;
