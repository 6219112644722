import React from "react";
import { View } from "native-base";

const CustomVoucherBackground = ({ children }) => {
  return (
    <View overflow={"hidden"} w={"100%"} margin={"auto"}>
      <View
        borderWidth={"2px"}
        borderColor="#949BA5"
        margin={"auto"}
        position={"relative"}
        borderStyle="dashed"
        borderRadius="4px"
        minH={"54px"}
        w={"100%"}
        marginBottom={"16px"}
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        py="16px"
        px="24px"
      >
        <View
          width="20px"
          height="20px"
          borderStyle="dashed"
          borderRadius={"50%"}
          borderColor="#949BA5"
          borderWidth={"2px"}
          position={"absolute"}
          top={"16px"}
          left={"-10px"}
          backgroundColor={"#fff"}
        ></View>
        <View
          width="20px"
          height="20px"
          borderRightWidth={"0px"}
          borderStyle="dashed"
          borderRadius={"50%"}
          borderColor="#949BA5"
          borderWidth={"2px"}
          position={"absolute"}
          top={"16px"}
          right={"-10px"}
          backgroundColor={"#fff"}
        ></View>
        {children}
      </View>
    </View>
  );
};

export default CustomVoucherBackground;
