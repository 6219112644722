import { Text, View } from "native-base";
import React from "react";
import { ActiveCard, AddingCard, SummaryCard } from "./BenefitsCards";
import benefit from "../../../assets/images/benefit.svg";
import { useNavigate } from "react-router-dom";
import { useTrackEvents } from "../../../hooks/useTrackEvents";
import { TrackEventTypes } from "../../../utils/enums";
import { useSelector } from "react-redux";
const ActiveBenefitSection = (props) => {
  const navigate = useNavigate();
  const trackEventsAdobeAnalytics = useTrackEvents();
  const manageBenefits = useSelector(state => state.manageBenefits);
  const handleAddMore = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.ADD_MORE_BENEFITS,
      walletBalance: manageBenefits?.walletDetails?.totalBalanceAmount
    })    
    navigate("/Features/ManageFeatures/AddFeature")
  };
  return (
    <View p="16px" _web={{ maxW: "412px" }} bgColor="#FFFFFF">
      {
        <View>
          {props.activeBenefits.length > 0 && (
            <View
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <View mb="12px">
                <Text
                  color="#303030"
                  fontWeight="600"
                  fontFamily="Quicksand"
                  fontSize="16px"
                  lineHeight="24px"
                >{`Active Features (${props.activeBenefits.length})`}</Text>
              </View>

              <View
                mb="12px"
                onClick={handleAddMore}
              >
                <Text
                  cursor={"pointer"}
                  color="#F37435"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="14px"
                  lineHeight="22px"
                >
                  +Add more
                </Text>
              </View>
            </View>
          )}
          <View>
            {props.activeBenefits.map((item, index) => (
              <View mb="12px" key={index}>
                <ActiveCard data={item} />
              </View>
            ))}
          </View>
        </View>
      }
    </View>
  );
};

export default ActiveBenefitSection;
