import React, { useEffect, useRef } from "react";
import { View, Text, Image, Modal } from "native-base";

import QRCode from "react-qr-code";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Close from "../../../svg/close";
import { useWindowDimensions } from "react-native-web";
import { useTrackEvents } from "../../../../hooks/useTrackEvents";
import { TrackEventTypes } from "../../../../utils/enums";
import { getPageNameByRoute } from "../../../../utils/functions";

const QrScreen = () => {
  const manageBenefits = useSelector((state) => state.manageBenefits);
  const navigate = useNavigate();
  const dimensions = useWindowDimensions();
  const newWindow = useRef(window);
  const location = useLocation();

  const trackEventsAdobeAnalytics = useTrackEvents();

  useEffect(() => {
    if (!manageBenefits.currentVoucher) {
      navigate("/Features/Forbidden");
    }
  }, []);

  const handleSummaryOfConditions = () => {
    newWindow.current = window.open(
        "https://loungefinder.loungekey.com/en/au-small-finance-bank/conditions-of-use/",
        "_blank",
        "width=600,height=400,left=200,top=200"
      );
      trackEventsAdobeAnalytics({
        linkName: TrackEventTypes.SUMMARY_OF_CONDITIONS,
        linkPageName: getPageNameByRoute(location.pathname),
      })
  }

  const handleGoBack = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.EXIT_QR_CODE_PAGE,
      linkPageName: getPageNameByRoute(location.pathname),
      previousPageName: getPageNameByRoute(location.pathname),
      pageName: getPageNameByRoute("/Features/ManageFeatures/Feature"),
    })
    navigate(-1)
  }

  return (
    <View w="100%" alignItems="center" bgColor={"#FFFFFF"}>
      <View
        alignItems={"center"}
        View
        _web={{ maxW: "412px" }}
        width="100%"
        bgColor={"#FFFFFF"}
      >
        <View
          w="100%"
          alignItems={"center"}
          justifyContent="space-between"
          py="16px"
          borderBottomColor="#EEF0F3"
          borderBottomWidth="2px"
          flexDirection={"row"}
          px="16px"
        >
          <View></View>
          <View>
            <Image
              source={{
                uri: process.env.REACT_APP_AWS_S3_BUCKET_URL + "loungekey.png",
              }}
              height="70px"
              width="116px"
              alt=""
            />
          </View>
          <View cursor="pointer" onClick={handleGoBack}>
            <Close size="24" color="#303030" />
          </View>
        </View>
        <View w="100%" mt="40px" alignItems={"center"}>
          <View p="12px" bgColor={"#FFFFFF"} zIndex={"2"}>
            <QRCode
              value={manageBenefits.currentVoucher.qrCodeData}
              size={
                dimensions.height > 840 && dimensions.width > 365
                  ? "220"
                  : "160"
              }
            />
          </View>
          {/* <View
        bgColor={"#6f42c1"}
        position="absolute"
        height={"600px"}
        width="100%"
        zIndex={"1"}
        top="100px"
      ></View> */}
          <View mt="16px" zIndex={"2"} w="100%" alignItems={"center"}>
            <View w="80%" alignItems={"center"} mt="12px">
              <Text
                color="#303030"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize={
                  dimensions.height > 840 && dimensions.width > 365
                    ? "24px"
                    : "20px"
                }
                lineHeight={
                  dimensions.height > 840 && dimensions.width > 365
                    ? "32px"
                    : "22px"
                }
              >
                Customer Name
              </Text>
              <Text
                mt="2px"
                color="#303030"
                fontWeight="600"
                fontFamily="Quicksand"
                fontSize={
                  dimensions.height > 840 && dimensions.width > 365
                    ? "20px"
                    : "16px"
                }
                lineHeight={
                  dimensions.height > 840 && dimensions.width > 365
                    ? "32px"
                    : "18px"
                }
              >
                {manageBenefits.currentVoucher.name}
              </Text>
            </View>
            <View w="80%" alignItems={"center"} mt="22px">
              <Text
                color="#303030"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize={
                  dimensions.height > 840 && dimensions.width > 365
                    ? "24px"
                    : "20px"
                }
                lineHeight={
                  dimensions.height > 840 && dimensions.width > 365
                    ? "32px"
                    : "24px"
                }
              >
                Barcode Reference No.
              </Text>
              <Text
                mt="2px"
                color="#303030"
                fontWeight="600"
                fontFamily="Quicksand"
                fontSize={
                  dimensions.height > 840 && dimensions.width > 365
                    ? "20px"
                    : "16px"
                }
                lineHeight={
                  dimensions.height > 840 && dimensions.width > 365
                    ? "32px"
                    : "18px"
                }
              >
                {manageBenefits.currentVoucher.voucherCode || ""}
              </Text>
            </View>
            <View w="80%" alignItems={"center"} mt="22px">
              <Text
                color="#303030"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize={
                  dimensions.height > 840 && dimensions.width > 365
                    ? "24px"
                    : "20px"
                }
                lineHeight={
                  dimensions.height > 840 && dimensions.width > 365
                    ? "32px"
                    : "24px"
                }
              >
                Start Date
              </Text>
              <Text
                mt="2px"
                color="#303030"
                fontWeight="600"
                fontFamily="Quicksand"
                fontSize={
                  dimensions.height > 840 && dimensions.width > 365
                    ? "20px"
                    : "16px"
                }
                lineHeight={
                  dimensions.height > 840 && dimensions.width > 365
                    ? "32px"
                    : "18px"
                }
              >
                {moment(manageBenefits.currentVoucher.voucherStartDate).format(
                  "DD MMM YYYY"
                )}
              </Text>
            </View>
            <View w="80%" alignItems={"center"} mt="22px">
              <Text
                color="#303030"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize={
                  dimensions.height > 840 && dimensions.width > 365
                    ? "24px"
                    : "20px"
                }
                lineHeight={
                  dimensions.height > 840 && dimensions.width > 365
                    ? "32px"
                    : "24px"
                }
              >
                End Date
              </Text>
              <Text
                mt="2px"
                color="#303030"
                fontWeight="600"
                fontFamily="Quicksand"
                fontSize={
                  dimensions.height > 840 && dimensions.width > 365
                    ? "20px"
                    : "16px"
                }
                lineHeight={
                  dimensions.height > 840 && dimensions.width > 365
                    ? "32px"
                    : "18px"
                }
              >
                {moment(manageBenefits.currentVoucher.voucherExpiryDate).format(
                  "DD MMM YYYY"
                )}
              </Text>
            </View>
          </View>
        </View>
        <View
          borderTopColor="#EEF0F3"
          borderTopWidth="2px"
          mt={
            dimensions.height > 840 && dimensions.width > 365 ? "48px" : "32px"
          }
          pt="24px"
          width="100%"
          _web={{ maxW: "412px" }}
          bottom="16px"
          px="16px"
          onClick={handleSummaryOfConditions}
        >
          <Text
            color="#303030"
            fontWeight="700"
            fontFamily="Quicksand"
            cursor="pointer"
            fontSize={
              dimensions.height > 840 && dimensions.width > 365
                ? "22px"
                : "18px"
            }
            lineHeight="32px"
          >
            Summary of Conditions
          </Text>
        </View>
      </View>
    </View>
  );
};

export default QrScreen;
